import { useTranslation } from 'react-i18next';

export function ListHeader() {
  const { t } = useTranslation();

  return (
    <div className="grid grid-cols-search items-center w-full text-base font-medium text-font-variant">
      <span>{t('Components.Search.token')}</span>
      <span>{t('Components.Search.networks')}</span>
      <span>{t('Components.Search.price')}</span>
      <span>{t('Components.Search.oneDayVolume')}</span>
      <span>{t('Components.Search.oneDayPerf')}</span>
      <span>{t('Components.Search.oneWeekPerf')}</span>
    </div>
  );
}
