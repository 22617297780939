import { atom, useRecoilValue } from 'recoil';
import { setRecoil, getRecoil } from 'recoil-nexus';

import {
  GetMainVault,
  GetMainVaultSummary,
  GetMainVaultSummary_mainVault,
  GetMainVault_mainVault,
  GetMainVaultAllChains_mainVault,
  Chain,
} from '@gql';
import {
  GET_USER_MAIN_VAULT,
  GET_USER_MAIN_VAULT_SUMMARY,
  GET_USER_MAIN_VAULT_ALL_CHAINS,
} from '@queries/main-vault.queries';
import { Loadable, Loader, chainOf } from '@utils';
import { useWallet } from '@wallet-hooks';

export const refreshMainVaultDataAtom = atom<number>({
  key: 'refreshMainVault',
  default: 1, // Dont start at 0, might be considered as false
});

export const refreshMainVaultData = () => {
  setRecoil(refreshMainVaultDataAtom, getRecoil(refreshMainVaultDataAtom) + 1);
};

export function useMainVault(mustConnect?: boolean, chain?: false): Loader<GetMainVault_mainVault | null>;
export function useMainVault(mustConnect?: boolean, chain?: true): Loader<GetMainVaultAllChains_mainVault | null>;
export function useMainVault(
  mustConnect: boolean,
  chain?: false | Loadable<Chain>,
): Loader<GetMainVault_mainVault | null>;
export function useMainVault(
  mustConnect = true,
  chain: boolean | Loadable<Chain> = false,
): Loader<GetMainVault_mainVault | null> | Loader<GetMainVaultAllChains_mainVault | null> {
  const chain$ = Loader.useWrap(chain);

  const refreshWhenChanges = useRecoilValue(refreshMainVaultDataAtom);

  return useWallet(mustConnect)
    .combine(chain$, (_wallet, _chain) =>
      _wallet.isAuthed ? { address: _wallet.address, currentChain: _chain } : Loader.skipped,
    )
    .query<GetMainVault>(
    [refreshWhenChanges],
    chain === true ? GET_USER_MAIN_VAULT_ALL_CHAINS : GET_USER_MAIN_VAULT,
    ({ address, currentChain }) =>
      address && !currentChain
        ? { chain: chainOf(address) }
        : { chain: typeof currentChain === 'string' ? currentChain : undefined },
  )
    .map(({ mainVault }) => mainVault);
}

export const useMainVaultSummary = (mustConnect: boolean = true): Loader<GetMainVaultSummary_mainVault> => {
  const refreshWhenChanges = useRecoilValue(refreshMainVaultDataAtom);

  return useWallet(mustConnect)
    .map(({ isAuthed }) => (!isAuthed ? Loader.skipped : {}))
    .query<GetMainVaultSummary>([refreshWhenChanges], GET_USER_MAIN_VAULT_SUMMARY, () => ({}))
    .map(({ mainVault }) => mainVault);
};
