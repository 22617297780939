import cls from 'classnames';

import { Cls } from '@utils';

import { ArrowsButton } from './ArrowsButton';

type VerticalProcessLayoutProps = {
  steps: { item: React.ReactNode; key: string }[];
  onArrowClick?: VoidFunction;
} & Cls;

export function VerticalProcessLayout({ steps, onArrowClick, className }: VerticalProcessLayoutProps) {
  return (
    <div className={cls('flex flex-col gap-1', className)}>
      {steps.map((step, i) => (
        <div key={step.key} className="relative">
          {step.item}
          {i < steps.length - 1 && (
            <div className={cls('absolute -bottom-[18px] left-1/2 -ml-4 z-20')}>
              <ArrowsButton onClick={onArrowClick} />
            </div>
          )}
        </div>
      ))}
    </div>
  );
}
