import { format } from 'date-fns';
import { match } from 'ts-pattern';

import { Periods } from '@constants';

const months = ['Jan.', 'Feb.', 'Mar.', 'Apr.', 'May', 'Jun.', 'Jul.', 'Aug.', 'Sep.', 'Oct.', 'Nov.', 'Dec.'];

export enum ExpireUnits {
  Years = 'years',
  Months = 'months',
  Days = 'days',
  Hours = 'hours',
  Minutes = 'minutes',
  Never = 'never',
}

export function formatDuration(eventDate: number) {
  if (!eventDate && eventDate !== 0) return '';

  const durationFromEvent = Math.abs(new Date().getTime() - eventDate);

  return match(durationFromEvent)
    .when(
      d => d < Periods.ONE_HOUR,
      v => `${(v / Periods.ONE_MINUTE).toFixed(0)}m`,
    )
    .when(
      d => d < Periods.ONE_DAY,
      v => `${(v / Periods.ONE_HOUR).toFixed(0)}h`,
    )
    .when(
      d => d < Periods.ONE_DAY * 30,
      v => `${(v / Periods.ONE_DAY).toFixed(0)}d`,
    )
    .when(
      d => d < Periods.ONE_YEAR,
      v => `${(v / (Periods.ONE_DAY * 30)).toFixed(0)}M`,
    )
    .otherwise(v => `${(v / Periods.ONE_YEAR).toFixed(0)}y`);
}

export function isInPeriod(date: string | Date | number | nil, period: Periods, precision = 1) {
  return date && new Date().getTime() - new Date(date).getTime() < period * precision;
}

export const formatTimestamp = (timestamp: number) => {
  if (!timestamp && timestamp !== 0) return '';

  const date = new Date(timestamp);
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  let hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';

  // Convert to 12-hour format
  hours = hours % 12 || 12;

  const formattedDate = `${day} ${month} ${year}, ${hours}:${minutes} ${ampm}`;
  return formattedDate;
};

export const secondsToMinutes = (n: number) => {
  const minutes = Math.floor(n / 60);
  return `${minutes} min${minutes <= 1 ? '' : 's'}`;
};

export const getHoursAndMinutes = (timestamp: number) => {
  if (!timestamp) return '';

  return format(new Date(timestamp), 'HH:mm');
};

export const timeSince = (date: Date) => {
  const seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000);

  let interval = seconds / 31536000;

  if (interval >= 1) {
    return Math.floor(interval) + ` year${interval > 1 ? 's' : ''} ago`;
  }
  interval = seconds / 2592000;
  if (interval >= 1) {
    return Math.floor(interval) + ` month${interval > 1 ? 's' : ''} ago`;
  }
  interval = seconds / 86400;
  if (interval >= 1) {
    return Math.floor(interval) + ` day${interval > 1 ? 's' : ''} ago`;
  }
  interval = seconds / 3600;
  if (interval >= 1) {
    return Math.floor(interval) + ` hour${interval > 1 ? 's' : ''} ago`;
  }
  interval = seconds / 60;
  if (interval >= 1) {
    return Math.floor(interval) + ` min${interval > 1 ? 's' : ''} ago`;
  }
  return Math.floor(seconds) + ` sec${interval > 1 ? 's' : ''} ago`;
};
