import { ParaSwap, SwapSide } from '@paraswap/sdk';
import { APIError } from '@paraswap/sdk/dist/legacy';
import { OptimalRate } from 'paraswap-core';
import { formatUnits } from 'viem';

import { Chain } from '@gql';
import { withoutChain, safeMult, parseNum, chainOf, unique, notNil } from '@utils';
import { networksByChain } from '@utils/networks';

import {
  AggregatorQuoteResponse,
  AggregatorRequest,
  DexAggregator,
  QuoteErrorReasons,
  QuoteFailedError,
} from './dex-aggregator-types';
import { ParaSwapAnswer } from './paraswap-types';

export async function defaultParaSwapFetcher(config: AggregatorRequest): Promise<ParaSwapAnswer | null> {
  const chains = unique(notNil([config.buyToken.id, config.spendToken.id, config.vaultAddress].map(chainOf)));
  if (chains.length !== 1) {
    throw new Error('Incoherent chains');
  }
  const chain = chains[0];
  switch (chain) {
    case Chain.opti:
      return null;
    default:
      break;
  }

  const networkId = networksByChain[chain].chainId;

  const paraSwap = new ParaSwap({
    chainId: networkId,
    fetch: fetch as any,
  });
  const amount = 'spendQty' in config ? config.spendQty : config.boughtQty;
  const swapSide = 'spendQty' in config ? SwapSide.SELL : SwapSide.BUY;
  const priceRoute: OptimalRate | APIError = await paraSwap.getRate(
    withoutChain(config.spendToken.id),
    withoutChain(config.buyToken.id),
    amount.toString(),
    withoutChain(config.vaultAddress),
    swapSide,
    { excludeDEXS: ['0x', 'ParaSwapPool', 'ParaSwapLimitOrders'], partner: 'nested' },
    config.spendToken.decimals,
    config.buyToken.decimals,
  );
  if ('message' in priceRoute) {
    if (priceRoute.message === 'No routes found with enough liquidity') {
      throw new QuoteFailedError(QuoteErrorReasons.INSUFFICIENT_ASSET_LIQUIDITY);
    }
    throw new Error(`Failed to fetch ParaSwap quote: ${priceRoute.message} (${priceRoute.status})`);
  }

  const srcAmount =
    swapSide === SwapSide.BUY ? safeMult(parseNum(priceRoute.srcAmount), 1 + config.slippage) : priceRoute.srcAmount;
  const destAmount =
    swapSide === SwapSide.SELL ? safeMult(parseNum(priceRoute.destAmount), 1 - config.slippage) : priceRoute.destAmount;

  const transaction = await paraSwap.buildTx(
    withoutChain(config.spendToken.id),
    withoutChain(config.buyToken.id),
    srcAmount.toString(),
    destAmount.toString(),
    priceRoute,
    withoutChain(config.vaultAddress),
    'nested.fi',
    undefined,
    undefined,
    undefined,
    { ignoreChecks: true, ignoreGasEstimate: true },
    config.spendToken.decimals,
    config.buyToken.decimals,
  );

  if ('message' in transaction) {
    throw new Error(`Failed to fetch ParaSwap transaction: ${transaction.message} (${transaction.status})`);
  }
  return {
    priceRoute,
    transaction,
  };
}

export async function fetchParaSwap(toFetch: AggregatorRequest): Promise<AggregatorQuoteResponse | null> {
  const response = await defaultParaSwapFetcher(toFetch);

  return response && paraSwapRespToQuoteResp(response);
}

// convert from the ParaSwap specific quote response to a more generic dex aggregator response type
function paraSwapRespToQuoteResp(answer: ParaSwapAnswer): AggregatorQuoteResponse {
  const priceImpact = parseFloat(answer.priceRoute.srcUSD) / parseFloat(answer.priceRoute.destUSD) - 1;
  const price =
    (parseNum(answer.priceRoute.destAmount) * 10n ** parseNum(answer.priceRoute.srcDecimals)) /
    parseNum(answer.priceRoute.srcAmount);

  return {
    aggregator: DexAggregator.Paraswap,
    chainId: answer.priceRoute.network,
    price: formatUnits(price, answer.priceRoute.destDecimals),
    to: answer.transaction.to as HexString,
    data: answer.transaction.data as HexString,
    value: answer.transaction.value,
    protocolFee: answer.priceRoute.partnerFee.toString(),
    buyTokenAddress: answer.priceRoute.destToken as HexString,
    sellTokenAddress: answer.priceRoute.srcToken as HexString,
    buyAmount: answer.priceRoute.destAmount,
    sellAmount: answer.priceRoute.srcAmount,
    allowanceTarget: answer.priceRoute.tokenTransferProxy as HexString,
    estimatedPriceImpact: priceImpact.toString(),
    guaranteedPrice: '0',
  };
}
