import Minisearch from 'minisearch';

import {
  GetUserBalances_myUser_walletBalances as Balances,
  GetUserBalances_myUser_walletBalances_token as Token,
} from '@gql';
import { Loader, holdingQuote, parseAddress } from '@utils';

const balancesSearchIndex = new Minisearch<Token>({
  idField: 'id',
  fields: ['name', 'symbol', 'address'],
  storeFields: ['name', 'symbol', 'id'],
  extractField: (token, fieldName) => {
    if (fieldName === 'address') {
      return parseAddress(token.id).address;
    }
    return Minisearch.getDefault('extractField')(token, fieldName);
  },
});

export function useSearchCoin(balances$: Loader<Balances[]>, searchValue: string): Loader<Balances[]> {
  balances$.onOk(_balances => {
    _balances?.forEach(({ token }) => {
      if (!balancesSearchIndex.has(token.id)) {
        balancesSearchIndex.add(token);
      }
    });
  });

  return Loader.array([balances$, searchValue] as const).map(([_balances, _search]) => {
    if (!_search) return _balances.sort((a, b) => holdingQuote(b) - holdingQuote(a));

    const smallSearch = _search.length === 1;
    const fields = smallSearch ? ['symbol'] : undefined;
    const matches = balancesSearchIndex.search(_search, { prefix: true, fuzzy: 0.2, fields });

    const foundBalances = matches
      .map(match => _balances?.find(_coin => _coin.token.id === match.id))
      .filter(Boolean) as Balances[];
    foundBalances.sort((a, b) => holdingQuote(b) - holdingQuote(a));
    return foundBalances;
  });
}
