import { QRCodeSVG } from 'qrcode.react';

import { Cls } from '@utils';

export type QrCodeProps = {
  bgColor?: string;
  fgColor?: string;
  value: string;
} & Cls;

export function QrCode({ bgColor = 'transparent', fgColor = 'var(--font)', value, className }: QrCodeProps) {
  return <QRCodeSVG bgColor={bgColor} fgColor={fgColor} className={className} value={value} />;
}
