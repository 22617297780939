import { toast } from 'react-toastify';

import { Alert, IAlert } from '@ui-kit/atoms/Alert';

/**
 * @deprecated
 * Toast won't be used anymore
 * Use `useAlertsAnimation` instead
 * */
export const useAlerts = () => {
  const triggerAlert = ({ variant, title, content, link }: IAlert) => {
    toast[variant || 'success'](
      <Alert title={title} variant={variant} content={content} link={link} className="" isFixed />,
      {
        position: toast.POSITION.BOTTOM_RIGHT,
        icon: false,
      },
    );
  };

  return {
    error: ({ title, content, link }: IAlert) => triggerAlert({ variant: 'error', title, content, link }),
    success: ({ title, content, link }: IAlert) => triggerAlert({ variant: 'success', title, content, link }),
    warning: ({ title, content, link }: IAlert) => triggerAlert({ variant: 'warning', title, content, link }),
    info: ({ title, content, link }: IAlert) => triggerAlert({ variant: 'info', title, content, link }),
  };
};
