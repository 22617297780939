import { Skeleton, SkeletonProps } from '@ui-kit/atoms/Skeleton';

export type WithLoadingInnerProps = { isLoading?: boolean } & Pick<
SkeletonProps,
'noAnimation' | 'invisibleChildren' | 'asOverlay'
>;

export function withLoading(Component: React.ComponentType<any>, skeletonClassName?: string) {
  return function WithLoadingComponent(props: WithLoadingInnerProps & any) {
    const { isLoading, noAnimation, invisibleChildren = true, asOverlay = true } = props;
    if (isLoading) {
      return (
        <Skeleton
          className={skeletonClassName}
          noAnimation={noAnimation}
          invisibleChildren={invisibleChildren}
          asOverlay={asOverlay}
        >
          <Component {...props} />
        </Skeleton>
      );
    }
    return <Component {...props} />;
  };
}
