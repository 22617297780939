import BudgetInputEntry, { BudgetInputEntryProps } from '@transactions/components/ui/BudgetInputEntry';
import { MultiPercentConfirm } from '@transactions/components/ui/MultiBudgetConfirm';
import MultiPercentInputEntry, {
  BudgetAllocation,
  MultiPercentInputEntryProps,
} from '@transactions/components/ui/MultiPercentInputEntry';
import { VerticalProcessLayout } from '@transactions/components/ui/VerticalProcessLayout';
import { Budget, BudgetWithQuote, ICoin, Loadable, Loader } from '@utils';

export type MultiBuyUIProps = {
  inputBudget: Loadable<BudgetWithQuote<ICoin>>;
  onInputBudgetChange?: (budget: Budget<ICoin>, isMax?: boolean) => void;
  inputHoldings?: Loadable<bigint>;
  targetAllocations: Loadable<(BudgetAllocation & { isUnfair?: boolean })[]>;
  targetHints?: MultiPercentInputEntryProps['hints'];
  onTargetAllocationChange?: MultiPercentInputEntryProps['onChange'];
  onRemove?: MultiPercentInputEntryProps['onRemove'];
  inputHint?: BudgetInputEntryProps['hint'];
  onEditSelection?: VoidFunction;
  targetHoldings?: MultiPercentInputEntryProps['holdings'];
  onSelectInputCoin?: VoidFunction;
  isConfirmed?: Loadable<boolean>;
  onBackToSelection?: VoidFunction;
  onSwitch?: VoidFunction;
  isOperationLoading?: boolean;
};

export default function MultiBuyUI({
  inputBudget,
  targetAllocations,
  targetHints,
  targetHoldings,
  inputHint,
  onInputBudgetChange,
  onEditSelection,
  onTargetAllocationChange,
  onRemove,
  onSelectInputCoin,
  inputHoldings,
  isConfirmed = false,
  onBackToSelection,
  onSwitch,
  isOperationLoading,
}: MultiBuyUIProps) {
  const inputBudget$ = Loader.useWrap(inputBudget);
  const targetAllocations$ = Loader.useWrap(targetAllocations);
  const _isConfirmed = Loader.useWrap(isConfirmed)
    .match.notOk(() => false)
    .ok(v => v);

  return (
    <VerticalProcessLayout
      onArrowClick={onSwitch}
      steps={[
        {
          key: 'in',
          item: (
            <BudgetInputEntry
              budget={inputBudget$}
              hint={inputHint}
              onChange={onInputBudgetChange}
              onSelectCoin={onSelectInputCoin}
              maxBudget={inputHoldings}
              disabled={isOperationLoading}
            />
          ),
        },
        {
          key: 'out',
          item: _isConfirmed ? (
            <MultiPercentConfirm allocationsWithUnfair={targetAllocations$} onEditSelection={onBackToSelection} />
          ) : (
            <MultiPercentInputEntry
              distribution={targetAllocations$}
              hints={targetHints}
              onEdit={onEditSelection}
              holdings={targetHoldings}
              onChange={onTargetAllocationChange}
              onRemove={onRemove}
            />
          ),
        },
      ]}
    />
  );
}
