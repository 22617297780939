import cls from 'classnames';
import { useTranslation } from 'react-i18next';

import { Button } from '@ui-kit/atoms/Button';
import { Skeleton } from '@ui-kit/atoms/Skeleton';
import { SimpleEntry as Entry } from '@ui-kit/organisms/Entry';
import EntryGroup from '@ui-kit/organisms/EntryGroup';
import { Budget, BudgetWithQuote, Cls, ICoin, Loadable, Loader } from '@utils';
import { wrapComponent } from '@utils/uiKitUtils/wrapComponent';

import BudgetInputEntry, { BudgetInputEntryProps, BudgetInputHint } from './BudgetInputEntry';

export type MultiBudgetInputEntryProps = {
  budgets: Loadable<BudgetWithQuote<ICoin>[]>;
  onChange?: (budget: Budget<ICoin>, isMax?: boolean) => void;
  holdings?: Loadable<Record<ChainAddress, bigint>>;
  hints?: Loadable<Record<ChainAddress, BudgetInputHint>>;
  onRemove?: (budget: Budget<ICoin>) => void;
  onEdit?: () => void;
};

const MultiBudgetGroup = wrapComponent(EntryGroup, ['entries']);

const renderBudgetEntry = (
  budget: Loadable<BudgetWithQuote<ICoin>>,
  onChange: MultiBudgetInputEntryProps['onChange'],
  onRemove: MultiBudgetInputEntryProps['onRemove'],
  hint: BudgetInputEntryProps['hint'],
  onSelectCoin?: VoidFunction,
  holdings?: bigint,
) => {
  return (
    <BudgetInputEntry
      budget={budget}
      onChange={onChange}
      maxBudget={holdings}
      hint={hint}
      onDelete={onRemove}
      autofocus={false}
      type="spot"
      onSelectCoin={onSelectCoin}
    />
  );
};

export function EmptySelectionEntry({ onEdit }: { onEdit: MultiBudgetInputEntryProps['onEdit'] }) {
  const { t } = useTranslation();
  return (
    <Entry isCard key="empty_header" className="!h-14 !min-h-0">
      <div className="flex justify-between items-center">
        <span className="text-font-disabled select-none">Add up to 5 tokens</span>
        <Button size="s" label={t('Transactions.MultiSwap.addTokens')} onClick={onEdit} />
      </div>
    </Entry>
  );
}

export function EditSelectionEntry({
  onEdit,
  tokenCount,
  maxTokenCount = 5,
  className,
}: {
  onEdit: MultiBudgetInputEntryProps['onEdit'];
  tokenCount: number;
  maxTokenCount?: number;
} & Cls) {
  const { t } = useTranslation();
  return (
    <Entry isCard className={cls(className, '!h-fit !min-h-0')}>
      <div className="flex justify-between text-sm select-none">
        <span className="text-font-variant">{`${tokenCount}/${maxTokenCount} tokens`}</span>
        <span role="button" tabIndex={0} className="text-accent" onClick={onEdit}>
          {t('Transactions.MultiSwap.editSelection')}
        </span>
      </div>
    </Entry>
  );
}

export default function MultiBudgetInputEntry({
  budgets,
  onChange,
  holdings,
  hints = {},
  onEdit,
  onRemove,
}: MultiBudgetInputEntryProps) {
  const holdings$ = Loader.useWrap(holdings);
  const hints$ = Loader.useWrap(hints);
  const entries$ = Loader.array([Loader.useWrap(budgets), holdings$, hints$] as const).map(
    ([_budgets, _holdings, _hints]) =>
      _budgets.length > 0
        ? [
          <EditSelectionEntry key="header" onEdit={onEdit} tokenCount={_budgets.length} maxTokenCount={5} />,
          ..._budgets.map(budget =>
            renderBudgetEntry(
              budget,
              onChange,
              onRemove,
              _hints[budget.token.id],
              onEdit,
              _holdings && _holdings[budget.token.id as ChainAddress],
            ),
          ),
        ]
        : Loader.skipped,
  );

  const isError = hints$
    .map(_hints =>
      Object.values(_hints)
        .filter(Boolean)
        .reduce((acc, hint: any) => acc || 'errorMessage' in hint!, false),
    )
    .match.notOk(() => false)
    .ok(v => v);

  const noFlickeringEntries = entries$
    .noFlickering()
    .match.notOk(() => [<EmptySelectionEntry key="header" onEdit={onEdit} />])
    .ok(v => v);

  return (
    <MultiBudgetGroup
      entries={entries$ as any}
      isError={isError}
      onLoad={() => (
        <Skeleton asOverlay>
          <EntryGroup entries={noFlickeringEntries} className="w-full" />
        </Skeleton>
      )}
      onError={e => <Entry isCard>{e.message}</Entry>}
      onSkip={() => <EmptySelectionEntry onEdit={onEdit} />}
    />
  );
}
