import cls from 'classnames';
import { ReactElement, SVGProps, cloneElement, useMemo, useState, useCallback, Fragment } from 'react';
import { match } from 'ts-pattern';

import { Badge } from '@ui-kit/atoms/Badge';
import { SVGIcon, Cls, OneOf } from '@utils';
import { withLoading, WithLoadingInnerProps } from '@utils/uiKitUtils/withLoading';

export type IChipProps = {
  id?: string;
  label?: string;
  // TODO (Jerem): maybe use ReactNode
  leadingItem?: OneOf<
  [
    { badgeLabel: string },
    { Icon: ReactElement<SVGProps<SVGSVGElement>> },
    { Logo: SVGIcon | string | nil },
    { text: string },
    { image: string },
  ]
  >;
  trailingItem?: { Icon: ReactElement<SVGProps<SVGSVGElement>> };
  state?: 'default' | 'active' | 'disabled' | 'inactive';
  transitionOnHoverOnly?: boolean;
  onClick?: (id: string) => void;
  dataCy?: string;
  indication?: string;
  labelClassName?: string;
  link?: string;
  itemsClassName?: string;
} & Cls;

export function SimpleChip({
  id,
  label,
  leadingItem,
  trailingItem,
  state = 'default',
  className,
  transitionOnHoverOnly,
  onClick: clickHandler,
  dataCy,
  indication,
  labelClassName,
  itemsClassName,
  link,
}: IChipProps) {
  const [hasTransition, setHasTransition] = useState(!transitionOnHoverOnly);

  const _leadingItem = useMemo(() => {
    if (leadingItem) {
      if ('text' in leadingItem) {
        return <span className="text-font-variant">{leadingItem.text}</span>;
      }
      if ('badgeLabel' in leadingItem && leadingItem.badgeLabel) {
        return <Badge content={leadingItem.badgeLabel} />;
      }
      if ('Logo' in leadingItem && leadingItem.Logo) {
        return typeof leadingItem.Logo === 'string' ? (
          <img src={leadingItem.Logo} className="w-5 h-5 mr-1" />
        ) : (
          <leadingItem.Logo className="w-5 h-5 mr-1" />
        );
      }
      if ('Icon' in leadingItem && leadingItem.Icon) {
        return cloneElement(leadingItem.Icon, {
          className: cls(
            'w-3.5 h-3.5',
            state === 'disabled' && 'text-font-disabled',
            leadingItem.Icon.props?.className,
          ),
        });
      }
      if ('image' in leadingItem && leadingItem.image) {
        return <img src={leadingItem.image} className="w-6 h-6 rounded-full" />;
      }
    }
  }, [leadingItem]);

  const onClick = useCallback(() => clickHandler?.(id || ''), [clickHandler, id]);

  const Container = link ? 'a' : Fragment;
  const containerProps = link ? { href: link, target: '_blank', rel: 'noreferrer' } : undefined;

  return (
    <Container {...containerProps}>
      <div
        onClick={state === 'disabled' ? undefined : onClick}
        onMouseEnter={() => setHasTransition(true)}
        onMouseLeave={() => setTimeout(() => setHasTransition(false), 150)}
        className={cls(
          'text-base font-medium rounded-2xl flex flex-col justify-between select-none',
          label ? 'px-3' : 'px-1.5',
          leadingItem && 'Logo' in leadingItem && leadingItem.Logo && 'pl-1.5',
          { 'transition-colors': hasTransition },
          match(state)
            .with('default', () => 'bg-surface-muted cursor-pointer hover:bg-surface-muted-hover-font text-font')
            .with('active', () => 'bg-surface-muted-active-font cursor-pointer text-font')
            .with('disabled', () => 'bg-surface-variant-muted cursor-default text-font-disabled')
            .with('inactive', () => 'bg-surface-muted cursor-pointer !text-font-variant')
            .run(),
          className,
        )}
        data-cy={dataCy}
      >
        {indication && <span className="text-sm text-font-variant font-normal">{indication}</span>}
        <div className={cls(itemsClassName, 'flex justify-center items-center gap-1 w-fit py-1.5')}>
          {_leadingItem}
          {label && <span className={cls('whitespace-nowrap', labelClassName)}>{label}</span>}
          {trailingItem &&
            trailingItem.Icon &&
            cloneElement(trailingItem.Icon, {
              className: cls(label ? 'w-3.5 h-3.5' : 'w-5 h-5', trailingItem.Icon.props?.className),
            })}
        </div>
      </div>
    </Container>
  );
}

export function Chip(props: IChipProps & WithLoadingInnerProps) {
  return withLoading(SimpleChip)(props);
}
