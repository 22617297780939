/* eslint-disable max-len */
import cls from 'classnames';
import { useRef } from 'react';
import { match } from 'ts-pattern';

import { Loadable, Loader, SVGIcon } from '@utils';

import { Skeleton } from './Skeleton';

export type ButtonSize = 's' | 'm' | 'l';
export type ButtonVariant =
  | 'accent'
  | 'surface'
  | 'ghost'
  | 'info'
  | 'success'
  | 'danger'
  | 'surface-accent'
  | 'surface-inactive'
  | 'success-muted'
  | 'danger-muted'
  | 'warning'
  | 'elevated'
  // Brand variants
  | 'lime'
  | 'orange'
  | 'blue'
  | 'pink'
  | 'cyan'
  | 'red'
  | 'lilac';

export interface IButton {
  className?: string;
  iconClassName?: string;
  /** @default m */
  size?: ButtonSize;
  /** @default accent */
  variant?: ButtonVariant;
  Icon?: SVGIcon;
  label?: string;
  /** @default inside */
  labelPosition?: 'inside' | 'outside';
  disabled?: boolean;
  type?: 'submit' | 'reset' | 'button';
  dataCy?: string;
  onClick?: Loadable<() => any>;
  fullWidth?: boolean;
  isLoading?: boolean;
  freezeLoading?: boolean;
  isLoadingLabel?: string;
}

const disableClasses = 'text-font-disabled bg-surface-disabled';

export const getVariantClasses = (variant: ButtonVariant, disabled?: boolean) =>
  match(variant)
    .with('accent', () =>
      disabled
        ? disableClasses
        : cls(
          'bg-accent text-font-on-accent',
          'hover:bg-accent-hover-font-on-accent focus:bg-accent-hover-font-on-accent active:bg-accent-hover-font-on-accent',
        ),
    )
    .with('surface', () =>
      disabled
        ? disableClasses
        : cls(
          'text-font bg-surface-muted hover:bg-surface-muted-hover-font',
          'active:bg-surface-muted-active-font focus:bg-surface-muted-hover-font',
        ),
    )
    .with('ghost', () =>
      disabled
        ? 'text-font-disabled'
        : cls('text-accent hover:bg-hover-accent', 'active:bg-hover-accent focus:bg-hover-accent'),
    )
    .with('surface-accent', () =>
      disabled
        ? disableClasses
        : cls(
          'bg-surface-accent text-accent',
          'hover:bg-surface-accent-hover-accent focus:bg-surface-accent-hover-accent active:bg-surface-accent-hover-accent',
        ),
    )
    .with('info', () =>
      disabled
        ? disableClasses
        : cls(
          'text-info bg-surface-info',
          'hover:bg-surface-info-hover-accent active:bg-surface-info-hover-accent focus:bg-surface-info-hover-accent',
        ),
    )
    .with('success-muted', () =>
      disabled
        ? disableClasses
        : cls(
          'text-success bg-surface-success',
          'hover:bg-surface-success-hover-success active:bg-surface-success-hover focus:bg-surface-success-hover',
        ),
    )
    .with('danger-muted', () =>
      disabled
        ? disableClasses
        : cls(
          'text-danger bg-surface-danger',
          'hover:bg-surface-danger-hover-danger active:bg-surface-danger-hover focus:bg-surface-danger-hover',
        ),
    )
    .with('surface-inactive', () =>
      disabled
        ? disableClasses
        : cls(
          'text-font-variant bg-surface-muted',
          'hover:bg-surface-muted-hover-font active:bg-surface-muted-hover focus:bg-surface-muted-hover',
        ),
    )
    .with('success', () =>
      disabled
        ? disableClasses
        : cls(
          'bg-success text-font-on-accent',
          'hover:bg-success-hover-font-on-accent active:bg-success-hover-font-on-accent focus:bg-success-hover-font-on-accent',
        ),
    )
    .with('warning', () =>
      disabled
        ? disableClasses
        : cls(
          'bg-warning text-font-on-accent',
          'hover:bg-warning-hover-font-on-accent active:bg-warning-hover-font-on-accent focus:bg-warning-hover-font-on-accent',
        ),
    )
    .with('danger', () =>
      disabled
        ? disableClasses
        : cls(
          'bg-danger text-font-on-accent',
          'hover:bg-danger-hover-font-on-accent active:bg-danger-hover-font-on-accent focus:dbg-anger-hover-font-on-accent',
        ),
    )
    .with('elevated', () =>
      disabled
        ? disableClasses
        : cls(
          'bg-surface-elevated text-font',
          'hover:bg-surface-elevated-hover-font active:surface-elevated-hover-font focus:surface-elevated-hover-font',
        ),
    )
    // Brand variants
    .with('lime', () =>
      disabled
        ? disableClasses
        : cls(
          'bg-theme-lime-muted text-theme-lime',
          'hover:bg-interaction-lime-muted-hover-lime active:bg-interaction-lime-muted-hover-lime',
        ),
    )
    .with('orange', () =>
      disabled
        ? disableClasses
        : cls(
          'bg-theme-orange-muted text-theme-orange',
          'hover:bg-interaction-orange-muted-hover-orange active:bg-interaction-orange-muted-hover-orange',
        ),
    )
    .with('blue', () =>
      disabled
        ? disableClasses
        : cls(
          'bg-theme-blue-muted text-theme-blue',
          'hover:bg-interaction-blue-muted-hover-blue active:bg-interaction-blue-muted-hover-blue',
        ),
    )
    .with('pink', () =>
      disabled
        ? disableClasses
        : cls(
          'bg-theme-pink-muted text-theme-pink',
          'hover:bg-interaction-pink-muted-hover-pink active:bg-interaction-pink-muted-hover-pink',
        ),
    )
    .with('cyan', () =>
      disabled
        ? disableClasses
        : cls(
          'bg-theme-cyan-muted text-theme-cyan',
          'hover:bg-interaction-cyan-muted-hover-cyan active:bg-interaction-cyan-muted-hover-cyan',
        ),
    )
    .with('red', () =>
      disabled
        ? disableClasses
        : cls(
          'bg-theme-red-muted text-theme-red',
          'hover:bg-interaction-red-muted-hover-red active:bg-interaction-red-muted-hover-red',
        ),
    )
    .with('lilac', () =>
      disabled
        ? disableClasses
        : cls(
          'bg-theme-lilac-muted text-theme-lilac',
          'hover:bg-interaction-lilac-muted-hover-lilac active:bg-interaction-lilac-muted-hover-lilac',
        ),
    )
    .exhaustive();

export function Button({
  className,
  iconClassName,
  size = 'm',
  variant = 'accent',
  Icon,
  label,
  disabled,
  type = 'button',
  dataCy,
  onClick,
  fullWidth = false,
  isLoading,
  freezeLoading,
  isLoadingLabel,
  labelPosition = 'inside',
}: IButton) {
  const ref = useRef<HTMLButtonElement>(null);

  const isRounded: boolean = !!Icon && (!label || (!!label && labelPosition === 'outside'));
  const variantClasses = getVariantClasses(variant, disabled);

  const sizeClasses = match(size)
    .with('s', () => `!h-8 text-base ${isRounded ? 'w-8 p-0' : 'w-min px-4 py-0'}`)
    .with('m', () => `!h-10 text-base ${isRounded ? 'w-10 p-0' : 'w-min px-5 py-0'}`)
    .with('l', () => `!h-12 text-lg ${isRounded ? 'w-12 p-0' : 'w-min px-6 py-0'}`)
    .exhaustive();
  const iconClasses = match(size)
    .with('s', () => `${isRounded ? 'w-4 h-4' : 'w-3.5 h-3.5'}`)
    .with('m', () => `${isRounded ? 'w-5 h-5' : 'w-3.5 h-3.5'}`)
    .with('l', () => `${isRounded ? 'w-6 h-6' : 'w-4 h-4'}`)
    .exhaustive();

  const fontClasses = match(size)
    .with('s', () => 'font-youth-black text-base font-black')
    .with('m', () => 'font-youth-black text-base font-black')
    .with('l', () => 'font-youth-black text-lg font-black')
    .exhaustive();

  const outsideLabelColorClasses = match(variant)
    .with('accent', 'surface-accent', 'ghost', () => 'text-accent')
    .with('surface', () => 'text-font')
    .with('info', () => 'text-info')
    .with('danger-muted', () => 'text-danger')
    .with('success-muted', () => 'text-success')
    .with('surface-inactive', () => 'text-font-variant')
    .with('success', 'warning', 'danger', () => 'text-font-on-accent')
    .with('elevated', () => 'text-font')
    .with('lime', () => 'text-lime')
    .with('orange', () => 'text-orange')
    .with('blue', () => 'text-blue')
    .with('pink', () => 'text-pink')
    .with('cyan', () => 'text-cyan')
    .with('red', () => 'text-red')
    .with('lilac', () => 'text-lilac')
    .exhaustive();

  const commonClasses = cls(
    'flex items-center gap-1.5 justify-center transition-colors whitespace-nowrap !rounded-full',
    sizeClasses,
    fontClasses,
    fullWidth && '!w-full',
  );

  const clickLoader = Loader.useWrap(onClick);
  const performOnClick = clickLoader.makeCallback(s => s?.());

  return isLoading || clickLoader.isLoading ? (
    <Skeleton
      className={cls(commonClasses, className)}
      label={isLoadingLabel ? `${isLoadingLabel}...` : ''}
      noAnimation={freezeLoading}
    />
  ) : (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      className={cls(
        'flex flex-col items-center gap-1 h-min w-min p-0 select-none',
        'border-none hover:border-none !outline-none hover:outline-none focus:!outline-none active:!outline-none',
        fullWidth && '!w-full',
        { 'cursor-pointer': !disabled },
        labelPosition === 'inside' && 'rounded-full',
        className,
      )}
      ref={ref}
      onClick={() => !disabled && performOnClick()}
      onMouseLeave={() => ref.current?.blur()}
      disabled={disabled}
      data-cy={dataCy}
    >
      <div className={cls(commonClasses, variantClasses, fullWidth && '!w-full')}>
        {Icon && <Icon className={cls('fill-current flex-shrink-0 my-auto', iconClasses, iconClassName)} />}
        {label && labelPosition === 'inside' && <span className="my-auto">{label}</span>}
      </div>
      {label && labelPosition === 'outside' && (
        <span className={cls('text-sm font-medium', outsideLabelColorClasses)}>{label}</span>
      )}
    </button>
  );
}
