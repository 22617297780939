import { useState } from 'react';

import { useEventListener } from './useEventListener';
import { useIsomorphicLayoutEffect } from './useIsomorphicLayoutEffect';

interface Position {
  x: number;
  y: number;
}

export function useElementPosition<T extends HTMLElement = HTMLDivElement>({
  deps,
}: {
  deps: any[];
}): [(node: T | null) => void, Position] {
  const [ref, setRef] = useState<T | null>(null);
  const [position, setPosition] = useState<Position>({
    x: 0,
    y: 0,
  });
  const [scrollPosition, setScrollPosition] = useState({
    x: 0,
    y: 0,
  });

  useIsomorphicLayoutEffect(() => {
    getPosition();
    getScrollPosition();
  }, [...deps]);

  const handleResize = () => {
    getPosition();
  };

  const handleScroll = () => {
    getScrollPosition();
  };

  const getPosition = () => {
    setPosition({
      x: ref?.offsetLeft || 0,
      y: ref?.offsetTop || 0,
    });
  };

  const getScrollPosition = () => {
    setScrollPosition({
      x: window.scrollX,
      y: window.scrollY,
    });
  };

  useEventListener('resize', handleResize);
  useEventListener('scroll', handleScroll);

  useIsomorphicLayoutEffect(() => {
    handleResize();
  }, []);

  return [
    setRef,
    {
      x: position.x - scrollPosition.x,
      y: position.y - scrollPosition.y,
    },
  ];
}
