import { gql } from '@apollo/client';

import { nullish, parseNum } from './basic-utils';
import { Loadable, Loader } from './loader';
import { IAsset, ICoin, assetDecimals, isCoin, toNumber, withoutChain, isChainAddress } from './utils';
import { LoadTokenBalance, LoadTokenBalanceVariables } from '../gql/.gql-types-autogen';
import { TokenSummary } from '../gql/fragments';

/**
 * Multiplies a bignumber by a non integer ratio
 *
 * When n is bignumber = 1000 * 10 ** 18
 * formatEther(mulRatio(n, 1.00))) 👉 '10000'
 * formatEther(mulRatio(n, 1.00002))) 👉 '10000.02'
 * formatEther(mulRatio(n, 0.0000003))) 👉 '0.0003'
 * formatEther(mulRatio(n, 398392))) 👉 '3983922000'
 *
 */
export function safeMult(bn: bigint, ratio: number): bigint;
export function safeMult(bn: bigint | nil, ratio: number | nil): bigint | null;
export function safeMult(bn: bigint | nil, ratio: number | nil): bigint | null {
  if (!bn || ratio === 1) {
    return bn ?? null;
  }
  if (nullish(ratio)) {
    return null;
  }

  // Math.log10(0) will be infinity, ratio of 0 will be 0.
  if (ratio <= 0) {
    return 0n;
  }

  // try to keep 10 significant decimals in the multiplicator ratio
  let precision = 10 - Math.round(Math.log10(ratio));
  // at least try to keep 2 decimals (if ratio is large)
  if (precision < 2) {
    precision = 2;
  }

  // In order to avoid bignumber overflow, precision + log10(ratio) must be less than 15. (see BigNumber.from doc)
  if (precision + Math.log10(ratio) >= 15) {
    precision = Math.floor(15 - Math.log10(ratio) - 1);
  }

  // the ratio, raised to the precision we want
  const largeRatio = ratio * 10 ** precision;

  // BigNumber.from won't throw overflow because we dimensionned earlier to be under 0x1fffffffffffff
  const ratioWithPrecision = parseNum(Math.floor(largeRatio));

  return precision >= 0
    ? (bn * ratioWithPrecision) / 10n ** parseNum(precision)
    : bn * ratioWithPrecision * 10n ** parseNum(-precision);
}

export function divideBigNumbers(a: bigint, b: bigint, precision = 18): number {
  const bigResult = (a * 10n ** parseNum(precision)) / b;
  return toNumber(bigResult, precision);
}

export interface Budget<T extends IAsset | nil = IAsset | nil> {
  /** Amount, in base unit (i.e. something like 123400000000000000000) */
  amtBase: bigint;
  /** Sell token */
  token: T;
}

export type TokenBudget = Budget<ICoin>;

export function isTokenBudget(iBudget: Budget<IAsset | nil>): iBudget is TokenBudget {
  return isChainAddress(iBudget?.token?.id);
}

export interface BudgetWithQuote<T extends IAsset | nil = IAsset | nil> extends Budget<T> {
  quote: number;
}

export function budgetNum(budget: Budget | nil): number {
  return budget ? toNumber(budget.amtBase, assetDecimals(budget.token)) ?? 0 : 0;
}

export function budgetNumValue(budget: BudgetWithQuote | nil): number {
  return budgetNum(budget) * (budget?.quote || 0);
}

/**
 * return the bigInt or the bounds if they are reached
 */
export function boundedValue<T extends number | bigint = number>(value: T, min: T, max: T): T {
  // eslint-disable-next-line no-nested-ternary
  return value < min ? min : value > max ? max : value;
}

/**
 * Truncates a bignumber to a given number of decimals.
 * @param amt - The amount to truncate.
 * @param tokenDecimals - The number of decimals in the token.
 * @param decimals - The number of decimals to truncate to.
 * @returns The truncated amount.
 */
export function truncAmt(amt: bigint, tokenDecimals: number, decimals: number): bigint {
  const diff = tokenDecimals - decimals;
  if (diff > 0) {
    // to many decimals
    const divider = 10n ** BigInt(diff);
    return (amt / divider) * divider;
  }
  return amt; // no need to truncate the number of decimals is ok
}

/**
 * Truncates the amount in a budget to a given number of decimals.
 * @param budget - The budget to truncate.
 * @param decimals - The number of decimals to truncate to.
 * @returns The budget with the truncated amount.
 */
export function truncBudget(budget: Budget<ICoin>, decimals: number): Budget<ICoin> {
  return {
    amtBase: truncAmt(budget.amtBase, assetDecimals(budget.token), decimals),
    token: budget.token,
  };
}

/**
 * Convert a bignumber qty for one token's to another token's decimals
 * @param amt the amount to convert expressed in the fromDecimals
 * @param fromDecimals
 * @param toDecimals
 */
export function convertAmt(amt: bigint | undefined, fromDecimals: number, toDecimals: number): bigint {
  if (!amt) return 0n;
  if (fromDecimals === toDecimals) return amt;
  if (fromDecimals > toDecimals) {
    const diff = fromDecimals - toDecimals;
    const divider = 10n ** BigInt(diff);
    return amt / divider;
  }
  const diff = toDecimals - fromDecimals;
  const multiplier = 10n ** BigInt(diff);
  return amt * multiplier;
}

export function isNativeToken(token: ICoin | ChainAddress | nil): boolean {
  const id = typeof token === 'string' ? token : token?.id;
  if (!id) {
    return false;
  }
  return withoutChain(id) === '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee';
}

export function useMybalance(token: Loadable<ChainAddress | IAsset | nil>): Loader<BudgetWithQuote> {
  return Loader.useWrap(token)
    .map(x => (x && (typeof x === 'string' || isCoin(x)) ? x : Loader.skipped))
    .query<LoadTokenBalance, LoadTokenBalanceVariables>(
    gql`
        query LoadTokenBalance($token: ERC20!) {
          myUser {
            walletBalances(tokens: [$token]) {
              qty
            }
          }
          token(id: $token) {
            ...ITokenSummary
            quote
          }
        }
        ${TokenSummary}
      `,
    x => ({ token: typeof x === 'string' ? x : x.id }),
  )
    .map<BudgetWithQuote>(x => ({
    amtBase: parseNum(x.myUser?.walletBalances?.[0]?.qty) ?? 0n,
    quote: x.token?.quote ?? 0,
    token: x.token,
  }));
}
