import { gql } from '@apollo/client';

import { TrendingTokens, TrendingTokensVariables, SearchTokens_tokensAggregated, Chain } from '@gql';
import { Loader } from '@utils';

const TRENDING_TOKENS = gql`
  query TrendingTokens($limit: Int!, $chains: [Chain!]) {
    trendingTokensAggregated(limit: $limit, chains: $chains) {
      id
      logo
      symbol
      name
      chainIds
      price: quote
      oneDayVolume: volume(timeframe: p1d)
      oneDayPerf: curves(timeframe: p1d) {
        totalPerf
      }
      oneWeekPerf: curves(timeframe: p1w) {
        totalPerf
      }
    }
  }
`;

export const useTrendingTokens = (
  params: { chain: Chain | nil },
  refetchWhenChanges?: any[],
): Loader<SearchTokens_tokensAggregated[] | nil> => {
  return Loader.query<TrendingTokens, TrendingTokensVariables>(TRENDING_TOKENS, {
    variables: {
      limit: 20,
      chains: params.chain ? [params.chain] : undefined,
    },
    refetchWhenChanges,
  }).map(({ trendingTokensAggregated }) => trendingTokensAggregated?.map(token => ({ ...token })));
};
