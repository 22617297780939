import cls from 'classnames';
import { useState, useEffect, useRef } from 'react';

import { useElementSize } from '@hooks/useElementSize';
import { IProgressBar, ProgressBar } from '@ui-kit/atoms/ProgressBar';
import { Cls } from '@utils';
import { scaleInterval } from '@utils/chartUtils';
import { NumberFormatType, formatNumber } from '@utils/numbers/NumberFormat';

import { Chip } from './Chip';
import { getSupportingLabels } from './Slider.utils';

const DOT_SIZE = 16;
export type ISliderProps = {
  pointsCount: number;
  maxValue: number;
  minValue: 0 | 1;
  value?: number;
  hasMultiplicationSign?: boolean;
  onChange: (value: number) => void;
  totalCrantsCount?: IProgressBar['totalCrantsCount'];
  isDisabled?: boolean;
  hasBubble?: boolean;
  format?: NumberFormatType;
  precision?: number;
} & Cls;

export function Slider({
  maxValue,
  pointsCount,
  minValue,
  value,
  hasMultiplicationSign,
  totalCrantsCount,
  onChange,
  isDisabled,
  hasBubble = true,
  format,
  className,
  precision = 2,
}: ISliderProps) {
  const [sliderPosition, setSliderPosition] = useState(
    typeof value === 'number' ? scaleInterval(value, minValue, maxValue, 0, 100) : 0,
  );

  const sliderRef = useRef<HTMLDivElement>(null);

  const [_, chipRef, { width: chipWidth }] = useElementSize();

  const handleMouseMove = (event: MouseEvent) => {
    if (sliderRef && sliderRef.current) {
      const sliderWidth = sliderRef.current.clientWidth;
      const offsetX = event.clientX - sliderRef.current.getBoundingClientRect().left;
      const percentage = (offsetX / sliderWidth) * 100;
      const _value = Math.max(0, Math.min(100, percentage));
      setSliderPosition(_value);
      onChange(Number(scaleInterval(_value, 0, 100, minValue, maxValue).toFixed(precision)));
    }
  };

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    if (isDisabled) {
      return;
    }
    e.stopPropagation();
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseUp = (e: MouseEvent) => {
    e.stopPropagation();
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  useEffect(() => {
    if (typeof value === 'number' || value === '') {
      setSliderPosition(scaleInterval(value, minValue, maxValue, 0, 100));
    }
  }, [maxValue, minValue, value]);

  return (
    <div className={cls('relative flex flex-col gap-2 pb-4 w-full', hasBubble && 'pt-[28px]', className)}>
      {hasBubble ? (
        <div
          ref={chipRef}
          className="absolute -top-2 -translate-x-2"
          style={{ left: `calc(${sliderPosition}% - ${chipWidth / 2 - DOT_SIZE / 2}px)` }}
        >
          <Chip
            label={`${hasMultiplicationSign ? 'x' : ''}${formatNumber(
              scaleInterval(sliderPosition, 0, 100, minValue, maxValue),
              format ?? 'price',
            )}`}
          />
        </div>
      ) : null}
      <div ref={sliderRef} onMouseDown={handleMouseDown}>
        {!isDisabled ? (
          <div
            style={{ left: `${sliderPosition}%` }}
            className={cls(
              'absolute h-4 w-4 flex-shrink-0 rounded-full -translate-x-2 cursor-pointer z-20 bg-font',
              hasBubble && 'top-7',
            )}
          />
        ) : null}
        <ProgressBar
          valuePercent={sliderPosition}
          totalCrantsCount={totalCrantsCount ?? pointsCount}
          theme={isDisabled ? 'disabled' : undefined}
        />
      </div>
      <div className="flex justify-between font-medium text-sm text-font-variant px-1">
        {getSupportingLabels(maxValue, pointsCount, minValue).map(label => (
          <div key={label} className="flex flex-col relative items-center">
            <span className="absolute w-6 text-center">{format ? formatNumber(label, format) : label}</span>
          </div>
        ))}
      </div>
    </div>
  );
}
