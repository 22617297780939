import cls from 'classnames';
import { ReactNode } from 'react';

import { Cls } from '@utils';

import { ILabelItem, LabelItem } from './LabelItem';

export interface ILabelItemList {
  labelItems: ILabelItem[];
  headerItem?: ReactNode;
  hideDividers?: boolean;
  contentClassname?: string;
  isLoading?: boolean;
}

export const LabelItemList = ({
  labelItems,
  headerItem,
  hideDividers,
  className,
  contentClassname,
  isLoading,
}: ILabelItemList & Cls) => {
  const margin = hideDividers ? 'mt-4' : 'mt-2';

  const filteredLabelItems = isLoading ? labelItems : labelItems.filter((it): it is ILabelItem => !!it.item);

  return (
    <div className={cls('flex flex-col', className)}>
      {headerItem}
      <div
        className={cls(
          'flex flex-col',
          { [margin]: headerItem },
          hideDividers ? '' : 'divide-y divide-outline',
          hideDividers ? 'gap-4' : 'gap-6',
          contentClassname,
        )}
      >
        {filteredLabelItems.map((item: ILabelItem) => (
          <LabelItem
            key={item.label}
            {...item}
            vertical={false}
            className={cls(item?.className)}
            isLoading={isLoading}
          />
        ))}
      </div>
    </div>
  );
};
