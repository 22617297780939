import { Budget, ICoin, Loader, isCoin, isNativeToken, withoutChain, Loadable } from '@utils';

export interface RawDeposit {
  type: 'deposit';
  script: string;
  token: ICoin;
  amount: bigint;
}

export function useDeposit(budget: Loadable<Budget>): Loader<RawDeposit> {
  return Loader.useWrap(budget).map(x => {
    if (!x.token) {
      return Loader.error('No token selected');
    }
    if (!isCoin(x.token)) {
      return Loader.error('Cannot deposit FIAT');
    }
    const isNative = isNativeToken(x.token);
    const args = `${x.amtBase.toString(10)}u ${withoutChain(x.token.id)}`;
    return {
      type: 'deposit',
      // readeable version:
      //     deposit(5 usdc);
      // actually generates things like:
      //     deposit(3989348934893u 0x2791bca1f2de4661ed88a30c99a7a9449aa84174);
      script: isNative ? `vault.depositNative(${args});` : `vault.deposit(${args});`,
      amount: x.amtBase,
      token: x.token,
    } satisfies RawDeposit;
  });
}
