import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { match } from 'ts-pattern';

import { DropdownEntryType } from '@components/Dropdown';
import { defaultTokens } from '@constants';
import { TransactionType, TxFilter } from '@gql';
import { useTokenInfo, useTokensInfo } from '@hooks/useTokensInfo';
import { BridgeIcon, WithdrawIcon, DepositIcon, SwapCoinIcon, CreateVaultIcon } from '@ui-kit/Icons';
import { ChipGroup } from '@ui-kit/organisms/ChipGroup';
import { TokenLogo } from '@ui-kit/organisms/TokenLogo';
import { capitalize, chainOf } from '@utils';
import { allNetworks } from '@utils/networks';

export type TransactionManagerFiltersProps = {
  filters?: TxFilter | null;
  onFilterChange: <K extends keyof TxFilter>(filter: K, value: TxFilter[K]) => void;
};

export const TransactionManagerFilters = ({ filters, onFilterChange }: TransactionManagerFiltersProps) => {
  const { t } = useTranslation();

  const nativeTokens = allNetworks.filter(it => !it.disabled).map(net => defaultTokens[net.chain]);
  const nativeTokens$ = useTokensInfo(nativeTokens).noFlickering();
  const inputTokenSelection = useTokenInfo(filters?.inputToken, true)
    .noFlickering()
    .match.notOk(() => null)
    .ok(x => `${x.symbol} (${chainOf(x.id)})`);
  const outputTokenSelection = useTokenInfo(filters?.outputToken, true)
    .noFlickering()
    .match.notOk(() => null)
    .ok(x => `${x.symbol} (${chainOf(x.id)})`);

  const typeOptions = useMemo(
    () =>
      [
        TransactionType.swap,
        TransactionType.deposit,
        TransactionType.withdraw,
        TransactionType.bridge,
        TransactionType.vaultCreation,
      ].map(type => {
        const typeEntry = match(type)
          .with(TransactionType.swap, () => ({
            label: t('Transactions.TransactionManager.filters.type.swap'),
            icon: <SwapCoinIcon className="h-4 w-4" />,
          }))
          .with(TransactionType.deposit, () => ({
            label: t('Transactions.TransactionManager.filters.type.deposit'),
            icon: <DepositIcon className="h-4 w-4" />,
          }))
          .with(TransactionType.withdraw, () => ({
            label: t('Transactions.TransactionManager.filters.type.withdraw'),
            icon: <WithdrawIcon className="h-4 w-4" />,
          }))
          .with(TransactionType.bridge, () => ({
            label: t('Transactions.TransactionManager.filters.type.bridge'),
            icon: <BridgeIcon className="h-4 w-4" />,
          }))
          .with(TransactionType.vaultCreation, () => ({
            label: t('Transactions.TransactionManager.filters.type.vaultCreation'),
            icon: <CreateVaultIcon className="h-4 w-4" />,
          }))
          .run();
        return {
          id: type,
          selectLabel: typeEntry.label,
          content: { top: typeEntry.label },
          leadingItem: typeEntry.icon,
        };
      }),
    [t],
  );

  const inputTokenOptions = nativeTokens$.match
    .notOk(() => [] as DropdownEntryType[])
    .ok(_nativeTokens =>
      [..._nativeTokens].map(it => ({
        id: it.id,
        content: { top: it.symbol },
        leadingItem: <TokenLogo token={it} withBadge className="w-6 h-6" />,
        noMinHeight: true,
        className: '!py-1',
      })),
    );

  const outputTokenOptions = nativeTokens$.match
    .notOk(() => [] as DropdownEntryType[])
    .ok(_nativeTokens =>
      [..._nativeTokens].map(it => ({
        id: it.id,
        content: { top: it.symbol },
        leadingItem: <TokenLogo token={it} withBadge className="w-6 h-6" />,
        noMinHeight: true,
        className: '!py-1',
      })),
    );

  return (
    <div className="flex gap-2 flex-wrap">
      <ChipGroup
        items={[
          {
            id: 'type',
            type: 'dropdown',
            itemProps: {
              label: capitalize(filters?.type) || t('Transactions.TransactionManager.filters.type.title'),
              entries: typeOptions,
              alignment: 'bottomLeft',
              clearSelectionLabel: t('Transactions.TransactionManager.filters.type.allTypes'),
              allowClearSelection: true,
              onSelectEntries: ([selected]) => onFilterChange('type', selected?.id as TransactionType),
            },
          },
        ]}
      />
      <ChipGroup
        items={[
          {
            id: 'type',
            type: 'dropdown',
            itemProps: {
              label: capitalize(inputTokenSelection) || t('Transactions.TransactionManager.filters.tokens.input'),
              entries: inputTokenOptions,
              alignment: 'bottomLeft',
              clearSelectionLabel: t('Transactions.TransactionManager.filters.tokens.clearTokens'),
              allowClearSelection: true,
              onSelectEntries: ([selected]) =>
                onFilterChange('inputToken', selected ? (selected.id as ERC20) : undefined),
            },
          },
        ]}
      />
      <ChipGroup
        items={[
          {
            id: 'type',
            type: 'dropdown',
            itemProps: {
              label: capitalize(outputTokenSelection) || t('Transactions.TransactionManager.filters.tokens.output'),
              entries: outputTokenOptions,
              alignment: 'bottomLeft',
              clearSelectionLabel: t('Transactions.TransactionManager.filters.tokens.clearTokens'),
              allowClearSelection: true,
              onSelectEntries: ([selected]) =>
                onFilterChange('outputToken', selected ? (selected.id as ERC20) : undefined),
            },
          },
        ]}
      />
    </div>
  );
};
