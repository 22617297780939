import { TFunction } from 'i18next';
import { match } from 'ts-pattern';

import {
  GetTransactions_transactions_results as Transaction,
  TransactionType,
  getAllLimitOrders_limitOrders_results as LimitOrder,
  GetTransaction_transaction,
  Chain,
} from '@gql';
import { Pill } from '@ui-kit/atoms/Pill';
import { chainOf } from '@utils';
import { allNetworks } from '@utils/networks';
import { TransactionState } from '@utils/uiKitUtils/statusTransactionUtils';

export const getTxSource = (tx: Transaction, t: TFunction<any, any>): string => {
  return match(tx.type)
    .with(TransactionType.deposit, () => t('UIKit.EntryHistory.Sources.wallet'))
    .with(TransactionType.withdraw, () => t('UIKit.EntryHistory.Sources.spotAccount'))
    .with(TransactionType.bridge, () => t('UIKit.EntryHistory.Sources.spotAccount'))
    .with(TransactionType.limitOrder, () => t('UIKit.EntryHistory.Sources.spotAccount'))
    .with(TransactionType.swap, () => t('UIKit.EntryHistory.Sources.spotAccount'))
    .with(TransactionType.multibuy, () => t('UIKit.EntryHistory.Sources.spotAccount'))
    .with(TransactionType.multisell, () => t('UIKit.EntryHistory.Sources.spotAccount'))
    .otherwise(() => t('UIKit.EntryHistory.Sources.unknown'));
};

export const getTxDestination = (tx: Transaction, t: TFunction<any, any>): string => {
  return match(tx.type)
    .with(TransactionType.deposit, () => t('UIKit.EntryHistory.Destinations.spotAccount'))
    .with(TransactionType.withdraw, () => t('UIKit.EntryHistory.Destinations.wallet'))
    .with(TransactionType.bridge, () => t('UIKit.EntryHistory.Destinations.spotAccount'))
    .with(TransactionType.limitOrder, () => t('UIKit.EntryHistory.Destinations.spotAccount'))
    .with(TransactionType.swap, () => t('UIKit.EntryHistory.Destinations.spotAccount'))
    .with(TransactionType.multibuy, () => t('UIKit.EntryHistory.Sources.spotAccount'))
    .with(TransactionType.multisell, () => t('UIKit.EntryHistory.Sources.spotAccount'))
    .otherwise(() => t('UIKit.EntryHistory.Destinations.unknown'));
};

// TEMPRORARY
// IN THE FUTURE, THE LIMIT ORDERS WILL BE FETCHED FROM THE SAME QUERY AS THE OTHER TRANSACTIONS
export const fromLimitOrderToTransaction = (o: LimitOrder) => {
  return {
    __typename: 'GProcessedTx',
    id: `${chainOf(o.vault)}:${o.id}` as TxId,
    type: TransactionType.limitOrder,
    txTimestamp: o.creationDate,
    processedAt: undefined as unknown as DateTime,
    inputs: [
      {
        __typename: 'QuotedTokenQty',
        token: o.make.token,
        qty: o.make.qty,
        quote: o.make.token.quote,
        usdValue: o.make.qtyNum * o.make.token.quote,
      },
    ],
    outputs: [
      {
        __typename: 'QuotedTokenQty',
        token: o.take.token,
        qty: o.take.qty,
        quote: o.take.token.quote,
        usdValue: o.take.qtyNum * o.take.token.quote,
      },
    ],
  } satisfies GetTransaction_transaction;
};

export function getNetworkIcon(network?: Chain | null) {
  const found = allNetworks.find(({ chain }) => chain === network);

  return found ? <Pill key={found.chain} TrailingIcon={found.MonochromeIcon} className="my-auto" /> : null;
}

export function getTransactionStatus(tx: Transaction): TransactionState {
  if (!tx.processedAt) {
    return TransactionState.Pending;
  }
  return TransactionState.Completed;
}
