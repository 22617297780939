import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useMainVault } from '@hooks/useMainVault';
import { useCreateOrCallVault } from '@tetris/tx';
import { useStopLoss } from '@tetris/useStopLoss';
import TransactionRecap from '@transactions/components/TransactionRecap';
import { useBudgetInputInfo } from '@transactions/hooks/useBudgetInputInfo';
import { useChangeCoin } from '@transactions/hooks/useChangeCoin';
import { useHighestHolding } from '@transactions/hooks/useHighestHolding';
import { useTransactionFlow } from '@transactions/hooks/useTransactionFlow';
import { getBudgetUSDvalue, getExpirationInSeconds } from '@transactions/utils';
import { Button } from '@ui-kit/atoms/Button';
import { Divider } from '@ui-kit/atoms/Divider';
import { BudgetWithQuote, ICoin, Loader, convertAmt, getDefaultTokenForChain, safeMult } from '@utils';

import { StopLossUI } from './StopLossUI';
import { supportedPairs } from './constants';
import { StopLossState, stopLossReducer } from './stoploss.reducer';
import { Expiration } from '../limit-orders/enum';

export function StopLoss() {
  const { currentChain } = useTransactionFlow();
  const inputRef = useRef<HTMLInputElement | null>(null);

  const { t } = useTranslation();

  const defaultInputTokenId$ = useHighestHolding(currentChain)
    .map(holding => holding?.token.id)
    .mapNotLoaded('skipped', [currentChain], () => getDefaultTokenForChain(currentChain))
    .map(_tokenId => _tokenId || getDefaultTokenForChain(currentChain));

  const [stopLossState$, dispatch] = Loader.array([defaultInputTokenId$] as const)
    .map<StopLossState>(([_defaultTokenId]) => ({
    inputTokenId: _defaultTokenId,
    outputTokenId:
        currentChain && supportedPairs[currentChain]
          ? (`${currentChain}:${supportedPairs[currentChain][0]}` as ChainAddress)
          : undefined,
    inputAmount: 0n,
    isMax: false,
    expiration: Expiration.Never,
    limitPrice: 0,
    triggerPrice: 0,
  }))
    .asReducer(stopLossReducer);

  const vault$ = useMainVault(false, true);

  const [inputTokenInfo$, inputTokenQuote$, inputTokenHoldings$] = useBudgetInputInfo(
    stopLossState$.map(s => s.inputTokenId || Loader.skipped),
    vault$,
  );

  const [outputTokenInfo$, outpuTokenQuote$, ouputTokenHoldings$] = useBudgetInputInfo(
    stopLossState$.map(s => s.outputTokenId || Loader.skipped),
    vault$,
  );

  // const inputTokenId = stopLossState$.map(s => s.inputTokenId).unwrapOr(null);
  const outputTokenId = stopLossState$.map(s => s.outputTokenId).unwrapOr(null);
  const changeSource = useChangeCoin(false, {
    onlyOwned: true,
    disabledCoins: outputTokenId ? { [outputTokenId]: 'Token selected as ouput' } : undefined,
    onlyCoins:
      currentChain && supportedPairs[currentChain]
        ? supportedPairs[currentChain][1].map(id => `${currentChain}:${id}` as ChainAddress)
        : undefined,
  });
  // const changeTarget = useChangeCoin(false, {
  //   disabledCoins: inputTokenId ? { [inputTokenId]: 'Token selected as input' } : undefined,
  // });

  const sourceBudget$ = Loader.array([inputTokenInfo$, inputTokenQuote$, stopLossState$] as const).map<
  BudgetWithQuote<ICoin>
  >(([tokenInfo, quote, state]) => {
    return {
      token: tokenInfo,
      quote,
      amtBase: state.inputAmount,
    };
  });

  const spendTokenBudget$ = Loader.array([sourceBudget$, stopLossState$, inputTokenHoldings$] as const).map(
    ([b, state, holdings]) => ({ ...b, amtBase: state.isMax ? holdings : b.amtBase }),
  );

  const targetBudget$ = Loader.array([
    inputTokenInfo$,
    outputTokenInfo$,
    outpuTokenQuote$,
    stopLossState$,
    spendTokenBudget$,
  ] as const).map<BudgetWithQuote<ICoin>>(([inputTokenInfo, outputTokenInfo, quote, state, spendBudget]) => {
    return {
      token: outputTokenInfo,
      quote,
      amtBase: safeMult(
        convertAmt(spendBudget.amtBase, inputTokenInfo.decimals, outputTokenInfo.decimals),
        state.limitPrice,
      ),
    };
  });

  const sourceHint$ = Loader.array([sourceBudget$, inputTokenHoldings$] as const).map(([sourceBudget, holdings]) => {
    if (!sourceBudget || (!holdings && holdings !== 0n)) return Loader.skipped;
    if (sourceBudget.amtBase > holdings) {
      return {
        errorMessage: t('Common.Errors.insufficientBalance'),
      };
    }
    return [
      {
        usdValue: getBudgetUSDvalue(sourceBudget),
      },
      getBudgetUSDvalue(sourceBudget) < 15 && sourceBudget.amtBase > 0n
        ? { errorMessage: 'value should be \u2265 $15' }
        : { message: 'value should be \u2265 $15' },
    ];
  });

  const marketPrice$ = Loader.array([inputTokenQuote$, outpuTokenQuote$] as const).map(([inp, out]) => {
    if (!out || !inp) {
      return Loader.skipped;
    }
    return inp / out;
  });

  const targetHint$ = Loader.array([sourceBudget$, outputTokenInfo$, outpuTokenQuote$, stopLossState$] as const).map(
    ([sourceBudget, targetInfo, targetQuote, state]) => {
      if (!targetInfo || !targetQuote) return Loader.skipped;
      return {
        marketRate: {
          value: state.limitPrice,
          source: sourceBudget.token,
          target: targetInfo,
          translationKey: 'Transactions.StopLoss.limitRate',
        },
      };
    },
  );

  const coinSelectHandler = sourceBudget$.makeCallback((budget, coin: ICoin | null) => {
    if (coin) {
      dispatch({
        type: 'SET_INPUT_AMOUNT',
        payload: { value: convertAmt(budget.amtBase, budget.token.decimals, coin.decimals), isMax: false },
      });
      dispatch({ type: 'SET_INPUT_TOKEN_ID', payload: coin.id as ChainAddress });
    }
    setTimeout(() => inputRef.current?.focus(), 100);
  });

  const stopLossOrder$ = useStopLoss({
    spendTokenBudget: spendTokenBudget$,
    buyTokenBudget: targetBudget$,
    limitPrice: stopLossState$.map(s => s.limitPrice),
    chain: currentChain,
    expiration: stopLossState$.map(s => getExpirationInSeconds(s.expiration)),
  });
  const { sendTx: sendTx$ } = useCreateOrCallVault(currentChain, [stopLossOrder$]);

  const cta = Loader.array([sourceBudget$, targetBudget$, stopLossState$] as const)
    .map(([source, target, state]) => {
      if (state.error) return Loader.error(state.error);
      if (!source || !target) return Loader.skipped;
      if (source.amtBase === 0n) return Loader.skipped;
      if (target.amtBase === 0n) return Loader.skipped;
      if (state.limitPrice === 0) return Loader.skipped;
      if (state.triggerPrice === 0) return Loader.skipped;
      return Loader.ok(true);
    })
    .combine(
      sendTx$.mapNotLoaded('error', () => Loader.error('Chain not supported yet')),
      (_, sendTx) => sendTx,
    )
    .match.skipped(() => (
      <Button
        className="btn btn-primary btn-lg w-full"
        disabled
        fullWidth
        label={t('Transactions.StopLoss.placeOrder')}
      />
    ))
    .loading(() => (
      <Button
        className="btn btn-primary btn-lg w-full"
        disabled
        isLoading
        fullWidth
        label={t('Transactions.StopLoss.placeOrder')}
      />
    ))
    .error(e => <Button className="btn btn-primary btn-lg w-full" disabled label={e.message} fullWidth />)
    .ok(sendTx => (
      <Button
        className="btn btn-primary btn-lg w-full"
        label={t('Transactions.StopLoss.placeOrder')}
        fullWidth
        onClick={sendTx.sendNext}
      />
    ));

  const switchTokenHandler = Loader.array([inputTokenInfo$, outputTokenInfo$] as const).makeCallback(
    ([inputInfo, outputInfo]) => {
      dispatch({
        type: 'SWITCH_TOKENS',
        payload: { inputDecimals: inputInfo.decimals, outputDecimals: outputInfo.decimals },
      });
    },
  );

  const recap = Loader.array([
    sourceBudget$.map(s => (s.amtBase > 0 ? s : Loader.skipped)),
    targetBudget$,
    stopLossState$,
  ] as const)
    .match.notOk(() => <Divider />)
    .ok(([, , state]) => (
      <TransactionRecap
        txData={{
          expirationDate: state.expiration,
          aggregator: 'brink',
          massFees: 0,
        }}
        noCountdown
      />
    ));

  return (
    <div className="flex flex-col gap-4">
      <StopLossUI
        inputRef={inputRef}
        inputBudget={sourceBudget$}
        outputBudget={targetBudget$}
        inputHoldings={inputTokenHoldings$}
        outputHoldings={ouputTokenHoldings$}
        onInputChange={(b, isMax) => dispatch({ type: 'SET_INPUT_AMOUNT', payload: { value: b.amtBase, isMax } })}
        onLimitPriceChange={(price: number) => dispatch({ type: 'SET_LIMIT_PRICE', payload: price })}
        onTriggerPriceChange={(price: number) => dispatch({ type: 'SET_TRIGGER_PRICE', payload: price })}
        onSelectInput={() => changeSource(coinSelectHandler)}
        onSelectOutput={() => {}} // no need to change, usdc is the only output
        switchInputAndOutput={switchTokenHandler}
        inputHint={sourceHint$}
        outputHint={targetHint$}
        marketPrice={marketPrice$}
        onError={e => dispatch({ type: 'SET_ERROR', payload: e })}
        onExpireChange={exp => dispatch({ type: 'SET_EXPIRATION', payload: exp })}
      />
      {recap}
      {cta}
    </div>
  );
}

export default StopLoss;
