import { CompilationMetadata } from '@nested-finance/sdk/web';

import { BudgetWithQuote, ICoin, convertAmt, safeMult } from '@utils';

import { computePriceDiff } from '../../../utils';
import { TransactionDetailsEntries } from '../../TransactionRecap';

export function getTransactionRecap(
  sourceBudget: BudgetWithQuote<ICoin>,
  targetBudget: BudgetWithQuote<ICoin>,
  sourceQuote: number,
  targetQuote: number,
  swap: Partial<CompilationMetadata>,
  slippage: number,
): Partial<TransactionDetailsEntries> {
  const estimatedReceived =
    swap?.price && Number(swap?.price) > 0
      ? {
        value: convertAmt(
          safeMult(sourceBudget.amtBase, Number(swap?.price)),
          sourceBudget.token.decimals,
          targetBudget.token.decimals,
        ), // get amount from guaranteed price expressed as amount of target token
        target: targetBudget.token,
      }
      : undefined;

  const unfairPrice = swap?.price ? computePriceDiff(Number(swap!.price), sourceQuote, targetQuote) : 0;

  // This is not applicable with dex.all at the moment
  // const protocolFees =
  //   currentChain && swap?.protocolFee && +(swap?.protocolFee ?? 0) > 0 && nativeToken
  //     ? {
  //       value: +swap!.protocolFee,
  //       unit: nativeToken,
  //     }
  //     : undefined;

  return {
    estimatedReceived,
    rate: {
      value: swap?.price ? Number(swap.price) : 0,
      source: sourceBudget.token,
      target: targetBudget.token,
      slippage,
    },
    unfairPrice,
    aggregator: swap?.dex,
    //    protocolFees,

    massFees: 0,
  };
}
