import cls from 'classnames';
import React from 'react';

import { Divider } from '@ui-kit/atoms/Divider';
import { IEntry } from '@ui-kit/organisms/Entry';
import { Cls } from '@utils';

type EntryGroupProps = {
  entries: React.ReactElement<IEntry & { isCard: true; skeletonClassName?: string }>[];
  isError?: boolean;
} & Cls;

export default function EntryGroup({ entries, isError, className }: EntryGroupProps) {
  return (
    <div
      className={cls('!rounded-xl border', className, {
        'border-transparent focus-within:!border-font-variant': !isError,
        'border-danger': isError,
      })}
    >
      {entries
        .map((entry, i) => (
          <div key={entry.props.id} className="first:!rounded-t-xl last:!rounded-b-xl border-transparent">
            {React.cloneElement(entry, {
              ...entry.props,
              isCard: true,
              className: cls(entry.props.className, '!rounded-none border-none', {
                '!rounded-t-xl': i === 0,
                '!rounded-b-xl': i === entries.length - 1,
              }),
              skeletonClassName: cls(entry.props.skeletonClassName, '!rounded-none', {
                '!rounded-t-xl': i === 0,
                '!rounded-b-xl': i === entries.length - 1,
              }),
            })}
          </div>
        ))
        .reduce((acc, x) =>
          !acc ? (
            x
          ) : (
            <>
              {acc}
              <Divider />
              {x}
            </>
          ),
        )}
    </div>
  );
}
