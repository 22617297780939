import { t } from 'i18next';
import sanitizeHtml from 'sanitize-html';
import { match } from 'ts-pattern';

import { SideContentHeader } from '@components/layouts/side-content/SideContentHeader';
import { ModalContentType } from '@components/modal/Modal';
import { Button, IButton } from '@ui-kit/atoms/Button';
import { StatusDisplay, StatusDisplayTheme } from '@ui-kit/atoms/StatusDisplay';
import { Loadable, Loader } from '@utils/loader';

export type SignState = 'pending' | 'loading' | 'warning' | 'error' | 'success';

type SignMessageProps = {
  signState: SignState;
  onSignMessage: (message: string) => void;
  onCancel: VoidFunction;
  message: Loadable<string>;
} & ModalContentType;

export function SignMessageModalScreen({ signState, onSignMessage, message, onCancel }: SignMessageProps) {
  const statusDisplayTheme = match(signState)
    .with('pending', () => StatusDisplayTheme.INFO)
    .with('loading', () => StatusDisplayTheme.LOADING)
    .with('success', () => StatusDisplayTheme.SUCCESS)
    .with('warning', () => StatusDisplayTheme.WARNING)
    .with('error', () => StatusDisplayTheme.ERROR)
    .exhaustive();

  const SignCta$ = Loader.array([message, signState] as const)
    .noFlickering()
    .map(([_message, _signState]) => ({
      disabled: _signState === 'loading' || _signState === 'success',
      _message,
    }));
  const commonCtaProps: Partial<IButton> = {
    label: t('Login.SignMessage.buttonLabel'),
    isLoadingLabel: t('Login.SignMessage.buttonLabel'),
    variant: 'accent',
    className: 'mt-10',
    fullWidth: true,
    size: 'l',
  };

  return (
    <>
      <SideContentHeader
        title={
          signState === 'warning' || signState === 'error'
            ? t('Login.SignMessage.titleRejected')
            : t('Login.SignMessage.title')
        }
      />
      <StatusDisplay className="mx-auto" theme={statusDisplayTheme} />
      <p
        className="text-font-variant font-light text-center text-base"
        dangerouslySetInnerHTML={{
          __html: sanitizeHtml(
            signState === 'warning' || signState === 'error'
              ? t('Login.SignMessage.warning')
              : t('Login.SignMessage.content'),
            {
              allowedTags: ['a'],
              allowedAttributes: {
                a: ['href', 'rel', 'target'],
              },
            },
          ),
        }}
      />
      <div className="flex flex-col w-full">
        {SignCta$.match
          .skipped(() => <Button {...commonCtaProps} disabled />)
          .loading(() => <Button {...commonCtaProps} isLoading />)
          .error(() => <Button {...commonCtaProps} disabled />)
          .ok(({ disabled, _message }) => (
            <Button {...commonCtaProps} disabled={disabled} onClick={() => onSignMessage(_message)} />
          ))}
        {signState === 'warning' && (
          <Button
            label={t('Login.SignMessage.cancel')}
            variant="surface"
            className="mt-4"
            fullWidth
            size="l"
            type="submit"
            onClick={onCancel}
          />
        )}
      </div>
    </>
  );
}
