import { atom } from 'recoil';

import { Chain } from '@gql';

import { localStorageEffect } from '../utils';

/** @deprecated isnt the name clear enough ? back away ! */
export const targetNetworkAtomDoNotUseUnlessSelectionScreen = atom<Chain | null>({
  key: 'targetNetwork',
  effects: [localStorageEffect('targetNetwork')],
  default: null,
});
