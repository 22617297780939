import { useTranslation } from 'react-i18next';

import { WithdrawIcon } from '@ui-kit/Icons';
import { Button } from '@ui-kit/atoms/Button';
import MassSunrise from '@videos/MassSunrise.mp4';

import { useWallet } from '../../wallet';

export function AlphaEligibility(): JSX.Element {
  const { t } = useTranslation();
  const linkClasses =
    'bg-accent text-background cursor-pointer' +
    ' hover:bg-accent-hover-font-on-accent hover:text-background' +
    ' px-6 py-3 rounded-3xl font-medium flex items-center gap-3';

  const wallet$ = useWallet();

  const alphaAccessButton = wallet$.match
    .notOk(() => <Button variant="accent" isLoading isLoadingLabel={t('Components.AlphaEligibility.check')} />)
    .ok(({ isAuthed, status, login }) => {
      return (
        <>
          {!isAuthed && status === 'not connected' && (
            <div onClick={login} className={linkClasses}>
              {t('Components.AlphaEligibility.check')}
              <WithdrawIcon className="w-6 h-6" />
            </div>
          )}
          <div className="flex flex-col gap-5 items-center justify-center">
            {!isAuthed && status === 'error' ? (
              <>
                <span className="text-font text-center">{t('Components.AlphaEligibility.notEligible')}</span>
                <a href="https://form.mass.money/2" target="_blank" rel="noreferrer" className={linkClasses}>
                  {t('Components.AlphaEligibility.applyNow')}
                  <WithdrawIcon className="w-6 h-6" />
                </a>
              </>
            ) : (
              isAuthed && (
                <>
                  <span className="text-font text-center">{t('Components.AlphaEligibility.eligible')}</span>
                  <a href="/" className={linkClasses}>
                    {t('Components.AlphaEligibility.accessNow')}
                    <WithdrawIcon className="w-6 h-6" />
                  </a>
                </>
              )
            )}
          </div>
        </>
      );
    });

  return (
    <div className="fixed inset-0 flex flex-col justify-center h-screen w-screen">
      <video
        className="absolute z-10 inset pointer-events-none object-cover"
        src={MassSunrise}
        autoPlay
        loop
        muted
        playsInline
      />
      <div
        className="absolute z-20 bottom-0 left-0 right-0 h-[28%] flex flex-col-reverse"
        style={{ background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 85%)' }}
      >
        <div className="flex flex-col items-center mb-10">{alphaAccessButton}</div>
      </div>
    </div>
  );
}
