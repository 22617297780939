import { useTranslation } from 'react-i18next';

import BudgetInputEntry from '@transactions/components/ui/BudgetInputEntry';
import { VerticalProcessLayout } from '@transactions/components/ui/VerticalProcessLayout';
import { SpotIcon } from '@ui-kit/Icons';
import { Skeleton } from '@ui-kit/atoms/Skeleton';
import { Entry } from '@ui-kit/organisms/Entry';
import EntryGroup from '@ui-kit/organisms/EntryGroup';
import {
  Budget,
  BudgetWithQuote,
  ICoin,
  Loadable,
  Loader,
  toNumber,
  budgetNumValue,
  withoutChain,
  shortenAddress,
} from '@utils';
import { formatNumber } from '@utils/numbers/NumberFormat';

export type DepositUIProps = {
  walletAddress: Loadable<ChainAddress>;
  holdingsValue: Loadable<bigint>;
  sourceBudget: Loadable<BudgetWithQuote<ICoin>>;
  sourceBalance: Loadable<bigint>;
  onSourceChange: (budget: Budget<ICoin>, isMax?: boolean) => void;
  onSourceCoinSelect: VoidFunction;
  onSwitchToWithdraw?: VoidFunction;
  disabled?: boolean;
};

export function DepositUI({
  walletAddress,
  holdingsValue,
  sourceBudget,
  sourceBalance,
  onSourceChange,
  onSourceCoinSelect,
  onSwitchToWithdraw,
  disabled,
}: DepositUIProps) {
  const { t } = useTranslation();

  const walletAddress$ = Loader.useWrap(walletAddress);
  const holdingsValue$ = Loader.useWrap(holdingsValue);
  const sourceBudget$ = Loader.useWrap(sourceBudget);
  const sourceBalance$ = Loader.useWrap(sourceBalance);

  const hint$ = Loader.array([sourceBudget$, sourceBalance$] as const).map(([_sourceBudget, _sourceBalance]) => {
    if (_sourceBudget.amtBase > _sourceBalance) {
      return { errorMessage: t('Common.Errors.insufficientBalance') };
    }
    return { usdValue: budgetNumValue(_sourceBudget) };
  });

  return (
    <VerticalProcessLayout
      onArrowClick={onSwitchToWithdraw}
      steps={[
        {
          key: 'in',
          item: (
            <EntryGroup
              isError={hint$.match.notOk(() => false).ok(({ errorMessage }) => Boolean(errorMessage))}
              entries={[
                <Entry
                  key="wallet"
                  isCard
                  noMinHeight
                  content={{
                    top: (
                      <div className="flex justify-between select-none">
                        <span className="text-base font-medium">{t('Transactions.Deposit.from')}</span>
                        <span className="text-font-variant text-sm">
                          {walletAddress$.match
                            .notOk(() => <Skeleton className="w-20 h-4" />)
                            .ok(_walletAddress => {
                              const address = withoutChain(_walletAddress);
                              return shortenAddress(address, true);
                            })}
                        </span>
                      </div>
                    ),
                  }}
                />,
                <BudgetInputEntry
                  key="budgetInput"
                  budget={sourceBudget$}
                  onChange={onSourceChange}
                  maxBudget={sourceBalance}
                  hint={hint$}
                  onSelectCoin={onSourceCoinSelect}
                  disabled={disabled}
                />,
              ]}
            />
          ),
        },
        {
          key: 'out',
          item: (
            <Entry
              isCard
              disabled
              noMinHeight
              content={{
                top: (
                  <div className="flex justify-between">
                    <span className="text-base font-medium select-none">{t('Transactions.Deposit.to')}</span>
                    {Loader.array([holdingsValue$, sourceBudget$] as const)
                      .match.notOk(() => <Skeleton className="w-20 h-4" />)
                      .ok(([_holdingsValue, _sourceBudget]) => (
                        <span className="flex gap-1 items-center text-font-variant text-sm">
                          <SpotIcon height={12} width={12} />
                          {formatNumber(toNumber(_holdingsValue, _sourceBudget.token.decimals), 'qty')}
                        </span>
                      ))}
                  </div>
                ),
              }}
            />
          ),
        },
      ]}
    />
  );
}
