import cls from 'classnames';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line import/no-cycle
import { Dropdown, IDropdown } from '@components/Dropdown';
import { Chain, GetMainVaultSummary_mainVault as MainVault } from '@gql';
import { Cls, Loadable, Loader, isValidEnum } from '@utils';
import { allNetworks, networksByChain, INetwork } from '@utils/networks';

import { Skeleton } from '../ui-kit/atoms/Skeleton';

export type NetworkSelectorProps = {
  network?: Loadable<Chain | INetwork | nil>;
  onSelectNetwork: (network?: Chain) => void;
  withVaultsOnly?: boolean;
  allowClearSelection?: boolean;
  isDisabled?: boolean;
  enabledChains?: Loadable<string[]>;
  vaults?: Loadable<MainVault>;
  alignment?: IDropdown['alignment'];
  isPortal?: boolean;
} & Cls;

export const NetworkSelector = ({
  network,
  className,
  onSelectNetwork,
  withVaultsOnly = false,
  allowClearSelection = false,
  isDisabled = false,
  enabledChains,
  vaults,
  alignment = 'bottomLeft',
  isPortal,
}: NetworkSelectorProps) => {
  const { t } = useTranslation();
  const network$ = Loader.useWrap(network).map(n => (isValidEnum(Chain, n) ? networksByChain[n] : n));

  const networksWithVaults$ = Loader.useWrap(vaults)
    .map(gmv => gmv?.existsOn)
    .map(chains => (withVaultsOnly ? allNetworks.filter(n => chains?.includes(n.chain)) : allNetworks));

  const networkAndVaults$ = network$.combine(networksWithVaults$, (_n, _withVaults) => ({ _n, _withVaults }));
  networkAndVaults$.onOk(({ _n, _withVaults }) => {
    if (_n && !_withVaults?.find(n => n.chain === _n.chain)) {
      onSelectNetwork(_withVaults[0]?.chain);
    }
  });

  const enabledNetworks = Loader.useWrap(enabledChains)
    .match.notOk(() => null)
    .ok(_enabledChains => _enabledChains);

  return (
    <>
      {networkAndVaults$.match
        .loadingOrSkipped(() => <Skeleton className="h-8 w-28" isRound />)
        .error(() => null)
        .ok(({ _n, _withVaults }) => (
          <Dropdown
            className={cls('max-h-8 gap-4', className)}
            defaultSelectedEntriesIds={_n?.chain ? [_n.chain] : undefined}
            filterOnSelect={false}
            label={_n?.name ? _n.name : t('Common.networks')}
            entries={allNetworks
              .filter(n => !n.disabled)
              .map(({ name: chainName, chain, Icon, MonochromeIcon }) => {
                const disabled =
                  (withVaultsOnly && !_withVaults?.find(n => n.chain === chain)) ||
                  Boolean(enabledNetworks && !enabledNetworks.includes(chain));
                return {
                  className: disabled ? 'opacity-50' : '',
                  leadingItem: (
                    <div className="w-6 h-6">
                      {disabled ? (
                        <MonochromeIcon className="w-6 h-6 shrink-0" />
                      ) : (
                        <Icon className="w-6 h-6 shrink-0" />
                      )}
                    </div>
                  ),
                  leadingItemSelected: <Icon className="w-5 h-5 shrink-0" />,
                  content: {
                    top: <span className="text-base font-medium text-font whitespace-nowrap">{chainName}</span>,
                  },
                  disabled,
                  id: `${chain}`,
                  selectLabel: chainName,
                };
              })}
            onSelectEntries={c => onSelectNetwork(c[0]?.id as Chain | undefined)}
            allowClearSelection={allowClearSelection}
            clearSelectionLabel={t('UIKit.Dropdown.allNetworks')}
            alignment={alignment}
            isDisabled={isDisabled}
            isPortal={isPortal}
          />
        ))}
    </>
  );
};
