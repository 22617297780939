import {
  ApolloClient,
  ApolloLink,
  DefaultOptions,
  from,
  GraphQLRequest,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from 'apollo-link-error';
import jwtDecode from 'jwt-decode';
import { useMemo } from 'react';
import { atom, useRecoilValue } from 'recoil';
import { getRecoil, setRecoil } from 'recoil-nexus';

import { isEphemeralEnv } from '@env';
import { localStorageEffect, notNil } from '@utils';

// dont use cache...
const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

const cache = new InMemoryCache({
  dataIdFromObject: () => null!,
  typePolicies: {
    Query: {
      fields: {
        nft: {
          keyArgs: ['id'],
          merge: true,
        },
        tokens: {
          // random key, to avoid merging
          keyArgs: () => Math.random().toString(),
          merge: false,
        },
        myUser: { merge: true },
        portfolio: { merge: true },
      },
    },
  },
  possibleTypes: {
    IHasHoldings: ['NFT', 'Portfolio'],
    IUserSummary: ['User', 'UserSummary'],
    INotification: [
      'NftBurned',
      'NftCreated',
      'NftModified',
      'NftReceived',
      'NftReplicated',
      'NftSent',
      'OriginalNftBurned',
      'OriginalNftModified',
      'OriginalNftTransferred',
      'RoyaltiesReceived',
      'NewsNotification',
    ],
    IProcessableNotification: [
      'NftBurned',
      'NftCreated',
      'NftModified',
      'NftReceived',
      'NftReplicated',
      'NftSent',
      'OriginalNftBurned',
      'OriginalNftModified',
      'OriginalNftTransferred',
      'RoyaltiesReceived',
    ],
  },
});

export const apolloAuthToken = cache.makeVar<string | null>(null);
export const apolloLang = cache.makeVar<string | null>('en-US'); // App language is forced to english

// NOT EXPORTED ! stop interacting directly with atoms everywhere,
// and create clean apis please (otherwise its too messy)
/** Is being authenticated ? */
const _authLoading = atom<boolean>({
  key: 'authLoading',
  default: true,
});
let authStatusResolved: () => void;
export function resolveAuthStatus() {
  authStatusResolved();
  setRecoil(_authLoading, false);
}
// eslint-disable-next-line react-hooks/rules-of-hooks
resolveAuthStatus.useIsLoading = () => useRecoilValue(_authLoading);
// eslint-disable-next-line no-promise-executor-return, no-return-assign
resolveAuthStatus.isLoadingAsPromise = new Promise<void>(resolve => (authStatusResolved = resolve));

// eslint-disable-next-line import/no-mutable-exports
export let hadPreviousQueries = false;

const EPHEMERAL_API_ROOT = !isEphemeralEnv ? null : localStorage.getItem('EPHEMERAL_API');
export const NESTED_API_URL: string =
  (EPHEMERAL_API_ROOT && `${EPHEMERAL_API_ROOT}/graphql`) || import.meta.env.VITE_API_URL!;

export function fetchEphemeral(relativeUrl: string, opts?: RequestInit) {
  if (!EPHEMERAL_API_ROOT) {
    throw new Error('Not an ephemeral env');
  }
  return fetch(`${EPHEMERAL_API_ROOT}/ephemeral/${relativeUrl[0] === '/' ? relativeUrl.substring(1) : relativeUrl}`, {
    ...opts,
    credentials: 'include',
  });
}

export function fetchEphemeralGql(opts?: RequestInit) {
  if (!EPHEMERAL_API_ROOT) {
    throw new Error('Not an ephemeral env');
  }
  return fetch(`${EPHEMERAL_API_ROOT}/graphql`, {
    ...opts,
    credentials: 'include',
  });
}

const authTokenAtom = atom<string | null>({
  key: 'token',
  effects: [localStorageEffect('token')],
  default: null,
});

export function setAuthToken(token: string | null) {
  setRecoil(authTokenAtom, coerceToken(token));
}

export function useAuthToken() {
  const ret = useRecoilValue(authTokenAtom);
  return useMemo(() => coerceToken(ret), [ret]);
}

export function getAuthToken() {
  const token = getRecoil(authTokenAtom);
  return coerceToken(token);
}

function coerceToken(token: string | nil): string | null {
  if (!token) {
    return null;
  }
  // check if expired
  const { exp } = jwtDecode<{ exp: number }>(token);
  const currentTime = new Date().getTime() / 1000;
  return currentTime > exp ? null : token;
}

const httpLink = new HttpLink({
  uri: NESTED_API_URL,
  // include cookies (ephemeral session for UTs, or load-balancer cookie in a deployed environment)
  credentials: 'include',
});

function queryName({ query, variables }: GraphQLRequest) {
  const name = notNil(query.definitions.map(x => (x.kind === 'OperationDefinition' ? x.name?.value : null)))[0];
  return `query ${name} with args ${JSON.stringify(variables)}`;
}

const authLink = setContext(async (op, { headers }) => {
  await resolveAuthStatus.isLoadingAsPromise;
  hadPreviousQueries = true;
  // get the authentication token from local storage if it exists
  const token = getRecoil(authTokenAtom);
  const lang = apolloLang();
  if (isEphemeralEnv) {
    // eslint-disable-next-line no-console
    console.log(`Sending ${token ? 'authenticated' : 'anonymous'} ${queryName(op)}`);
  }
  // return the headers to the context so httpLink can read them
  return token
    ? {
      headers: {
        ...headers,
        'Accept-Language': lang,
        Authorization: token ? `Bearer ${token}` : '',
      },
    }
    : {};
});

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    // eslint-disable-next-line array-callback-return
    graphQLErrors.map(graphQLError => {
      // eslint-disable-next-line no-console
      console.error(
        // eslint-disable-next-line max-len
        `[GraphQL error on ${queryName(operation)}]: Message: ${graphQLError.message}, Location: ${
          graphQLError.locations
        }, Path: ${graphQLError.path}`,
        graphQLError,
      );
      if (graphQLError.extensions?.code === 'UNAUTHENTICATED') {
        apolloAuthToken('LOGOUT');
      }
    });
  }
  if (networkError) {
    // eslint-disable-next-line no-console
    console.error(`[Network error on ${queryName(operation)}]: `, networkError);
  }
});

export const apolloClient = new ApolloClient({
  cache,
  defaultOptions,
  link: from([errorLink as unknown as ApolloLink, authLink, httpLink]),
});
