import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

import { DEFAULT_CHAIN } from '@constants';
import { Chain } from '@gql';
import { useWallet } from '@wallet';

import { CurrentTransactionFlowState, Settings, TransactionFlowProps, TransactionFlowType } from '../types';

export const currentTransactionFlowAtom = atom<CurrentTransactionFlowState>({
  key: 'currentTransactionFlowAtom',
  default: {
    showHeader: true,
    focusFlowTrigger: 0,
    currentChain: DEFAULT_CHAIN,
    type: null,
    props: {},
    settings: {
      slippage: 0.005,
    },
    animationTrigger: 0,
  },
});

export function useTransactionFlow() {
  const {
    type: currentFlow,
    props,
    showHeader,
    currentChain,
    settings,
    focusFlowTrigger,
    animationTrigger,
  } = useRecoilValue(currentTransactionFlowAtom);

  const wallet$ = useWallet();

  const setCurrentTransactionAtom = useSetRecoilState(currentTransactionFlowAtom);

  const setCurrentFlow = <T extends TransactionFlowType>(
    type: T,
    _props?: TransactionFlowProps[T],
    triggerAnimation?: boolean,
  ) => {
    setCurrentTransactionAtom(state => ({
      ...state,
      type,
      props: _props || {},
      showHeader: true,
      focusFlowTrigger: state.focusFlowTrigger + 1,
      animationTrigger: state.animationTrigger + (triggerAnimation ? 1 : 0),
    }));
  };

  const setSettings = (newSettings: Settings) => {
    setCurrentTransactionAtom(state => ({ ...state, settings: { ...settings, ...newSettings } }));
  };

  const setCurrentChain = (chain?: Chain) => setCurrentTransactionAtom(x => ({ ...x, currentChain: chain }));

  const resetScroll = () => {
    const scrollableSideContent = document.getElementById('top-bottom-side-contents');
    // TODO(Hadrien) : harmoniser lorsqu'on retravaillera le scroll du side content
    // https://linear.app/mass/issue/MASS-1255/impr-scroll-behavior
    if (scrollableSideContent !== null) {
      scrollableSideContent.scrollTop = 0;
    }
    // permet de chainer avant l'éxecution du callback
    return true;
  };

  wallet$.onOk([currentChain], _w => {
    if (currentChain && _w.isAuthed) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      _w.changeNetwork(currentChain);
    }
  });

  const setIsHeaderVisible = (show: boolean) => {
    setCurrentTransactionAtom(x => ({ ...x, showHeader: show }));
  };

  return {
    currentFlow,
    settings,
    setSettings,
    setCurrentFlow,
    showHeader,
    props,
    setIsHeaderVisible,
    setCurrentChain,
    currentChain,
    focusFlowTrigger,
    animationTrigger,
    resetScroll,
  };
}
