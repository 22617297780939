import Minisearch from 'minisearch';

import {
  SearchCoinVariables,
  SearchCoin as SearchCoinQuery,
  Chain,
  GetUserBalances,
  GetUserBalancesVariables,
  GetMainVault_mainVault_spot,
  GetUserBalances_myUser_walletBalances,
  GetMainVault_mainVault_spot_token,
} from '@gql';
import { useMainVault } from '@hooks/useMainVault';
import { SEARCH_TOKEN_QUERY } from '@queries/token.queries';
import { GET_USER_BALANCES } from '@queries/user.queries';
import { Loader, holdingQuote, parseAddress, uniqueBy } from '@utils';
import { useWallet } from '@wallet';

const balancesOrHoldingsSearchIndex = new Minisearch<GetMainVault_mainVault_spot_token>({
  idField: 'id',
  fields: ['name', 'symbol', 'address'],
  storeFields: ['name', 'symbol', 'id'],
  extractField: (token, fieldName) => {
    if (fieldName === 'address') {
      return parseAddress(token.id).address;
    }
    return Minisearch.getDefault('extractField')(token, fieldName);
  },
});

export function useSearchCoinWithBalances(
  searchValue: string,
  currentChain: Chain | nil,
  sortBy: 'balances' | 'holdings',
  onlyOwned: boolean,
  onlyCoins?: ChainAddress[],
): Loader<Omit<GetMainVault_mainVault_spot, '__typename'>[]> {
  const wallet$ = useWallet(false);
  const vault$ = useMainVault(false, currentChain || false);

  const coinsSearchResult$ = Loader.wrap(searchValue)
    .query<SearchCoinQuery, SearchCoinVariables>(SEARCH_TOKEN_QUERY, _searchValue => {
    return {
      search: _searchValue,
      limit: 10,
      chain: currentChain,
      ids: onlyCoins,
    };
  })
    .debounce(100);

  const balances$: Loader<GetUserBalances_myUser_walletBalances[]> = wallet$
    .map(w => (sortBy === 'balances' ? w : Loader.skipped))
    .query<GetUserBalances, GetUserBalancesVariables>(GET_USER_BALANCES, () => ({ chain: currentChain }))
    .map(({ myUser }) => [...(myUser?.walletBalances ?? [])])
    .mapNotLoaded('skipped', () => []);

  const holdings$: Loader<readonly GetMainVault_mainVault_spot[]> = vault$
    .map(v => (sortBy === 'holdings' ? v : Loader.skipped))
    .map(_vault => _vault?.spot || [])
    .mapNotLoaded<readonly GetMainVault_mainVault_spot[]>('skipped', () => []);

  const balancesOrHoldings$: Loader<
  | Omit<GetUserBalances_myUser_walletBalances, 'qtyNum' | '__typename'>[]
  | Omit<GetMainVault_mainVault_spot, '__typename'>[]
  | undefined
  > = Loader.array([balances$, holdings$] as const).map(([balances, holdings]) => {
    return [...(balances ?? []), ...(holdings ?? [])];
  });

  balancesOrHoldings$.onOk(_balancesOrHoldings => {
    _balancesOrHoldings?.forEach(({ token }) => {
      if (!balancesOrHoldingsSearchIndex.has(token.id)) {
        balancesOrHoldingsSearchIndex.add(token);
      }
    });
  });

  const searchResults$ = Loader.array([coinsSearchResult$, balancesOrHoldings$] as const).map(
    ([_coinsSearchResult, _balancesOrHoldings]) => {
      const matches = balancesOrHoldingsSearchIndex.search(searchValue, {
        prefix: true,
        fields: searchValue.length === 1 ? ['symbol'] : undefined,
        fuzzy: 0.2,
        filter: ({ id }) => _coinsSearchResult.tokens.some(token => token.id === id),
      });

      const foundBalancesOrHoldings = searchValue
        ? (
          matches
            .map(match => _balancesOrHoldings?.find(_coin => _coin.token.id === match.id))
            .filter(Boolean) as GetUserBalances_myUser_walletBalances[]
        ).filter(coin => !onlyCoins || onlyCoins.includes(coin.token.id))
        : (_balancesOrHoldings
          ?.filter(Boolean)
          .filter(coin => !onlyCoins || onlyCoins.includes(coin.token.id))
          .sort((a, b) => holdingQuote(b) - holdingQuote(a)) as GetUserBalances_myUser_walletBalances[]);

      const coinsWithZeroBalance = _coinsSearchResult.tokens.map(token => ({
        token,
        qty: '0',
      })) as GetUserBalances_myUser_walletBalances[];

      const results = onlyOwned
        ? foundBalancesOrHoldings || []
        : uniqueBy([...(foundBalancesOrHoldings || []), ...(coinsWithZeroBalance || [])], coin => coin!.token.id);

      return results;
    },
  );

  return searchResults$;
}
