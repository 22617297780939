import { Chain as ViemChain } from 'viem';
import { arbitrum, avalanche, optimism, bsc, mainnet, polygon, base } from 'viem/chains';

import { Chain } from '@gql';
import {
  ArbitrumNetwork,
  AvalancheNetwork,
  BinanceNetwork,
  EthereumNetwork,
  OptimismNetwork,
  PolygonNetwork,
  ArbitrumIcon,
  AvalancheIcon,
  BNBChainIcon,
  EthereumIcon,
  OptimismIcon,
  PolygonIcon,
  BaseNetwork,
  BaseIcon,
} from '@ui-kit/Icons';

import { SVGIcon } from '../basic-utils';

export type ChainId = 1 | 10 | 56 | 137 | 8453 | 42161 | 43114;
export interface INetwork {
  readonly token: string;
  readonly name: string;
  readonly IconPattern: SVGIcon;
  readonly Icon: SVGIcon;
  readonly MonochromeIcon: SVGIcon;
  readonly chain: Chain;
  readonly chainId: ChainId;
  readonly rpcUrls: string[];
  readonly blockExplorerUrls: string[];
  readonly disabled?: boolean;
  readonly color: string;
  readonly viem: ViemChain;
}

export const allNetworks: readonly INetwork[] = [
  {
    name: 'Arbitrum',
    IconPattern: ArbitrumIcon,
    Icon: ArbitrumNetwork,
    MonochromeIcon: ArbitrumIcon,
    chain: Chain.arbi,
    token: 'ETH',
    chainId: 42161,
    rpcUrls: ['https://arb1.arbitrum.io/rpc'],
    blockExplorerUrls: ['https://arbiscan.io/'],
    color: '#2d3549',
    viem: arbitrum,
  },
  {
    name: 'Avalanche',
    IconPattern: AvalancheIcon,
    Icon: AvalancheNetwork,
    MonochromeIcon: AvalancheIcon,
    chain: Chain.avax,
    token: 'AVAX',
    chainId: 43114,
    rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
    blockExplorerUrls: ['https://cchain.explorer.avax.network'],
    color: '#e13e41',
    viem: avalanche,
    disabled: true,
  },
  {
    name: 'Base',
    IconPattern: BaseIcon,
    Icon: BaseNetwork,
    MonochromeIcon: BaseIcon,
    chain: Chain.base,
    token: 'ETH',
    chainId: 8453,
    rpcUrls: ['https://mainnet.base.org'],
    blockExplorerUrls: ['https://basescan.org'],
    color: '#035BF8',
    viem: base,
    disabled: true,
  },
  {
    name: 'BNB Chain',
    IconPattern: BNBChainIcon,
    Icon: BinanceNetwork,
    MonochromeIcon: BNBChainIcon,
    chain: Chain.bsc,
    token: 'BNB',
    chainId: 56,
    rpcUrls: [
      'https://bsc-dataseed.binance.org',
      'https://bsc-dataseed1.defibit.io/',
      'https://bsc-dataseed1.ninicoin.io/',
    ],
    blockExplorerUrls: ['https://bscscan.com'],
    color: '#e9b30d',
    viem: bsc,
    disabled: true,
  },
  {
    name: 'Ethereum',
    IconPattern: EthereumIcon,
    Icon: EthereumNetwork,
    MonochromeIcon: EthereumIcon,
    chain: Chain.eth,
    token: 'ETH',
    chainId: 1,
    rpcUrls: ['https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
    blockExplorerUrls: ['https://etherscan.io'],
    color: '#6e81c7',
    viem: mainnet,
    disabled: true,
  },
  {
    name: 'Optimism',
    IconPattern: OptimismIcon,
    Icon: OptimismNetwork,
    MonochromeIcon: OptimismIcon,
    chain: Chain.opti,
    token: 'ETH',
    chainId: 10,
    rpcUrls: ['https://mainnet.optimism.io/'],
    blockExplorerUrls: ['https://optimistic.etherscan.io'],
    color: '#f2081f',
    viem: optimism,
  },
  {
    name: 'Polygon',
    IconPattern: PolygonIcon,
    Icon: PolygonNetwork,
    MonochromeIcon: PolygonIcon,
    chain: Chain.poly,
    token: 'MATIC',
    chainId: 137,
    // Official polygon recommended Rpcs https://docs.polygon.technology/docs/develop/network-details/network/
    rpcUrls: [
      'https://polygon-rpc.com',
      'https://rpc-mainnet.matic.network',
      'https://matic-mainnet.chainstacklabs.com',
      'https://rpc-mainnet.maticvigil.com',
      'https://rpc-mainnet.matic.quiknode.pro',
      'https://matic-mainnet-full-rpc.bwarelabs.com',
    ],
    blockExplorerUrls: ['https://polygonscan.com'],
    color: '#8247e5',
    viem: polygon,
    disabled: true,
  },
];
export const networksByChain = Object.fromEntries(allNetworks.map(x => [x.chain, x]));

export const getNetworkFromChainId = (chainId: ChainId | number | nil) =>
  chainId ? allNetworks.find(n => n.chainId === chainId) : undefined;
