import { ApolloClient, DocumentNode, OperationVariables, TypedDocumentNode, useApolloClient } from '@apollo/client';
import ExpiryMap from 'expiry-map';
import { DependencyList, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { AtomEffect, atom, useRecoilValue } from 'recoil';

import { deepEqual } from './basic-utils';
// eslint-disable-next-line import/no-cycle
import { Loader, ResultOf, SyncResult } from './loader';

export type ReactState<T> = [T, (value: T) => any];
export type SetStateDispatcher<T> = (dispatch: (prev: T) => T) => void;

/** Just a helper that avoids typescript errors when creating an effect with an async callback */
export function useAsyncEffect(callback: () => Promise<any>, deps?: DependencyList) {
  return useEffect(() => {
    callback().catch(e => e);
  }, deps);
}

/** Same as useDelayed(), but will be initialized with its initial value */
export function useDebounce<T>(value: T, delay: number): T {
  return useDelayed(value, delay) ?? value;
}

/** Will return the given value, but only after a given delay (will return null in the meantime) */
export function useDelayed<T>(value: T, delay: number): T | null {
  const [delayedVvalue, setDelayedVvalue] = useState<T | null>(null);
  const [lastValue, setLastValue] = useState<T | null>(null);
  const [timeoutVal, setTimeoutVal] = useState<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    if (deepEqual(lastValue, value)) {
      return;
    }
    setLastValue(value);
    if (timeoutVal) {
      clearTimeout(timeoutVal);
    }
    const handler = setTimeout(() => {
      if (deepEqual(value, delayedVvalue)) {
        return;
      }
      setDelayedVvalue(value);
    }, delay);

    setTimeoutVal(handler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
  return delayedVvalue;
}

export type Refetch<TVariables> = (variables?: Partial<TVariables>) => void;

export interface UseQueryOptions<TVariables extends object = OperationVariables> {
  skip?: boolean;
  variables?: TVariables;
  refetchWhenChanges?: any[];
}

export function useQueryLoader<TData = any, TVariables extends object = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: UseQueryOptions,
): [loader: Loader<ResultOf<TData>>, refetch: Refetch<TVariables>] {
  // perform query
  // To the future guy who might lose his mind wondering why "loading" is never returning to "true", without error.
  // ... just set a "break on all exceptions",
  // while the query is performing, and you'll see an error:
  //    "Store reset while query was in flight (not completed in link chain)".
  // ... this can be because of a race condition where store.resetStore()
  //   is called in parallel, and it seems to break apollo-client.
  // see https://github.com/apollographql/apollo-client/issues/3766
  const apollo = useApolloClient();
  const [refetchCounter, setRefetchCounter] = useState<{ cnt: number; vars: Partial<TVariables> }>({
    cnt: 0,
    vars: {},
  });
  const variablesDeduped = useDeduplicated(options?.variables);
  const loader = Loader.async([variablesDeduped, refetchCounter, ...(options?.refetchWhenChanges ?? [])], async () => {
    if (options?.skip) {
      return Loader.skipped;
    }
    return fetchApollo<TData>(apollo, query, variablesDeduped);
  });

  const refetch = useCallback(
    (vars?: Partial<TVariables>) =>
      setRefetchCounter(c => ({
        cnt: c.cnt,
        vars: { ...c.vars, vars },
      })),
    [],
  );

  return [loader, refetch];
}

export function useDeduplicated<T>(value: T): T {
  const [ret, setRet] = useState(value);
  useEffect(() => {
    if (deepEqual(value, ret)) {
      return;
    }
    setRet(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
  return ret;
}

export async function fetchApollo<TData, TVariables = any>(
  apollo: ApolloClient<object>,
  query: DocumentNode,
  variables: TVariables | undefined,
): Promise<SyncResult<TData>> {
  const result = await apollo.query<TData, TVariables>({
    query,
    variables,
  });
  if (result.error) {
    return Loader.error(result.error);
  }
  if (result.errors?.length) {
    return Loader.error(result.errors[0].message);
  }
  return result.data;
}

// cache URL gets for 5s
const fetchCache = new ExpiryMap<string, Loader<any>>(1000);

export function useFetch<T>(
  url: string,
  opts?: {
    refresh?: boolean;
    skip?: boolean;
    fetcher?: typeof fetch;
  },
): Loader<T> {
  return Loader.async([url, opts?.refresh], async () => {
    if (opts?.skip) {
      return Loader.skipped;
    }
    const cache = (data: Loader<T>) => {
      fetchCache.set(url, data);
      return data;
    };
    try {
      const ret = fetchCache.get(url);
      if (ret) {
        return ret;
      }
      const response = await (opts?.fetcher ?? fetch)(url);
      const json = await response.json();
      if (response.ok) {
        return cache(Loader.ok(json));
      }
      // eslint-disable-next-line no-console
      console.error(`Failed to fetch ${url} (${response.status})`, json);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Failed to fetch ' + url, e);
      return cache(Loader.error(e as Error));
    }
    return cache(Loader.error(new Error('Unkown fetch error')));
  });
}

/** Just a simple counter that is incremented every now and then */
export function useRefresherCounter(intervalMs: number, isBackgroundRefreshAllowed = false) {
  const [counter, setCounter] = useState(0);

  // Increment a counter every now and then, but only when the document is focused.
  const hidden = useDocumentHidden();
  const [sleptAt, setSleptAt] = useState<number | null>(
    document.hidden && !isBackgroundRefreshAllowed ? Date.now() : null,
  );
  useEffect(() => {
    if (hidden && !isBackgroundRefreshAllowed) {
      // only when tab is focused
      if (!sleptAt) {
        setSleptAt(Date.now());
      }
      return;
    }
    const inc = () => setCounter(cnt => cnt + 1);
    // normal behaviour: refresh every now and then
    const normalBehaviour = () => {
      const int = setInterval(inc, intervalMs);
      return () => clearInterval(int);
    };
    if (!sleptAt) {
      return normalBehaviour();
    }

    // Once document is focused again while we missed an interval, then increase counter and resume ticking.
    // This ensures that all underlying mechanisms that prevent requests by using `document.hidden`
    // will get refreshed when we focus the document for the first time
    const diff = Date.now() - sleptAt;
    setSleptAt(null);
    if (diff >= intervalMs) {
      // increment now (because we werent on tab a lot of time), and resume normal behaviour
      inc();
      return normalBehaviour();
    }
    // resume behaviour after a while
    const int = setTimeout(() => {
      inc();
      reset = normalBehaviour(); // hackkkkyyy
    }, intervalMs - diff);
    let reset = () => clearTimeout(int);
    return () => reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hidden]);
  return counter;
}

function cleanObjectDeep(myObject: any) {
  const newObject = {};
  Object.keys(myObject).forEach(k => {
    // if there is no value or an empty array, ignore it
    if ((!myObject[k] && myObject[k] !== 0) || (Array.isArray(myObject[k]) && !myObject[k].length)) {
      // do nothing
    } else if (typeof myObject[k] === 'object') {
      // The property is an object, make a recursive call in there
      cleanObjectDeep(myObject[k]);
      // if we have an object with real values in it, keep it, otherwise ignore it
      if (Object.keys(myObject[k]).length) {
        Object.assign(newObject, { [k]: myObject[k] });
      }
    } else {
      // if we have a value, keep it
      Object.assign(newObject, { [k]: myObject[k] });
    }
  });
  return newObject;
}

export function useSearchLocation<T extends Object>() {
  const history = useLocation();
  const navigate = useNavigate();

  // serialization / deserialization

  function serialize(data: T): Record<string, string> {
    return Object.fromEntries(
      Object.entries(cleanObjectDeep(data) ?? {}).map(([k, v]) => [k, btoa(JSON.stringify(v))]),
    );
  }

  function deserialize(search: string): T {
    if (!search) {
      return {} as T;
    }
    try {
      const data = new URLSearchParams(search);
      const ret = Object.fromEntries([...data.entries()].map(([k, v]) => [k, JSON.parse(atob(v))]));
      return ret as T;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn('Invalid search URL', e);
      return {} as T;
    }
  }

  // hold data
  const [data, setData] = useState(deserialize(history.search));

  // watch for changes
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setData(deserialize(history.search)), [history.search]);

  // update url
  function push(newValue: T) {
    navigate({ search: new URLSearchParams(serialize(newValue)).toString() });
  }

  return [data, push] as const;
}

export function useInfiniteScrollEffect(fn: () => Promise<void>, deps?: DependencyList, scrollId = '#page'): void {
  const [scrollLimitReached, setScrollLimitReached] = useState<boolean>(false);
  const scrollHandler = (e: Event) => handleScroll(e, fn, scrollLimitReached, setScrollLimitReached);
  useEffect(() => {
    document.querySelector(scrollId)?.addEventListener('scroll', scrollHandler);

    return () => {
      document.querySelector(scrollId)?.removeEventListener('scroll', scrollHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deps]);
}

function handleScroll(e: Event, fn: () => Promise<void>, scrollLimitReached: any, setScrollLimitReached: any) {
  const documentElement = e.target as any;
  /**
   *     height of the window
   *   + number of pixels scrolled
   */
  const currentScrollHeight = window.innerHeight + (documentElement.scrollTop ?? 0);
  const SCROLL_LIMIT_PERCENTAGE = 0.9;
  //     Has scrolled 90% of the page
  const hasReachedScrollLimit = currentScrollHeight >= (documentElement?.scrollHeight ?? 0) * SCROLL_LIMIT_PERCENTAGE;
  if (!scrollLimitReached && hasReachedScrollLimit) {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fn();
    setScrollLimitReached(true);
  }
  if (scrollLimitReached && !hasReachedScrollLimit) {
    setScrollLimitReached(false);
  }
}

export function useDocumentHidden() {
  const evt = useNestedEventListene('visibilitychange');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => document.hidden, [evt]);
}

interface DocEventData<K extends keyof DocumentEventMap> {
  count: number;
  lastData?: DocumentEventMap[K];
}

export function useNestedEventListene<K extends keyof DocumentEventMap>(event: K): DocEventData<K> {
  const [val, setVal] = useState<DocEventData<K>>({ count: 0 });
  useEffect(() => {
    const listener = (lastData: DocumentEventMap[K]) => setVal(({ count }) => ({ count: count + 1, lastData }));
    document.addEventListener(event, listener);
    return () => document.removeEventListener(event, listener);
  }, [event]);
  return val;
}

export const entryPathAtom = atom<{ path: string | null; time: number }>({
  key: 'entryPath',
  default: { path: '', time: 0 },
});

export function useGoBackOrFallback(fallbackUrl: string) {
  const entrypath = useRecoilValue(entryPathAtom);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  return () => {
    if (entrypath.path !== pathname) {
      // when we entered the app from another location as this one, then just go back
      navigate(-1);
    } else {
      // else, we can't know for sure wether if we're not coming from twitter,
      // if we just opened the tab, or if the user just refreshed the page.
      // => if we're coming from another webpage, then we dont want to go back there.
      // => just fallback !
      navigate(fallbackUrl, { replace: true });
    }

    // 👉 NB: we used the below code before, but it will get back on twitter, which we dont want.

    /**
     * Go to fallback page when there is no history
     * This will try to go back and redirect to fallback page if it fails.
     * Only looking at history.length is not enough to have a consistent behavior on
     * all browsers since most of them start their history.length at 1 and not 0.
     * Full discussion on this here: https://stackoverflow.com/a/7651297
     * */
    /*
        if (history.length) {
          history.goBack();
        } else {
          const prevPage = window.location.href;
          window.history.go(-1);
          setTimeout(function () {
            if (window.location.href === prevPage) {
              window.location.href = fallbackUrl;
            }
          }, 300);
        } */
  };
}

export function localStorageEffect<T>(key: string): AtomEffect<T> {
  return ({ setSelf, onSet }) => {
    try {
      const savedValue = localStorage.getItem(key);
      if (savedValue != null) {
        setSelf(JSON.parse(savedValue));
      }
    } catch (e) {
      console.error(`Could not initialize value of atom ${key}`, e);
    }

    onSet((newValue, _, isReset) => {
      if (isReset) {
        localStorage.removeItem(key);
      } else {
        localStorage.setItem(key, JSON.stringify(newValue));
      }
    });
  };
}
