import { gql } from '@apollo/client';

export const GET_NONCE_QUERY = gql`
  query getNonce($address: String!) {
    auth {
      nonce(address: $address)
    }
  }
`;

export const AUTHENTICATE_QUERY = gql`
  query authenticate($address: UserId!, $signature: HexString!, $message: String!, $magicAuth: MagicAuth) {
    auth {
      authenticate(address: $address, signature: $signature, message: $message, magicAuth: $magicAuth) {
        token
      }
    }
  }
`;

export const GET_MY_INFO = gql`
  query getMyInfo {
    myUser {
      id
      onboarding: setting(id: "onboarding")
    }
  }
`;
