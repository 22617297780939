import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

import { mainRoutes } from '@routes/mainRoutes';

import { SuspenseWithErrorFallback } from './LazyErrorRoute';

const CoinDetails = lazy(() => import('@coin-details/CoinDetails'));
const CoinTableList = lazy(() => import('@market/CoinTableList'));
const Favorites = lazy(() => import('@market/Favorites'));
const Overview = lazy(() => import('@overview/Overview'));

export const mainAppRoutes = [
  {
    path: mainRoutes.home.path,
    element: <Navigate to={mainRoutes.overview.path} />,
  },
  {
    path: mainRoutes.market.path,
    element: (
      <SuspenseWithErrorFallback>
        <CoinTableList />
      </SuspenseWithErrorFallback>
    ),
  },
  {
    path: mainRoutes.overview.path,
    element: (
      <SuspenseWithErrorFallback>
        <Overview />
      </SuspenseWithErrorFallback>
    ),
  },
  {
    path: mainRoutes.coinDetails.path,
    element: (
      <SuspenseWithErrorFallback>
        <CoinDetails />
      </SuspenseWithErrorFallback>
    ),
  },
  {
    path: mainRoutes.favorites.path,
    element: (
      <SuspenseWithErrorFallback>
        <Favorites />
      </SuspenseWithErrorFallback>
    ),
  },
  {
    path: mainRoutes.community.path,
    element: <div>Community page</div>,
  },
  {
    path: mainRoutes.rewards.path,
    element: <div>Rewards page</div>,
  },
];
