import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { match } from 'ts-pattern';

import { useMainVault, refreshMainVaultData } from '@hooks/useMainVault';
import { useTokenInfo } from '@hooks/useTokensInfo';
import { SingleSwapPayload, useSwap } from '@tetris/swaps';
import { SendTxButton } from '@transactions/components/SendTxButton';
import { TxSpeed } from '@transactions/components/TransactionSpeed';
import IncentiveMessage from '@transactions/components/ui/IncentiveMessage';
import { Button } from '@ui-kit/atoms/Button';
import { Divider } from '@ui-kit/atoms/Divider';
import { TxType } from '@ui-kit/organisms/SingleTxReviewContent';
import {
  BudgetWithQuote,
  ICoin,
  Loader,
  chainOf,
  convertAmt,
  getDefaultTokenForChain,
  parseAddress,
  parseNum,
  useTokenQuote,
} from '@utils';
import { useWallet } from '@wallet';

import { SwapUI } from './SwapUI';
import { SwapFlowReducer, SwapFlowState } from './swapFlow.reducer';
import { getTransactionRecap } from './swapFlow.utils';
import { useChangeCoin } from '../../../hooks/useChangeCoin';
import { useHighestHolding } from '../../../hooks/useHighestHolding';
import { useTransactionFlow } from '../../../hooks/useTransactionFlow';
import { SwapFlowProps, UnfairLevel } from '../../../types';
import { computePriceDiff, getUnfairLevelForPriceDiff } from '../../../utils';
import TransactionRecap, { TransactionDetailsEntries } from '../../TransactionRecap';

export default function SwapFlow({ prefillInputTokenId, prefillOutputTokenId }: SwapFlowProps) {
  const { currentChain, setCurrentChain, settings, resetScroll } = useTransactionFlow();
  const [refresh, setRefresh] = useState(true);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const { t } = useTranslation();

  const wallet$ = useWallet(false);
  const login = wallet$.makeCallback(({ login: l }) => l());

  useEffect(() => {
    setRefresh(r => !r);
  }, [settings.slippage]);

  const authed$ = wallet$.map(w => w.isAuthed);
  const defaultInputTokenId$ = useHighestHolding(currentChain)
    .map(holding => holding?.token.id)
    .mapNotLoaded('skipped', [currentChain], () => getDefaultTokenForChain(currentChain))
    .map(_tokenId => _tokenId || getDefaultTokenForChain(currentChain));

  const [swapState$, dispatch] = Loader.array([authed$, defaultInputTokenId$] as const)
    .noFlickering()
    .map<SwapFlowState>(([isAuthed, _defaultTokenId]) => ({
    inputTokenId: _defaultTokenId,
    outputTokenId: prefillOutputTokenId,
    inputAmount: 0n,
    isMaxBudget: false,
    isAuthed,
    lastBudget: null,
  }))
    .asReducer(SwapFlowReducer);

  Loader.array([swapState$, defaultInputTokenId$] as const).onOk(function resetTokensIfNotOnCurrentChain([
    _state,
    _defaultTokenId,
  ]) {
    // reset output token if it's not on the current chain
    if (_state.outputTokenId && chainOf(_state.outputTokenId) !== currentChain) {
      dispatch({
        type: 'SET_OUTPUT_TOKEN_ID',
        payload: undefined,
      });
    }

    // reset input token if it's not on the current chain, in that case we use the default token id.
    if (_state.inputTokenId && chainOf(_state.inputTokenId) !== currentChain) {
      if (_defaultTokenId && !currentChain) {
        (async () => setCurrentChain(chainOf(_defaultTokenId)))().catch(e => e);
      }
      dispatch({
        type: 'SET_DEFAULT',
        payload: {
          inputTokenId: _defaultTokenId,
          inputAmount: 0n,
        },
      });
    }
  });

  useEffect(
    function setPrefillInputTokenIdIfProvided() {
      if (prefillInputTokenId) {
        (async () => setCurrentChain(chainOf(prefillInputTokenId)))().catch(e => e);
        dispatch({ type: 'SET_INPUT_TOKEN_ID', payload: prefillInputTokenId });
      }
    },
    [prefillInputTokenId],
  );

  useEffect(
    function setPrefillOutputTokenIdIfProvided() {
      if (prefillOutputTokenId) {
        (async () => setCurrentChain(chainOf(prefillOutputTokenId)))().catch(e => e);
        dispatch({ type: 'SET_OUTPUT_TOKEN_ID', payload: prefillOutputTokenId });
      }
    },
    [prefillOutputTokenId],
  );

  const inputTokenId$ = swapState$.map(s => s.inputTokenId);
  const outputTokenId$ = swapState$.map(s => s.outputTokenId);
  const inputTokenInfo$ = useTokenInfo(inputTokenId$, true);
  const inputQuote$ = useTokenQuote(inputTokenId$).noFlickering();
  const outputTokenInfo$ = useTokenInfo(outputTokenId$, true).noFlickering();
  const outputQuote$ = useTokenQuote(outputTokenId$).noFlickering();

  const inputTokenId = inputTokenId$.unwrapOr(null);
  const outputTokenId = inputTokenId$.unwrapOr(null);

  const changeSourceCoin = useChangeCoin(false, {
    disabledCoins: outputTokenId ? { [outputTokenId]: '' } : undefined,
    onlyOwned: true,
  });

  const changeTargetCoin = useChangeCoin(false, {
    disabledCoins: inputTokenId ? { [inputTokenId]: '' } : undefined,
  });

  const vault$ = useMainVault(false, true).mapNotLoaded('skipped', () => null);

  const sourceHoldings$ = vault$.combine(inputTokenId$, (_vault, _inputTokenId) => {
    return parseNum(_vault?.spot.find(holding => holding.token.id === _inputTokenId)?.qty || 0n);
  });

  const targetHoldings$ = vault$.combine(outputTokenId$, (_vault, _outputTokenId) => {
    return parseNum(_vault?.spot.find(holding => holding.token.id === _outputTokenId)?.qty || 0n);
  });

  const sourceBudget$ = Loader.array([inputTokenInfo$, inputQuote$, swapState$] as const).map<BudgetWithQuote<ICoin>>(
    ([tokenInfo, quote, state]) => {
      return {
        token: tokenInfo,
        quote,
        amtBase: state.inputAmount,
      };
    },
  );
  // save last source budget to restore it on coin change
  sourceBudget$.onOk(b => dispatch({ type: 'SET_LAST_BUDGET', payload: b }));

  const swapPayload$ = Loader.array([
    sourceBudget$,
    outputTokenInfo$,
    sourceHoldings$,
    swapState$.map(s => s.isMaxBudget),
  ] as const).map<SingleSwapPayload>(([_sourceBudget, _outputTokenInfo, _sourceHoldings, _isMaxBudget]) => {
    if (_sourceBudget.amtBase === 0n) return Loader.skipped as any;
    return {
      spendTokenBudget: _isMaxBudget ? { ..._sourceBudget, amtBase: _sourceHoldings } : _sourceBudget,
      buyTokenAddress: _outputTokenInfo.id,
      slippage: settings.slippage,
    };
  });
  const {
    sendTx: send$,
    compilation: compilation$,
    operation,
  } = useSwap(
    currentChain,
    swapPayload$,
    {
      failedToast: {
        content: t('Transactions.ToastStatus.tradeFailed', {
          inputToken: sourceBudget$.match.notOk(() => '').ok(budget => budget.token.symbol),
          outputToken: outputTokenInfo$.match.notOk(() => '').ok(token => token.symbol),
        }),
      },
    },
    refresh,
  );

  operation.onOk([dispatch], () => {
    dispatch({
      type: 'RESET_FLOW',
    });

    refreshMainVaultData();
  });

  const swapData$ = compilation$.map(c => c.metadata[0]);

  const targetAmount$ = swapData$
    .map([outputTokenInfo$], swapData => (outputTokenInfo$.isLoading ? Loader.loading : BigInt(swapData?.outputAmount)))
    .mapNotLoaded('skipped', () => 0n)
    .mapNotLoaded('error', () => 0n);

  const targetBudget$ = Loader.array([outputTokenInfo$, outputQuote$, targetAmount$] as const).map<
  BudgetWithQuote<ICoin>
  >(([_outputTokenInfo, _outputQuote, _targetAmount]) => {
    return {
      token: _outputTokenInfo,
      quote: _outputQuote,
      amtBase: _targetAmount,
    };
  });

  const WarnigMessage = Loader.array([compilation$, send$] as const)
    .noFlickering()
    .match.loadingOrSkipped(() => null)
    .error(e => {
      if (e.message === 'INSUFFICIENT_ASSET_LIQUIDITY') {
        <IncentiveMessage
          type="error"
          title={t('Transactions.IncentiveMessage.insufficientLiquidityTitle')}
          description={t('Transactions.IncentiveMessage.insufficientLiquidityDescription')}
        />;
      }
      return null;
    })
    .ok(() => null);

  const sendTxButton = Loader.array([
    sourceBudget$,
    sourceHoldings$,
    targetBudget$,
    inputQuote$,
    outputQuote$,
    send$.map(s => s.sendNext),
    swapData$,
  ] as const)
    .match.skipped(() => <Button size="l" label={t('Transactions.Swap.confirmSwap')} fullWidth disabled />)
    .loading(() => (
      <Button
        size="l"
        label={t('Transactions.Swap.fetchingQuotes')}
        fullWidth
        disabled
        isLoadingLabel={t('Transactions.Swap.fetchingQuotes')}
        isLoading
      />
    ))
    .error(e => {
      if (e.message === 'INSUFFICIENT_ASSET_LIQUIDITY') {
        return <Button size="l" label={t('Transactions.Recap.insufficientLiquidity')} fullWidth disabled />;
      }
      return <Button size="l" label={t('Transactions.Recap.noRouteFound')} fullWidth disabled />;
    })
    .ok(([sourceBudget, sourceBalance, targetBudget, inputQuote, outputQuote, _onConfirm, _swapData]) => {
      const unfairLevel = getUnfairLevelForPriceDiff(
        computePriceDiff(Number(_swapData.price), inputQuote, outputQuote),
      );

      if (sourceBudget.amtBase > sourceBalance) {
        return <Button size="l" label={t('Transactions.Recap.insufficientBalance')} fullWidth disabled />;
      }
      const isInputSameAsOutput = sourceBudget.token.id === targetBudget.token.id;

      return (
        <SendTxButton
          sendTx={_onConfirm}
          disabled={!sourceBudget.amtBase || !sourceBudget.token || isInputSameAsOutput}
          isLoading={operation.isLoading}
          label={match(unfairLevel)
            .with(UnfairLevel.MEDIUM, () => t('Transactions.Swap.swapAnyway'))
            .otherwise(() => t('Transactions.Swap.confirmSwap'))}
          dataCy="AmountScreen_cta"
          txData={{
            variant: TxType.swap,
            inputToken: { ...sourceBudget, quote: inputQuote },
            outputToken: { ...targetBudget, quote: outputQuote },
            fees: { total: 0, paraswap: 0, nested: 0, network: 0 },
            txSpeed: TxSpeed.normal,
            onSelectTxSpeed: () => {},
            onOpenSlippageSettings: () => {},
            rate: settings.slippage,
            aggregator: _swapData.dex,
          }}
          unfairLevel={getUnfairLevelForPriceDiff(computePriceDiff(Number(_swapData.price), inputQuote, outputQuote))}
        />
      );
    });

  const transactionRecap$ = Loader.array([
    sourceBudget$,
    targetBudget$,
    inputQuote$,
    outputQuote$,
    swapData$,
  ] as const).map<Partial<TransactionDetailsEntries>>(
    [outputTokenInfo$],
    ([_sourceBudget, _targetBudget, _sourceQuote, _targetQuote, _swapData]) => {
      // as we can switch budget immediately the swap might still be in former state.
      // We need to wait for the swap to be ready to produce the right data and avoid a glitch.
      if (
        parseAddress(_targetBudget.token.id).address !== _swapData.outputToken &&
        parseAddress(_sourceBudget.token.id).address !== _swapData.inputToken
      ) {
        return Loader.loading as any;
      }
      return getTransactionRecap(
        _sourceBudget,
        _targetBudget,
        _sourceQuote,
        _targetQuote,
        _swapData,
        settings.slippage,
      );
    },
  );

  const switchTokens = Loader.array([sourceBudget$, targetBudget$]).makeCallback(([_sourceBudget, _targetBudget]) => {
    dispatch({
      type: 'SWITCH_TOKENS',
      payload: {
        inputAmount: convertAmt(_sourceBudget.amtBase, _sourceBudget.token.decimals, _targetBudget.token.decimals),
      },
    });
  });

  const hasEnoughBalance = Loader.array([sourceBudget$, sourceHoldings$, swapState$.map(_s => _s.isAuthed)] as const)
    .match.notOk(() => true)
    .ok(([_sourceBudget, _sourceHoldings, _isAuthed]) => {
      return _isAuthed ? _sourceBudget.amtBase <= _sourceHoldings : true;
    });

  const coinSelectHandler = sourceBudget$.makeCallback((budget, coin: ICoin | null) => {
    if (coin) {
      resetScroll();
      dispatch({
        type: 'SET_INPUT_AMOUNT',
        payload: { value: convertAmt(budget.amtBase, budget.token.decimals, coin.decimals), isMaxBudget: false },
      });
      dispatch({ type: 'SET_INPUT_TOKEN_ID', payload: coin.id as ChainAddress });
    }
    setTimeout(() => inputRef.current?.focus(), 100);
  });

  return (
    <section className="flex flex-col gap-4 overflow-scroll h-full hide-scrollbars">
      <SwapUI
        sourceBudget={sourceBudget$}
        targetBudget={targetBudget$}
        price={swapData$.map(swap => Number(swap?.price))}
        onSourceChange={(b, isMax) => {
          dispatch({ type: 'SET_INPUT_AMOUNT', payload: { value: b.amtBase, isMaxBudget: !!isMax } });
        }}
        onSourceCoinSelect={() => changeSourceCoin(coinSelectHandler)}
        sourceBalance={sourceHoldings$}
        targetBalance={targetHoldings$}
        onTargetCoinSelect={() =>
          changeTargetCoin(
            coin =>
              coin && resetScroll() && dispatch({ type: 'SET_OUTPUT_TOKEN_ID', payload: coin.id as ChainAddress }),
          )
        }
        inputRef={inputRef}
        onSwitchTokens={switchTokens}
        sourceInputError={!hasEnoughBalance ? t('Common.Errors.insufficientBalance') : undefined}
        isOperationLoading={operation.isLoading}
      />
      {WarnigMessage}
      {swapState$.match
        .notOk(() => <Divider />)
        .ok(_s =>
          _s.inputTokenId && _s.outputTokenId && _s.inputAmount > 0n ? (
            <div className="h-fit">
              <TransactionRecap
                txData={transactionRecap$}
                onCountdownComplete={() => setRefresh(r => !r)}
                resetDep={_s.inputTokenId}
                operation={operation}
              />
            </div>
          ) : (
            <Divider />
          ),
        )}
      {Loader.array([swapState$, authed$])
        .match.notOk(() => <Button size="l" label={t('Transactions.Swap.confirmSwap')} fullWidth disabled />)
        .ok(([_s, _authed]) =>
          _authed ? (
            sendTxButton
          ) : (
            <Button label={t('Common.connectWallet')} onClick={login} variant="surface-accent" size="l" fullWidth />
          ),
        )}
    </section>
  );
}
