/* eslint-disable max-classes-per-file */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import { OperationVariables, useApolloClient } from '@apollo/client';
import { DocumentNode } from 'graphql';
import {
  Dispatch,
  Reducer,
  SetStateAction,
  useCallback,
  useDebugValue,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { deepEqual, isPromiseLike, nullish } from './basic-utils';
// eslint-disable-next-line import/no-cycle
import { fetchApollo, useQueryLoader, UseQueryOptions } from './react-utils';

/**
=============================== ASYNC LOADER ===============================

Examples of what can be done:

export function FetchValue() {
  const [refresh, setRefresh] = useState(0);

  const txt = Loader
    .async([refresh], async () => {
      const value = await fetch('https://api.staging.nested.finance/');
      return await value.text();
    });

  return <div>Querying:
    {txt.match
      .loadingOrSkipped(() => 'Loading...')
      .error((err) => `Error: ${err.message}`)
      .ok((value) => <div>Response <b>{value}</b></div>)
    }
    <button onClick={() => setRefresh(refresh + 1)}>Refresh</button>
  </div>
}

export function SearchTokens() {
  const [value, setValue] = useState('');

  const searcher = Loader
    .useWrap(value)
    .map(x => x.trim().toLowerCase()) // trim and lowercase
    .map(x => x : Loader.skipped) // skip when nothing to search
    .debounce(500) // debounce search str
    .inspect(x => console.log('searching for', x)) // log search
    .query<SearchToken>( // perform search
      gql_`query SearchToken($search: String!) {
      # tokens(chain: eth, search: $search) { id name }
    }`, txt => ({ search: txt }))
    .map(x => x.tokens); // map result

  const display = searcher
    .noFlickering() // avoid showing "loading" when being searched

  return <div>
    <input type="text" value={value} onChange={e => setValue(e.target.value)} />
    Results ({searcher.status === 'loading' ? '🔥' : '😴'})
    {display.match
      .loading(() => <div>Loading</div>)
      .skipped(() => <div>Type to search</div>)
      .error((e) => <div>Error: {e.message}</div>)
      .ok((toks) => <div> {toks.map(x => <div key={x.id}>{x.name}</div>)} </div>)
    }
  </div>;
}

export function TokenList() {
  const [query] = useQueryLoader<GetToks>(gql_`query GetToks {
    # tokens(chain: eth) { id name }
  }`);

  const tokens = query
    .map(x => x.tokens);

  const tokenIds = tokens
    .map(toks => toks.map(t => t.id))

  const quotes = useTokensQuotes(tokenIds);

  const withQuotes = tokens
    .combine(quotes, (tokens, quotes) => tokens.map(t => ({
      ...t,
      quote: quotes.get(t.id)
    })));

  const hello = tokens
    .makeCallback((toks, target: MouseEvent) => {
      target.preventDefault();
      alert('hello ' + toks[0].name);
    }, (status) => alert('Not loaded ! Status: ' + status));

  return <div>
    {withQuotes.match
      .loading(() => <div>Loading</div>)
      .skipped(() => <div>Skipped</div>)
      .error((e) => <div>Error: {e.message}</div>)
      .ok((toks) => <div> {toks.map(x => <div key={x.id}>{x.name}
            is worth <Float.UsdPrice value={x.quote} /> </div>)} </div>)
    }
    <button onClick={hello}>Hello</button>
  </div>
}

 */

export type ResultBuilder<result> = SyncResult<result> | PromiseLike<SyncResult<result>>;
export type SyncResult<result> = result | Loader<result>;

export type ResultOf<result> = result extends Loader<infer inner>
  ? IfAny<inner, never, inner>
  : result extends PromiseLike<infer inner>
    ? ResultOf<inner>
    : IfAny<result, never, result>;

export type Unwrap<value> = value extends Loader<infer inner> ? Unwrap<inner> : value;

export type LoadStatus = 'ok' | NotOkStatus;
export type NotOkStatus = 'loading' | 'error' | 'skipped';

export type Loadable<val> = val | Loader<val>;

export interface Loader<val> {
  /** Current loader status */
  readonly status: LoadStatus;

  readonly isOk: boolean;
  readonly isLoading: boolean;
  readonly isSkipped: boolean;
  readonly isLoadingOrSkipped: boolean;
  readonly isError: boolean;

  /**
   * Match loader value, to ensure proper state handling
   *
   * ex:
   * ```typescript
   *   return <div>
   *      {
   *        myLoader.match
   *          .loading(() => <div>loading</div>)
   *          .error(error => <div>error: {error}</div>)
   *          .skipped(() => <div>skipped</div>)
   *          .ok(value => <div>value: {value}</div>)
   *     }
   *  </div>;
   * ```
   */
  match: LoaderMatcher<val>;

  /**
   * Maps a result to another type.
   *
   * ex:
   * ```typescript
   *   loader.map(value => value + 1)
   * ```
   *
   * nb: you can return a promise, or a custom load value like `Loader.error()`, `Loader.skipped()`...
   */
  map<result extends ResultBuilder<any>>(value: (value: val) => result): Loader<ResultOf<result>>;
  map<result extends ResultBuilder<any>>(
    dependencies: any[] | nil,
    value: (value: val) => result,
  ): Loader<ResultOf<result>>;

  /**
   * Accumulates all loaded values into a single value.
   *
   * ex:
   * ```typescript
   * const [page, setPage] = useState(0);
   * const paginationLoader = Loader.query(gqlx`query GetData($page: Int!){...}`, { variables: {page}})
   *                   .map(x => x.myData);
   * const allResults = paginationLoader.reduce((prev, next) => [...prev, ...next])
   * const nextPage = () => setPage(page + 1);
   * ```
   *
   * nb: you can pass an optional last argument which will reset the accumulator when the given value has changed.
   */
  reduce<result>(
    value: (previous: result | null, next: val) => result,
    clearWhenHasNewValue?: any,
  ): Loader<ResultOf<result>>;
  reduce<result>(
    dependencies: any[] | nil,
    value: (previous: result | null, next: val) => result,
    clearWhenHasNewValue?: any,
  ): Loader<result>;
  /**
   * Combines this loader with another loader
   *
   * ex:
   * ```typescript
   *  loader.combine(otherLoader, (value, other) => value + other)
   * ```
   */
  combine<other, result extends ResultBuilder<any>>(
    other: Loader<other>,
    value: (value: val, other: other) => result,
  ): Loader<ResultOf<result>>;
  combine<other, result extends ResultBuilder<any>>(
    dependencies: any[] | nil,
    other: Loader<other>,
    value: (value: val, other: other) => result,
  ): Loader<ResultOf<result>>;

  /**
   * Builds a new loader,
   * that will skipped when the current loader is not of the given status,
   * but which will start loading when this loader is of the given status.
   *
   * ex:
   * ```typescript
   * const skipable = ...;
   *
   * const whenSkipped = skipable.mapNotLoaded('skipped', () => do something);
   */
  mapNotLoaded<result extends ResultBuilder<any>>(
    status: NotOkStatus,
    value: () => result,
  ): Loader<ResultOf<result> | val>;
  mapNotLoaded<result extends ResultBuilder<any>>(
    status: NotOkStatus,
    dependencies: any[] | nil,
    value: () => result,
  ): Loader<ResultOf<result> | val>;

  /**
   * Create a loader which OK values will be debounced (value in ms), to avoid too fast changes.
   *
   * ex:
   * ```typescript
   * const debounced = Loader.ok(myTextInput.value)
   *      .debounce(1000)
   *      .map(value => doSomethingCostly(value));
   * ```
   *
   * By default the loader will be in loading state only after the debounce delay.
   * You can force it to be in loading state immediately by passing `true` as second argument.
   * The loader will then be in loading state as soon as the parent loader is in loading state.
   */
  debounce(ms: number, loadImmediately?: boolean): Loader<val>;

  /**
   * Prevents data from flickering: Once it reached an OK state, then it wont display any further "loading" state.
   *
   * You can provide an optional invariant,
   * which will be able to reset this behaviour in order to re-enter loading state.
   */
  noFlickering(invariant?: any): Loader<val>;

  /** Triggers an effect on OK */
  onOk(effect: (value: val) => void): void;
  onOk(dependencies: any[] | nil, effect: (value: val) => void): void;

  /** Triggers an effect on error */
  onError(effect: (error: Error) => void): void;
  onError(dependencies: any[] | nil, effect: (error: Error) => void): void;

  /** Triggers an effect on skipped */
  onSkipped(effect: () => void): void;
  onSkipped(dependencies: any[] | nil, effect: () => void): void;

  /** Triggers an effect on loading */
  onLoading(effect: () => void): void;
  onLoading(dependencies: any[] | nil, effect: () => void): void;

  /** Triggers a graphql query, which variables are based on the current loader value */
  query<TData, TVariables extends object = OperationVariables>(
    query: DocumentNode,
    variables: (value: val) => TVariables,
  ): Loader<TData>;
  query<TData, TVariables extends object = OperationVariables>(
    dependencies: any[] | nil,
    query: DocumentNode,
    variables: (value: val) => TVariables,
  ): Loader<TData>;

  /**
   * Builds a callback that will have access to the current loader value.
   * The callback will be called only when the loader is OK.
   *
   * nb: use the optional second argument if you'd like to be called even if the loader is not OK.
   *
   * ex:
   * ```typescript
   * // onClick will have the type: (target: HTMLElement) => void
   * const onClick = loader.makeCallback((loadedData, target) => doSomething(loadedData, target));
   *
   * return <button onClick={onClick} disabled={onClick.loader.isLoading}>click me</button>
   * ```
   *
   * nb: `onClick.loader` will be a skipped loader, until the first time the callback is called.
   * Once it IS called, it will become a loading loader until the callback is done,
   *   in which case, it will be an OK loader containing the return value of the callback.
   *
   * ex usage:
   * ```typescript
   * // like this
   * const otherLoader = onClick.loader.query(gqlx`query GetData($id: ID!){...}`, id => { variables: {id}});
   *
   * // or like this
   * return <button onClick={onClick} disabled={onClick.loader.isLoading}>click me</button>
   * ```
   */
  makeCallback<fn extends (value: val, ...args: any[]) => any, errorResult = void>(
    okCallback: fn,
    otherStatesCallback?: (status: Error | 'loading' | 'skipped') => errorResult,
  ): CallbackWithData<val, fn, errorResult>;
  makeCallback<fn extends (value: val, ...args: any[]) => any, errorResult = void>(
    dependencies: any[] | nil,
    okCallback: fn,
    otherStatesCallback?: (status: Error | 'loading' | 'skipped', ...args: any[]) => errorResult,
  ): CallbackWithData<val, fn, errorResult>;

  /**
   * Use this loader to build a react state, that will take this loader as initial value.
   */
  asState<ofType = val>(): val extends ofType ? [Loader<ofType | val>, Dispatch<SetStateAction<ofType>>] : never;

  asReducer<A>(reducerFn: Reducer<val, A>): [Loader<val>, Dispatch<A>];

  /**
   * @deprecated DEBUG ONLY
   * inspect the loader value when it changes (for debug purpose).
   *
   * ex:
   *  loader
   *     .combine(...)
   *     .inspect(value => console.log(value))
   *     .map(...)
   */
  inspect(value: (value: val | 'loading' | 'skipped' | Error) => void): Loader<val>;

  /** Adds this loader in react devtools (via a useDebug()) */
  debug(): Loader<val>;

  /** Log this loader value every time it changes */
  log(msg?: string): Loader<val>;

  unwrapOr<T>(fallback: T): val | T;
}

export type CallbackWithData<val, fn, otherResult> = fn extends (value: val, ...args: infer args) => infer result
  ? Callback<args, ResultOf<result | otherResult>>
  : never;

export type Callback<args extends any[], result> = ((...args: args) => void) & { readonly loader: Loader<result> };

export interface LoaderMatcher<val> {
  skipped<result>(value: () => result): ChooseLoading<val, result>;
  loadingOrSkipped<result>(value: () => result): ChooseError<val, result>;
  notOk<result>(value: (status: NotOkStatus) => result): ChooseValue<val, result>;
}

export interface ChooseLoading<val, prevResult> {
  loading<result>(value: () => result): ChooseError<val, prevResult | result>;
  notOk<result>(value: (status: NotOkStatus) => result): ChooseValue<val, prevResult | result>;
}

export interface ChooseError<val, prevResult> {
  error<result>(value: (error: Error) => result): ChooseValue<val, prevResult | result>;
}

export interface ChooseValue<val, prevResult> {
  ok<result>(value: (value: val) => result): prevResult | result;
}

type LoadPattern<val> = {
  data: val;
  error: Error | null;
  isLoading: boolean;
};

interface LoaderBuilder {
  /** Builds a loader that is always in loaded state (instances changes at each call) */
  ok<val>(value: val): Loader<val>;
  /** Builds a loader that is always in loaded state, and memoize it */
  useOk<val>(value: val): Loader<val>;
  /** Shortcut for `isLoader(value) ? value : Loader.ok(value)` */
  wrap<val>(value: val): Loader<Unwrap<val>>;
  /** Converts a react async pattern ({data, isError, isLoading }) to a loader */
  fromReactAsyncPattern<val>(val: LoadPattern<val>): Loader<val>;
  /** Same as `Loader.wrap()`, but memoized */
  useWrap<val>(value: val): Loader<Unwrap<val>>;
  /** Builds a loader that is always in error state */
  error<val = any>(error: string | Error): Loader<val>;
  /** Builds a loader that is always in error state, and memoize it */
  useError<val = any>(error: Error): Loader<val>;
  /** The "loading" state (you can compare a loader to this, like `if (myLoader === Loader.loading)) */
  readonly loading: Loader<any>;
  /** The "skipped" state */
  readonly skipped: Loader<any>;
  /**
   * Builds a loader from an async function
   *
   * NB: This will ensure that no two promise execution will overlap.
   * If too many executions are scheduled, then only the last scheduled will be executed.
   */
  async<val extends ResultBuilder<any>>(value: () => val): Loader<ResultOf<val>>;
  async<val extends ResultBuilder<any>>(deps: any[] | nil, value: () => val): Loader<ResultOf<val>>;
  /**
   * Builds a single loader from an array of loaders
   * ex:
   * ```typescript
   * const valueSum = Loader
   *  .array([a, b, c])
   *  .map(values => sum(values));
   * ```
   */
  array<loaders extends readonly Loadable<any>[]>(loaders: loaders): Loader<LoaderArray<loaders>>;
  /**
   * Starts a graphql query.
   *
   * (shortcut for useQuery(...)[0])
   */
  query<TData, TVariables extends OperationVariables = {}>(
    query: DocumentNode,
    options?: UseQueryOptions<TVariables>,
  ): Loader<ResultOf<TData>>;
}

type LoaderArray<l> = l extends readonly [Loadable<infer val>, ...infer rest]
  ? readonly [val, ...LoaderArray<rest>]
  : l extends readonly Loadable<infer val>[]
    ? readonly val[]
    : l extends readonly []
      ? readonly []
      : never;

// ======================================================================
// =========================== IMPLEMENTATION ===========================
// ======================================================================

const _loading = Symbol('loading');
const _skipped = Symbol('skipped');
type _internalState<val> = // ⚡ DO NOT EXPORT !!
  val | Error | typeof _loading | typeof _skipped;

function getStatus(v: _internalState<any>): LoadStatus {
  if (v === _loading) {
    return 'loading';
  }
  if (v === _skipped) {
    return 'skipped';
  }
  if (v instanceof Error) {
    return 'error';
  }
  return 'ok';
}

// ⚡ DO NOT EXPORT !!
class LoaderImpl<val> implements Loader<val> {
  get status(): LoadStatus {
    return getStatus(this._v);
  }

  get isSkipped() {
    return this.status === 'skipped';
  }

  get isOk() {
    return this.status === 'ok';
  }

  get isLoading() {
    return this.status === 'loading';
  }

  get isError() {
    return this.status === 'error';
  }

  get isLoadingOrSkipped() {
    return this.isLoading || this.isSkipped;
  }

  get match(): LoaderMatcher<val> {
    return new LoaderMatcherImpl(this._v);
  }

  constructor(
    private readonly _v: _internalState<val>, // ⚡ MUST REMAIN PRIVATE !!
  ) {
    if (isLoader(_v)) {
      throw new Error('invalid loader value');
    }
  }

  /** @deprecated do not use elsewhere (except this file) */
  _getValueInternal(): _internalState<val> {
    // ⚡ DO NOT DECLARE IN INTERFACE !!
    return this._v;
  }

  map<result>(
    a: any[] | nil | ((value: val) => ResultBuilder<result>),
    b?: (value: val) => ResultBuilder<result>,
  ): Loader<result> {
    const dependencies = Array.isArray(a) || nullish(a) ? a ?? [] : [];
    const fetcher = Array.isArray(a) || nullish(a) ? b! : a;
    // react to inputs
    return _useDeduplicator<result>(
      Loader.loading,
      () => {
        if (this._v === _loading || this._v === _skipped || this._v instanceof Error) {
          return this as unknown as Loader<result>;
        }

        // ===== OK  => fetch
        return fetcher(this._v);
      },
      [this._v, ...dependencies],
    );
  }

  reduce<result>(
    a: any[] | nil | ((previous: result | null, next: val) => result),
    b?: (previous: result | null, next: val) => result,
    c?: any,
  ): Loader<result> {
    const dependencies = Array.isArray(a) || nullish(a) ? a ?? [] : [];
    const fetcher = Array.isArray(a) || nullish(a) ? b! : a;
    const clear = Array.isArray(a) || nullish(a) ? c : b;

    const [previousResult, setPreviousResult] = useState<result | null>(null);
    const [changeFlag, setChangeFlag] = useState(clear);

    return this.map(dependencies, async newValue => {
      let previous: result | null = null;
      if (deepEqual(changeFlag, clear)) {
        // fetch previous value
        previous = previousResult;
      } else {
        // reset the accumulator
        setChangeFlag(clear);
      }

      const ret = fetcher(previous, newValue);
      setPreviousResult(ret);
      return ret;
    });
  }

  mapNotLoaded<result>(
    status: NotOkStatus,
    a: any[] | nil | (() => ResultBuilder<result>),
    b?: () => ResultBuilder<result>,
  ): Loader<result | val> {
    const dependencies = Array.isArray(a) || nullish(a) ? a ?? [] : [];
    const fetcher = Array.isArray(a) || nullish(a) ? b! : a;
    // react to inputs
    return _useDeduplicator<result | val>(
      this,
      () => {
        if (this.status !== status) {
          return this;
        }
        // fetch when this loader is skipped
        return fetcher();
      },
      [this._v, ...dependencies],
    );
  }

  debounce(ms: number, loadImmediately?: boolean): Loader<val> {
    const [delayedValue, setDelayedValue] = useState<LoaderImpl<val>>(Loader.loading as LoaderImpl<val>);

    useEffect(() => {
      if (deepEqual(delayedValue._v, this._v)) {
        return;
      }
      if (loadImmediately && this.isLoading) {
        setDelayedValue(Loader.loading as LoaderImpl<val>);
      }
      const handler = setTimeout(() => {
        if (deepEqual(this._v, delayedValue._v)) {
          return;
        }
        setDelayedValue(this);
      }, ms);
      return () => {
        clearTimeout(handler);
      };
    }, [this._v]);
    return delayedValue;
  }

  noFlickering(invariant?: any): Loader<val> {
    const [finalData, setFinalData] = useState(this);
    const [resetInvariant, setResetInvariant] = useState(invariant);
    useEffect(() => {
      if (deepEqual(finalData._v, this._v)) {
        return;
      }
      if (deepEqual(resetInvariant, invariant) && finalData.status === 'ok' && this._v === _loading) {
        // do not notify changes when we're going from 'ok' to 'loading'
        return;
      }
      setResetInvariant(invariant);
      setFinalData(this);
    }, [this._v, invariant]);
    return finalData;
  }

  asState<ofType = val>(): val extends ofType ? [Loader<ofType | val>, Dispatch<SetStateAction<ofType>>] : never {
    const [val, setVal] = useState<Loader<ofType | val>>(this);
    const [finished, setFinished] = useState(false);

    this.onLoading(() => {
      setFinished(false);
      setVal(Loader.loading);
    });

    this.onOk([finished], newValue => {
      if (!finished) {
        setVal(Loader.ok(newValue));
      }
    });

    this.onSkipped([finished], () => {
      if (!finished) {
        setVal(Loader.skipped);
      }
    });

    this.onError([finished], error => {
      if (!finished) {
        setVal(Loader.error(error));
      }
    });

    const setState: Dispatch<SetStateAction<ofType>> = useCallback(
      newVal => {
        if (typeof newVal === 'function') {
          const dispatcher = newVal as (prev: ofType) => ofType;
          if (val.isOk) {
            setFinished(true);
            setVal(v => Loader.ok(dispatcher(getOkValueDangerous(v) as ofType)));
          }
        } else {
          setFinished(true);
          setVal(Loader.ok(newVal));
        }
      },
      [val.isOk],
    );

    return [val, setState] as any;
  }

  asReducer<ActionType>(reducerFn: Reducer<val, ActionType>): [Loader<val>, Dispatch<ActionType>] {
    const [$val, setVal] = this.asState<val>();

    const dispatch = useCallback(
      (action: ActionType) => {
        setVal(val => reducerFn(val, action));
      },
      [setVal],
    );

    return [$val, dispatch];
  }

  inspect(value: (value: val | 'loading' | 'skipped' | Error) => void): Loader<val> {
    useEffect(() => {
      if (this._v === _loading) {
        value('loading');
      } else if (this._v === _skipped) {
        value('skipped');
      } else {
        value(this._v);
      }
    }, [this._v]);
    return this;
  }

  debug(): Loader<val> {
    useDebugValue(this._v);
    return this;
  }

  log(msg?: string): Loader<val> {
    useEffect(() => console.log(msg || '', this._v), [this._v]);
    return this;
  }

  unwrapOr<T>(fallback: T): val | T {
    return this.match.notOk(() => fallback).ok(x => x);
  }

  combine<other, result>(
    a: any[] | nil | Loader<other>,
    b?: Loader<other> | ((value: val, other: other) => ResultBuilder<result>),
    c?: (value: val, other: other) => ResultBuilder<result>,
  ): Loader<result> {
    const dependencies = Array.isArray(a) || nullish(a) ? a ?? [] : [];
    const otherLoader = (Array.isArray(a) || nullish(a) ? b! : a) as LoaderImpl<other>;
    const fetcher = (Array.isArray(a) || nullish(a) ? c! : b!) as (value: val, other: other) => ResultBuilder<result>;
    // react to inputs
    return _useDeduplicator<result>(
      Loader.loading,
      () => {
        if (this._v === _loading || this._v === _skipped || this._v instanceof Error) {
          return this as unknown as Loader<result>;
        }

        if (otherLoader._v === _loading || otherLoader._v === _skipped || otherLoader._v instanceof Error) {
          return otherLoader as unknown as Loader<result>;
        }

        // both loader are OK => fetch
        return fetcher(this._v, otherLoader._v);
      },
      [this._v, otherLoader._v, ...dependencies],
    );
  }

  onOk(a: any[] | nil | ((value: val) => void), b?: (value: val) => void): void {
    const dependencies = Array.isArray(a) || nullish(a) ? a ?? [] : [];
    const callback = Array.isArray(a) || nullish(a) ? b! : a;
    useEffect(() => {
      if (this._v === _loading || this._v === _skipped || this._v instanceof Error) {
        return;
      }
      return callback(this._v);
    }, [this._v, ...dependencies]);
  }

  onError(a: any[] | nil | ((error: Error) => void), b?: (error: Error) => void): void {
    const dependencies = Array.isArray(a) || nullish(a) ? a ?? [] : [];
    const callback = Array.isArray(a) || nullish(a) ? b! : a;
    useEffect(() => {
      if (this._v instanceof Error) {
        callback(this._v);
      }
    }, [this._v, ...dependencies]);
  }

  onSkipped(a: any[] | nil | (() => void), b?: () => void): void {
    const dependencies = Array.isArray(a) || nullish(a) ? a ?? [] : [];
    const callback = Array.isArray(a) || nullish(a) ? b! : a;
    useEffect(() => {
      if (this._v === _skipped) {
        callback();
      }
    }, [this._v, ...dependencies]);
  }

  onLoading(a: any[] | nil | (() => void), b?: () => void): void {
    const dependencies = Array.isArray(a) || nullish(a) ? a ?? [] : [];
    const callback = Array.isArray(a) || nullish(a) ? b! : a;
    useEffect(() => {
      if (this._v === _loading) {
        callback();
      }
    }, [this._v, ...dependencies]);
  }

  query<result>(
    a: any[] | nil | DocumentNode,
    b: DocumentNode | ((value: val) => Record<string, any>),
    c?: (value: val) => Record<string, any>,
  ): Loader<result> {
    const hasDeps = Array.isArray(a) || nullish(a);
    const deps = (hasDeps ? a ?? [] : []) as any[];
    const query = (hasDeps ? b! : a) as DocumentNode;
    const variables = (hasDeps ? c! : b!) as (value: val) => Record<string, any>;

    const apollo = useApolloClient();
    return this.map(deps, async val => {
      const vars = variables(val);
      const result = await fetchApollo<result>(apollo, query, vars);
      return result;
    });
  }

  makeCallback(a: any, b?: any, c?: any): any {
    const deps = Array.isArray(a) || nullish(a) ? a ?? [] : [];
    const okCallback = Array.isArray(a) || nullish(a) ? b! : a;
    const otherStatesCallback = Array.isArray(a) || nullish(a) ? c! : b;
    const [resultLoader, setResultLoader] = useState(Loader.skipped);

    const cb = (...args: any[]) => {
      if (this._v === _loading) {
        return otherStatesCallback?.('loading', ...args);
      }
      if (this._v === _skipped) {
        return otherStatesCallback?.('skipped', ...args);
      }
      if (this._v instanceof Error) {
        return otherStatesCallback?.(this._v, ...args);
      }
      if (resultLoader.isLoading) {
        return otherStatesCallback?.('loading', ...args);
      }
      try {
        const result = okCallback(this._v, ...args);

        // when callback returned a loader, we use it
        if (isLoader(result)) {
          setResultLoader(result);
          return;
        }

        // when callback returned a promise, we'll wait for it (and propagate loading state in loader)
        if (isPromiseLike(result)) {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          (async () => {
            try {
              setResultLoader(Loader.loading);
              const asyncResult = await result;
              setResultLoader(Loader.ok(asyncResult));
            } catch (e) {
              setResultLoader(Loader.error(e as any));
              console.error(e);
            }
          })();
          return;
        }

        // loader just returned a value => we're done
        setResultLoader(Loader.ok(result));
      } catch (e) {
        setResultLoader(Loader.error(e as any));
        throw e;
      }
    };
    cb.loader = resultLoader;

    return useCallback(cb, [this._v, resultLoader, ...deps]);
  }
}

class LoaderMatcherImpl<val>
implements LoaderMatcher<val>, ChooseError<val, any>, ChooseLoading<val, any>, ChooseValue<val, any> {
  private rendered?: any;

  private finished?: boolean;

  constructor(private value: _internalState<val>) {}

  loading(value: () => any): any {
    if (this.finished) {
      return this;
    }
    if (this.value === _loading) {
      this.rendered = value();
      this.finished = true;
      return this;
    }
    return this;
  }

  loadingOrSkipped(value: () => any): any {
    if (this.finished) {
      return this;
    }
    if (this.value === _loading || this.value === _skipped) {
      this.rendered = value();
      this.finished = true;
      return this;
    }
    return this;
  }

  notOk(value: (status: NotOkStatus) => any): any {
    if (this.finished) {
      return this;
    }
    const status = getStatus(this.value);
    if (status !== 'ok') {
      this.rendered = value(status);
      this.finished = true;
      return this;
    }
    return this;
  }

  skipped(value: () => any): any {
    if (this.finished) {
      return this;
    }
    if (this.value === _skipped) {
      this.rendered = value();
      this.finished = true;
      return this;
    }
    return this;
  }

  error(value: (error: Error) => any): ChooseValue<val, any> {
    if (this.finished) {
      return this;
    }
    if (this.value instanceof Error) {
      this.rendered = value(this.value);
      this.finished = true;
      return this;
    }
    return this;
  }

  ok<result>(value: (value: val) => result): this {
    if (this.finished) {
      return this.rendered;
    }
    return this.rendered ?? value(this.value as val);
  }
}

/**
 * Ensures that two promises are not running concurrently,
 * and that discards all  */
function _useDeduplicator<result>(initialValue: Loader<result>, fetchResult: () => ResultBuilder<result>, deps: any[]) {
  const [finalLoader, setFinalLoader] = useState<Loader<result>>(initialValue);
  const queue = useRef<{ running: boolean; next:(() => void) | nil }>({ running: false, next: null });

  useEffect(() => {
    const wrapper = async () => {
      try {
        queue.current.running = true;
        const gotResult = fetchResult();

        // when returned a loader
        if (isLoader(gotResult)) {
          setFinalLoader(gotResult);
          return;
        }

        // when returned a promise
        if (isPromiseLike(gotResult)) {
          // Promise => fetch !
          setFinalLoader(Loader.loading);
          const v = await gotResult;
          setFinalLoader(isLoader(v) ? v : Loader.ok(v));
          return;
        }

        // when returned a simple value
        if (!deepEqual(gotResult, (finalLoader as LoaderImpl<result>)._getValueInternal())) {
          setFinalLoader(Loader.ok(gotResult));
        }
      } catch (e) {
        setFinalLoader(Loader.error(e as Error));
      } finally {
        // start the next exec
        const next = queue.current.next;
        queue.current.next = null;
        queue.current.running = !!next;
        next?.();
      }
    };

    if (!queue.current.running) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      wrapper();
    } else {
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      queue.current.next = wrapper;
    }
  }, deps);

  return finalLoader;
}

// ======================================================================
// ============================== PRIMITIVES  ===========================
// ======================================================================

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Loader: LoaderBuilder = {
  ok<val>(value: val): Loader<val> {
    return new LoaderImpl(value);
  },
  useOk<val>(value: val): Loader<val> {
    const [memValue, setMemValue] = useState(Loader.ok(value));
    useEffect(() => {
      if (deepEqual(getOkValueDangerous(memValue), value)) {
        return;
      }
      setMemValue(Loader.ok(value));
    }, [value]);
    return memValue;
  },
  wrap<val>(value: val): Loader<Unwrap<val>> {
    return isLoader(value) ? value : new LoaderImpl(value as any);
  },
  useWrap<val>(value: val): Loader<Unwrap<val>> {
    const [memValue, setMemValue] = useState(Loader.wrap(value));
    useEffect(() => {
      if (isLoader(value)) {
        if (value !== memValue) {
          setMemValue(value);
        }
        return;
      }
      if (deepEqual(getOkValueDangerous(memValue), value as unknown)) {
        return;
      }
      setMemValue(Loader.wrap(value));
    }, [value]);
    return memValue;
  },
  error<val = any>(error: string | Error): Loader<val> {
    let err: Error;
    if (typeof error === 'string') {
      err = new Error(error);
    } else if (error instanceof Error) {
      err = error;
    } else {
      err = new Error('Unknown error: ' + JSON.stringify(error));
    }
    return new LoaderImpl<val>(err);
  },
  useError<val = any>(error: Error): Loader<val> {
    return useMemo(() => Loader.error(error), [error]);
  },
  loading: new LoaderImpl(_loading),
  skipped: new LoaderImpl(_skipped),
  async<val extends ResultBuilder<any>>(deps?: any[] | nil | (() => val), value?: () => val): Loader<ResultOf<val>> {
    const dependencies = Array.isArray(deps) || nullish(deps) ? deps : [];
    const getter = Array.isArray(deps) || nullish(deps) ? value! : deps;
    return Loader.ok(true).map(dependencies, () => getter());
  },
  array<loaders extends readonly Loadable<any>[]>(maybeLoaders: loaders): Loader<LoaderArray<loaders>> {
    const [result, setResult] = useState(Loader.loading);
    const lodList = maybeLoaders.map(l => Loader.useWrap(l));
    useEffect(() => {
      const err = lodList.find(l => l.status === 'error');
      if (err) {
        setResult(err);
        return;
      }
      const skipped = lodList.find(l => l.status === 'skipped');
      if (skipped) {
        setResult(skipped);
        return;
      }
      const loading = lodList.find(l => l.status === 'loading');
      if (loading) {
        setResult(loading);
        return;
      }
      const newResult = lodList.map(l => getOkValueDangerous(l));
      if (result.isOk && deepEqual(newResult, getOkValueDangerous(result))) {
        return;
      }
      setResult(Loader.ok(newResult));
    }, lodList ?? []);
    return result;
  },
  query<TData>(query: DocumentNode, options?: UseQueryOptions): Loader<ResultOf<TData>> {
    return useQueryLoader<TData>(query, options)[0];
  },
  fromReactAsyncPattern<val>({ data, error, isLoading }: LoadPattern<val>): Loader<val> {
    return useMemo<Loader<val>>(() => {
      if (isLoading) {
        return Loader.loading;
      }
      if (error) {
        return Loader.error(error);
      }
      return Loader.ok(data);
    }, [data, error, isLoading]);
  },
};

/** Checks if a given value is a loader */
export function isLoader(val: any): val is Loader<any> {
  return val instanceof LoaderImpl;
}

/**
 * @deprecated Do not bypass status handling.
 *
 * This allows you to get the loader internal value,
 * but this bypasses any loading/skipped/error handling.
 * => Prefer using  `myLoader.match` instead.
 */
export function getOkValueDangerous<T>(val: Loader<T>): T | undefined {
  const value = (val as LoaderImpl<T>)._getValueInternal();
  if (value === _loading || value === _skipped || value instanceof Error) {
    return undefined;
  }
  return value;
}
