/** @type {import('tailwindcss').Config} */

export default {
  content: ['./index.html', './src/**/*.{js,ts,jsx,tsx}'],
  darkMode: 'class',
  theme: {
    colors: {
      current: 'currentColor',
      white: 'var(--white)',

      /**
       *    [LIGHT MODE]
       *    TODO: When the light mode will be added,
       *          each color will be reconfigured with this pattern (ex: with the 'info' color):
       *
       *          {
       *            info: {
       *              DEFAULT: 'var(--info)',        --> dark mode color used by default
       *              light:   'var(--info-light),   --> light mode color to define
       *            }
       *          }
       *
       *          We'll also include a 'light:' variant that lets us style differently when light mode is enabled.
       *          Usage in a class name: <div className="text-info light:text-info-light" />
       */
      background: 'var(--background)',
      'background-variant': 'var(--background-variant)',
      surface: 'var(--surface)',
      'surface-variant': 'var(--surface-variant)',
      'surface-muted': 'var(--surface-muted)',
      'surface-elevated': 'var(--surface-elevated)',
      'surface-muted-hover': 'var(--surface-muted-hover)',
      'surface-muted-active': 'var(--surface-muted-active)',
      'surface-variant-muted': 'var(--surface-variant-muted)',
      'surface-variant-disabled': 'var(--surface-variant-disabled)',
      'surface-muted-active-accent': 'var(--surface-muted-active-accent)',
      'surface-muted-active-font': 'var(--surface-muted-active-font)',
      'surface-invert': 'var(--surface-invert)',
      'surface-accent': 'var(--surface-accent)',
      'surface-accent-hover': 'var(--surface-accent-hover)',
      'surface-disabled': 'var(--surface-disabled)',
      'surface-gradient-muted': 'var(--surface-gradient-muted)',
      'surface-gradient-transparent': 'var(--surface-gradient-transparent)',
      'surface-success-hover-success': 'var(--surface-success-hover-success)',
      'surface-accent-hover-accent': 'var(--surface-accent-hover-accent)',
      'surface-danger-hover-danger': 'var(--surface-danger-hover-danger)',
      'surface-variant-muted-hover-font': 'var(--surface-variant-muted-hover-font)',
      'surface-elevated-hover-font': 'var(--surface-elevated-hover-font)',
      accent: 'var(--accent)',
      'accent-hover': 'var(--accent-hover)',
      'accent-muted': 'var(--accent-muted)',
      outline: 'var(--outline)',

      font: 'var(--font)',
      'font-variant': 'var(--font-variant)',
      'font-variant-disabled': 'var(--font-variant-disabled)',
      'font-muted': 'var(--font-muted)',
      'font-invert': 'var(--font-invert)',
      'font-on-accent': 'var(--font-on-accent)',
      'font-disabled': 'var(--font-disabled)',
      'font-muted-disabled': 'var(--font-muted-disabled)',

      success: 'var(--success)',
      'success-muted': 'var(--success-muted)',
      'surface-success': 'var(--surface-success)',
      'surface-success-hover': 'var(--surface-success-hover)',
      'success-hover-font-on-accent': 'var(--success-hover-font-on-accent)',
      danger: 'var(--danger)',
      'danger-muted': 'var(--danger-muted)',
      'surface-danger': 'var(--surface-danger)',
      'surface-danger-hover': 'var(--surface-danger-hover)',
      'danger-hover-font-on-accent': 'var(--danger-hover-font-on-accent)',
      warning: 'var(--warning)',
      'warning-muted': 'var(--warning-muted)',
      'surface-warning': 'var(--surface-warning)',
      'warning-hover-font-on-accent': 'var(--warning-hover-font-on-accent)',
      info: 'var(--info)',
      'info-muted': 'var(--info-muted)',
      'surface-info': 'var(--surface-info)',
      overlay: 'var(--overlay)',
      transparent: 'var(--transparent)',
      'accent-hover-font-on-accent': 'var(--accent-hover-font-on-accent)',
      'surface-variant-hover-invert': 'var(--surface-variant-hover-invert)',
      'surface-muted-hover-font': 'var(--surface-muted-hover-font)',
      'surface-variant-muted-hover-invert': 'var(--surface-variant-muted-hover-invert)',
      'surface-info-hover-accent': 'var(--surface-info-hover-accent)',
      'hover-accent': 'var(--hover-accent)',
      'hover-invert': 'var(--hover-invert)',

      'theme-lime': 'var(--theme-lime)',
      'theme-lime-muted': 'var(--theme-lime-muted)',
      'interaction-lime-muted-hover-lime': 'var(--interaction-lime-muted-hover-lime)',
      'theme-orange': 'var(--theme-orange)',
      'theme-orange-muted': 'var(--theme-orange-muted)',
      'interaction-orange-muted-hover-orange': 'var(--interaction-orange-muted-hover-orange)',
      'theme-blue': 'var(--theme-blue)',
      'theme-blue-muted': 'var(--theme-blue-muted)',
      'interaction-blue-muted-hover-blue': 'var(--interaction-blue-muted-hover-blue)',
      'theme-pink': 'var(--theme-pink)',
      'theme-pink-muted': 'var(--theme-pink-muted)',
      'interaction-pink-muted-hover-pink': 'var(--interaction-pink-muted-hover-pink)',
      'theme-cyan': 'var(--theme-cyan)',
      'theme-cyan-muted': 'var(--theme-cyan-muted)',
      'interaction-cyan-muted-hover-cyan': 'var(--interaction-cyan-muted-hover-cyan)',
      'theme-red': 'var(--theme-red)',
      'theme-red-muted': 'var(--theme-red-muted)',
      'interaction-red-muted-hover-red': 'var(--interaction-red-muted-hover-red)',
      'theme-lilac': 'var(--theme-lilac)',
      'theme-lilac-muted': 'var(--theme-lilac-muted)',
      'interaction-lilac-muted-hover-lilac': 'var(--interaction-lilac-muted-hover-lilac)',
      'theme-surface-arbitrum': 'var(--theme-surface-arbitrum',
      'interaction-surface-arbitrum-hover-font': 'var(--interaction-surface-arbitrum-hover-font',
      'theme-surface-avalanche': 'var(--theme-surface-avalanche',
      'interaction-surface-avalanche-hover-font': 'var(--interaction-surface-avalanche-hover-font',
      'theme-surface-base': 'var(--theme-surface-base',
      'interaction-surface-base-hover-font': 'var(--interaction-surface-base-hover-font',
      'theme-surface-bnbchain': 'var(--theme-surface-bnbchain',
      'interaction-surface-bnbchain-hover-font': 'var(--interaction-surface-bnbchain-hover-font',
      'theme-surface-ethereum': 'var(--theme-surface-ethereum',
      'interaction-surface-ethereum-hover-font': 'var(--interaction-surface-ethereum-hover-font',
      'theme-surface-optimism': 'var(--theme-surface-optimism',
      'interaction-surface-optimism-hover-font': 'var(--interaction-surface-optimism-hover-font',
      'theme-surface-polygon': 'var(--theme-surface-polygon',
      'interaction-surface-polygon-hover-font': 'var(--interaction-surface-polygon-hover-font',
    },
    keyframes: {
      'background-slide-x': {
        '0%': { backgroundPosition: '200% 50%' },
        '100%': { backgroundPosition: '0% 50%' },
      },
      spin: {
        '0%': { transform: 'rotate(0deg)' },
        '100%': { transform: 'rotate(360deg)' },
      },
      'decrease-width': {
        '0%': { width: '100%' },
        '1%': { width: '99%', borderTopRightRadius: '0px' }, // to prevent '0%' from being '100%
        '100%': { width: '0%', borderTopRightRadius: '0px' },
      },
    },
    animation: {
      'background-slide-x': 'background-slide-x 1s linear infinite',
      spin: 'spin 1s linear infinite',
      'decrease-width': 'decrease-width 1s linear infinite',
    },
    fontFamily: {
      sans: ['Outfit', 'sans-serif'],
      'youth-black': ['Youth-Black'],
      'youth-black-italic': ['Youth-Black-Italic'],
      'youth-bold': ['Youth-Bold'],
      'youth-bold-italic': ['Youth-Bold-Italic'],
      'youth-italic': ['Youth-Italic'],
      'youth-light': ['Youth-Light'],
      'youth-light-italic': ['Youth-Light-Italic'],
      'youth-medium': ['Youth-Medium'],
      'youth-medium-italic': ['Youth-Medium-Italic'],
      'youth-regular': ['Youth-Regular'],
      'youth-thin': ['Youth-Thin'],
      'youth-thin-italic': ['Youth-Thin-Italic'],
    },
    fontSize: {
      sm: ['12px', '16px'],
      base: ['14px', '20px'],
      lg: ['16px', '24px'],
      xl: ['18px', '18px'],
      '2xl': ['20px', '28px'],
      '3xl': ['24px', '32px'],
      '4xl': ['28px', '36px'],
      '5xl': ['36px', '48px'],
      '5.5xl': ['40px', '64px'],
      '6xl': ['48px', '64px'],
      '7xl': ['72px', '88px'],
      'title-small': [
        '18px',
        {
          lineHeight: '26px',
          letterSpacing: '-0.005em',
          fontWeight: '500',
        },
      ],
      'label-medium': [
        '14px',
        {
          lineHeight: '20px',
          fontWeight: '500',
        },
      ],
      'body-medium': [
        '14px',
        {
          lineHeight: '20px',
          fontWeight: '400',
        },
      ],
    },
    boxShadow: {
      'border-2': '0px 0px 0px 2px inset',
    },
    extend: {
      height: {
        4.5: '72px',
      },
      backdropBlur: {
        '2xl': '32px',
      },
      blur: {
        xxl: '32px',
      },
      opacity: {
        12: '.12',
      },
      lineHeight: {
        6.5: '26px',
      },
      keyframes: {
        'pulse-up': {
          '0%': { transform: 'translateY(0)' },
          '6%': { transform: 'translateY(-200%)', opacity: 1 },
          '7%': { transform: 'translateY(-200%)', opacity: 0 },
          '8%': { transform: 'translateY(200%)', opacity: 0 },
          '9%': { transform: 'translateY(200%)', opacity: 1 },
          '16%': { transform: 'translateY(0)' },
          '100%': { transform: 'translateY(0)' },
        },
        'pulse-down': {
          '0%': { transform: 'translateY(0)' },
          '6%': { transform: 'translateY(200%)', opacity: 1 },
          '7%': { transform: 'translateY(200%)', opacity: 0 },
          '8%': { transform: 'translateY(-200%)', opacity: 0 },
          '9%': { transform: 'translateY(-200%)', opacity: 1 },
          '16%': { transform: 'translateY(0)' },
          '100%': { transform: 'translateY(0)' },
        },
        'rotate-45': {
          '0%': { transform: 'translateY(-45deg)' },
          '100%': { transform: 'rotate(45deg)' },
        },
        'translate-y-100': {
          '0%': { transform: 'translateY(-100%)' },
          '100%': { transform: 'translateY(100%)' },
        },
      },
      animation: {
        'pulse-up': 'pulse-up 5s linear infinite',
        'pulse-down': 'pulse-down 5s linear infinite',
        'rotate-45': 'rotate-45 1s linear',
        'translate-y-100': 'translate-y-100 1s linear',
      },
      gridTemplateColumns: {
        activity: '64px repeat(6, minmax(80px, 1fr)) 77px 56px',
        search: 'minmax(100px, 1fr) 80px repeat(4, minmax(100px, 1fr))',
      },
    },
  },
};
