import { useTranslation } from 'react-i18next';

import { SideContentProps } from '@components/SideContent/SideContent.types';
import { SideContentHeader } from '@components/layouts/side-content/SideContentHeader';

import SlippageInput from './SlippageInput';
import { Settings as SettingsType } from '../../types';

export type SettingsProps = {
  settings: SettingsType;
  setSettings: (newSettings: SettingsType) => void;
  setResult: SideContentProps['setResult'];
};

export function Settings({ setResult, setSettings, settings }: SettingsProps) {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-6">
      <SideContentHeader title={t('Transactions.Settings.title')} onGoBack={setResult} />
      <SlippageInput onChange={slippage => setSettings({ slippage })} activeButtonId={`${settings.slippage}`} />
    </div>
  );
}
