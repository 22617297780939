import { gql } from '@apollo/client';

import { TokenSummaryWithQuote } from '@gql';

export const GET_ALL_LIMIT_ORDERS = gql`
  query getAllLimitOrders($filters: GLimitOrdersFilters) {
    limitOrders(filter: $filters) {
      results {
        id
        vault
        creationDate
        status
        expiryDate
        take {
          qtyNum
          qty
          token {
            ...ITokenSummaryWithQuote
            chain
          }
        }
        make {
          qtyNum
          qty
          token {
            ...ITokenSummaryWithQuote
            chain
          }
        }
      }
    }
  }
  ${TokenSummaryWithQuote}
`;

export const REFRESH_LIMIT_ORDERS = gql`
  mutation refreshLimitOrder($chain: Chain!) {
    refreshLimitOrders(chain: $chain) {
      totalCount
    }
  }
`;
