import cls from 'classnames';
import { useTranslation } from 'react-i18next';

import { Timeframe } from '@gql'; // eslint-disable-line import/no-restricted-paths
import { Cls, Float, Loadable, Loader, formatDate } from '@utils';

import { Headline, HeadlineProps } from './Headline';
import { Skeleton } from './Skeleton';
import { Variation } from './Variation';

export type IHeadingValueVariationProps = {
  value: Loadable<ValueAndVariation>;
  dateTime?: number;
  timeframe?: Timeframe;
  valueClassName?: string;
  variationClassName?: string;
  timeframeClassName?: string;
  alignment?: HeadlineProps['alignment'];
} & Cls;

export interface ValueAndVariation {
  value: number;
  variation: number;
  variationUsdValue?: number;
}

export function HeadingValueVariation({
  value,
  timeframe,
  dateTime,
  valueClassName,
  variationClassName,
  timeframeClassName,
  className,
  alignment,
}: IHeadingValueVariationProps) {
  const { t } = useTranslation();

  const valueLoader = Loader.wrap(value);

  const formattedTime = formatDate(new Date(dateTime || 0), 'date-and-time');

  const heading = valueLoader.match
    .loadingOrSkipped(() => <Skeleton className="w-40 h-12 mb-1" />)
    .error(() => null)
    .ok(v => <Float.UsdValue className={valueClassName} value={v.value} />);

  const _timeframe = timeframe ? t(`Common.timeframe.${timeframe}`) : null;

  const subheading = valueLoader.match
    .loadingOrSkipped(() => <Skeleton className="w-60 h-6 rounded-md" />)
    .error(() => null)
    .ok(({ variation, variationUsdValue }) => {
      return (
        <div className="flex items-center text-2xl font-medium gap-1">
          <Variation className={cls('!text-2xl', variationClassName)} value={{ variation, usd: variationUsdValue }} />
          {_timeframe || dateTime ? (
            <span className={cls('text-font-variant font-light', timeframeClassName)}>
              {dateTime ? formattedTime : _timeframe}
            </span>
          ) : null}
        </div>
      );
    });

  return <Headline heading={heading} underline={subheading} className={className} alignment={alignment} />;
}
