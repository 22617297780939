import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { DEFAULT_CHAIN } from '@constants';
import { useMainVaultSummary } from '@hooks/useMainVault';
import emoji from '@images/mass/mass-emoji.png';

import { BridgeFlow } from './flows/bridge/BridgeFlow';
import { Dca } from './flows/dca/Dca';
import { DepositFlow } from './flows/deposit/DepositFlow';
import { LimitOrders } from './flows/limit-orders/LimitOrders';
import MultiBuyFlow from './flows/multiSwap/multiBuy/MultiBuyFlow';
import MultiSellFlow from './flows/multiSwap/multiSell/MultiSellFlow';
import { StopLoss } from './flows/stopLoss/StopLoss';
import SwapFlow from './flows/swap/SwapFlow';
import { WithdrawFlow } from './flows/withdraw/WithdrawFlow';
import { TransactionsHeader } from './ui/TransactionsHeader';
import TxSubmittedAnimation from '../../../components/TxSubmittedAnimation';
import { useTransactionFlow } from '../hooks/useTransactionFlow';
import {
  BridgeFlowProps,
  SwapFlowProps,
  TransactionFlowProps,
  TransactionFlowType,
  DepositFlowProps,
  WithdrawFlowProps,
  LimitOrdersProps, DcaProps,
} from '../types';

const getCurrentFlow = (currentFlow: TransactionFlowType | nil, props: TransactionFlowProps[TransactionFlowType]) => {
  switch (currentFlow) {
    case TransactionFlowType.BRIDGE:
      return <BridgeFlow {...(props as BridgeFlowProps)} />;
    case TransactionFlowType.SWAP:
      return <SwapFlow {...(props as SwapFlowProps)} />;
    case TransactionFlowType.DEPOSIT:
      return <DepositFlow {...(props as DepositFlowProps)} />;
    case TransactionFlowType.WITHDRAW:
      return <WithdrawFlow {...(props as WithdrawFlowProps)} />;
    case TransactionFlowType.LIMIT:
      return <LimitOrders {...(props as LimitOrdersProps)} />;
    case TransactionFlowType.DCA:
      return <Dca {...(props as DcaProps)} />;
    // case TransactionFlowType.TRANSFER:
    //   return <div>Transfer</div>;
    case TransactionFlowType.MULTISELL:
      return <MultiSellFlow />;
    case TransactionFlowType.MULTIBUY:
      return <MultiBuyFlow />;
    case TransactionFlowType.STOP_LOSS:
      return <StopLoss />;
    // case TransactionFlowType.CROSS_CHAIN_SWAP:
    //   return <div>Cross Chain Swap</div>;
    default:
      return <SwapFlow />;
  }
};

const shouldHideNetworkSelector = (flow: TransactionFlowType | nil) => {
  return !!(flow && [TransactionFlowType.BRIDGE, TransactionFlowType.CROSS_CHAIN_SWAP].includes(flow));
};

export function Transactions() {
  const { currentFlow, props, currentChain, setCurrentFlow, showHeader, setCurrentChain, animationTrigger } =
    useTransactionFlow();

  const [showAnimation, setShowAnimation] = useState(false);
  useEffect(() => {
    if (animationTrigger > 0 && !showAnimation) {
      setShowAnimation(true);
      setTimeout(() => setShowAnimation(false), 1000);
    }
  }, [animationTrigger]);

  const [searchParams] = useSearchParams();
  useEffect(() => {
    const flow = searchParams.get('flow');
    if (flow && flow !== currentFlow && Object.values(TransactionFlowType).includes(flow as TransactionFlowType)) {
      setCurrentFlow(flow as TransactionFlowType);
      setCurrentChain(DEFAULT_CHAIN);
    }
    // only on mount
  }, []);

  const vaults$ = useMainVaultSummary(false);
  const vaultExistsOn$ = vaults$.map(gmv => gmv?.existsOn || []);

  vaultExistsOn$.noFlickering().onOk(existsOn => {
    if (!existsOn.length) {
      setCurrentFlow(TransactionFlowType.DEPOSIT);
      setCurrentChain(DEFAULT_CHAIN);
      return;
    }

    const chainToSet = existsOn.includes(DEFAULT_CHAIN) ? DEFAULT_CHAIN : existsOn[0];

    setCurrentChain(chainToSet);
    setCurrentFlow(TransactionFlowType.SWAP);
  });

  return (
    <div className="flex flex-col h-full">
      {showHeader && (
        <TransactionsHeader
          currentFlow={currentFlow || TransactionFlowType.SWAP}
          setCurrentFlow={setCurrentFlow}
          hideNetwork={shouldHideNetworkSelector(currentFlow)}
          setCurrentChain={setCurrentChain}
          currentChain={currentChain}
        />
      )}
      <div className="flex-1 overflow-auto hide-scrollbars">{getCurrentFlow(currentFlow, props)}</div>
      {showAnimation && (
        <div className="top-0 left-0 right-0 bottom-0 absolute overflow-hidden rounded-xl z-20">
          <div
            className="
            bg-accent w-full h-full rounded-xl
            flex items-center justify-center animate-translate-y-100 -translate-x-full
          "
          >
            <img src={emoji} alt="mass emoji" width={120} className="animate-rotate-45 -rotate-45" />
          </div>
        </div>
      )}
      <TxSubmittedAnimation />
    </div>
  );
}
