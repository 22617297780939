import { match } from 'ts-pattern';

import { Timeframe } from '@gql';
import { DateFormat, formatDate } from '@utils';

const today = new Date().getTime();
const firstDayOfMillennium = new Date('01/01/2000').getTime();
const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
const oneWeekInMilliseconds = 7 * oneDayInMilliseconds;
const oneMonthInMilliseconds = 30 * oneDayInMilliseconds;
const sixMonthsInMilliseconds = 180 * oneDayInMilliseconds;
const oneYearInMilliseconds = 365 * oneDayInMilliseconds;

export const makeFlatGraphWithTimeframe = (timeframe: Timeframe = Timeframe.all): Graph => {
  const startDate = match(timeframe)
    .with(Timeframe.all, () => firstDayOfMillennium)
    .with(Timeframe.p5y, () => firstDayOfMillennium)
    .with(Timeframe.p1d, () => today - oneDayInMilliseconds)
    .with(Timeframe.p1w, () => today - oneWeekInMilliseconds)
    .with(Timeframe.p1m, () => today - oneMonthInMilliseconds)
    .with(Timeframe.p6m, () => today - sixMonthsInMilliseconds)
    .with(Timeframe.p1y, () => today - oneYearInMilliseconds)
    .exhaustive();

  return {
    data: [
      [startDate, 0.01],
      [today, 0.01],
    ],
    name: 'flatGraph',
  };
};

export type Point = [t: number, v: number];
export type TimeSerie = Point[];
export type Graph = {
  data: TimeSerie;
  color?: string;
  name: string;
};

export enum ChartColors {
  RED = 'var(--danger)',
  GREEN = 'var(--success)',
  GREY = 'var(--font-variant)',
  DISABLED = 'var(--font-disabled)',
}

export function toRechartData(graphs?: Graph[]) {
  if (!graphs || graphs.length === 0 || (graphs.length === 1 && graphs[0].data.length === 0)) {
    return { dataSeries: [], rangeX: [0, 0], rangeY: [0, 0] };
  }
  const dataMap = new Map();
  const rangeY: [number, number] = [Infinity, 0];
  const rangeX: [number, number] = [Infinity, 0];
  graphs.forEach(({ data, name }) => {
    const currentGraphRange: [number, number] = [Infinity, 0];
    data.forEach(([t, v]) => {
      if (t < rangeX[0]) rangeX[0] = t;
      if (t > rangeX[1]) rangeX[1] = t;
      if (v < rangeY[0]) rangeY[0] = v;
      if (v > rangeY[1]) rangeY[1] = v;

      if (v < currentGraphRange[0]) currentGraphRange[0] = v;
      if (v > currentGraphRange[1]) currentGraphRange[1] = v;
      dataMap.set(t, { ...dataMap.get(t), [name]: v, t });
    });

    if (currentGraphRange[1] === currentGraphRange[0]) {
      // this adds a very small value to the flat line to make it visible
      // for unknown reason if the line is perfectly flat, it will be invisible
      dataMap.set(rangeX[0], {
        ...dataMap.get(rangeX[0]),
        [name]: currentGraphRange[0] + 0.000001 * currentGraphRange[0],
        t: rangeX[0],
      });
    }
  });
  return { dataSeries: Array.from(dataMap.values()), rangeY, rangeX };
}

export function getHorizontalLinesForChart(props: { offset: { height: number; top: number } }, linesCount: number) {
  const result = [];
  for (let k = 0; k <= linesCount + 1; k++) {
    result.push(props.offset.top + (k * props.offset.height) / (linesCount + 1));
  }
  return result;
}

export function getTrendColorForData(data: Point[]) {
  const sortedData = data.sort((a, b) => a[0] - b[0]);
  const delta = sortedData.length ? sortedData[sortedData.length - 1][1] - sortedData[0][1] : 0;
  if (delta > 0) {
    return ChartColors.GREEN;
  }
  if (delta < 0) {
    return ChartColors.RED;
  }
  return ChartColors.GREY;
}

export function scaleInterval(
  number: number,
  sourceLowerBound: number,
  sourceUpperBound: number,
  destinationLowerBound: number,
  destinationUpperBound: number,
) {
  return (
    ((number - sourceLowerBound) * (destinationUpperBound - destinationLowerBound)) /
      (sourceUpperBound - sourceLowerBound) +
    destinationLowerBound
  );
}

export function formatChartTicker(date: Date, format: DateFormat) {
  return formatDate(new Date(date), format);
}

export const timeframeToDays = (timeframe: Timeframe) => {
  // do not modify these values, they are the only values that coinGecko accepts
  return match(timeframe)
    .with(Timeframe.all, () => undefined)
    .with(Timeframe.p1d, () => 1)
    .with(Timeframe.p1w, () => 7)
    .with(Timeframe.p1m, () => 30)
    .with(Timeframe.p6m, () => 180)
    .with(Timeframe.p1y, () => 365)
    .with(Timeframe.p5y, () => 365 * 5)
    .exhaustive();
};
