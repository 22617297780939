import { nullish } from './basic-utils';
import { readERC20 } from './contracts';
import { Loader } from './loader';
import { withoutChain } from './utils';

export function useIsApproved(
  token: ChainAddress,
  owner: ChainAddress,
  spender: ChainAddress,
  forAmount?: bigint,
): Loader<boolean> {
  return Loader.async([token, owner, spender, forAmount], () => {
    return isApproved(token, owner, spender, forAmount);
  });
}

export async function isApproved(
  token: ChainAddress,
  owner: ChainAddress,
  spender: ChainAddress,
  forAmount?: bigint | nil,
): Promise<boolean> {
  const amt = await readERC20(token).read.allowance([withoutChain(owner), withoutChain(spender)]);

  // if we have a specific amount, compare to it
  if (!nullish(forAmount)) {
    return amt >= forAmount;
  }

  // else, just compare to a very large number
  return amt > 10n ** 50n;
}
