import cls from 'classnames';
import { ReactNode } from 'react';

import { Cls } from '@utils';

export type HeadlineProps = {
  overline?: ReactNode;
  heading: ReactNode;
  underline?: ReactNode;
  alignment?: 'left' | 'center' | 'right';
} & Cls;

export function Headline({ overline, heading, underline, className, alignment = 'left' }: HeadlineProps) {
  return (
    <div
      className={cls(
        'flex flex-col w-fit',
        {
          'items-start': alignment === 'left',
          'items-center': alignment === 'center',
          'items-end': alignment === 'right',
        },
        className,
      )}
    >
      {overline}
      <div className="flex items-center text-font text-5xl font-youth-black font-black">{heading}</div>
      {underline}
    </div>
  );
}
