import { RefObject, useEffect } from 'react';

/**
 * Get alerted when user clicked outside
 * Usage: https://usehooks.com/useOnClickOutside/ */
export function useOnClickOutside(refs: RefObject<HTMLInputElement | HTMLDivElement>[], handler: () => void) {
  useEffect(() => {
    const listener = (event: any) => {
      // Do nothing if clicking ref's element or descendent elements
      if (refs.find(ref => !ref.current || ref.current.contains(event.target as any))) {
        return;
      }
      handler();
    };

    const events = ['click', 'blur', 'focusout', 'mousedown', 'touchstart'];
    events.forEach(e => document.addEventListener(e, listener, true));

    return () => {
      events.forEach(e => document.removeEventListener(e, listener, true));
    };
  }, [refs, handler]);
}
