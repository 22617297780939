import cls from 'classnames';
import { useId } from 'react';

import { CheckIcon } from '@ui-kit/Icons';

export type IRadio = {
  checked?: boolean;
  disabled?: boolean;
  onClick?: VoidFunction;
};

export function Radio({ checked = false, disabled = false, onClick }: IRadio) {
  const id = useId();
  return (
    <label className="h-6 w-6 flex relative items-center" htmlFor={id}>
      <input
        type="radio"
        id={id}
        className={cls(
          'h-full w-full appearance-none focus:ring rounded-full',
          disabled ? 'border-0 bg-surface-disabled' : 'cursor-pointer',
          !disabled && !checked && 'bg-transparent border border-font-variant hover:bg-hover-accent',
          !disabled && checked && 'bg-accent hover:bg-accent-hover-font-on-accent',
        )}
        checked={checked}
        disabled={disabled}
        onClick={() => onClick?.()}
      />
      {checked && (
        <CheckIcon
          className={cls(
            disabled ? 'text-font-disabled' : 'text-font-on-accent',
            'h-3 w-3 absolute left-1.5 top-1.5 pointer-events-none',
          )}
        />
      )}
    </label>
  );
}
