import { useState } from 'react';

import {
  ITokenSummary,
  Chain,
  GetUserBalances_myUser_walletBalances,
  GetUserAccountInfo,
  GetUserAccountInfoVariables,
} from '@gql';
import { GET_USER_ACCOUNT_INFO } from '@queries/user.queries';
import { Loader, chainOf } from '@utils';
import { useWallet } from '@wallet-hooks';

import { MyAccountUI } from './ui/MyAccountUI';
import { useSearchCoin } from './useSearchCoin';
import { SideContentProps } from '../../components/SideContent/SideContent.types';
// eslint-disable-next-line import/no-restricted-paths
import { useTransactionFlow } from '../transactions/hooks/useTransactionFlow';
// eslint-disable-next-line import/no-restricted-paths
import { TransactionFlowType } from '../transactions/types';

export default function MyAccount({ setResult }: SideContentProps) {
  const wallet$ = useWallet(true);
  const { setCurrentFlow, setCurrentChain } = useTransactionFlow();
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [chainFilter, setChainFilter] = useState<Chain | undefined>();

  const onLogout = wallet$.match
    .notOk(() => () => null)
    .ok(({ logout }) => () => {
      logout();
      setResult(undefined);
    });

  const onDepositClick = async ({ id: prefillInputTokenId }: ITokenSummary) => {
    await setCurrentChain(chainOf(prefillInputTokenId));
    setCurrentFlow(TransactionFlowType.DEPOSIT, { prefillInputTokenId });
    setResult(undefined);
  };

  const isAuthed = wallet$.match.notOk(() => false).ok(x => x.isAuthed);

  const userBalances$ = Loader.query<GetUserAccountInfo, GetUserAccountInfoVariables>(GET_USER_ACCOUNT_INFO, {
    variables: { chain: chainFilter },
    skip: !isAuthed,
    refetchWhenChanges: [isAuthed],
  }).map(({ myUser, mainVault }) => ({
    balances: myUser?.walletBalances ?? [],
    totalBalance: myUser?.walletValue ?? 0,
    profilePicture: '',
    variation: mainVault?.curves.totalPerf ?? 0,
  }));
  const balances$ = userBalances$.map(({ balances }) => balances || []) as Loader<
  GetUserBalances_myUser_walletBalances[]
  >;

  const filteredBalances$ = useSearchCoin(balances$, searchFilter);

  return (
    <MyAccountUI
      onLogout={onLogout}
      wallet={wallet$}
      coins={filteredBalances$}
      searchFilter={searchFilter}
      setSearchFilter={setSearchFilter}
      chainFilter={chainFilter}
      setChainFilter={setChainFilter}
      onCoinClick={() => null}
      totalBalance={userBalances$.map(({ totalBalance }) => totalBalance)}
      onGoBack={() => setResult(undefined)}
      profilePicture={userBalances$.match.notOk(() => '').ok(({ profilePicture }) => profilePicture)}
      balanceVariation={userBalances$.match.notOk(() => 0).ok(({ variation }) => variation)}
      onDepositClick={onDepositClick}
    />
  );
}
