/* eslint-disable max-len */
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useAccount } from 'wagmi';

// had to modify the given widget script as it is not working properly...
const HashmailScript = `
      let dapp_id="7421cd37-3a85-41fd-92ad-dac59bd884a6";
      let hashmail_settings={};
      (function(){window.hashmail||(window.hashmail=[]),window.hashmail.queue=[];let i=["load","identify","track"],t=function(i){return function(){a=Array.prototype.slice.call(arguments),a.unshift(i),window.hashmail.queue.push(a)}};for(var e=0;i.length>e;e++)window.hashmail[i[e]]=t(i[e]);hashmail.methods=i,window.hashmail.load=function(i,t){window.hashmail.dapp_id=i,window.hashmail.settings=t;var e=document,s=e.getElementsByTagName("script")[0],h=e.createElement("script");h.type="text/javascript",h.async=!0,h.src="https://widget.hashmail.dev/notifier_tracking_script.js",s.parentNode.insertBefore(h,s)},window.hashmail.identify=i=>{window.hashmail.wallet_address=i,localStorage.setItem("hashmail-wallet_address",i)},window.hashmail.load(dapp_id,hashmail_settings)})();
      `;

const Cookie3Script = `
    var cookie3Options = {"siteId":541,"additionalTracking":true,"cookielessEnabled":true}
    window._paq = window._paq || [];
    (function () {
        var d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
        g.async = true; g.src = 'https://cdn.cookie3.co/scripts/analytics/latest/cookie3.analytics.min.js'; s.parentNode.insertBefore(g, s);
    })();
    `;

const Hashmail = () => {
  const { isConnected, address } = useAccount();
  useEffect(() => {
    // @ts-expect-error hashmail
    if (!window.hashmail) return;

    if (isConnected && address) {
      // @ts-expect-error hashmail
      window.hashmail.identify(address);
    }
    // @ts-expect-error hashmail
    if (!isConnected && window.hashmail && window.hashmail.disconnect) {
      // @ts-expect-error hashmail
      window.hashmail.disconnect();
    }
  }, [isConnected, address]);
  return (
    <Helmet
      script={[
        (window as any).Cypress
          ? {}
          : {
            type: 'text/javascript',
            innerHTML: HashmailScript,
          },
      ]}
    />
  );
};

const Cookie3 = () => (
  <Helmet
    script={[
      (window as any).Cypress
        ? {}
        : {
          type: 'text/javascript',
          innerHTML: Cookie3Script,
        },
    ]}
  />
);
export default function Head() {
  return (
    <>
      {Hashmail()}
      {Cookie3()}
    </>
  );
}
