import cls from 'classnames';
import { match } from 'ts-pattern';

import { Cls } from '@utils';

type MessageType = 'info' | 'success' | 'error' | 'accent' | 'warning';

export type IncentiveMessageProps = {
  title: string;
  description: string;
  type?: MessageType;
} & Cls;

export default function IncentiveMessage({ className, title, description, type = 'accent' }: IncentiveMessageProps) {
  const { textColor, bgColor } = match(type)
    .with('success', () => ({ textColor: 'text-success', bgColor: 'bg-success' }))
    .with('error', () => ({ textColor: 'text-danger', bgColor: 'bg-danger' }))
    .with('warning', () => ({ textColor: 'text-warning', bgColor: 'bg-warning' }))
    .with('info', () => ({ textColor: 'text-info', bgColor: 'bg-info' }))
    .with('accent', () => ({ textColor: 'text-accent', bgColor: 'bg-accent' }))
    .exhaustive();

  return (
    <div
      className={cls(
        'relative py-5 px-4 z-1 bg-surface-muted flex flex-col gap-3 rounded-2xl overflow-hidden',
        className,
      )}
    >
      <div className={cls('rounded-t-3xl h-1 absolute inset-0 top-0.5', bgColor)} />
      <span className={cls('text-base font-medium', textColor)}>{title}</span>
      <span className="text-base font-normal">{description}</span>
    </div>
  );
}
