import cls from 'classnames';
import React, { InputHTMLAttributes } from 'react';
import { match } from 'ts-pattern';

import { formatInput } from '@components/bigInput/bigInputUtils';
import { Tooltip } from '@ui-kit/molecules/Tooltip';
import { Entry } from '@ui-kit/organisms/Entry';
import { Cls } from '@utils';
import { formatNumber } from '@utils/numbers/NumberFormat';

export type TriggerPriceInputEntryProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> & {
  disabled: boolean;
  valueVariation: {
    textClass: string;
    class: string;
    value: string;
  };
  value: string;
  resetInput: () => void;
  onChange: (v: string) => void;
  error?: string;
  label: string;
  inputTextClass: string;
  unit?: string;
  actions?: React.ReactNode[];
  tooltipContent?: string;
} & Cls;

export const getValueVariation = (value: number, refValue: number, margin: number) => {
  const variationPercentage = value / refValue - 1;
  return match(variationPercentage)
    .when(
      x => x > 0 + margin,
      () => ({
        value: `+${formatNumber(variationPercentage * 100, 'percentage')}`,
        class: 'text-success',
        textClass: 'text-font-variant',
      }),
    )
    .when(
      x => x < 0 - margin,
      () => ({
        value: `${formatNumber(variationPercentage * 100, 'percentage')}`,
        class: 'text-danger',
        textClass: 'text-font-variant',
      }),
    )
    .otherwise(() => ({
      value: '(0.00%)',
      class: 'text-font-variant',
      textClass: 'text-font-variant',
    }));
};
export function TriggerPriceInputEntry({
  disabled,
  valueVariation,
  value,
  resetInput,
  onChange,
  error,
  label,
  inputTextClass,
  className,
  unit,
  actions,
  tooltipContent,
  ...rest
}: TriggerPriceInputEntryProps) {
  return (
    <Entry
      isCard
      isActive={false}
      disabled={disabled}
      className={cls(className, '!overflow-visible', {
        'border !border-danger': !!error,
      })}
      content={{
        className: 'gap-2',
        top: (
          <div className="flex justify-between text-sm font-medium">
            <div className="gap-1 flex">
              <span className={cls(valueVariation?.textClass, { 'text-font-variant-disabled': disabled })}>
                {label}
              </span>
              {tooltipContent && <Tooltip className="text-font-variant" tooltipContent={tooltipContent} />}
              <span className={cls(valueVariation?.class, { 'text-font-variant-disabled': disabled })}>
                {valueVariation?.value}
              </span>
            </div>
            <span className="flex gap-1">{actions}</span>
          </div>
        ),
        bottom: (
          <div className="relative w-full font-youth-medium flex" data-cy="TriggerPriceInput">
            <input
              value={value}
              disabled={disabled}
              placeholder="0"
              className={cls(
                inputTextClass,
                'cursor-caret',
                'placeholder:text-font-variant',
                'outline-none focus:outline-none bg-transparent',
                'w-full my-auto',
                { 'text-font-disabled text-sm': disabled },
              )}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const text = e.target.value;
                const formattedText = formatInput(text);
                onChange(formattedText);
                if (!formattedText) {
                  resetInput();
                }
              }}
              {...rest}
            />
            <div className="flex gap-1 my-auto ml-1">
              <span className="font-youth-medium font-medium text-sm text-font">{unit}</span>
            </div>
          </div>
        ),
      }}
    />
  );
}
