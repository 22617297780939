import { useTranslation } from 'react-i18next';

import { ArrowsButton } from '@transactions/components/ui/ArrowsButton';
import BudgetInputEmptyEntry from '@transactions/components/ui/BudgetInputEmptyEntry';
import BudgetInputEntry, { BudgetInputEntryProps } from '@transactions/components/ui/BudgetInputEntry';
import { Divider } from '@ui-kit/atoms/Divider';
import { Loader } from '@utils';
import { formatNumber } from '@utils/numbers/NumberFormat';

import StopLossConditions, { StopLossConditionsProps } from './StopLossConditions';

export type StopLossUIProps = {
  inputRef?: React.RefObject<HTMLInputElement>;
  inputBudget: BudgetInputEntryProps['budget'];
  outputBudget: BudgetInputEntryProps['budget'];
  inputHint: BudgetInputEntryProps['hint'];
  outputHint: BudgetInputEntryProps['hint'];
  inputHoldings: BudgetInputEntryProps['maxBudget'];
  outputHoldings: BudgetInputEntryProps['maxBudget'];
  onSelectInput: BudgetInputEntryProps['onSelectCoin'];
  onSelectOutput: BudgetInputEntryProps['onSelectCoin'];
  onInputChange: BudgetInputEntryProps['onChange'];
  marketPrice: StopLossConditionsProps['marketPrice'];
  onTriggerPriceChange: StopLossConditionsProps['onLimitPriceChange'];
  onLimitPriceChange: StopLossConditionsProps['onLimitPriceChange'];
  switchInputAndOutput: VoidFunction;
  onError: StopLossConditionsProps['onError'];
  onExpireChange: StopLossConditionsProps['onExpireChange'];
};

export function StopLossUI({
  inputRef,
  inputBudget,
  inputHint,
  onSelectInput,
  onSelectOutput,
  outputBudget,
  outputHint,
  onInputChange,
  inputHoldings,
  outputHoldings,
  marketPrice,
  onTriggerPriceChange,
  onLimitPriceChange,
  switchInputAndOutput,
  onError,
  onExpireChange,
}: StopLossUIProps) {
  const { t } = useTranslation();
  const sourceBudget$ = Loader.useWrap(inputBudget);
  const outputBudget$ = Loader.useWrap(outputBudget);
  const marketPrice$ = Loader.useWrap(marketPrice);

  const targetBudgetInput = outputBudget$.match
    .skipped(() => <BudgetInputEmptyEntry onSelect={onSelectOutput} />)
    .loading(() => (
      <BudgetInputEntry
        budget={Loader.loading}
        interactive={false}
        label={t('Common.buy')}
        onSelectCoin={onSelectOutput}
        hint={outputHint}
        maxBudget={outputHoldings}
        type="spot"
      />
    ))
    .error(e => e.toString())
    .ok(_targetBudget =>
      _targetBudget ? (
        <BudgetInputEntry
          budget={_targetBudget}
          interactive={false}
          label={t('Common.buy')}
          onSelectCoin={onSelectOutput}
          hint={outputHint}
          maxBudget={outputHoldings}
          type="spot"
        />
      ) : (
        <BudgetInputEmptyEntry onSelect={onSelectOutput} />
      ),
    );

  return (
    <div className="flex flex-col gap-2">
      <BudgetInputEntry
        budget={inputBudget}
        onChange={onInputChange}
        label={t('Common.sell')}
        hint={inputHint}
        onSelectCoin={onSelectInput}
        maxBudget={inputHoldings}
        type="spot"
        ref={inputRef}
      />
      <StopLossConditions
        inputBudget={inputBudget}
        outputBudget={outputBudget}
        marketPrice={marketPrice}
        onLimitPriceChange={onLimitPriceChange}
        onTriggerPriceChange={onTriggerPriceChange}
        onError={onError}
        onExpireChange={onExpireChange}
      />
      {Loader.array([sourceBudget$, outputBudget$, marketPrice$] as const)
        .match.notOk(() => null)
        .ok(([source, target, _marketPrice]) => (
          <span className="text-font-variant text-sm">
            {t('Transactions.BudgetInput.marketRate', {
              rate: '' + formatNumber(_marketPrice, 'qty'),
              sourceSymbol: source.token.symbol,
              targetSymbol: target.token.symbol,
            })}
          </span>
        ))}
      <div className="flex gap-3 my-2">
        <Divider className="my-auto flex-1" />
        <div className="bg-surface-muted flex items-center justify-center rounded-full w-8 h-8">
          <ArrowsButton onClick={switchInputAndOutput} />
        </div>
        <Divider className="my-auto flex-1" />
      </div>
      {targetBudgetInput}
    </div>
  );
}
