import cls from 'classnames';
import { useTranslation } from 'react-i18next';

import { WarningIcon } from '@ui-kit/Icons';

import { DivWithTooltip } from './DivWithTooltip';
import { SmallError } from './SmallError';
import { getOkValueDangerous, isLoader, Loader } from './loader';
import { formatNumber, NumberFormatType } from './numbers/NumberFormat';
import { Cls, isValidNumber } from './utils';

export function Int({ value }: { value: number }) {
  return <>{Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(Math.round(value))}</>;
}

export const MAX_DIGITS = 9;
export const MIN_NUMBER = 10 ** -MAX_DIGITS;
// this is '<0.000000001'  (if MAX_DIGITS still is 9, like at time of writing)
const MIN_NUMBER_STR =
  '<0.' +
  Array(MAX_DIGITS - 1)
    .fill('0')
    .join('') +
  '1';

// https://linear.app/nested-finance/issue/DEV-215/ameliorer-la-logique-daffichage-des-nombres
export function renderQuantity(origValue: number) {
  const value = Math.abs(origValue);

  const str = (() => {
    if (!value) {
      return '0';
    }
    // very small numbers will be displayed as fixed
    if (value < MIN_NUMBER) {
      return MIN_NUMBER_STR;
    }

    // reasonably small numbers will feature 4 significant decimals (but trim the zeros)
    if (value < 1) {
      // maximumFractionDigits & maximumSignificantDigits parameters are incompatible :(
      const ret = new Intl.NumberFormat('en-US', { minimumSignificantDigits: 4, maximumSignificantDigits: 4 }).format(
        value,
      );

      //  => trim the result to emulate maximumFractionDigits
      const digitsCount = ret.length - '0.'.length;
      const maxed = digitsCount > MAX_DIGITS ? ret.substr(0, '0.'.length + MAX_DIGITS) : ret;
      return maxed.replace(/0+$/, '');
    }

    // 1-100 range will always feature 4 significant decimals
    if (value < 100) {
      return new Intl.NumberFormat('en-US', { maximumFractionDigits: 4 }).format(value);
    }

    // american format for classic numbers
    if (value < 1_000_000) {
      return new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(value);
    }

    // big numbers will be displayed with SI prefix (but we only support M & B prefixes)
    const { ref, suffix } =
      value >= 1_000_000_000 ? { ref: 1_000_000_000, suffix: 'B' } : { ref: 1_000_000, suffix: 'M' };
    const num = new Intl.NumberFormat('en-US', { maximumSignificantDigits: 4, minimumSignificantDigits: 4 }).format(
      value / ref,
    );
    return num + suffix;
  })();

  return origValue < 0 ? '-' + str : str;
}

export type NumberOrLoader = number | Loader<number | nil> | nil;
export type NumberOrWarnOrLoader = number | WithWarn | Loader<number | WithWarn | nil> | nil;
export interface WithWarn {
  readonly value: number;
  readonly hasUnknownQuote?: boolean;
}

function isWithWarn(value: any): value is WithWarn {
  return value && typeof value === 'object' && 'value' in value;
}

function Custom({
  className,
  unitClassName,
  value: _value,
  showSign = false,
  unit = '',
  unitAfterValue = false,
  shouldHideWarning = false,
  hideTrailingZeros = false,
  takeAbsoluteValue = false,
  type,
}: {
  value: NumberOrWarnOrLoader | nil;
  showSign?: boolean;
  unit?: string;
  unitAfterValue?: boolean;
  /** Show "<" if computed number is below this value. ie: "$ <0.00" */
  shouldHideWarning?: boolean;
  hideTrailingZeros?: boolean;
  unitClassName?: string;
  takeAbsoluteValue?: boolean;
  type: NumberFormatType;
} & Cls) {
  const { t } = useTranslation();
  let originalValue: number | nil;
  let hasWarn = false;

  if (typeof _value === 'object') {
    // when given a loader, and if there is an error, we display the error
    let loadedValue: number | WithWarn | nil;
    if (isLoader(_value)) {
      if (_value.status === 'error') {
        return <SmallError />;
      }
      loadedValue = getOkValueDangerous(_value);
    } else {
      loadedValue = _value;
    }

    // when given
    if (isWithWarn(loadedValue)) {
      hasWarn = !!loadedValue.hasUnknownQuote;
      originalValue = loadedValue.value;
    } else {
      originalValue = loadedValue;
    }
  } else {
    originalValue = _value;
  }

  let value: string | undefined;

  if (typeof originalValue === 'number') {
    value = formatNumber(takeAbsoluteValue ? Math.abs(originalValue) : originalValue, type);

    if (originalValue > 0 && showSign) {
      value = '+' + value;
    }
  }
  const isBigUnit = unit?.length > 1;

  return (
    <span className={cls(className, 'whitespace-nowrap')}>
      {!isValidNumber(originalValue) ? (
        '-'
      ) : (
        <>
          {unit && !isBigUnit && !unitAfterValue && <span className={cls(unitClassName, 'mr-0.5')}>{unit}</span>}
          {hideTrailingZeros && value ? value.replace(/\.0+$/, '') : value}
        </>
      )}
      {unit && (isBigUnit || unitAfterValue) && <span className={cls(unitClassName, 'ml-1')}>{unit}</span>}
      {hasWarn && !shouldHideWarning && (
        <DivWithTooltip className="inline" tooltipContent={t('Common.unkownQuote')} placement="bottom">
          <WarningIcon className="fill-current text-yellow inline mx-1 mt-[-3px] h-3" />
        </DivWithTooltip>
      )}
    </span>
  );
}

function UsdValue({
  showSign,
  value,
  ...args
}: {
  value: NumberOrWarnOrLoader;
  showSign?: boolean;
  shouldHideWarning?: boolean;
  unitClassName?: string;
  isCompact?: boolean;
} & Cls) {
  return (
    <>
      {
        // eslint-disable-next-line no-nested-ternary
        typeof value === 'number' && showSign ? (value < 0 ? '- ' : '+ ') : ''
      }
      <Custom {...args} unit="$" value={typeof value === 'number' ? Math.abs(value) : value} type="price" />
    </>
  );
}
function UsdPrice({
  showSign = false,
  value,
  className,
  unitClassName,
  inParenthesis,
}: {
  value: NumberOrWarnOrLoader;
  showSign?: boolean;
  unitClassName?: string;
  inParenthesis?: boolean;
} & Cls) {
  return (
    <span className={className}>
      {inParenthesis && '('}
      {
        // eslint-disable-next-line no-nested-ternary
        typeof value === 'number' && showSign ? (value < 0 ? '- ' : '+ ') : ''
      }
      <Custom
        unit="$"
        type="amount"
        value={typeof value === 'number' ? Math.abs(value) : value}
        unitClassName={unitClassName}
      />
      {inParenthesis && ')'}
    </span>
  );
}
function Percent(
  args: {
    value: NumberOrLoader;
    showSign?: boolean;
    unitClassName?: string;
    takeAbsoluteValue?: boolean;
  } & Cls,
) {
  return (
    <Custom
      {...args}
      value={Loader.useWrap(args.value).map(v => (typeof v === 'number' ? v * 100 : undefined))}
      unit="%"
      unitAfterValue
      type="amount"
    />
  );
}

export const Float = {
  /** A custom float value */
  Custom,
  /**
   * An USD value, which will be always displayed with 2 decimals;
   * This component includes a "$" prefix.
   * ⚠️ DO NOT USE THIS FOR PRICES (which could need more than 2 decimals)
   */
  UsdValue,
  /** An USD price.
   * This component includes a "$" prefix.
   */
  UsdPrice,
  /** Give it a ratio, it will display a nice percentage (0.123 will be displayed "12.30%") */
  Percent,
};
