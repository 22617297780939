import { convertAmt } from '@utils';

import { Expiration } from '../limit-orders/enum';

export type StopLossState = {
  inputTokenId: ChainAddress | nil;
  outputTokenId: ChainAddress | nil;
  inputAmount: bigint;
  isMax: boolean;
  triggerPrice: number;
  limitPrice: number;
  expiration: Expiration;
  error?: string;
};

export type StopLossAction =
  | { type: 'SET_INPUT_TOKEN_ID'; payload: ChainAddress | nil }
  | { type: 'SET_OUTPUT_TOKEN_ID'; payload: ChainAddress | nil }
  | { type: 'SET_INPUT_AMOUNT'; payload: { value: bigint; isMax?: boolean } }
  | { type: 'SET_TRIGGER_PRICE'; payload: number }
  | { type: 'SET_LIMIT_PRICE'; payload: number }
  | { type: 'SET_EXPIRATION'; payload: Expiration }
  | { type: 'SET_ERROR'; payload: string | undefined }
  | { type: 'SWITCH_TOKENS'; payload: { inputDecimals: number; outputDecimals: number } };

export const stopLossReducer = (state: StopLossState, action: StopLossAction): StopLossState => {
  switch (action.type) {
    case 'SET_INPUT_TOKEN_ID':
      return {
        ...state,
        inputTokenId: action.payload,
      };
    case 'SET_OUTPUT_TOKEN_ID':
      return {
        ...state,
        outputTokenId: action.payload,
      };
    case 'SWITCH_TOKENS':
      return {
        ...state,
        inputAmount: convertAmt(state.inputAmount, action.payload.inputDecimals, action.payload.outputDecimals),
        inputTokenId: state.outputTokenId,
        outputTokenId: state.inputTokenId,
      };
    case 'SET_INPUT_AMOUNT':
      return {
        ...state,
        inputAmount: action.payload.value,
        isMax: !!action.payload.isMax,
      };
    case 'SET_TRIGGER_PRICE':
      return {
        ...state,
        triggerPrice: action.payload,
      };
    case 'SET_LIMIT_PRICE':
      return {
        ...state,
        limitPrice: action.payload,
      };
    case 'SET_EXPIRATION':
      return {
        ...state,
        expiration: action.payload,
      };
    case 'SET_ERROR':
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};
