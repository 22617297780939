/* eslint-disable react/no-array-index-key */
import cls from 'classnames';
import { match } from 'ts-pattern';

import { Cls, Loadable, Loader } from '@utils';

import { Skeleton } from './Skeleton';

export type IProgressBar = {
  valuePercent: Loadable<number>;
  id?: string;
  theme?: 'info' | 'success' | 'warning' | 'error' | 'accent' | 'disabled';
  totalCrantsCount?: number;
} & Cls;

export function ProgressBar({ id, valuePercent, className, theme = 'accent', totalCrantsCount = 0 }: IProgressBar) {
  const webkitProgressValue = match(theme)
    .with('info', () => '[&::-webkit-progress-value]:bg-info [&::-moz-progress-bar]:bg-info')
    .with('success', () => '[&::-webkit-progress-value]:bg-success [&::-moz-progress-bar]:bg-success')
    .with('warning', () => '[&::-webkit-progress-value]:bg-warning [&::-moz-progress-bar]:bg-warning')
    .with('error', () => '[&::-webkit-progress-value]:bg-danger [&::-moz-progress-bar]:bg-danger')
    .with('accent', () => '[&::-webkit-progress-value]:bg-accent [&::-moz-progress-bar]:bg-accent')
    .with('disabled', () => '[&::-webkit-progress-value]:bg-font-variant [&::-moz-progress-bar]:bg-font-variant')
    .run();

  const bgColor = match(theme)
    .with('info', () => 'bg-info')
    .with('success', () => 'bg-success')
    .with('warning', () => 'bg-warning')
    .with('error', () => 'bg-danger')
    .with('accent', () => 'bg-accent')
    .with('disabled', () => 'bg-font-variant')
    .run();

  const simpleProgress = Loader.useWrap(valuePercent)
    .noFlickering()
    .match.loadingOrSkipped(() => <Skeleton className="h-1 w-full" />)
    .error(() => <Skeleton className="h-1 w-full" noAnimation />)
    .ok(_val => (
      <progress
        id={id}
        max="100"
        value={_val}
        className={cls(
          'h-1 w-full',
          '[&::-webkit-progress-bar]:rounded-full [&::-webkit-progress-value]:rounded-full',
          '[&::-webkit-progress-bar]:bg-surface-variant',
          'bg-surface-variant rounded-full [&::-moz-progress-bar]:rounded-full',
          totalCrantsCount && 'translate-y-[-7px]',
          webkitProgressValue,
          className,
        )}
      />
    ));

  const crants = Loader.useWrap(valuePercent)
    .noFlickering()
    .match.notOk(() => null)
    .ok(_valuePercent => (
      <div className="absolute inset-0 top-[5px] justify-between flex">
        {Array(totalCrantsCount)
          .fill(0)
          .map((_, index) => (
            <div
              key={`crant-${index}`}
              className={cls(
                'rounded-full w-2 h-2',
                isCrantActive(index, totalCrantsCount, _valuePercent) ? bgColor : 'bg-surface-variant',
              )}
            />
          ))}
      </div>
    ));

  return !totalCrantsCount ? (
    simpleProgress
  ) : (
    <div className={cls('relative h-4', { 'pointer-events-none': theme === 'disabled' })}>
      {simpleProgress}
      {crants}
    </div>
  );
}

function isCrantActive(crantIndex: number, totalCrantsCount: number, valuePercent: number) {
  if (!valuePercent) {
    return false;
  }
  if (valuePercent === 100) {
    return true;
  }

  const crantProgress = (100 / (totalCrantsCount - 1)) * crantIndex;
  return valuePercent >= crantProgress;
}
