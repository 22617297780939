import cls from 'classnames';
import { match } from 'ts-pattern';

import { CheckIcon } from '@ui-kit/Icons';
import { Cls } from '@utils/utils';

export type IToggle = {
  checked?: boolean;
  size?: 's' | 'l';
  disabled?: boolean;
  dataCy?: string;
  onToggle?: VoidFunction;
} & Cls;

export const Toggle = ({ className, checked = false, size = 'l', disabled, dataCy, onToggle }: IToggle) => {
  const bgColors = match([disabled, checked])
    .when(
      ([d]) => d,
      () => ({ container: 'bg-surface-disabled', content: 'bg-font-disabled' }),
    )
    .when(
      ([d, v]) => !d && !!v,
      () => ({ container: 'bg-accent hover:bg-accent-hover-font-on-accent', content: 'bg-font' }),
    )
    .otherwise(() => ({ container: 'bg-surface-variant hover:bg-surface-variant-hover-invert', content: 'bg-font' }));
  return (
    <div
      data-cy={`${dataCy}-toggle`}
      onClick={disabled ? undefined : onToggle}
      className={cls(
        'flex flex-row justify-between items-center rounded-full p-1',
        'transition-all ease duration-300',
        size === 's' ? 'w-10 h-6' : 'w-14 h-8',
        bgColors.container,
        { 'cursor-pointer': !disabled },
        className,
      )}
    >
      <div className="relative flex items-center w-full h-full">
        <div
          className={cls(
            'flex justify-center items-center',
            'absolute rounded-full transition-all ease duration-300',
            bgColors.content,
            size === 's' ? 'w-4 h-4' : 'w-6 h-6',
            checked ? 'left-full -translate-x-full bg-font-on-accent' : 'left-0',
          )}
        >
          <CheckIcon
            className={cls(
              'w-3 h-3 fill-current text-accent transition-all ease duration-300',
              checked && !disabled ? 'opacity-100' : 'opacity-0',
            )}
          />
        </div>
      </div>
    </div>
  );
};
