import { useTranslation } from 'react-i18next';

import { Chain } from '@gql';
import { openFeatureFlagsSidebar } from '@hooks/useFeatureFlags';
import { ReactComponent as NoRefCoin } from '@images/coins/NoRefCoin.svg';
import { CocktailPatternIcon, ProfileIcon, WalletIcon } from '@ui-kit/Icons';
import { Button } from '@ui-kit/atoms/Button';
import { Skeleton } from '@ui-kit/atoms/Skeleton';
import { BadgedItem } from '@ui-kit/organisms/BadgedItem';
import { withoutChain, Loader, Loadable, Float, shortenAddress } from '@utils';
import { networksByChain } from '@utils/networks';
import { getTransactionIcon } from '@utils/uiKitUtils/statusTransactionUtils';

import { env } from '../../env/env';
import { useTxManagerAlerts } from '../../hooks/useTxManagerAlerts';

type IAccountSummary = {
  isAuth: Loadable<boolean>;
  walletAddress: Loadable<{ address: ChainAddress | nil; chain: Chain | nil }>;
  balance?: Loadable<number>;
  login: () => void;
  onHistoryClick: () => void;
  onWalletClick: () => void;
};
export function AccountSummary({
  isAuth,
  walletAddress,
  balance,
  login,
  onWalletClick,
  onHistoryClick,
}: IAccountSummary) {
  const { t } = useTranslation();

  const isAuthed$ = Loader.useWrap(isAuth);
  const walletAddress$ = Loader.useWrap(walletAddress);
  const balance$ = Loader.useWrap(balance);
  const authenticated$ = Loader.array([isAuthed$, walletAddress$, balance$] as const);

  const { pendingTransactionCount: count, topTransactionState } = useTxManagerAlerts();
  const { Icon: txManagerIcon, iconClassName, variant: txManagerVariant } = getTransactionIcon(topTransactionState);

  const connectButton = <Button variant="surface-accent" fullWidth onClick={login} label={t('Common.connectWallet')} />;

  return (
    <div className="w-full flex justify-between gap-2 h-[48px]">
      {authenticated$
        ?.noFlickering()
        .match.skipped(() => connectButton)
        .loading(() => <LoadingAccount />)
        .error(() => connectButton)
        .ok(([_authed, _address, _balance]) => {
          if (!_authed || !_address) return connectButton;

          const address = withoutChain(_address.address);
          const NetworkIcon = _address.chain ? networksByChain[_address.chain]?.Icon : NoRefCoin;

          return (
            <div className="flex justify-start items-center gap-2 flex-grow cursor-pointer" onClick={onWalletClick}>
              <BadgedItem
                className="flex-grow-0"
                badge={{
                  content: <NetworkIcon width={16} height={16} className="rounded-full" />,
                  style: 'outline',
                }}
              >
                <Button variant="surface" Icon={ProfileIcon} />
              </BadgedItem>
              <div className="flex flex-col">
                <span className="font-medium">{shortenAddress(address)}</span>
                <span className="flex gap-1 items-center font-medium text-font-variant text-sm">
                  <WalletIcon height={12} width={12} />
                  <Float.UsdValue value={_balance} />
                </span>
              </div>
            </div>
          );
        })}

      {env !== 'production' && (
        <Button
          onClick={openFeatureFlagsSidebar}
          Icon={CocktailPatternIcon}
          iconClassName="text-font-on-accent"
          variant="accent"
        />
      )}

      <BadgedItem badge={count ? { content: `${count}` } : null} className="cursor-pointer">
        <Button
          onClick={onHistoryClick}
          Icon={txManagerIcon}
          iconClassName={iconClassName}
          variant={txManagerVariant}
        />
      </BadgedItem>
    </div>
  );
}

const LoadingAccount = () => (
  <div className="flex justify-start items-center gap-2 flex-grow cursor-pointer">
    <Button isLoading />
    <div className="flex flex-col gap-1">
      <Skeleton className="w-24 h-5" />
      <span className="flex gap-1 items-center font-medium text-font-variant">
        <WalletIcon height={12} width={12} />
        <Skeleton className="w-12 h-4" />
      </span>
    </div>
  </div>
);
