import './polyfills';
import { ApolloProvider } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  RouterProvider,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { RecoilRoot } from 'recoil';
import RecoilNexus from 'recoil-nexus';

import 'react-toastify/dist/ReactToastify.css';
import './index.css';

import { Root, RouteNotFound } from './Root';
import { apolloClient } from './apollo';
import Head from './head';
import { mainAppRoutes } from './routes/mainAppRoutes';
import { WalletProvider } from './wallet/WalletProvider';

declare const __COMMIT_HASH__: string;
const env = import.meta.env.VITE_ENVIRONEMENT ? import.meta.env.VITE_ENVIRONEMENT : 'dev';

Sentry.init({
  // Dont set dsn in dev to avoid logging errors
  dsn: env === 'dev' ? null! : 'https://c805ee27138843098acb4662878babbc@o1011993.ingest.sentry.io/4505447490125824',
  environment: env,
  release: env === 'dev' ? '' : __COMMIT_HASH__,
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      // Temporarily disabled as the header injected is not yet authorized by our cloudfront distribution
      // tracePropagationTargets:
      //   import.meta.env.VITE_ENVIRONEMENT === 'staging'
      //     ? [/^https:\/\/api\.staging\.nested\.finance\/graphql/]
      //     : [/^https:\/\/api\.nested\.finance\/graphql/],
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 0.1,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  // TODO: test replays on staging first
  // replaysSessionSampleRate: 0.1,
  // replaysOnErrorSampleRate: 1.0,
});

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);
const router = sentryCreateBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <RouteNotFound />,
    children: mainAppRoutes,
  },
]);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  // without the suspense the lazy loading of the routes throw an error
  // might be related to recoil
  <RecoilRoot>
    <RecoilNexus />
    <ApolloProvider client={apolloClient}>
      <WalletProvider>
        <Head />
        <RouterProvider router={router} />
      </WalletProvider>
    </ApolloProvider>
  </RecoilRoot>,
);

ReactDOM.createRoot(document.getElementById('root-bis') as HTMLElement).render(<ToastContainer className="z-10" />);
