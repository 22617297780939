import { chainOf, enumValues, notNil, parseNum, unique, wrapToken } from '@utils';

import { fetch0xSwap } from './0x';
import {
  AggregatorQuoteResponse,
  AggregatorRequest,
  DexAggregator,
  QuoteErrorReasons,
  QuoteFailedError,
} from './dex-aggregator-types';
import { fetchKyberSwap } from './kyberswap';
import { fetchParaSwap } from './paraswap';

export async function fetchLowestQuote(
  _request: AggregatorRequest,
  onlyUseAggregators?: DexAggregator[],
): Promise<AggregatorQuoteResponse> {
  const aggregators = onlyUseAggregators ?? enumValues(DexAggregator);

  // wrap tokens if needed
  const request = {
    ..._request,
    buyToken: wrapToken(_request.buyToken),
    spendToken: wrapToken(_request.spendToken),
  };

  // check that all chains are coherent
  const chains = unique(notNil([request.buyToken.id, request.vaultAddress, request.spendToken.id].map(chainOf)));
  if (chains.length !== 1) {
    throw new Error('Incoherent chains');
  }

  // declare all aggregators
  const dexAggregators = {
    ZeroEx: {
      name: DexAggregator.ZeroEx,
      fetch: fetch0xSwap,
    },
    Paraswap: {
      name: DexAggregator.Paraswap,
      fetch: fetchParaSwap,
    },
    KyberSwap: {
      name: DexAggregator.KyberSwap,
      fetch: fetchKyberSwap,
    },
  };

  // fetch quotes
  const dexAggrRequests = Object.values(dexAggregators)
    .filter(dAggr => aggregators.includes(dAggr.name))
    .map(dAggr => dAggr.fetch(request));

  // wait until all quotes have settled, and only keep successufl fetches
  const dexAggrQuotesResp = await Promise.allSettled(dexAggrRequests);
  const successfulQuotes = dexAggrQuotesResp
    .filter(resp => resp.status === 'fulfilled' && !!resp.value)
    .map(resp => (resp as PromiseFulfilledResult<AggregatorQuoteResponse>).value);

  // when there is no successful quotes, then will throw a proper error
  if (successfulQuotes.length === 0) {
    // find "legit" errors
    const quoteFailedErrors: QuoteFailedError[] = notNil(
      dexAggrQuotesResp.map(x => (x.status === 'rejected' && x.reason instanceof QuoteFailedError ? x.reason : null)),
    );
    if (quoteFailedErrors.length === 1) {
      // if only one error, then rethrow it
      throw quoteFailedErrors[0];
    } else if (quoteFailedErrors.length) {
      // if has a liquidity error, then throw a liquidity error
      if (quoteFailedErrors.some(x => x.reason === QuoteErrorReasons.INSUFFICIENT_ASSET_LIQUIDITY)) {
        throw new QuoteFailedError(QuoteErrorReasons.INSUFFICIENT_ASSET_LIQUIDITY);
      }
      // else, throw an unkown error
      throw new QuoteFailedError(QuoteErrorReasons.UNKNOWN_ERROR);
    }
    // dont know why all aggregator failed
    throw new Error('All DEX aggregators quotes were unsuccessful');
  }

  // return the best quote
  return successfulQuotes.sort((quoteA, quoteB) =>
    parseNum(quoteB.buyAmount) > parseNum(quoteA.buyAmount) ? 1 : -1,
  )[0];
}
