import cls from 'classnames';
import { useTranslation } from 'react-i18next';

import { EntryList } from '@components/entries/EntryList';
import { IEntryProduct } from '@components/entries/EntryProduct';
import { SideContentHeader } from '@components/layouts/side-content/SideContentHeader';
import { AddIcon, CheckIcon, SearchIcon } from '@ui-kit/Icons';
import { Button } from '@ui-kit/atoms/Button';
import { Skeleton } from '@ui-kit/atoms/Skeleton';
import { Entry } from '@ui-kit/organisms/Entry';
import { TextInput, TextInputSize } from '@ui-kit/organisms/TextInput';
import { AssetLogo } from '@ui-kit/organisms/TokenLogo';
import { Float, Loadable, Loader, chainOf } from '@utils';
import { networksByChain } from '@utils/networks';

export type SelectCoinProps = {
  searchValue?: string;
  onSearchValueChange: (value: string) => void;
  coins: Loadable<readonly IEntryProduct[]>;
  onSelect: (coin: IEntryProduct) => void;
  onGoBack: () => void;
  multiSelect?: boolean;
};

export const renderCoin = (coin: IEntryProduct, multiSelect = false) => {
  const { IconPattern } = networksByChain[chainOf(coin.id as any)];
  return (
    <Entry
      isCard
      disabled={multiSelect && coin.disabled}
      leadingItem={<AssetLogo asset={coin} withBadge={false} />}
      trailingItem={
        multiSelect ? (
          <Button Icon={coin.isChecked ? CheckIcon : AddIcon} variant={coin.isChecked ? 'surface-accent' : 'surface'} />
        ) : undefined
      }
      leadingBackground
      className={cls({ 'opacity-50': coin.disabled })}
    >
      {coin.disabled && coin.disabledReason ? (
        <div>
          <div className="text-lg text-font">{coin.symbol}</div>
          <div className="text-body-medium text-font-variant break-words whitespace-pre-line">
            {coin.disabledReason}
          </div>
        </div>
      ) : (
        <div className="flex justify-between">
          <div className="flex flex-col justify-center shrink w-2/3">
            <span className="text-lg text-font">{coin.symbol}</span>
            <span className="text-font-variant flex flex-row align-middle gap-1 justify-start text-lg">
              <span className="text-ellipsis overflow-hidden">{coin.name}</span>
              <span>
                <IconPattern className="inline h-6 w-6 bg-surface-muted rounded-md p-1 ml-1" />
              </span>
            </span>
          </div>
          <div className="flex flex-col justify-center items-end shrink-0">
            <Float.Custom value={coin.quantity} type="qty" />
            {coin.quantity && coin.quantity > 0 && coin.quote ? (
              <Float.UsdValue value={coin.quote * coin.quantity} className="text-font-variant" />
            ) : null}
          </div>
        </div>
      )}
    </Entry>
  );
};

export const renderLoading = () => {
  return (
    <>
      {Array.from({ length: 5 }).map((_, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <Skeleton className="w-full !h-[76px] shrink-0" key={'loading' + i}>
          <div className="flex flex-1 justify-start p-4">
            <Skeleton isRound className="w-10 h-10" />
            <div className="flex flex-row justify-between ml-3 flex-1">
              <div>
                <Skeleton className="w-20 h-4" />
                <Skeleton className="w-10 h-3 mt-1" />
              </div>
              <div className="flex flex-col items-end">
                <Skeleton className="w-10 h-3" />
                <Skeleton className="w-20 h-4 mt-2" />
              </div>
            </div>
          </div>
        </Skeleton>
      ))}
    </>
  );
};

export default function SelectCoinUI({
  searchValue,
  onSearchValueChange,
  coins,
  onSelect,
  onGoBack,
  multiSelect = false,
}: SelectCoinProps) {
  const isEmpty = Loader.wrap(coins)
    .match.loadingOrSkipped(() => false)
    .error(() => true)
    .ok(_coins => _coins.length === 0);

  const { t } = useTranslation();
  const screenTitle = multiSelect
    ? t('Transactions.SearchCoin.header.titleMultiple')
    : t('Transactions.SearchCoin.header.title');

  return (
    <section>
      <div className="relative mb-6">
        <SideContentHeader title={screenTitle} onGoBack={onGoBack} />
      </div>
      <TextInput
        LeadingVisual={<SearchIcon className="text-font-variant flex-shrink-0" />}
        value={searchValue}
        onChange={e => onSearchValueChange(e.target.value)}
        placeholder={t('UIKit.SearchInput.placeholder')}
        size={TextInputSize.M}
      />
      {isEmpty ? (
        <div className="text-center w-auto mt-6">{t('Transactions.SearchCoin.content.noResults')}</div>
      ) : (
        <EntryList<IEntryProduct>
          entries={coins}
          render={(e: IEntryProduct) => renderCoin(e, multiSelect)}
          renderLoading={renderLoading}
          variant="small-spaced"
          onClickEntry={onSelect}
          className="mt-6 w-full"
          contentClassName="pr-1 pb-3"
        />
      )}
    </section>
  );
}
