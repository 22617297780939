/* eslint-disable no-await-in-loop */

import { zeroExFetcher } from '@env';
import { Chain } from '@gql';
import { chainOf, delay, parseNum, rateLimit, unreachableError, withoutChain } from '@utils';

import { ZeroXAnswer } from './0x-types';
import { AggregatorRequest, DexAggregator, QuoteErrorReasons, QuoteFailedError } from './dex-aggregator-types';

export enum ZeroXErrorCodes {
  'INSUFFICIENT_ASSET_LIQUIDITY' = 1004,
}

function zxQuoteUrl(config: AggregatorRequest, _zeroExUrl: ((chain: Chain) => string) | undefined): string {
  const endpoint = (_zeroExUrl ?? zxEndpoint)(chainOf(config.vaultAddress));

  const op =
    'spendQty' in config ? `&sellAmount=${parseNum(config.spendQty)}` : `&buyAmount=${parseNum(config.boughtQty)}`;
  // eslint-disable-next-line max-len
  return `${endpoint}swap/v1/quote?sellToken=${withoutChain(config.spendToken.id)}&buyToken=${withoutChain(
    config.buyToken.id,
  )}${op}&slippagePercentage=${config.slippage}`;
}

function zxEndpoint(chain: Chain) {
  switch (chain) {
    case Chain.eth:
      return 'https://api.0x.org/';
    case Chain.bsc:
      return 'https://bsc.api.0x.org/';
    case Chain.avax:
      return 'https://avalanche.api.0x.org/';
    case Chain.poly:
      return 'https://polygon.api.0x.org/';
    case Chain.opti:
      return 'https://optimism.api.0x.org/';
    case Chain.arbi:
      return 'https://arbitrum.api.0x.org/';
    case Chain.base:
      return 'TODO';
    default:
      throw unreachableError(chain);
  }
}

// 0x api is limited to 6 requests per sec, and 120 per min
// => use a lower limit to avoid hitting it.
const fetchLimited = rateLimit(fetch, [
  { interval: 1000, limit: 5 },
  { interval: 60 * 1000, limit: 110 },
]);

export async function defaultZeroExFetcher(
  config: AggregatorRequest,
  _zeroExUrl?: ((chain: Chain) => string) | undefined,
): Promise<ZeroXAnswer> {
  const url = zxQuoteUrl(config, _zeroExUrl);
  let retry = 0;
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const response = await fetchLimited(url, {
      headers: {
        '0x-api-key': '804a2ea2-e25e-4979-9df4-f5d7e8ccb52a',
      },
    });

    // 429: too many requests => retry 10 times, over 5 seconds
    if (response.status === 429) {
      if (++retry > 10) {
        throw new Error('Failed to fetch 0x quote because you are over-quota (tried 10 times).');
      }
      await delay(500);
      continue;
    }

    let json: any;
    try {
      json = await response.json();
    } catch (e) {
      // nop !
    }

    // 400: validation failed. A problem with our inputs
    if (response.status === 400) {
      try {
        const errs = json?.validationErrors as { field: string; code: number; reason: string }[];
        if (errs.find(e => e?.code === ZeroXErrorCodes[QuoteErrorReasons.INSUFFICIENT_ASSET_LIQUIDITY])) {
          throw new QuoteFailedError(QuoteErrorReasons.INSUFFICIENT_ASSET_LIQUIDITY);
        }
      } catch (e) {
        if (e instanceof QuoteFailedError) {
          throw e;
        }
        // else do nothing, will be handled below
      }
    }

    // other rerror
    if (!response.ok) {
      const error = json?.validationErrors?.[0].reason || QuoteErrorReasons.UNKNOWN_ERROR;
      throw new Error(`Failed to fetch 0x quote: ${error} while fetching ${url} (${response.status})`);
    }
    if (!json) {
      throw new Error(`Failed to fetch 0x quote: invalid json returned while fetching ${url} (${response.status})`);
    }
    return json;
  }
}

export async function fetch0xSwap(toFetch: AggregatorRequest): Promise<AggregatorQuoteResponse> {
  const result = await (zeroExFetcher ?? defaultZeroExFetcher)(toFetch);
  return zeroExRespToQuoteResp(result);
}

// convert from the 0x specific quote response to a more generic dex aggregator response type
function zeroExRespToQuoteResp(answer: ZeroXAnswer): AggregatorQuoteResponse {
  return {
    aggregator: DexAggregator.ZeroEx,
    ...answer,
  };
}

export interface AggregatorQuoteResponse {
  aggregator: DexAggregator;
  chainId: number;
  price: string;
  guaranteedPrice: string;
  to: HexString;
  data: HexString;
  value: string;
  gas?: string;
  estimatedGas?: string;
  gasPrice?: string;
  protocolFee?: string;
  minimumProtocolFee?: string;
  buyTokenAddress: HexString;
  sellTokenAddress: HexString;
  buyAmount: string;
  sellAmount: string;
  allowanceTarget: HexString;
  estimatedPriceImpact: string;
}
