import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { defaultTokens } from '@constants';
import { GetUserBalances, GetUserBalancesVariables } from '@gql';
import { refreshMainVaultDataAtom, refreshMainVaultData } from '@hooks/useMainVault';
import { getTokenInfo } from '@hooks/useTokensInfo';
import { GET_USER_BALANCES, getUserAggregatedHoldings } from '@queries/user.queries';
import { useDeposit } from '@tetris/deposit';
import { useCreateOrCallVault } from '@tetris/tx';
import { SendTxButton } from '@transactions/components/SendTxButton';
import { TxSpeed } from '@transactions/components/TransactionSpeed';
import { Button } from '@ui-kit/atoms/Button';
import { Divider } from '@ui-kit/atoms/Divider';
import { TxType } from '@ui-kit/organisms/SingleTxReviewContent';
import {
  Loader,
  holdingQuote,
  chainOf,
  useTokenQuote,
  parseNum,
  TokenBudget,
  isTokenBudget,
  isCoin,
  ICoin,
  Budget,
  convertAmt,
} from '@utils';
import { useWallet } from '@wallet-hooks';

import { DepositUI } from './DepositUI';
import { useChangeCoin } from '../../../hooks/useChangeCoin';
import { useTransactionFlow } from '../../../hooks/useTransactionFlow';
import { DepositFlowProps, TransactionFlowType } from '../../../types';

export const DepositFlow: React.FC<DepositFlowProps> = ({ prefillInputTokenId: inputs, prefillInputAmount }) => {
  const { t } = useTranslation();
  const { setCurrentFlow, currentChain, resetScroll } = useTransactionFlow();
  const [lastSourceBudget, setLastSourceBudget] = useState<Budget<ICoin> | null>(null);
  const refreshWhenChanges = useRecoilValue(refreshMainVaultDataAtom);

  const prefillInputTokenId = !inputs
    ? undefined
    : [...new Set(typeof inputs === 'string' ? [inputs] : inputs)]?.find(it => chainOf(it) === currentChain);
  const wallet$ = useWallet(true);
  const balances$ = wallet$
    .query<GetUserBalances, GetUserBalancesVariables>([currentChain, refreshWhenChanges], GET_USER_BALANCES, x => ({
    chain: currentChain || (prefillInputTokenId && chainOf(prefillInputTokenId)) || chainOf(x.address),
  }))
    .map(({ myUser }) => [...(myUser?.walletBalances ?? [])].sort((a, b) => holdingQuote(b) - holdingQuote(a)));

  const [inputTokenId, setInputTokenId] = useState<ChainAddress | undefined>(prefillInputTokenId);
  useEffect(() => {
    setInputTokenId(prefillInputTokenId);
  }, [prefillInputTokenId]);

  const [inputBudget$, setInputBudget$] = Loader.array([wallet$, balances$, inputTokenId] as const)
    .map(([_wallet, balances, _inputTokenId]) => {
      let tok;
      if (_inputTokenId) {
        const bal = balances.find(x => x.token.id === _inputTokenId);
        const hasBalance = bal?.token && parseNum(bal?.qty);
        tok = hasBalance ? bal?.token : { id: _inputTokenId };
      } else {
        const highest = balances[0];
        tok = highest?.token ?? null;
      }

      return getTokenInfo(tok?.id || defaultTokens[chainOf(_wallet.address)]);
    })
    .map(x => x || Loader.error('No token found'))
    .map<TokenBudget>(token => ({
    token,
    amtBase: lastSourceBudget
      ? convertAmt(lastSourceBudget.amtBase, lastSourceBudget.token.decimals, token.decimals)
      : prefillInputAmount || 0n,
  }))
    .asState<TokenBudget>();

  inputBudget$.onOk(b => setLastSourceBudget(b));

  const inputQuote$ = useTokenQuote(inputBudget$.map(x => x?.token?.id));
  const inputBudgetWithQuote$ = Loader.array([inputBudget$, inputQuote$] as const).map(([budget, quote]) => ({
    ...budget,
    quote,
  }));

  const inputToken$ = inputBudget$.map(x => x.token);
  const budgetBalance$ = Loader.array([balances$, inputToken$] as const).map(([_bls, _inputToken]) => {
    const tok = _bls.find(x => x.token.id === _inputToken.id);
    return tok
      ? {
        amtBase: parseNum(tok?.qty) ?? 0n,
        quote: tok.token?.quote ?? 0,
        token: tok.token,
      }
      : null;
  });

  const inputTokenHolding$ = wallet$
    .map([refreshWhenChanges], ({ address }) => getUserAggregatedHoldings(address ? chainOf(address) : null))
    .map(x => x?.mainVault.spot ?? [])
    .combine(inputToken$, (holdings, tok) => ({ holdings, tok }))
    .map(({ holdings, tok }) => holdings.find(x => x.token.id === tok.id) ?? null);

  const changeCoin = useChangeCoin(false, { sortBy: 'balances' });

  // ==================================================
  // =================== PREPARE TX ===================
  // ==================================================
  const deposit = useDeposit(inputBudget$);
  const toastConfig = inputBudget$.match
    .notOk(() => ({}))
    .ok(budget => ({
      failedToast: {
        content: t('Transactions.ToastStatus.depositFailed', { token: budget?.token?.symbol }),
      },
    }));

  const chain$ = inputBudget$.map(x => {
    if (!isCoin(x.token)) {
      return Loader.error('Transactions.TransactionReview.deposit.onlyTokens');
    }
    return chainOf(x?.token?.id);
  });
  const { sendTx, operation } = useCreateOrCallVault(chain$, [deposit], toastConfig);

  operation.onOk(() => {
    resetInputBudget();
  });

  const resetInputBudget = inputBudget$.makeCallback(input => {
    if (input) {
      setInputBudget$({ ...input, amtBase: 0n });
      refreshMainVaultData();
    }
  });

  const onSwitchToWithdraw = inputBudget$.match
    .notOk(() => undefined)
    .ok(_inputBudget => () => {
      setCurrentFlow(TransactionFlowType.WITHDRAW, {
        prefillInputTokenId: _inputBudget.token.id,
        prefillInputAmount: _inputBudget.amtBase,
      });
    });

  const sendTxButton = Loader.array([inputBudgetWithQuote$, budgetBalance$, sendTx.map(x => x.sendNext)] as const)
    .noFlickering()
    .match.loadingOrSkipped(() => (
      <Button size="l" label={t('Transactions.Deposit.confirmDeposit')} fullWidth disabled />
    ))
    .error(() => <Button size="l" label={t('Transactions.Recap.insufficientBalance')} fullWidth disabled />)
    .ok(([budget, bBal, _sendTx]) => {
      const notEnoughBalance = !bBal?.amtBase || budget.amtBase > bBal.amtBase;
      if (notEnoughBalance) {
        return <Button size="l" label={t('Transactions.Recap.insufficientBalance')} fullWidth disabled />;
      }

      const onContinue = () => {
        if (budget.token && budget.amtBase) {
          _sendTx();
        }
      };
      return (
        <SendTxButton
          sendTx={onContinue}
          isLoading={operation.isLoading}
          disabled={!budget.amtBase || !budget.token || operation.isLoading}
          label={t('Transactions.Deposit.confirmDeposit')}
          dataCy="AmountScreen_cta"
          txData={{
            variant: TxType.deposit,
            inputToken: budget,
            outputToken: budget,
            fees: { total: 0, paraswap: 0, nested: 0, network: 0 },
            txSpeed: TxSpeed.normal,
            onSelectTxSpeed: () => {},
            onOpenSlippageSettings: () => {},
          }}
        />
      );
    });

  const onSourceChange = (a: Budget<ICoin>, isMax?: boolean) => {
    if (isTokenBudget(a)) {
      const maxAmt = budgetBalance$.match.notOk(() => 0n).ok(h => h?.amtBase);
      setInputBudget$({ ...a, amtBase: (isMax && maxAmt) || a.amtBase });
    }
  };

  return (
    <>
      <DepositUI
        walletAddress={wallet$.map(x => x.address)}
        holdingsValue={inputTokenHolding$.map(x => parseNum(x?.qty) ?? 0n)}
        sourceBudget={inputBudgetWithQuote$}
        sourceBalance={budgetBalance$.map(b => b?.amtBase || 0n)}
        onSourceChange={onSourceChange}
        onSourceCoinSelect={() => changeCoin(token => token && resetScroll() && setInputTokenId(token.id))}
        onSwitchToWithdraw={onSwitchToWithdraw}
        disabled={operation.isLoading}
      />
      <Divider className="my-6" />
      {sendTxButton}
    </>
  );
};
