import { useEffect } from 'react';
import { useRecoilState, atomFamily } from 'recoil';

export const refresherStateFamily = atomFamily({
  key: 'refresherToggle',
  default: { toggle: false, delay: 30000 },
});
// Repris de la v1 et adapté
export function useRefresh(delay: number, id: string): boolean {
  const [refresher, setRefresher] = useRecoilState(refresherStateFamily(id));

  useEffect(() => {
    const handler = setTimeout(() => {
      setRefresher({ toggle: !refresher.toggle, delay });
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [delay, refresher, setRefresher]);

  return refresher.toggle;
}
