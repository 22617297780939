import { formatUnits } from 'viem';

import { Chain } from '@gql';
import { parseNum, chainOf, unique, withoutChain, notNil } from '@utils';
import { networksByChain } from '@utils/networks';

import { AggregatorQuoteResponse, AggregatorRequest, DexAggregator } from './dex-aggregator-types';
import { KyberSwapResponse } from './kyberswap-types';

const KYBERSWAP_CHAIN_MAPPING: Partial<Record<Chain, string>> = Object.freeze({
  [Chain.eth]: 'ethereum',
  [Chain.bsc]: 'bsc',
  [Chain.poly]: 'polygon',
  [Chain.avax]: 'avalanche',
  [Chain.arbi]: 'arbitrum',
  [Chain.opti]: 'optimism',
});

const KYBERSWAP_BASE_URI = 'https://aggregator-api.kyberswap.com/';
const KYBERSWAP_CLIENT_ID = 'mass.money';

export async function defaultKyberSwapFetcher(config: AggregatorRequest): Promise<KyberSwapResponse | null> {
  const chains = unique(notNil([config.buyToken.id, config.spendToken.id, config.vaultAddress].map(chainOf)));

  if (chains.length !== 1) {
    throw new Error('Incoherent chains');
  }

  const chain = KYBERSWAP_CHAIN_MAPPING[chains[0]];

  if (!chain) {
    throw new Error('Unsupported chain for KyberSwap');
  }

  const amount = 'spendQty' in config ? parseNum(config.spendQty) : parseNum(config.boughtQty);

  const kyberswapUrl = `${KYBERSWAP_BASE_URI}${chain}/api/v1/routes?tokenIn=${withoutChain(
    config.spendToken.id,
  )}&tokenOut=${withoutChain(config.buyToken.id)}&amountIn=${amount}&gasInclude=true`;

  const routeResponse = await fetch(kyberswapUrl, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-client-id': KYBERSWAP_CLIENT_ID,
    },
  });

  type KyberswapRoute = { data: { routeSummary: any } };

  const routeData: KyberswapRoute = await routeResponse.json();

  const body = {
    source: KYBERSWAP_CLIENT_ID,
    routeSummary: routeData.data.routeSummary,
    // Unit is bip. The value is in ranges [0, 2000], 10 means 0.1%
    slippageTolerance: Math.floor(config.slippage * 100.0 * 100.0),
    sender: withoutChain(config.vaultAddress),
    recipient: withoutChain(config.vaultAddress),
  };

  const response = await fetch(`${KYBERSWAP_BASE_URI}${chain}/api/v1/route/build`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
      'x-client-id': KYBERSWAP_CLIENT_ID,
    },
  });

  const data: KyberSwapResponse = (await response.json()).data;

  return data;
}

export async function fetchKyberSwap(toFetch: AggregatorRequest): Promise<AggregatorQuoteResponse | null> {
  const response = await defaultKyberSwapFetcher(toFetch);

  return response && KyberSwapRespToQuoteResp(response, toFetch);
}

// convert from the KyberSwap specific quote response to a more generic dex aggregator response type
function KyberSwapRespToQuoteResp(answer: KyberSwapResponse, config: AggregatorRequest): AggregatorQuoteResponse {
  const priceImpact = parseFloat(answer.amountInUsd) / parseFloat(answer.amountOutUsd) - 1;
  const price = (parseNum(answer.amountOut) * 10n ** parseNum(config.spendToken.decimals)) / parseNum(answer.amountIn);

  return {
    aggregator: DexAggregator.KyberSwap,
    chainId: networksByChain[chainOf(config.buyToken.id)].chainId,
    price: formatUnits(price, config.buyToken.decimals),
    to: answer.routerAddress as HexString,
    data: answer.data as HexString,
    value: '0',
    protocolFee: '0',
    buyTokenAddress: withoutChain(config.buyToken.id) as HexString,
    sellTokenAddress: withoutChain(config.spendToken.id) as HexString,
    buyAmount: answer.amountOut,
    sellAmount: answer.amountIn,
    allowanceTarget: answer.routerAddress as HexString,
    estimatedPriceImpact: priceImpact.toString(),
    guaranteedPrice: '0',
    gasPrice: answer.gasPrice,
    gas: answer.gas,
  };
}
