import cls from 'classnames';

import { Cls, Elt } from '@utils';

export type SkeletonProps = {
  label?: string;
  isRound?: boolean;
  noAnimation?: boolean;
  // Use this if you need a skeleton the same size as your content but without showing the content
  invisibleChildren?: boolean;
  children?: Elt;
  asOverlay?: boolean;
} & Cls;

export const Skeleton = ({
  label,
  isRound,
  noAnimation,
  className,
  invisibleChildren = false,
  children,
  asOverlay = false,
}: SkeletonProps) => {
  return (
    <div
      className={cls(
        className,
        'relative overflow-hidden',
        'flex flex-row items-center justify-center',
        'bg-surface-variant-disabled min-w-fit',
        isRound ? 'rounded-full' : 'rounded-xl',
      )}
    >
      <div
        className={cls(
          'absolute inset-0 w-full h-full',
          `${children && !invisibleChildren && !asOverlay && '-z-[1]'}`,
          { 'pointer-events-none': asOverlay },
          { 'animate-background-slide-x': !noAnimation },
          'bg-gradient-to-r bg-[length:200%_200%]',
          'from-surface-gradient-transparent from-25%',
          'via-surface-gradient-muted via-50%',
          'to-surface-gradient-transparent to-75%',
        )}
      />
      <span className="relative text-font-disabled select-none">{label}</span>
      {invisibleChildren ? <div className="invisible">{children}</div> : children}
    </div>
  );
};
