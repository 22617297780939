import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CancelLimitOrderButton } from '@components/CancelLimitOrderButton';
import { SideContentHeader } from '@components/layouts/side-content/SideContentHeader';
import { getAllLimitOrders_limitOrders_results as LimitOrder } from '@gql';
import { ArrowRightIcon, DoubleArrowHorizontalIcon, LimitBuyIcon } from '@ui-kit/Icons';
import { Pill } from '@ui-kit/atoms/Pill';
import { LabelItemList } from '@ui-kit/organisms/LabelItemList';
import { Float, toNumber, chainOf } from '@utils';
import { networksByChain } from '@utils/networks';
import { formatNumber } from '@utils/numbers/NumberFormat';

export type LimitOrderDetailsUIProps = {
  limitOrder: LimitOrder;
  onGoBack?: VoidFunction;
};

export function LimitOrderDetailsUI({ limitOrder, onGoBack }: LimitOrderDetailsUIProps) {
  const { t } = useTranslation();
  const [displayOutputPerInput, setDisplayOutputPerInput] = useState(true);

  const input = {
    token: limitOrder.make.token,
    qty: limitOrder.make.qty,
    quote: limitOrder.make.token.quote,
    usdValue: limitOrder.make.qtyNum * limitOrder.make.token.quote,
  };
  const output = {
    token: limitOrder.take.token,
    qty: limitOrder.take.qty,
    quote: limitOrder.take.token.quote,
    usdValue: limitOrder.take.qtyNum * limitOrder.take.token.quote,
  };
  const fees: any = null;
  const network = networksByChain[chainOf(limitOrder.vault)];

  const statusPill = (
    <Pill theme={{ type: 'default', variant: 'soft' }} label={t('Common.TransactionStatus.pending')} />
  );

  const type = {
    Icon: LimitBuyIcon,
    label: t('TransactionDetails.type.limitOrder'),
  };

  const inputPerOutput = toNumber(input.qty, input.token.decimals) / toNumber(output.qty, output.token.decimals);
  const outputPerInput = toNumber(output.qty, output.token.decimals) / toNumber(input.qty, input.token.decimals);

  const fields = [
    {
      label: t('TransactionDetails.fields.type'),
      item: (
        <div className="flex gap-1 items-center text-font font-medium">
          <type.Icon className="fill-current w-3.5 h-3.5" />
          <span>{type.label}</span>
        </div>
      ),
    },
    {
      label: t('TransactionDetails.fields.fromTo'),
      item: (
        <div className="flex gap-1 items-center">
          {input.token.symbol}
          <ArrowRightIcon className="w-3 h-3 text-font-variant" />
          {output.token.symbol}
        </div>
      ),
    },
    {
      label: t('TransactionDetails.fields.amountSent'),
      item: (
        <>
          <Float.Custom
            key={input.token.symbol}
            value={toNumber(input.qty, input.token.decimals)}
            type="qty"
            unit={input.token.symbol}
            takeAbsoluteValue
          />
          <br />
        </>
      ),
    },
    {
      label: t('TransactionDetails.fields.triggerPrice'),
      item: (
        <div className="flex gap-1 cursor-pointer" onClick={() => setDisplayOutputPerInput(prev => !prev)}>
          {displayOutputPerInput
            ? `${formatNumber(outputPerInput, 'amount')} ${output.token.symbol} per ${input.token.symbol}`
            : `${formatNumber(inputPerOutput, 'amount')} ${input.token.symbol} per ${output.token.symbol}`}
          <DoubleArrowHorizontalIcon className="h-3.5 w-3.5 my-auto text-accent" />
        </div>
      ),
    },
    {
      label: t('TransactionDetails.fields.amountReceived'),
      item: (
        <>
          <Float.Custom
            key={output.token.symbol}
            value={toNumber(output.qty, output.token.decimals)}
            type="qty"
            unit={output.token.symbol}
            takeAbsoluteValue
          />
          <br />
        </>
      ),
    },
    {
      label: t('TransactionDetails.fields.protocolFees'),
      itemTooltipContent: t('TransactionDetails.fields.protocolFees'),
      item: fees ? (
        <Float.Custom
          value={toNumber(fees.qty, fees.token.decimals)}
          type="qty"
          unit={fees.token.symbol}
          takeAbsoluteValue
        />
      ) : undefined,
    },
    {
      label: t('TransactionDetails.fields.massFees'),
      itemTooltipContent: t('TransactionDetails.fields.massFees'),
      item: <Pill label={t('TransactionDetails.free')} theme={{ type: 'accent', variant: 'heavy' }} />,
    },
    // uncomment when we have the expiry date from the API
    // {
    //   label: t('TransactionDetails.fields.expirationDate'),
    //   item: limitOrder.expiryDate
    //     ? formatDate(new Date(limitOrder.expiryDate), 'long-date-time')
    //     : t('TransactionDetails.noExpirationDate'),
    // },
    {
      label: t('TransactionDetails.fields.network'),
      item: <Pill LeadingIcon={network.MonochromeIcon} label={network.name} />,
    },
    { label: t('TransactionDetails.fields.transactionState'), item: statusPill },
  ].map(field => ({ ...field, className: 'py-3.5' }));

  return (
    <div className="h-full flex flex-col w-full gap-6 pb-8">
      <SideContentHeader title={t('TransactionDetails.title')} onGoBack={onGoBack} alignment="left" />
      <div className="overflow-y-auto hide-scrollbars flex flex-col gap-6 h-full">
        <LabelItemList
          contentClassname="!gap-0"
          labelItems={fields.map(field => ({ ...field, className: '!py-4 text-base' }))}
        />
        <CancelLimitOrderButton chain={network.chain} orderHash={limitOrder.id} />
      </div>
    </div>
  );
}
