import { t } from 'i18next';
import { useState } from 'react';

import { SideContentProps } from '@components/SideContent/SideContent.types';
import { IEntryProduct } from '@components/entries/EntryProduct';
import { Button } from '@ui-kit/atoms/Button';
import { toNumber, parseNum, ICoin } from '@utils';

import SelectCoinUI from './ui/SelectCoinUI';
import { useSearchCoinWithBalances } from '../hooks/useSearchCoinWithBalances';
import { useTransactionFlow } from '../hooks/useTransactionFlow';

const MAX_COINS = 5;

export type SearchCoinProps = SideContentProps<Map<ChainAddress, ICoin>> & {
  currentSelectedCoins?: Map<ChainAddress, ICoin>;
  disabledCoins?: { [key: ChainAddress]: string };
  sortBy?: 'balances' | 'holdings';
  onlyOwned?: boolean;
};

export default function SelectMultipleCoinsContainer({
  setResult,
  sortBy = 'holdings',
  currentSelectedCoins = new Map(),
  disabledCoins = {},
  onlyOwned = false,
}: SearchCoinProps) {
  const [searchValue, setSearchValue] = useState<string>('');
  const { currentChain } = useTransactionFlow();
  const [selectedCoins, setSelectCoins] = useState<Map<ChainAddress, ICoin>>(new Map(currentSelectedCoins));
  const coins$ = useSearchCoinWithBalances(searchValue, currentChain, sortBy, onlyOwned).map([selectedCoins], coins => {
    return coins.map<IEntryProduct>(coin => ({
      ...coin.token,
      quantity: toNumber(parseNum(coin.qty), coin.token.decimals) ?? 0,
      isChecked: selectedCoins.has(coin.token.id),
      disabled:
        (selectedCoins.size >= MAX_COINS && !selectedCoins.has(coin.token.id)) || coin.token.id in disabledCoins,
      disabledReason: disabledCoins?.[coin.token.id],
    }));
  });

  return (
    <section className="flex flex-col w-full h-full flex-grow justify-between">
      <SelectCoinUI
        searchValue={searchValue}
        onSearchValueChange={setSearchValue}
        coins={coins$}
        onSelect={coin => {
          if (selectedCoins.has(coin.id as ChainAddress)) {
            const set = new Map(selectedCoins);
            set.delete(coin.id as ChainAddress);
            setSelectCoins(set);
          } else if (selectedCoins.size < MAX_COINS) {
            setSelectCoins(s => new Map(s).set(coin.id as ChainAddress, coin as ICoin));
          }
        }}
        onGoBack={() => setResult(selectedCoins)}
        multiSelect
      />
      <Button
        onClick={() => setResult(selectedCoins)}
        label={t('Transactions.SearchCoin.content.confirmSelection', {
          count: selectedCoins.size,
          max: MAX_COINS,
          defaultValue: '',
        })}
        className="h-8 shrink-0 pt-6"
        fullWidth
        disabled={selectedCoins.size === 0}
      />
    </section>
  );
}
