export const convertToUSNumber = (input: string) => {
  if (!input) return '';

  const endsWithDot = input[input.length - 1] === '.';

  let formattedInput = input;

  if (endsWithDot) formattedInput = removeLastChar(formattedInput);

  const regex = /\B(?=(\d{3})+(?!\d))/g;

  const parts = formattedInput.toString().split('.');
  const integerPart = parts[0].replace(regex, ',');
  const decimalPart = parts[1] ? `.${parts[1]}` : '';

  const number = parseFloat(formattedInput);

  if (!Number.isNaN(number)) {
    return `${integerPart}${decimalPart}`;
  }
  return input;
};

export const keepNumericsAndDots = (input: string) => {
  return input.replace(/[^0-9.]/g, '');
};

export const formatInput = (input: string) => {
  if (!input || input === '.' || input === ',') return '';
  let formattedInput = input;

  const endsWithDot = formattedInput[input.length - 1] === '.';
  const inputHas2Dot = formattedInput.split('.').length === 3;

  if (endsWithDot && inputHas2Dot) return removeLastChar(formattedInput);

  const lastCharIsComa = input[input.length - 1] === ',';

  if (lastCharIsComa) {
    const lastIndex = input.length - 1;
    formattedInput = input.slice(0, lastIndex) + '.';
  }

  const lastCharIsDot = formattedInput[input.length - 1] === '.';
  const inputHas1Dot = formattedInput.split('.').length === 2;

  formattedInput = keepNumericsAndDots(input);
  formattedInput = convertToUSNumber(formattedInput);

  return lastCharIsDot && inputHas1Dot ? formattedInput + '.' : formattedInput;
};

export const removeLastChar = (input: string) => {
  if (!input) return '';

  const lastIndex = input.length - 1;
  return input.slice(0, lastIndex);
};
