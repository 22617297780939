import React, { Component, Suspense, ReactNode } from 'react';

import { Skeleton } from '@ui-kit/atoms/Skeleton';

import { NotFound } from '../categories/miscellaneous/NotFound';

// Do not export this component, it is only used by SuspenseWithErrorFallback
class ErrorBoundary extends Component<{ children: ReactNode }, { hasError: boolean }> {
  constructor(props: { children: ReactNode }) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error) {
    if (error.message.includes('dynamically imported module')) {
      this.setState({ hasError: true });
      return;
    }
    throw error;
  }

  render() {
    if (this.state.hasError) {
      return <NotFound />;
    }

    return this.props.children;
  }
}

export const SuspenseWithErrorFallback = ({ children }: { children: React.ReactNode }) => (
  <ErrorBoundary>
    <Suspense
      fallback={
        <div className="h-full flex flex-col gap-6 bg-background-variant rounded-2xl">
          <Skeleton className="rounded-2xl h-full w-full" />
        </div>
      }
    >
      {children}
    </Suspense>
  </ErrorBoundary>
);
