import { SendTransactionResult } from '@wagmi/core';
import { useEffect, useCallback, useState } from 'react';
import { atom, useRecoilState } from 'recoil';
import { match } from 'ts-pattern';
import { TransactionReceipt } from 'viem';

import { IAlert } from '@ui-kit/atoms/Alert';
import { TransactionState } from '@utils/uiKitUtils/statusTransactionUtils';

import { useAlerts } from './useAlerts';
import { useAlertsAnimation } from './useAlertsAnimation';
import { RequireAtLeastOne } from '../utils';

export type Transaction = RequireAtLeastOne<{ id: string; hash: SendTransactionResult['hash'] }> & {
  state: TransactionState;
  tx?: TransactionReceipt;
} & ToastDetails;

export type ToastDetails = Pick<IAlert, 'title' | 'content' | 'link'>;

export const transactionHistoryAtom = atom<Transaction[]>({
  key: 'transactionHistory',
  default: [],
});

export const topTransactionAtom = atom<Transaction | null>({
  key: 'topTransaction',
  default: null,
});

export const useTxManagerAlerts = () => {
  const [transactionList, setTransactionList] = useRecoilState(transactionHistoryAtom);
  // the top transaction is displayed in the transaction manager
  const [topTransaction, setTopTransaction] = useRecoilState(topTransactionAtom);

  const alert = useAlerts();
  const alertAnimation = useAlertsAnimation();

  useEffect(() => {
    // count 3 seconds before removing the top transaction if it's not pending
    if (topTransaction?.state === TransactionState.Pending) return;
    const timeout = setTimeout(() => {
      setTopTransaction(null);
    }, 10000);
    return () => clearTimeout(timeout);
  }, [setTopTransaction, topTransaction]);

  const createOrUpdateTransaction = useCallback(
    (transaction: Transaction) => {
      const { id, hash } = transaction;

      setTransactionList(prev => {
        // find the latest transaction with the same id or hash
        // (there might be duplicate ids when their hash is not yet known)

        const hashIdx = hash ? prev.findIndex(({ hash: txHash }) => txHash === hash) : -1;
        const idIdx = prev.findIndex(({ id: txId, hash: txHash }) => txId === id && !txHash);

        if (hashIdx === -1 && idIdx === -1) {
          return [...prev, { ...transaction }];
        }

        return prev.map((t, i) => {
          if (idIdx === i) {
            return t.hash ? { ...t, ...transaction } : { ...t, hash, ...transaction };
          }
          if (hashIdx === i) {
            return t.id ? { ...t, ...transaction } : { ...t, id, ...transaction };
          }
          return t;
        });
      });
      if (topTransaction?.state !== TransactionState.Failed) {
        setTopTransaction({ ...transaction });
      }
    },
    [setTransactionList, topTransaction, setTopTransaction],
  );

  const postAlert = (transaction: Transaction) => {
    const { state, title, content, link } = transaction;
    if (!state) return;

    createOrUpdateTransaction(transaction);

    match(state)
      // TODO Sylvain COMPLETLY REMOVE TOAST WHEN ERROR ALERT ARE HANDLE IN THE FLOWS LIKE SUCCESS ARE
      .with(TransactionState.Failed, () => alert.error({ title, content, link }))
      .with(TransactionState.Success, () => alertAnimation.success())
      .with(TransactionState.Completed, () => {})
      .with(TransactionState.Pending, () => {})
      .with(TransactionState.Processing, () => {})
      .exhaustive();
  };

  // TODO(Hadrien) : This is hacky, improve backend synchonicity or use Websocket
  const lastTxState = useDelay(transactionList.slice(-1)?.[0]?.state);

  return {
    transactionList,
    pendingTransactionCount: transactionList.filter(({ state }) => state === TransactionState.Pending).length,
    topTransactionState: topTransaction?.state,
    lastTxState,
    postAlert,
    createOrUpdateTransaction,
  };
};

function useDelay<T>(value: T): T {
  const [currentValue, setCurrentValue] = useState<T>(value);
  useEffect(() => {
    const to = setTimeout(() => setCurrentValue(value), 3000);
    return () => clearTimeout(to);
  }, [value]);
  return currentValue;
}
