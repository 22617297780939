import cls from 'classnames';
import { RefObject, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { match } from 'ts-pattern';

import { EntryList } from '@components/entries/EntryList';
import { useElementPosition } from '@hooks/useElementPosition';
import { useEventListener } from '@hooks/useEventListener';
import { useOnClickOutside } from '@hooks/useOnClickOutside';
import { useWindowSize } from '@hooks/useWindowSize';
import { ChevronDownIcon, ChevronUpIcon } from '@ui-kit/Icons';
import { Checkbox } from '@ui-kit/atoms/Checkbox';
import { Chip, IChipProps } from '@ui-kit/molecules/Chip';
// eslint-disable-next-line import/no-cycle
import { TextInput, TextInputSize } from '@ui-kit/organisms/TextInput';
import { Cls, SVGIcon } from '@utils';

import { Entry, EntryType, IEntry } from '../ui-kit/organisms/Entry';

export type DropdownEntryType = EntryType & { leadingItemSelected?: EntryType['leadingItem'] };

export type IDropdown = {
  id?: string;
  entries: DropdownEntryType[];
  alignment?: 'bottomRight' | 'bottomLeft' | 'topRight' | 'topLeft';
  LabelIcon?: SVGIcon;
  label?: string;
  onSelectEntries: (selectedEntries: EntryType[]) => void;
  canSelectMultiple?: boolean;
  inputType?: 'chip' | 'textInput' | 'entry';
  entryProps?: IEntry;
  placeholder?: string;
  defaultSelectedEntriesIds?: string[];
  filterOnSelect?: boolean;
  onFilter?: (searchValue: string) => EntryType[];
  // Maximum height in px of the list, if given the list will be scrollable when the height is reached
  maxHeightScroll?: string;
  isDisabled?: boolean;
  clearSelectionLabel?: string;
  allowClearSelection?: boolean;
  isLoading?: boolean;
  freezeLoading?: boolean;
  indication?: IChipProps['indication'];
  labelClassName?: string;
  isPortal?: boolean;
  parentScrollableRef?: RefObject<HTMLElement>;
  containerClassName?: string;
  chipItemsClassName?: string;
} & Cls;

const CLEAR_ALL_ID = 'clear-all';
const ARBITRARY_MARGIN = 120;
// The button can be a pills or an input, no need to have an exact heigt
const AVERAGE_HEIGHT_BUTTON = 20;

export function Dropdown({
  id: dropdownId = 'dropdown',
  canSelectMultiple = false,
  entries = [],
  alignment = 'bottomLeft',
  LabelIcon,
  label = '',
  onSelectEntries = () => {},
  inputType = 'chip',
  entryProps,
  placeholder,
  defaultSelectedEntriesIds = [],
  filterOnSelect = true,
  onFilter,
  className,
  maxHeightScroll = '',
  isDisabled,
  clearSelectionLabel,
  allowClearSelection = false,
  isLoading,
  freezeLoading,
  indication,
  labelClassName,
  isPortal,
  parentScrollableRef,
  containerClassName,
  chipItemsClassName,
}: IDropdown) {
  const [isOpen, setIsOpen] = useState(false);
  const [maxHeight, setMaxHeight] = useState<string>(maxHeightScroll);

  const inpuRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const portalListRef = useRef<HTMLDivElement>(null);
  const [mainDivRef, elementPosition] = useElementPosition({ deps: [isOpen, maxHeight] });

  const { windowHeight } = useWindowSize();

  useEventListener('scroll', () => setIsOpen(false), parentScrollableRef);

  useEffect(() => {
    if (maxHeightScroll) return;

    const computedMaxHeight = match(alignment)
      .with('bottomLeft', () => windowHeight - elementPosition.y - ARBITRARY_MARGIN - AVERAGE_HEIGHT_BUTTON)
      .with('bottomRight', () => windowHeight - elementPosition.y - ARBITRARY_MARGIN - AVERAGE_HEIGHT_BUTTON)
      .with('topLeft', () => elementPosition.y - AVERAGE_HEIGHT_BUTTON)
      .with('topRight', () => elementPosition.y - AVERAGE_HEIGHT_BUTTON)
      .exhaustive();

    setMaxHeight(computedMaxHeight.toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const { t } = useTranslation();

  const [selectedEntriesIds, setSelectedEntriesIds] = useState<string[]>(defaultSelectedEntriesIds);
  const [inputSearchValue, setInputSearchValue] = useState<string>('');
  const [filteredEntries, setFilteredEntries] = useState<DropdownEntryType[]>(entries);

  const onSetFilteredEntries = () => {
    setFilteredEntries(filterOnSelect ? entries.filter(entry => entry.id !== selectedEntry?.id) : entries);
  };

  const selectedEntry =
    !canSelectMultiple && defaultSelectedEntriesIds.length
      ? entries.find(entry => entry.id === defaultSelectedEntriesIds[0])
      : null;

  useEffect(() => {
    if (defaultSelectedEntriesIds.length) {
      setSelectedEntriesIds(defaultSelectedEntriesIds);
      onSetFilteredEntries();
    }
  }, [defaultSelectedEntriesIds]);

  useEffect(() => {
    onSetFilteredEntries();
  }, [entries]);

  const selectEntry = (entry: DropdownEntryType) => {
    if (entry.id === CLEAR_ALL_ID) {
      setSelectedEntriesIds([]);
      onSetFilteredEntries();
      onSelectEntries([]);
      setInputSearchValue('');
      setIsOpen(false);
      return;
    }
    if (entry.disabled) return;
    if (!canSelectMultiple) selectOneEntry(entry);
    else selectMultipleEntries(entry);
  };

  const selectOneEntry = (entry: DropdownEntryType) => {
    setSelectedEntriesIds([entry.id]);
    setFilteredEntries(filterOnSelect ? entries.filter(entr => entr.id !== entry.id) : entries);
    onSelectEntries([entry]);
    setIsOpen(false);
    if (inputType === 'textInput') setInputSearchValue(entry.selectLabel || '');
  };

  useEffect(() => {
    if (inputType === 'textInput') {
      const filteredEntryList = onFilter ? onFilter(inputSearchValue) : entries;
      setFilteredEntries(filteredEntryList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputSearchValue]);

  useOnClickOutside([containerRef], () => !isPortal && setIsOpen(false));
  useOnClickOutside([containerRef, portalListRef], () => isPortal && setIsOpen(false));

  const onKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setIsOpen(false);
      if (inpuRef.current) inpuRef.current.blur();
    }
  };

  useEventListener('keydown', onKeyDown);

  const selectMultipleEntries = (entry: DropdownEntryType) => {
    const entryIndex = selectedEntriesIds.indexOf(entry.id);
    let updatedEntryIds = [...selectedEntriesIds];

    if (entryIndex === -1) {
      updatedEntryIds = [...selectedEntriesIds, entry.id];
    } else {
      updatedEntryIds = selectedEntriesIds.filter(id => id !== entry.id);
    }
    setSelectedEntriesIds(updatedEntryIds);
    onSelectEntries(entries.filter(entr => updatedEntryIds.includes(entr.id)));
  };

  const entriesWithCheckboxes = useMemo<DropdownEntryType[]>(
    () =>
      entries.map(entry => ({
        ...entry,
        trailingItem: <Checkbox checked={selectedEntriesIds.includes(entry.id)} className="rounded-[4px]" />,
        onClick: () => selectEntry(entry),
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [entries, selectedEntriesIds],
  );

  const clearSelectionEntry = {
    id: CLEAR_ALL_ID,
    content: {
      top: (
        <span className="text-left text-lg text-font-variant">
          {clearSelectionLabel || t('UIKit.Dropdown.clearSelection')}
        </span>
      ),
    },
    onClick: () => setSelectedEntriesIds([]),
  };

  const selectedLeadingItem = selectedEntry?.leadingItemSelected || selectedEntry?.leadingItem;
  const selectedIcon = selectedLeadingItem ? { Icon: selectedLeadingItem } : undefined;
  const labelIcon =
    LabelIcon && !selectedEntry ? { Icon: <LabelIcon className="w-3.5 h-3.5 text-font fill-current" /> } : selectedIcon;
  const leadingItem =
    canSelectMultiple && selectedEntriesIds.length > 0 ? { badgeLabel: `${selectedEntriesIds.length}` } : labelIcon;

  const focusInput = () => {
    if (inpuRef.current) inpuRef.current.focus();
  };

  const closeDropdown = () => {
    setIsOpen(false);
    if (inpuRef.current) inpuRef.current.blur();
  };

  const refRect = containerRef.current?.getBoundingClientRect();

  const List = (
    <div
      ref={portalListRef}
      className={cls(
        'absolute z-50 min-w-full select-none',
        match(alignment)
          .with('bottomLeft', () => cls('mt-3', { 'left-0': !isPortal }))
          .with('bottomRight', () =>
            cls('mt-3', {
              '-translate-x-full': isPortal,
              'right-0 left-auto': !isPortal,
            }),
          )
          .with('topLeft', () => cls('-translate-y-full', { '-mt-3': isPortal, '-top-3': !isPortal }))
          .with('topRight', () =>
            cls('-translate-y-full', {
              '-mt-3 -translate-x-full': isPortal,
              '-top-3 right-0 left-auto': !isPortal,
            }),
          )
          .exhaustive(),
        inputType === 'textInput' && 'w-[100%]',
      )}
      style={
        isPortal && refRect
          ? {
            top: refRect.top + (alignment.includes('bottom') ? refRect.height : 0),
            left: refRect.left + (alignment.includes('Right') ? refRect.width : 0),
            minWidth: refRect.width,
          }
          : undefined
      }
    >
      <EntryList
        variant="menu"
        entries={canSelectMultiple ? entriesWithCheckboxes : filteredEntries}
        onClickEntry={selectEntry}
        onClickAdditionalEntry={selectEntry}
        additionalEntry={selectedEntriesIds.length > 0 && allowClearSelection ? clearSelectionEntry : undefined}
        noMaxWidth={inputType === 'textInput'}
        maxHeightScroll={maxHeight}
      />
    </div>
  );

  return (
    <div
      ref={containerRef}
      data-cy={dropdownId}
      className={cls(containerClassName, 'relative', { 'w-fit': inputType !== 'entry' })}
    >
      <div ref={mainDivRef}>
        {inputType === 'chip' && (
          <Chip
            label={!canSelectMultiple ? selectedEntry?.selectLabel || label : label}
            trailingItem={{
              Icon: isOpen ? <ChevronUpIcon className="fill-white" /> : <ChevronDownIcon className="fill-white" />,
            }}
            state={isDisabled ? 'disabled' : undefined}
            onClick={() => !isDisabled && setIsOpen(prevIsOpen => !prevIsOpen)}
            leadingItem={leadingItem}
            className={cls(isOpen ? 'bg-surface-muted-hover-font' : 'bg-surface-muted', className)}
            isLoading={isLoading}
            noAnimation={freezeLoading}
            indication={indication}
            labelClassName={labelClassName}
            itemsClassName={chipItemsClassName}
          />
        )}

        {inputType === 'entry' && entryProps && (
          <Entry
            onClick={() => !isDisabled && setIsOpen(prevIsOpen => !prevIsOpen)}
            disabled={isDisabled}
            trailingItem={
              isOpen ? (
                <ChevronUpIcon className="fill-white w-4 h-4" />
              ) : (
                <ChevronDownIcon className="fill-white w-4 h-4" />
              )
            }
            {...entryProps}
          />
        )}

        {inputType === 'textInput' && (
          <TextInput
            ref={inpuRef}
            placeholder={placeholder}
            onFocus={() => setIsOpen(true)}
            size={TextInputSize.L}
            onChange={e => setInputSearchValue(e.target.value)}
            value={inputSearchValue}
            className={className}
            TrailingVisual={{ Icon: isOpen ? ChevronUpIcon : ChevronDownIcon }}
            onTrailingVisualClick={isOpen ? closeDropdown : focusInput}
            trailingVisualClassName="fill-white"
            isLoading={isLoading}
            freezeLoading={freezeLoading}
          />
        )}

        {isOpen && (isPortal ? createPortal(List, document.body) : List)}
      </div>
    </div>
  );
}
