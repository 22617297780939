import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import { match, P } from 'ts-pattern';
import { polygon } from 'viem/chains';
import { useAccount, useDisconnect, useNetwork, useSignMessage, useSwitchNetwork } from 'wagmi';

import { ModalContentType } from '@components/modal/Modal';
import { DEFAULT_CHAIN } from '@constants';
import { getNonce, getNonceVariables } from '@gql';
import { getAuthenticationToken, getMyInfo } from '@login/login.helpers';
import { GET_NONCE_QUERY } from '@login/login.queries';
import { Loader } from '@utils';
import { getNetworkFromChainId, networksByChain } from '@utils/networks';
import { alphaEligibleAtom } from '@wallet/alphaEligible';
import { targetNetworkAtomDoNotUseUnlessSelectionScreen } from '@wallet/hooks-hidden-state';

import { SignMessageModalScreen, SignState } from '../../screens/SignMessageModalScreen';
import { magicConnector } from '../magic/MagicConnector';

type SignMessageProps = { address?: HexString } & ModalContentType;
export const SignMessageContainer = ({ modalProps, address: _ }: SignMessageProps) => {
  const { closeModal } = modalProps || {};

  const { signMessageAsync, isLoading, isError, isSuccess } = useSignMessage();
  const { connector, address: walletAddress } = useAccount();
  const { chain } = useNetwork();
  const { switchNetworkAsync } = useSwitchNetwork();
  const { t } = useTranslation();
  const { disconnect } = useDisconnect();
  const [authError, setAuthError] = useState(false);

  const setNetwork = useSetRecoilState(targetNetworkAtomDoNotUseUnlessSelectionScreen);
  const setAlphaEligible = useSetRecoilState(alphaEligibleAtom);
  const address$ = Loader.useWrap(walletAddress).map(x => (x ? x.toLowerCase() : Loader.loading));

  const nonce$ = address$.query<getNonce, getNonceVariables>(GET_NONCE_QUERY, x => ({ address: x?.toLowerCase() }));
  const messageToSign$ = Loader.array([nonce$, address$] as const).map(([x, address]) => ({
    address,
    message: t('Login.SignMessage.messageToSign', {
      url: 'https://alpha.mass.money',
      address,
      nonce: x.auth.nonce,
      interpolation: { escapeValue: false },
      defaultValue: 'To sign in, please sign the following message with your wallet: \n\n',
    }),
  }));

  const signMessageHandler = messageToSign$.makeCallback(async ({ message, address }) => {
    const signature = await signMessageAsync({ message });
    if (!signature) {
      setAuthError(true);
      return;
    }
    if (!(await getAuthenticationToken({ address, signature, message }))) {
      setAuthError(true);
      // Remove the following lines after the alpha filter is removed
      setAlphaEligible(false);
      // closeModal?.(); // TODO(Hadrien) : Readd after alpha filter is removed ?
      return;
    }
    setAlphaEligible(true);
    const myInfo = await getMyInfo();
    if (myInfo) {
      const isFirstTime = !myInfo.onboarding;
      if (isFirstTime && connector?.name === magicConnector.name) {
        if (chain !== polygon && switchNetworkAsync) {
          await switchNetworkAsync(polygon.id);
        }
        // TODO(Hadrien) : MagicEducationContainer implem ? Magic login is deprecated for now
      }
      // const isAnonymous = myInfo.name.startsWith('Anonymous');
      // if (isAnonymous) {
      //   // TODO(Hadrien) : UsernameScreenContainer implem ?
      // }
    }

    const defaultNetwork = networksByChain[DEFAULT_CHAIN];
    const network = getNetworkFromChainId(chain?.id)?.chain;

    setNetwork(network || defaultNetwork.chain);

    closeModal?.();
  });

  const cancelHandler = useCallback(async () => {
    setAlphaEligible(null);
    disconnect();
    // Delay necessary to allow fully disconnecting before closing the modal
    setTimeout(() => closeModal?.(), 10);
  }, [closeModal, disconnect, setAlphaEligible]);

  const signState = match<[boolean, boolean, boolean, boolean], SignState>([isLoading, isError, authError, isSuccess])
    .with([true, true, P.boolean, P.boolean], [true, false, P.boolean, P.boolean], () => 'loading')
    .with([false, true, P.boolean, P.boolean], () => 'warning')
    .with([false, false, false, false], () => 'pending')
    .with([false, false, false, true], () => 'success')
    .with([false, false, true, P.boolean], () => 'error')
    .exhaustive();

  return (
    <SignMessageModalScreen
      onSignMessage={signMessageHandler}
      signState={signState}
      message={messageToSign$.map(({ message }) => message)}
      onCancel={cancelHandler}
      modalProps={modalProps}
    />
  );
};
