import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { EntryList } from '@components/entries/EntryList';
import { EntryOption, IEntryOption } from '@components/entries/EntryOption';
import { SocketQuote } from '@tetris/socket-bridge';
import { Tooltip } from '@ui-kit/molecules/Tooltip';
import { Loadable, Loader } from '@utils';
import { secondsToMinutes } from '@utils/dateUtils';

import TransactionRecapUI from './TransactionRecapUI';

export type BridgeRoutesSelectionProps = {
  routes: Loadable<readonly SocketQuote[]>;
  onSelectRoute: (route: SocketQuote) => void;
  onCountdownComplete: VoidFunction;
  countdownDurationInMs?: number;
};

export const BridgeRoutesSelection: React.FC<BridgeRoutesSelectionProps> = ({
  routes,
  onSelectRoute,
  onCountdownComplete,
  countdownDurationInMs = 10000,
}) => {
  const { t } = useTranslation();
  const [holdCountdown, setHoldCountdown] = useState(true);
  const routes$ = Loader.useWrap(routes);

  routes$.onOk(() => {
    setHoldCountdown(false);
    const refresh = setInterval(() => {
      onCountdownComplete();
    }, countdownDurationInMs);
    return () => clearInterval(refresh);
  });

  routes$.onLoading(() => {
    setHoldCountdown(true);
  });

  return (
    <TransactionRecapUI
      countdownDurationInMs={10000}
      holdCountdown={holdCountdown}
      unfairLevel={0}
      content={
        <EntryList<IEntryOption>
          className="text-font-variant"
          variant="small-spaced"
          render={EntryOption}
          entries={routes$.match
            .loadingOrSkipped(() => Array.from({ length: 3 }, () => ({ variant: 'route-loading' } as IEntryOption)))
            .error(() => [])
            .ok(_routes =>
              _routes.map(route => ({
                variant: 'route',
                gasfee: route.totalGasFeesInUsd,
                id: route.routeId,
                logo:
                  route.userTxs[0].protocol?.icon ??
                  route.userTxs[0].steps!.find(s => s.type === 'bridge')!.protocol.icon,
                name:
                  route.userTxs[0].protocol?.displayName ??
                  route.userTxs[0].steps!.find(s => s.type === 'bridge')!.protocol.displayName,
                selected: false,
                onSelect: () => onSelectRoute(route),
                outputAmount: route.outputValueInUsd,
                processDuration: secondsToMinutes(route.serviceTime),
              })),
            )}
          header={routes$.match
            .notOk(() => (
              <span className="flex gap-1">
                {t('Transactions.Bridge.route')}
                <Tooltip tooltipContent={t('Transactions.SelectRoute.tooltip')} className="my-auto" />
              </span>
            ))
            .ok(_routes => {
              if (_routes.length === 0) {
                return (
                  <div className="text-center text-white text-body-medium py-5">{t('Transactions.Bridge.noRoute')}</div>
                );
              }

              return (
                <span className="flex gap-1">
                  {t('Transactions.Bridge.route')}
                  <Tooltip tooltipContent={t('Transactions.SelectRoute.tooltip')} className="my-auto" />
                </span>
              );
            })}
        />
      }
    />
  );
};
