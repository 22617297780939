import { authenticate } from '@gql';

import { AUTHENTICATE_QUERY, GET_MY_INFO } from './login.queries';
import { apolloClient, setAuthToken } from '../../apollo';

export const getAuthenticationToken = async ({
  address,
  signature,
  message,
}: {
  address: string;
  signature: HexString;
  message: string;
}) => {
  const {
    data: {
      auth: { authenticate: authenticateData },
    },
  } = await apolloClient.query<authenticate>({
    query: AUTHENTICATE_QUERY,
    variables: {
      address: address.toLowerCase(),
      signature,
      message,
      // TODO magic auth?
    },
  });

  if (authenticateData) {
    setAuthToken(authenticateData.token);
    return true;
  }
  // TODO
  return false;
};

export interface MyInfo {
  readonly id: string;
  readonly name: string;
  readonly onboarding: {
    readonly welcome: DateTime | null;
  };
}

export const getMyInfo: () => Promise<MyInfo | undefined> = async () => {
  const { data } = await apolloClient.query({
    query: GET_MY_INFO,
  });

  return (data?.myUser satisfies MyInfo) ?? undefined;
};
