import cls from 'classnames';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { match } from 'ts-pattern';

import { DcaTimeframes } from '@tetris/useDca';
import { Skeleton } from '@ui-kit/atoms/Skeleton';
import { Entry } from '@ui-kit/organisms/Entry';

export interface TimeframeInputProps {
  count: number;
  timeframe: DcaTimeframes;
  setCount: Dispatch<SetStateAction<number>>;
  defaultCount: number;
  isLoading?: boolean;
  indication: string;
}

export function TimeframeInput({
  count: timeframeCount,
  timeframe,
  setCount,
  defaultCount,
  isLoading,
  indication,
}: TimeframeInputProps) {
  const { t } = useTranslation();

  const timeframeLabel = (count: number, tf: DcaTimeframes) =>
    match(tf)
      .with(DcaTimeframes.Hours, () => t('Screens.Dca.Timeframes.hour', { count }))
      .with(DcaTimeframes.Days, () => t('Screens.Dca.Timeframes.day', { count }))
      .with(DcaTimeframes.Weeks, () => t('Screens.Dca.Timeframes.week', { count }))
      .with(DcaTimeframes.Months, () => t('Screens.Dca.Timeframes.month', { count }))
      .run();

  const TimeframeInputEntry = (
    <Entry
      isCard
      isActive={false}
      disabled={isLoading}
      className=""
      content={{
        className: 'gap-2',
        top: <div className="flex justify-between text-sm text-font-variant font-medium select-none">{indication}</div>,
        bottom: (
          <div className="relative w-full font-youth-medium flex" data-cy="TimeframeInput">
            <input
              value={timeframeCount}
              disabled={isLoading}
              placeholder="0"
              className={cls(
                'cursor-caret caret-accent',
                'text-font placeholder:text-font-variant',
                'outline-none focus:outline-none bg-transparent',
                'w-full my-auto',
              )}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const c = parseInt(e.target.value, 10);
                if (!c) {
                  setCount(defaultCount);
                } else {
                  setCount(c);
                }
              }}
            />
            <div className="flex gap-1 my-auto ml-1 select-none">
              <div className="flex justify-between text-sm font-medium select-none lowercase">
                {timeframeLabel(timeframeCount, timeframe)}
              </div>
            </div>
          </div>
        ),
      }}
    />
  );

  return isLoading ? (
    <Skeleton className="z-0 !min-w-0 w-full !block">{TimeframeInputEntry}</Skeleton>
  ) : (
    TimeframeInputEntry
  );
}
