import { gql } from '@apollo/client';

import { ChainDataPortfolio, GraphHistoryFrame, TokenSummaryWithQuote, TokenWithHistory } from '@gql';

export const GET_SPOT_ACCOUNT = gql`
  query GetSpotAccount($frame: Timeframe!) {
    mainVault {
      ...IChainDataPortfolio
    }
  }
  ${ChainDataPortfolio}
`;

export const GET_TRADING_DATA = gql`
  query GetTradingData($id: ERC20!) {
    mainVault {
      tokenStats(token: $id) {
        realizedPnl {
          usd
          ratio
        }
        unrealizedPnl {
          usd
          ratio
        }
        averageBuyPrice
        averageSellPrice
      }
    }
  }
`;

export const GET_CURRENT_ACCOUNT_HOLDINGS = gql`
  query GetSpotAccountHoldings($frame: Timeframe!) {
    mainVault {
      spot {
        token {
          id
          category
          chain
          flags
          ...ITokenSummaryWithQuote
          ...ITokenWithHistory
        }
        qty
      }
    }
  }
  ${TokenSummaryWithQuote}
  ${TokenWithHistory}
`;

export const GET_MY_ASSETS = gql`
  query GetMyAssets($frame: Timeframe!, $chain: Chain) {
    mainVault(chain: $chain) {
      curves(timeframe: $frame) {
        ...IGraphHistoryFrame
      }
    }
  }
  ${GraphHistoryFrame}
`;

export const GET_VOTES_FOR_FEATURE = gql`
  query GetVotesForFeature($tag: String!) {
    getVoteFeature(tag: $tag) {
      id
      tag
      maxResponses
      possibleResponses
      responsesCount {
        response
        count
      }
      userResponsesCount
      userResponses {
        response
      }
    }
  }
`;

export const SUBMIT_VOTE_FOR_FEATURE = gql`
  mutation SubmitFeatureVote($tag: String!, $response: String!) {
    submitFeatureVote(tag: $tag, response: $response)
  }
`;
