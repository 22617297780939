import { useCallback } from 'react';

import { makeCenterSideContent } from '@components/SideContent';
import { Chain } from '@gql';
import { ICoin } from '@utils';

import SearchCoin from '../components/SearchCoinContainer';
import SelectMultipleCoins from '../components/SelectMultipleCoinsContainer';

type changeCoinOptions<T extends boolean> = T extends false
  ? {
    disabledCoins?: { [key: ChainAddress]: string };
    onlyCoins?: ChainAddress[];
    defaultChain?: Chain | nil;
    sortBy?: 'balances' | 'holdings';
    onlyOwned?: boolean;
  }
  : {
    disabledCoins?: { [key: ChainAddress]: string };
    currentSelectedCoins?: Map<ChainAddress, ICoin>;
    defaultChain?: Chain | nil;
    sortBy?: 'balances' | 'holdings';
    onlyOwned?: boolean;
  };

export function useChangeCoin(
  mutliSelect: true,
  options?: changeCoinOptions<true>,
): (cb: (coins: null | Map<ChainAddress, ICoin>) => void) => Promise<void>;
export function useChangeCoin(
  mutliSelect: false,
  options?: changeCoinOptions<false>,
): (cb: (coin: null | ICoin) => void) => Promise<void>;
export function useChangeCoin(
  mutliSelect: boolean = false,
  options: changeCoinOptions<true> | changeCoinOptions<false> = {},
) {
  return useCallback(
    async (cb: (coinId: nil | ICoin | Map<ChainAddress, ICoin>) => void) => {
      if (mutliSelect) {
        cb(
          await makeCenterSideContent(SelectMultipleCoins)
            .withProps({ ...options } as changeCoinOptions<true>)
            .withConfig({ fullSize: true })
            .openWithResult(),
        );
      } else {
        cb(
          await makeCenterSideContent(SearchCoin)
            .withProps({ ...options } as changeCoinOptions<false>)
            .withConfig({ fullSize: true })
            .openWithResult(),
        );
      }
    },
    [mutliSelect, options],
  );
}
