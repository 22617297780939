/* eslint-disable import/no-restricted-paths */
/* eslint-disable import/extensions */
import cls from 'classnames';
import { Fragment, ReactElement } from 'react';
import { match } from 'ts-pattern';

// eslint-disable-next-line import/no-cycle
import { AssetSelectorProps } from '@components/AssetSelector';
import { Dropdown, IDropdown } from '@components/Dropdown';
import { IChipProps, Chip } from '@ui-kit/molecules/Chip';
import { Cls } from '@utils';

import { NetworkSelectorProps } from '../../components/NetworkSelector';
import { ProtocolSelectorProps } from '../../components/ProtocolSelector';

type PresetDropdown = NetworkSelectorProps | ProtocolSelectorProps | AssetSelectorProps;

export type ItemChipGroupType = {
  id: string;
};

export type ItemChipType = {
  type: 'chip';
  itemProps: IChipProps;
} & ItemChipGroupType;

export type ItemDropdownType = {
  type: 'dropdown';
  itemProps: IDropdown;
} & ItemChipGroupType;

export type IChipGroup = {
  id?: string;
  items: (ItemChipType | ItemDropdownType | ReactElement<PresetDropdown>)[];
} & Cls;

export function ChipGroup({ id, items = [], className }: IChipGroup) {
  return (
    <div className={cls('flex gap-2 flex-wrap', className)}>
      {items.map(item =>
        'id' in item ? (
          <Fragment key={item.id}>
            {match(item.type)
              .with('chip', () => (
                <Chip id={item.id} {...(item.itemProps as IChipProps)} dataCy={`${id || ''}_${item.id}`} />
              ))
              .with('dropdown', () => <Dropdown {...(item.itemProps as IDropdown)} />)
              .exhaustive()}
          </Fragment>
        ) : (
          item
        ),
      )}
    </div>
  );
}
