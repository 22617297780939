import { TFunction } from 'i18next';
import { match } from 'ts-pattern';

import { Periods } from '@constants';

export function getTimeAgoTranslation(t: TFunction, eventDate: number | Date | string) {
  if (!eventDate && eventDate !== 0) return '';

  const date = new Date(eventDate).getTime();
  const durationFromEvent = new Date().getTime() - date;

  return match(durationFromEvent)
    .when(
      d => d < Periods.ONE_MINUTE,
      () => t('UIKit.EventTiming.justNow'),
    )
    .when(
      d => Periods.ONE_MINUTE <= d && d < Periods.ONE_HOUR,
      v => t('UIKit.EventTiming.timeAgo', { time: `${(v / Periods.ONE_MINUTE).toFixed(0)} min` }),
    )
    .when(
      d => Periods.ONE_HOUR <= d && d < Periods.ONE_DAY,
      v => t('UIKit.EventTiming.timeAgo', { time: `${(v / Periods.ONE_HOUR).toFixed(0)}h` }),
    )
    .when(
      d => Periods.ONE_DAY <= d && d < Periods.ONE_DAY * 30,
      v => t('UIKit.EventTiming.timeAgo', { time: `${(v / Periods.ONE_DAY).toFixed(0)}d` }),
    )
    .when(
      d => Periods.ONE_DAY * 30 <= d && d < Periods.ONE_YEAR,
      v => t('UIKit.EventTiming.timeAgo', { time: `${(v / (Periods.ONE_DAY * 30)).toFixed(0)}M` }),
    )
    .otherwise(v => t('UIKit.EventTiming.timeAgo', { time: `${(v / Periods.ONE_YEAR).toFixed(0)}y` }));
}
