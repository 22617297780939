import { SOCKET_API_KEY } from '@constants';
import { Budget, Loadable, Loader, isChainAddress, isCoin, parseAddress, parseNum, wrapToken } from '@utils';
import { networksByChain } from '@utils/networks';

export interface UseSocketRouteArgs {
  sourceVaultAddress: Loadable<ChainAddress | HexString | nil>;
  fromBudget: Loadable<Budget | nil>;
  toTokenAddress: Loadable<ChainAddress | nil>;
  sortOrder?: 'output' | 'gas' | 'time';
  refreshOnChange?: any;
}

export function useSocketRoutes({
  sourceVaultAddress,
  fromBudget,
  toTokenAddress,
  sortOrder = 'output',
  refreshOnChange,
}: UseSocketRouteArgs): Loader<readonly SocketQuote[]> {
  return Loader.array([sortOrder, fromBudget, sourceVaultAddress, toTokenAddress] as const)
    .debounce(500) // avoid hitting the api too hard
    .map([refreshOnChange], async ([sort, from, _vaultAddress, _toToken]) => {
      if (!from || !isCoin(from.token) || !_toToken || !_vaultAddress) {
        return Loader.skipped;
      }

      // parse token addresses
      const fromToken = parseAddress(wrapToken(from.token.id));
      const toToken = parseAddress(_toToken);

      // validate vault address
      let vaultAddress: HexString;
      if (isChainAddress(_vaultAddress)) {
        const parsed = parseAddress(_vaultAddress);
        vaultAddress = parsed.address;
        if (parsed.chain !== fromToken.chain) {
          // eslint-disable-next-line max-len
          return Loader.error(
            `Vault address ${_vaultAddress} is not on the same chain as the from token ${from.token.id}`,
          );
        }
      } else {
        vaultAddress = _vaultAddress;
      }

      // build the url
      // see https://docs.socket.tech/socket-api/v2/quote
      // eslint-disable-next-line max-len
      // ex: curl -H 'API-KEY: dbca11ef-28bc-4b9e-9d67-d1da2657b01d' 'https://api.socket.tech/v2/quote?fromChainId=137&fromTokenAddress=0x2791bca1f2de4661ed88a30c99a7a9449aa84174&toChainId=1&toTokenAddress=0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48&fromAmount=12345678&userAddress=0x4242442424424244242442424424244242442424&uniqueRoutesPerBridge=true&sort=output&singleTxOnly=true&isContractCall=true&excludeBridges=stargate'
      const url = [
        `https://api.socket.tech/v2/quote?fromChainId=${networksByChain[fromToken.chain].chainId}`,
        `fromTokenAddress=${fromToken.address}`,
        `toChainId=${networksByChain[toToken.chain].chainId}`,
        `toTokenAddress=${toToken.address}`,
        `fromAmount=${from.amtBase}`,
        `userAddress=${vaultAddress}`,
        'uniqueRoutesPerBridge=true',
        `sort=${sort ?? 'output'}`,
        'singleTxOnly=true',
        'isContractCall=true', // must support transactions where sender is a smart contract
        'excludeBridges=stargate', // stargate asks for ETH as input, lets exclude it (see Telegram conv with Socket)
        'bridgeWithGas=false',
      ].join('&');

      // fetch quotes
      const response = await fetch(url, {
        headers: {
          'API-KEY': SOCKET_API_KEY,
        },
      });

      if (!response.ok) {
        return Loader.error(`Socket API returned ${response.status} ${response.statusText}`);
      }

      const json = await response.json();
      if (!json.success) {
        return Loader.error(`Socket API returned ${json.error ?? 'unknown error'}`);
      }

      const routes = json.result.routes as any[];

      return routes.map(
        x =>
          ({
            inputToken: fromToken.address,
            ...x,
          } as SocketQuote),
      );
    });
}

interface UserTx {
  steps?: {
    toAmount: string;
    minAmountOut: string;
    toAsset: {
      decimals: number;
      symbol: string;
    };
    gasFees: {
      gasAmount: string;
      asset: {
        symbol: string;
        decimals: number;
      };
    };
    protocol: {
      icon: string;
      displayName: string;
    };
    protocolFees: {
      amount: string;
      asset: {
        symbol: string;
        decimals: number;
      };
    };
    type: string;
  }[];

  // If theres only one step, these properties are on the top level, dont ask why...
  protocol?: {
    icon: string;
    displayName: string;
  };

  toAsset: {
    decimals: number;
  };
}

export interface SocketQuote {
  // just a typing trick to ensure that no smartass is gonna build a SocketQuote himself
  // (thus missing 90% of the fields that should be there)
  readonly __donotbuildthatyourself: unique symbol;
  readonly inputToken: HexString;

  // There are a bunch of other fields here, run the below query to see them all:
  // eslint-disable-next-line max-len
  // ex: curl -H 'API-KEY: dbca11ef-28bc-4b9e-9d67-d1da2657b01d' 'https://api.socket.tech/v2/quote?fromChainId=137&fromTokenAddress=0x2791bca1f2de4661ed88a30c99a7a9449aa84174&toChainId=1&toTokenAddress=0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48&fromAmount=12345678&userAddress=0x4242442424424244242442424424244242442424&uniqueRoutesPerBridge=true&sort=output&singleTxOnly=true&isContractCall=true&excludeBridges=stargate'

  readonly routeId: string;
  readonly toAmount: string;
  readonly usedBridgeNames: string[];
  readonly totalGasFeesInUsd: number;
  readonly receivedValueInUsd: number;
  readonly inputValueInUsd: number;
  readonly outputValueInUsd: number;
  readonly logo: string;
  readonly serviceTime: number;
  readonly maxServiceTime: number;
  readonly userTxs: UserTx[];
}

/**
 * Prepare a transaction operation for a given quote,
 * that you have retreived using the useSocketRoutes() hook.
 */
export function useBridgeOperation(route: Loadable<SocketQuote | nil>, refresh?: any): Loader<BridgeOperation> {
  return Loader.useWrap(route)
    .debounce(100, true) // debounce request to prevent spamming
    .map(x => x || Loader.skipped)
    .map([refresh], computeBridge);
}

export async function computeBridge(route: SocketQuote): Promise<BridgeOperation> {
  // build the tx
  // see https://docs.socket.tech/socket-api/v2/app/build-tx-get

  // fetch our bridge transaction
  const response = await fetch('https://api.socket.tech/v2/build-tx', {
    method: 'POST',
    body: JSON.stringify({ route }),
    headers: {
      'API-KEY': SOCKET_API_KEY,
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    throw new Error(`Socket API returned ${response.status} ${response.statusText}`);
  }

  const json = await response.json();
  if (!json.success) {
    throw new Error(`Socket API returned ${json.error ?? 'unknown error'}`);
  }

  const raw = json.result as SocketTx;

  // check that the bridge does not ask for a value
  if (parseNum(raw.value) !== 0n) {
    throw new Error('Socket API returned a tx with a non-zero value.');
  }

  // build script
  const script = `bridge.socket(${route.inputToken}, ${raw.txData});`;

  return {
    type: 'socketBridge',
    script,
    raw,
  } satisfies BridgeOperation;
}

export function getRouteProtocolName(route: SocketQuote): string {
  return (
    route.userTxs[0].protocol?.displayName ??
    route.userTxs[0].steps!.find(s => s.type === 'bridge')!.protocol.displayName
  );
}

export interface BridgeOperation {
  type: 'socketBridge';
  raw: SocketTx;
  script: string;
}

export interface SocketTx {
  txType: 'eth_sendTransaction';
  txData: HexString;
  // this should always be 0, since we're not refuelling,
  // and we're not using stargate (which asks for ETH as input)
  value: HexString;
}
