import cls from 'classnames';

import { Cls } from '@utils';

import { Dropdown, IDropdown } from './Dropdown';

export type BigInputDropdownProps = {
  indication: string;
  disabled?: boolean;
} & Omit<IDropdown, 'indication' | 'labelClassName'> &
Cls;

/** Dropdown preset */
export function BigInputDropdown({ indication, entries, className, disabled, ...props }: BigInputDropdownProps) {
  return (
    <Dropdown
      isPortal
      isDisabled={disabled}
      className={cls('p-3 h-[74px] !rounded-xl', className)}
      indication={indication}
      labelClassName="font-youth-medium font-medium text-[18px] leading-[26px] text-font"
      entries={entries.map(entry => ({
        ...entry,
        content: { ...entry.content, top: <span className="text-xl leading-[26px]">{entry.content?.top}</span> },
      }))}
      {...props}
    />
  );
}
