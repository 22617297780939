import { useRecoilState } from 'recoil';

import { AnimationAlert, animationAlert } from '@components/TxSubmittedAnimation';

export const useAlertsAnimation = () => {
  const [_, setAnimation] = useRecoilState(animationAlert);

  const triggerAlert = ({ variant }: AnimationAlert) => {
    setAnimation({ variant });
  };

  return {
    error: () => triggerAlert({ variant: 'error' }),
    success: () => triggerAlert({ variant: 'success' }),
    submitted: () => triggerAlert({ variant: 'submitted' }),
  };
};
