import cls from 'classnames';

import { ReactComponent as NoRefCoin } from '@images/coins/NoRefCoin.svg';
import { Cls } from '@utils';

export function UnknownTokenLogo({ className = 'w-10 h-10 flex-shrink-0' }: Cls) {
  return (
    <div className={cls('rounded-full flex items-center justify-center overflow-hidden bg-greyblue-200', className)}>
      <NoRefCoin className={cls(className, 'ml-auto')} />
    </div>
  );
}
