import { boundedValue, isChainAddress, safeMult, toBigNumber, toNumber } from '@utils';

// Why this fancy type for a simple percentage ?
// To avoid js floating point errors. We adopt a similar approach to the on used for tokens.
// Percent is a number represented by a bigint associated with decimals of precision
export type Percent = {
  value: bigint;
  precision: number;
};

export type Allocation = {
  percent: Percent;
  isLocked: boolean;
};

export type Distribution = {
  [key: ChainAddress]: Allocation;
};

export const PERCENT_PRECISION = 2;

export function updateDistribution(
  distribution: Distribution,
  allocation: Allocation & { tokenId: ChainAddress },
): Distribution {
  const tokensToUpdateArray = Object.entries(distribution).filter(
    ([tokenId, { isLocked }]) => !isLocked && tokenId !== allocation.tokenId,
  );

  if (tokensToUpdateArray.length === 0) {
    // every other token is locked
    return distribution;
  }

  const tokensToUpdate: Distribution = Object.fromEntries(tokensToUpdateArray);

  const allocationVariation = allocation.percent.value - (distribution[allocation.tokenId]?.percent.value || 0n);

  // allocationPool is used to keep track of the remaining allocation to distribute
  let allocationPool = -allocationVariation;

  const updatedDistribution: Distribution = Object.fromEntries(
    Object.entries(distribution).map<[ChainAddress, Allocation]>(([tokenId, { percent, isLocked }]) => {
      if (!isChainAddress(tokenId)) {
        throw new Error(`Invalid tokenId ${tokenId}`);
      }
      if (tokenId === allocation.tokenId) {
        return [tokenId, { percent: allocation.percent, isLocked: allocation.isLocked }];
      }
      if (isLocked) {
        return [tokenId, { percent, isLocked }];
      }
      if (!tokensToUpdate[tokenId as ChainAddress]) {
        return [tokenId, { percent, isLocked }];
      }
      const newPercentValue = boundedValue(
        percent.value - allocationVariation / BigInt(tokensToUpdateArray.length),
        0n,
        toPercent(100, percent.precision).value,
      );
      allocationPool -= newPercentValue - percent.value;
      return [tokenId, { percent: { value: newPercentValue, precision: percent.precision }, isLocked }];
    }),
  );

  // we distribute the remaining allocation to the tokensToUpdate in a round robin fashion
  // we start from the first token to update and add a unit of the remaining allocation
  // and go through the list until allocationPool is empty
  let k = 0;
  const sign = allocationPool > 0n ? 1n : -1n;
  while (sign * allocationPool > 0n) {
    const tokenId = Object.keys(tokensToUpdate)[k % Object.keys(tokensToUpdate).length] as ChainAddress | undefined;
    if (tokenId && updatedDistribution[tokenId].percent.value > 0n) {
      updatedDistribution[tokenId].percent.value += sign;
      allocationPool -= sign;
    }
    k++;
  }

  return updatedDistribution;
}

export function splitDistributionRatios(
  distribution: Distribution,
  precision: number = PERCENT_PRECISION,
): Distribution {
  let tokensToUpdateArray = Object.entries(distribution).filter(([, { isLocked }]) => !isLocked);

  let _distribution = distribution;

  if (tokensToUpdateArray.length === 0) {
    // every other token is locked
    _distribution = Object.fromEntries(
      Object.entries(distribution).map(([tokenId, { percent }]) => [tokenId, { percent, isLocked: false }]),
    );
    tokensToUpdateArray = Object.entries(_distribution);
  }

  const lockedTokens = Object.entries(_distribution).filter(([, { isLocked }]) => isLocked);
  const lockedRatio = lockedTokens.reduce((acc, [, { percent }]) => acc + percent.value, 0n);

  const availableRatioToUpdate = toPercent(100, precision).value - lockedRatio;

  const updatedDistribution: Distribution = Object.fromEntries(
    Object.entries(_distribution).map(([tokenId, { percent, isLocked }]) => {
      if (isLocked) {
        return [tokenId, { percent, isLocked }];
      }
      return [
        tokenId,
        { percent: { value: availableRatioToUpdate / BigInt(tokensToUpdateArray.length), precision }, isLocked: false },
      ];
    }),
  );

  const remainingRatio =
    toPercent(100, precision).value -
    Object.values(updatedDistribution).reduce((acc, { percent }) => acc + percent.value, 0n);

  const lastTokenId = tokensToUpdateArray.pop()?.[0] as ChainAddress | undefined;

  if (lastTokenId) {
    updatedDistribution[lastTokenId].percent.value += remainingRatio;
  }

  return updatedDistribution;
}

export function toPercent(number: number, precision: number = PERCENT_PRECISION): Percent {
  return { value: toBigNumber(number, precision), precision };
}

export function applyPercent(value: bigint, percent: Percent) {
  return safeMult(value, toNumber(percent.value, percent.precision) / 100);
}

export function allocateRemainings<T extends { spendQty: bigint }, U extends { amtBase: bigint }>(req: T[], budget: U) {
  const remainings = budget.amtBase - req.reduce((acc, s) => acc + s.spendQty, 0n);
  const result = [...req];
  result[result.length - 1].spendQty += remainings;
  return result;
}
