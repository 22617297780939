import { useTranslation } from 'react-i18next';
import { match } from 'ts-pattern';

import { Chain } from '@gql';
import { ChevronRightIcon, InfoIcon, ProfileIcon } from '@ui-kit/Icons';
import { Radio } from '@ui-kit/atoms/Radio';
import { Skeleton } from '@ui-kit/atoms/Skeleton';
import { Toggle } from '@ui-kit/atoms/Toggle';
import { Entry } from '@ui-kit/organisms/Entry';
import { Cls, Float, shortenAddress } from '@utils';
import { allNetworks } from '@utils/networks';

export type EntryRoute = {
  id: string;
  logo: string;
  name: string;
  processDuration: string;
  outputAmount: number;
  gasfee: number;
  onSelect: (id: string) => void;
  selected: boolean;
};

export type IEntryOption =
  | { variant: 'profile-settings' }
  | { variant: 'allow-notifs'; checked?: boolean; onToggle?: VoidFunction }
  | { variant: 'network'; network: Chain; connected?: boolean; hasTrailingItem?: boolean }
  | {
    variant: 'wallet';
    name: string;
    picture: string;
    address: HexString;
    selected?: boolean;
    onSelect?: VoidFunction;
  }
  | ({ variant: 'route' } & EntryRoute)
  | { variant: 'route-loading' };

export const EntryOption = ({ className, ...props }: IEntryOption & Cls) => {
  const { t } = useTranslation();

  return match(props)
    .with({ variant: 'profile-settings' }, () => {
      const top = <span className="text-font font-medium text-lg">{t('UIKit.EntryOption.profileSettings')}</span>;
      return (
        <Entry
          className={className}
          isCard
          leadingItem={<ProfileIcon className="w-6 h-6 fill-current text-font-variant" />}
          content={{ top }}
          trailingItem={<ChevronRightIcon className="w-4 h-4 fill-current text-font-variant" />}
        />
      );
    })
    .with({ variant: 'allow-notifs' }, ({ checked, onToggle }) => (
      <Entry
        className={className}
        isCard
        leadingItem={<ProfileIcon className="w-6 h-6 fill-current text-font-variant" />}
        content={{ top: <span className="text-font font-medium text-lg">{t('UIKit.EntryOption.allowNotifs')}</span> }}
        trailingItem={<Toggle size="s" checked={checked} onToggle={onToggle} />}
      />
    ))
    .with({ variant: 'network' }, ({ network, connected, hasTrailingItem = true }) => {
      const { Icon, name } = allNetworks.find(({ chain }) => chain === network) || {};
      const content = {
        top: <span className="text-font font-medium text-lg">{name}</span>,
        ...(connected && { bottom: <span className="text-accent text-lg">{t('UIKit.EntryOption.connected')}</span> }),
      };
      return (
        <Entry
          className={className}
          isCard
          leadingItem={Icon && <Icon className="w-10 h-10 rounded-lg" />}
          content={content}
          trailingItem={hasTrailingItem ? <InfoIcon className="w-6 h-6 fill-current text-font-variant" /> : undefined}
        />
      );
    })
    .with({ variant: 'wallet' }, ({ name, picture, address, selected, onSelect }) => (
      <Entry
        className={className}
        isCard
        leadingItem={<img src={picture} className="w-10 h-10 rounded-full" />}
        content={{
          top: <span className="text-font font-medium text-lg">{name}</span>,
          bottom: <span className="text-font-variant text-lg">{shortenAddress(address)}</span>,
        }}
        trailingItem={<Radio checked={selected} onClick={onSelect} />}
      />
    ))
    .with({ variant: 'route' }, ({ name, logo, processDuration, outputAmount, gasfee, onSelect, id }) => (
      <Entry
        onClick={() => onSelect(id)}
        className={className}
        isCard
        leadingItem={<img src={logo} className="w-10 h-10 rounded-full" />}
        content={{
          top: (
            <div className="text-font font-medium text-lg justify-between flex">
              <div>{name}</div>
              <div>
                {t('UIKit.EntryOption.get')} ~<Float.UsdPrice value={outputAmount} unitClassName="!mr-0" />
              </div>
            </div>
          ),
          bottom: (
            <div className="text-font-variant text-lg justify-between flex">
              <div>~{processDuration}</div>
              <div>
                {t('UIKit.EntryOption.gasFee')}
                <span className="ml-1">
                  <Float.UsdPrice value={gasfee} unitClassName="!mr-0" />
                </span>
              </div>
            </div>
          ),
        }}
      />
    ))
    .with({ variant: 'route-loading' }, () => (
      <Entry
        disabled
        className={className}
        isCard
        leadingItem={<Skeleton className="w-10 h-10 !rounded-full" />}
        content={{
          top: (
            <div className="justify-between flex">
              <Skeleton className="h-4 w-2/5" />
              <Skeleton className="h-4 w-1/5" />
            </div>
          ),
          bottom: (
            <div className="justify-between flex mt-2">
              <Skeleton className="h-4 w-1/5" />
              <Skeleton className="h-4 w-2/5" />
            </div>
          ),
        }}
      />
    ))
    .exhaustive();
};
