import cls from 'classnames';
import { useTranslation } from 'react-i18next';

import { Button } from '@ui-kit/atoms/Button';
import { Entry } from '@ui-kit/organisms/Entry';
import { Cls } from '@utils';

export type BudgetInputEmptyEntryProps = {
  onSelect?: VoidFunction;
  disabled?: boolean;
  className?: string;
  label?: string;
};

export default function BudgetInputEmptyEntry({
  onSelect,
  disabled,
  className,
  label,
}: BudgetInputEmptyEntryProps & Cls) {
  const { t } = useTranslation();
  return (
    <Entry
      disabled={disabled}
      isCard
      className={cls(className, 'relative font-youth-regular !rounded-2xl')}
      content={{
        top: (
          <div className="flex justify-between">
            <div className="flex justify-between flex-col text-font-variant-disabled">
              {label && <div className="text-sm h-4 font-sans font-normal">{label}</div>}
              <div className="text-3xl font-youth-medium">0.00</div>
              <div className="text-font-variant-disabled mt-2 text-sm select-none font-sans">
                {t('Transactions.BudgetInput.noRate')}
              </div>
            </div>
            <Button
              label={t('Transactions.BudgetInput.selectToken')}
              onClick={onSelect}
              disabled={disabled}
              size="s"
              className="my-auto"
            />
          </div>
        ),
      }}
    />
  );
}
