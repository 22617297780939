import cls from 'classnames';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { ExternalLinkIcon } from '@ui-kit/Icons';
import { Cls, SVGIcon } from '@utils';

export type ITextLink = {
  to: string;
  children: ReactNode;
  isExternal?: boolean;
  Icon?: SVGIcon | null;
} & Cls;

export const TextLink = ({ className, to, children, isExternal, Icon: _Icon }: ITextLink) => {
  const commonClassName = cls(
    'transition-all ease duration-300',
    'text-accent hover:text-accent-hover-font-on-accent font-medium text-base',
    className,
  );

  const Icon = _Icon ?? ExternalLinkIcon;

  return isExternal ? (
    <a className={cls('flex flex-row items-center gap-1', commonClassName)} href={to} target="_blank" rel="noreferrer">
      {children}
      {_Icon !== null && <Icon className="fill-curent w-3.5 h-3.5" />}
    </a>
  ) : (
    <Link className={commonClassName} to={to}>
      {children}
    </Link>
  );
};
