import cls from 'classnames';
import { useEffect, useState, KeyboardEvent, forwardRef, useImperativeHandle, InputHTMLAttributes } from 'react';

import { formatInput } from '@components/bigInput/bigInputUtils';
import { Budget, Cls, IAsset, assetDecimals, deepEqual, toNumber } from '@utils';
import { toBigNumber } from '@utils/to-bignumber';

import { useNumberInputHandler } from './useNumberInputHandler';

export type IBigInput = {
  value: Budget | nil;
  onChange: (arg: Budget<IAsset>) => void;
  disabled?: boolean;
  autofocus?: boolean;
} & Cls &
Omit<InputHTMLAttributes<HTMLInputElement>, 'value' | 'onChange'>;

const BudgetInput = forwardRef(
  ({ value, onChange, disabled = false, className, autofocus, ...rest }: IBigInput, ref) => {
    const [inputRef, handler] = useNumberInputHandler(v => v);

    useEffect(() => {
      if (inputRef.current && autofocus) {
        inputRef.current.focus();
      }
    }, [autofocus, inputRef]);

    useImperativeHandle(ref, () => inputRef.current);

    const [valueSetHere, setValueSetHere] = useState(value);

    // set text from current value at startup
    useEffect(() => {
      if (inputRef.current) {
        inputRef.current.value = budgetToDisplay(value);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // set text from current value when it changes from the outside
    useEffect(() => {
      if (
        !valueSetHere ||
        value?.amtBase !== valueSetHere.amtBase ||
        (parseFloat(inputRef.current?.value || '0') > 0 && value?.amtBase === 0n)
      ) {
        if (inputRef.current) {
          inputRef.current.value = budgetToDisplay(value);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]); // do NOT add 'valueSetHere' as dependency

    const handleInputChange = (e: KeyboardEvent) => {
      if (value?.token) {
        handler(curVal => {
          if (onChange) {
            const formattedText = formatInput(curVal);
            const formattedStringNumber = formattedText.replace(/,/g, '');
            const newValue = {
              amtBase: toBigNumber(formattedStringNumber, assetDecimals(value.token)),
              token: value.token!,
            };
            if (!deepEqual(newValue, value)) {
              onChange(newValue);
              setValueSetHere(newValue);
            }
          }
        })(e);
      }
    };
    // ======================================================================
    // =============================== RENDER ===============================
    // ======================================================================

    return (
      <div className="relative w-full font-youth-medium" data-cy="BudgetInput">
        {disabled && (
          <div
            className={cls(
              'text-3xl cursor-caret',
              'placeholder:text-font-variant placeholder:font-youth-medium',
              'outline-none focus:outline-none bg-transparent',
              'w-full',
              'text-font-variant',
              className,
            )}
          >
            {formatNum(
              toNumber(value?.amtBase || 0n, assetDecimals(value?.token)),
              Math.min(assetDecimals(value?.token), 8),
              { minimumFractionDigits: value?.amtBase ? undefined : 2 },
            )}
          </div>
        )}
        <input
          ref={inputRef}
          placeholder="0.00"
          className={cls(
            'text-3xl cursor-caret caret-accent',
            'placeholder:text-font-variant',
            'outline-none focus:outline-none bg-transparent',
            'w-full',
            className,
            { '!hidden': disabled },
          )}
          onKeyDown={handleInputChange}
          {...rest}
        />
      </div>
    );
  },
);

BudgetInput.displayName = 'BudgetInput';

export default BudgetInput;

function budgetToDisplay(budget: Budget | nil): string {
  if (!budget?.amtBase) {
    return '';
  }
  const asNum = toNumber(budget.amtBase, assetDecimals(budget.token));
  return formatNum(asNum, assetDecimals(budget.token));
}

function formatNum(num: number, decimals: number, opts?: Intl.NumberFormatOptions): string {
  return Intl.NumberFormat('en-US', {
    maximumFractionDigits: decimals,
    ...opts,
  }).format(num);
}
