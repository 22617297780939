import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Chain } from '@gql';
import { useLimitOrdersRefresh } from '@hooks/useLimitOrdersRefresh';
import { useCreateOrCallVault } from '@tetris/tx';
import { useCancelOrderLimit } from '@tetris/useLimitOrder';
import { Button } from '@ui-kit/atoms/Button';
import { Cls } from '@utils';

export type ICancelLimitOrderButton = {
  chain: Chain;
  orderHash: string;
} & Cls;

export const CancelLimitOrderButton = ({ chain, orderHash }: ICancelLimitOrderButton) => {
  const { t } = useTranslation();
  const [canCancel, setCanCancel] = useState(true);

  const orderLimit = useCancelOrderLimit(orderHash);

  const { sendTx, operation } = useCreateOrCallVault(chain, [orderLimit], { forcedChain: chain });

  const { refreshLimitOrdersForChain } = useLimitOrdersRefresh();

  const refreshOrdersAndHideButton = () => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      refreshLimitOrdersForChain(chain);
      setCanCancel(false);
    } catch (e) {
      // DO NOTHING
    }
  };

  // if the sendTx or the operation fails, it is likely that the order has already been canceled
  // It is worth to refresh the orders list and hide the button
  sendTx.onError(() => {
    refreshOrdersAndHideButton();
  });

  operation.onOk(() => {
    refreshOrdersAndHideButton();
  });

  return (
    <Button
      label={
        canCancel ? t('Components.CancelLimitOrderButton.cancelOrder') : t('Components.CancelLimitOrderButton.canceled')
      }
      size="l"
      fullWidth
      variant={canCancel ? 'danger-muted' : 'surface-inactive'}
      onClick={sendTx.map(x => x.sendNext)}
      disabled={!canCancel}
      isLoading={operation.isLoading}
    />
  );
};
