import { DocumentNode } from '@apollo/client';

import { apolloClient } from '../apollo';

export async function fetchApolloAsync<TData, TVariables = any>(
  query: DocumentNode,
  variables: TVariables | undefined,
): Promise<TData> {
  const result = await apolloClient.query<TData, TVariables>({
    query,
    variables,
  });
  if (result.error) {
    throw new Error(result.error.message);
  }
  if (result.errors?.length) {
    throw new Error(result.errors[0].message);
  }
  return result.data;
}
