import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { InputField } from '@components/InputField';
import { TextInputSize } from '@ui-kit/organisms/TextInput';
import { cleanNumericString } from '@utils/numbers/NumberFormat';

export type SlippageInputProps = {
  slippageOptions?: number[];
  onChange: (percent: number) => void;
  activeButtonId?: string;
};

export default function SlippageInput({
  slippageOptions = [0.005, 0.015, 0.03],
  onChange,
  activeButtonId,
}: SlippageInputProps) {
  const { t } = useTranslation();
  const [textValue, setTextValue] = useState('');

  const handleChangeText = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '') {
      setTextValue('');
      onChange(0);
      return;
    }
    const newValue = cleanNumericString(e.target.value);
    setTextValue(newValue);
    const numberValue = parseFloat(newValue) / 100;

    if (Number.isNaN(numberValue)) return;

    if (numberValue > 1) {
      onChange(1);
      setTextValue('100');
      return;
    }

    onChange(numberValue);
  };

  const handleChangeButton = (id: string) => {
    const numberId = Number(id);
    onChange(numberId);
    setTextValue('');
  };

  return (
    <InputField
      title={t('Transactions.Settings.slippageTolerance')}
      tooltip={t('Transactions.Settings.slippageToleranceTooltip')}
      input={{
        segmentedChips: {
          chips: {
            chips: slippageOptions.map(percent => ({
              id: percent.toString(),
              label: `${percent * 100}%`,
            })),
            onChange: handleChangeButton,
            activeChipId: activeButtonId,
          },
          text: {
            placeholder: t('Transactions.Settings.custom'),
            value: textValue,
            indication: '%',
            size: TextInputSize.S,
            onChange: handleChangeText,
          },
        },
      }}
    />
  );
}
