import { WheelEventHandler } from 'react';
import { atom, useRecoilState } from 'recoil';

const MainContentRefAtom = atom<HTMLDivElement | null>({
  key: 'MainContentRefAtom',
  default: null,
});

export function useMainContentScroll() {
  const [mainContentRef, setMainContentRef] = useRecoilState(MainContentRefAtom);
  const scrollMainContent: WheelEventHandler = e => {
    if (mainContentRef) {
      // TODO(Hadrien) : Pass ref in to scroll the correct one on each side
      mainContentRef.scrollTop += e.deltaY;
    }
  };

  return {
    setMainContentRef,
    scrollMainContentProps: {
      onWheel: scrollMainContent,
    },
  };
}
