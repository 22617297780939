import cls from 'classnames';

import { Chip, IChipProps } from './Chip';

type ChipProps = Pick<IChipProps, 'label'> & { id: string };

export type SegmentedChipsProps = {
  onChange?: (id: ChipProps['id']) => void;
  isFullWidth?: boolean;
  defaultActiveChipId?: ChipProps['id'];
  chips: ChipProps[];
  activeChipId?: ChipProps['id'];
};

export function SegmentedChips({ chips, activeChipId, isFullWidth, onChange }: SegmentedChipsProps) {
  return (
    <div className={cls('inline-flex rounded-2xl', isFullWidth && 'w-full')}>
      {chips.map(chip => (
        <Chip
          key={chip.id}
          className="first:rounded-l-2xl last:rounded-r-2xl rounded-none"
          onClick={() => onChange?.(chip.id)}
          state={chip.id === activeChipId ? 'active' : undefined}
          {...chip}
        />
      ))}
    </div>
  );
}
