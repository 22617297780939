import { ZeroExFetcher, ZeroXAnswer } from '@tetris/dexes/0x-types';
import { QuoteErrorReasons, QuoteFailedError } from '@tetris/dexes/dex-aggregator-types';

import { ephemeralApiUrl, isEphemeralEnv } from './env';
import { KNOWN_ACCOUNTS, MockProvider } from './mock-provider.dev';
import { fetchEphemeral } from '../apollo';

if (isEphemeralEnv) {
  // === override Date when in ephemeral env
  (window as any).Date = class extends Date {
    constructor(options: string | number | Date) {
      if (options) {
        super(options);
      } else {
        // default 1625224649811 value is the same as in ephemeral server
        super(parseInt(localStorage.getItem('EPHEMERAL_TIME') ?? '1625224649811', 10));
      }
    }
  };
}
export const zeroExFetcher: ZeroExFetcher | undefined = !isEphemeralEnv
  ? undefined
  : async req => {
    const _response = await fetchEphemeral('/fetch-0x-quote', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(req),
    });
    const response = await _response.json();

    // 400: validation failed. A problem with our inputs
    if (_response.status === 400 && 'errorReason' in response) {
      if (response?.errorReason === QuoteErrorReasons.INSUFFICIENT_ASSET_LIQUIDITY) {
        throw new QuoteFailedError(QuoteErrorReasons.INSUFFICIENT_ASSET_LIQUIDITY);
      } else {
        throw new QuoteFailedError(QuoteErrorReasons.UNKNOWN_ERROR);
      }
    }
    if (!_response.ok) {
      throw new QuoteFailedError(QuoteErrorReasons.UNKNOWN_ERROR);
    }
    return response as ZeroXAnswer;
  };

export function walletPreLoadLogic() {
  if (isEphemeralEnv && localStorage.getItem('ephemeralAccountName')) {
    (window as any).ephemeralInjectedWallet = new MockProvider({
      ...(KNOWN_ACCOUNTS[localStorage.getItem('ephemeralAccountName') as keyof typeof KNOWN_ACCOUNTS] ??
        KNOWN_ACCOUNTS.defaultuser),
      chainId: 1,
      debug: true,
    });
  }
}

export async function setEphemeralUser(u: string | null) {
  if (!u) {
    localStorage.removeItem('ephemeralAccountName');
    localStorage.removeItem('EPHEMERAL_API');
    localStorage.clear();
    window.location.reload();
    return;
  }

  const res = await fetch(`${ephemeralApiUrl}/ephemeral/start-session-no-redirect`);
  const session = await res.text();
  const exp = new Date(Date.now() + 1000 * 3600 * 24 * 365 * 5).toUTCString(); // 5y expiry

  document.cookie = `ephemeral_session=${session}; path=/; Secure; SameSite=None; Expires=${exp};`;
  localStorage.clear();
  localStorage.setItem('ephemeralAccountName', u);
  localStorage.setItem('targetNetwork', 'eth');
  localStorage.setItem('EPHEMERAL_API', ephemeralApiUrl);
  window.location.reload();
}

export const currentEphemeralUser = localStorage.getItem('ephemeralAccountName') ?? null;
