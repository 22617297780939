import { Chain } from '@gql';
import { useMainVault } from '@hooks/useMainVault';
import { holdingQuote } from '@utils';

export const useHighestHolding = (chain?: Chain | nil) => {
  const highestHolding$ = useMainVault(false, true).map([chain], vault => {
    return vault?.spot
      .filter(h => (chain ? h.token.chain === chain : true))
      .sort((a, b) => holdingQuote(b) - holdingQuote(a))[0];
  });

  return highestHolding$;
};

export const useHighestHoldings = (chain?: Chain | nil) => {
  const highestHolding$ = useMainVault(false, true).map([chain], vault => {
    return (
      vault?.spot
        .filter(h => (chain ? h.token.chain === chain : true))
        .sort((a, b) => holdingQuote(b) - holdingQuote(a)) || []
    );
  });

  return highestHolding$;
};
