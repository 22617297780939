import { parseNum } from './basic-utils';

/**
 * Builds a bignumber from a given number and token decimals
 *
 * formatEther(toBigNumber(433444123.4, 18)) 👉 '433444123.4'
 * formatEther(toBigNumber(-123.4, 18)) 👉 '-123.4'
 * formatEther(toBigNumber(0.00004, 18)) 👉 '0.00004'
 * formatUnits(toBigNumber(0.0033652, 4), 4) 👉 '0.0033' ⚠ token not precise enough
 */
export function toBigNumber(balance: number | string, tokenDecimals: number): bigint {
  // https://github.com/ethers-io/ethers.js/issues/228
  // parseUnits() does not support exponential notation 🤦 ... lets implement it ourselves
  if (!balance) {
    return 0n;
  }
  const match = /^(-)?(\d+)(\.(\d*))?(e(\+|-)(\d+))?$/.exec(balance.toString());
  if (!match) {
    throw new Error('Invalid number: ' + balance);
  }
  const [, minus, _num, , _dig, , eSign, _exp] = match;
  const targetDecimals = tokenDecimals + (eSign === '+' ? parseInt(_exp, 10) : -(parseInt(_exp, 10) || 0));

  // compute the decimal part
  let digits = (_dig ?? '').padEnd(targetDecimals, '0');

  // truncate to max decimals (in case we have too many digits)
  if (digits.length > targetDecimals) {
    digits = digits.slice(0, targetDecimals);
  }

  // get the bignumber to parse
  let final = _num + digits;

  // truncate to max decimals (in case we had a large negative exponentiation)
  if (targetDecimals < 0) {
    final = final.slice(0, final.length + targetDecimals);
  }

  // return result
  const bn = parseNum(final);
  return minus ? -bn : bn;
}
