import { Loadable, Loader, TokenBudget, withoutChain, wrapToken } from '@utils';

export interface RawLimitOrder {
  type: 'limitorder';
  script: string;
}

export type LimitOrderConfig = {
  input: Loadable<nil | TokenBudget>;
  output: Loadable<nil | TokenBudget>;
  expirationInHour?: number;
};

export function useLimitOrder({ input, output, expirationInHour }: LimitOrderConfig): Loader<RawLimitOrder> {
  return Loader.array([input, output, expirationInHour] as const).map(([inp, out]) => {
    const stringExp = expirationInHour ? `, ${expirationInHour}` : '';

    if (!inp || !out) {
      return {
        type: 'limitorder',
        script: '',
      } satisfies RawLimitOrder;
    }

    return {
      type: 'limitorder',
      // eslint-disable-next-line max-len
      script: `limit.oneInch.limitBuy(${inp.amtBase}u ${withoutChain(wrapToken(inp.token.id))}, ${
        out.amtBase
      }u ${withoutChain(wrapToken(out.token.id))}${stringExp});`,
    } satisfies RawLimitOrder;
  });
}

export interface RawCancelLimitOrder {
  type: 'cancelOrderHash';
  script: string;
}

export const useCancelOrderLimit = (orderHash: string): Loader<RawCancelLimitOrder> => {
  return Loader.useWrap({
    type: 'cancelOrderHash',
    script: `limit.oneInch.cancelOrderHash(${orderHash});`,
  });
};
