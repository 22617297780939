import cls from 'classnames';

import { Cls, Elts } from '@utils';

export type ISectionProps = {
  children: Elts;
} & Cls;

export function Section({ children, className }: ISectionProps) {
  return <div className={cls('flex flex-col gap-8', className)}>{children}</div>;
}
