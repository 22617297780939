import cls from 'classnames';

import { SearchTokens_tokensAggregated } from '@gql';
import { Variation } from '@ui-kit/atoms/Variation';
import { TokenLogo } from '@ui-kit/organisms/TokenLogo';
import { Float, chainOf } from '@utils';
import { networksByChain } from '@utils/networks';

export function SearchRow({ token }: { token: SearchTokens_tokensAggregated }) {
  return (
    <div className="grid grid-cols-search items-center bg-surface-muted px-2 py-3 rounded-lg w-full">
      <div className="flex items-center gap-2">
        <TokenLogo className="h-8 w-8" token={token} />
        <div className="text-base font-medium">{token.symbol}</div>
      </div>
      <div className="flex">
        {token.chainIds.map((chain, index) => {
          const NetworkIcon = networksByChain[chainOf(chain)].Icon;
          const isDisabled = networksByChain[chainOf(chain)].disabled;
          return isDisabled ? null : (
            <NetworkIcon className={cls('rounded-full w-[18px] h-[18px]', index > 0 && '-ml-1')} key={chain} />
          );
        })}
      </div>
      <Float.UsdValue value={token.price} />
      <Float.UsdValue value={token.oneDayVolume} />
      <Variation value={{ variation: token.oneDayPerf?.totalPerf ?? 0 }} />
      <Variation value={{ variation: token.oneWeekPerf?.totalPerf ?? 0 }} />
    </div>
  );
}
