import cls from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { EntryList } from '@components/entries/EntryList';
import { BridgeOperation, SocketQuote } from '@tetris/socket-bridge';
import { ArrowRightIcon } from '@ui-kit/Icons';
import { Pill } from '@ui-kit/atoms/Pill';
import { Skeleton } from '@ui-kit/atoms/Skeleton';
import { Chip } from '@ui-kit/molecules/Chip';
import { ILabelItem, LabelItem } from '@ui-kit/organisms/LabelItem';
import { BudgetWithQuote, ICoin, Loadable, Loader, chainOf, toNumber } from '@utils';
import { secondsToMinutes } from '@utils/dateUtils';
import { networksByChain } from '@utils/networks';
import { formatNumber } from '@utils/numbers/NumberFormat';

import TransactionRecapUI from './TransactionRecapUI';

export type BridgeTransactionRecapProps = {
  bridge: Loadable<BridgeOperation>;
  route: SocketQuote;
  moreRoutes: boolean;
  fromBudget: Loadable<BudgetWithQuote<ICoin>>;
  toBudget: Loadable<BudgetWithQuote<ICoin>>;
  onBack: VoidFunction;
  onCountdownComplete: VoidFunction;
  countdownDurationInMs?: number;
};

export const BridgeTransactionRecap: React.FC<BridgeTransactionRecapProps> = ({
  bridge,
  route,
  moreRoutes,
  fromBudget,
  toBudget,
  onBack,
  onCountdownComplete,
  countdownDurationInMs = 10000,
}) => {
  const bridge$ = Loader.useWrap(bridge);
  const [holdCountdown, setHoldCountdown] = useState(true);
  const { t } = useTranslation();

  const fromBudget$ = Loader.useWrap(fromBudget);
  const toBudget$ = Loader.useWrap(toBudget);

  bridge$.onOk(_bridge => {
    setHoldCountdown(false);
    const refresh = setInterval(() => {
      onCountdownComplete();
    }, countdownDurationInMs);
    return () => clearInterval(refresh);
  });

  bridge$.onLoading(() => {
    setHoldCountdown(true);
  });

  return (
    <Skeleton asOverlay={holdCountdown} className="w-full overflow-visible">
      {Loader.array([fromBudget$, toBudget$] as const)
        .match.notOk(() => null)
        .ok(([_fromBudget, _toBudget]) => {
          const txs = route.userTxs[0].steps!.find(s => s.type === 'bridge');

          if (!txs) return null;
          const logo = route.userTxs[0].protocol?.icon ?? txs.protocol.icon;
          const executionTime = secondsToMinutes(route.serviceTime);
          const name = route.userTxs[0].protocol?.displayName ?? txs.protocol.displayName;
          const items = [
            {
              label: t('Transactions.Bridge.from/to'),
              item: (
                <div className={cls('flex items-center gap-1 select-none', { 'text-font-disabled': holdCountdown })}>
                  {networksByChain[chainOf(_fromBudget.token.id)].name}
                  <ArrowRightIcon className="w-3 h-3 text-font-variant" />
                  {networksByChain[chainOf(_toBudget.token.id)].name}
                </div>
              ),
            },
            {
              label: t('Transactions.Bridge.estimatedOutput'),
              item: (
                <div className={cls('flex items-center gap-1', { 'text-font-disabled': holdCountdown })}>
                  <span>{formatNumber(toNumber(txs.toAmount, txs.toAsset.decimals), 'qty')}</span>
                  <span>{txs.toAsset.symbol}</span>
                </div>
              ),
            },
            {
              label: t('Transactions.Bridge.estimatedTime'),
              item: (
                <div className={cls('flex items-center gap-1', { 'text-font-disabled': holdCountdown })}>
                  <span>{executionTime}</span>
                </div>
              ),
            },
            {
              label: t('Transactions.Bridge.minimumReceived'),
              item: (
                <div className={cls('flex items-center gap-1', { 'text-font-disabled': holdCountdown })}>
                  <span>{formatNumber(toNumber(txs.minAmountOut, txs.toAsset.decimals), 'qty')}</span>
                  <span>{txs.toAsset.symbol}</span>
                </div>
              ),
            },
            {
              label: t('Transactions.Bridge.protocolFees'),
              item: (
                <div className={cls('flex items-center gap-1', { 'text-font-disabled': holdCountdown })}>
                  <span>{formatNumber(toNumber(txs.protocolFees.amount, txs.protocolFees.asset.decimals), 'qty')}</span>
                  <span>{txs.protocolFees.asset.symbol}</span>
                </div>
              ),
            },
            {
              label: t('Transactions.Bridge.massFees'),
              item: (
                <Pill
                  label={t('Transactions.Bridge.free')}
                  theme={{ variant: 'heavy', type: holdCountdown ? 'default' : 'accent' }}
                />
              ),
              itemTooltipContent: t('Transactions.Bridge.massFeesTooltip'),
            },
          ];

          return (
            <TransactionRecapUI
              countdownDurationInMs={countdownDurationInMs}
              holdCountdown={holdCountdown}
              unfairLevel={0}
              content={
                <EntryList<ILabelItem>
                  render={LabelItem}
                  entries={items}
                  contentClassName="!gap-6"
                  header={
                    <div className="flex justify-between items-center h-8">
                      <div className="flex items-center gap-2">
                        <img src={logo} alt={name} className="w-6 h-6 select-none" />
                        {name}
                      </div>
                      {moreRoutes && <Chip label={t('Transactions.Bridge.switch')} id="switch" onClick={onBack} />}
                    </div>
                  }
                />
              }
            />
          );
        })}
    </Skeleton>
  );
};
