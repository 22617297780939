import { useState } from 'react';

import { SideContentProps } from '@components/SideContent/SideContent.types';
import { Chain } from '@gql';
import { ICoin, parseNum, toNumber } from '@utils';

import SelectCoinUI from './ui/SelectCoinUI';
import { useSearchCoinWithBalances } from '../hooks/useSearchCoinWithBalances';
import { useTransactionFlow } from '../hooks/useTransactionFlow';

export type SearchCoinProps = SideContentProps<ICoin> & {
  sortBy?: 'balances' | 'holdings';
  defaultChain?: Chain | nil;
  disabledCoins?: { [key: ChainAddress]: string };
  onlyCoins?: ChainAddress[];
  onlyOwned?: boolean;
};

export default function SearchCoin({
  setResult,
  sortBy = 'holdings',
  defaultChain,
  disabledCoins = {},
  onlyCoins,
  onlyOwned = false,
}: SearchCoinProps) {
  const [searchValue, setSearchValue] = useState<string>('');
  const { currentChain } = useTransactionFlow();

  const coins$ = useSearchCoinWithBalances(searchValue, defaultChain || currentChain, sortBy, onlyOwned, onlyCoins).map(
    coins => {
      return coins.map(coin => ({
        ...coin.token,
        quantity: toNumber(parseNum(coin.qty), coin.token.decimals) ?? 0,
        disabled: coin.token.id in disabledCoins,
        disabledReason: disabledCoins?.[coin.token.id],
      }));
    },
  );

  return (
    <SelectCoinUI
      searchValue={searchValue}
      onSearchValueChange={setSearchValue}
      coins={coins$}
      onSelect={coin => setResult(coin as ICoin)}
      onGoBack={() => setResult(undefined)}
    />
  );
}
