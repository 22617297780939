import * as Sentry from '@sentry/react';
import { useWeb3Modal } from '@web3modal/wagmi/react';
import jwtDecode from 'jwt-decode';
import { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { getRecoil } from 'recoil-nexus';
import { WalletClient } from 'viem';
import { useAccount, useDisconnect, useNetwork } from 'wagmi';

import { isEphemeralEnv } from '@env';
import { Chain } from '@gql';
import { Loader, makeChainAddress } from '@utils';
import { networksByChain, getNetworkFromChainId } from '@utils/networks';

import { ConnectedWallet, WalletData } from './_interfaces';
import { alphaEligibleAtom } from './alphaEligible';
import { targetNetworkAtomDoNotUseUnlessSelectionScreen } from './hooks-hidden-state';
import { setAuthToken, useAuthToken } from '../apollo';
import { DEFAULT_CHAIN } from '../constants';

// =============================================================================
// ====================== USE WALLET ===========================================
// =============================================================================

/** Asks for a chain address. Will open the connection modal if neccessary */
export function useWallet(mustConnect: true): Loader<ConnectedWallet>;
export function useWallet(mustConnect?: boolean): Loader<WalletData>;
export function useWallet(mustConnect?: boolean): Loader<WalletData> {
  // wagmi usage is OK here coz we're in the login flow => DONT USE ELSEWHERE
  const { address, connector, status } = useAccount();
  const { open: openWeb3Modal } = useWeb3Modal();
  const { chain: walletChain } = useNetwork();
  const { disconnectAsync } = useDisconnect();

  const token = useAuthToken();
  const alphaEligible = useRecoilValue(alphaEligibleAtom);
  const [targetChain, setTargetNetwork] = useRecoilState(targetNetworkAtomDoNotUseUnlessSelectionScreen);

  const startLogin = async () => {
    if (!isEphemeralEnv) {
      await openWeb3Modal();
    } else {
      // ephemeral part is handled in WalletProvider
    }
  };

  // ------------ SENTRY ----------------
  useEffect(() => {
    Sentry.setUser({
      // Set address as user id in sentry to be able to track errors
      // by user even if they are not authed to our backend yet
      id: address,
    });
  }, [address]);
  useEffect(() => {
    Sentry.setTag('targetChain', targetChain);
    Sentry.setTag('walletChain', walletChain?.name);
  }, [targetChain, walletChain]);
  // ------------ END SENTRY ------------

  return Loader.async([address, targetChain, status, walletChain, token, alphaEligible], async () => {
    // pending connection
    if (status === 'reconnecting') {
      return Loader.loading;
    }

    // build a function that pops the right flow
    const notConnected = async () => {
      if (!mustConnect) {
        setTargetNetwork(targetChain || DEFAULT_CHAIN);
        // auth is optional: we're not forced to connect
        return makeNotConnected(startLogin);
      }
      if (status === 'connected') {
        // connected, but unsupported network
        // Doesnt matter anymore, network defaults to DEFAULT_CHAIN
        // and is not synced with the wallet, except when pushing txs
      } else {
        // not connected
        // await startLogin();
      }
      // return 'loading' just in case
      // ... the loader should retrigger recomputation in any case
      return Loader.loading;
    };

    // if wallet is not connected
    if (status === 'disconnected' || !address || !targetChain) {
      return notConnected();
    }

    // check that the chain is supported
    if (!targetChain) {
      return notConnected();
    }

    if (isEphemeralEnv) {
      return {
        status: 'connected',
        isAuthed: true,
        address: makeChainAddress(targetChain, address),
        walletClient: (await connector?.getWalletClient()) as WalletClient,
        logout: async () => {},
        login: startLogin,
        changeNetwork: async () => {
          /* not possible in ephemeral env */
          return Chain.eth;
        },
        connectWalletToTargetNetwork: async () => {
          /* not possible in ephemeral env */
        },
        walletChain: Chain.eth,
      } satisfies ConnectedWallet;
    }

    // if wallet is connected, but not authed
    // Replace with the following line after the alpha filter is removed
    if (!token) {
      if (alphaEligible === false) {
        return {
          status: 'error',
          isAuthed: false,
          logout: () => {},
          login: startLogin,
          walletClient: null,
        };
      }
      return notConnected();
    }

    // if wrong auth
    const jwt = jwtDecode<{ sub: HexString }>(token);
    if (address.toLowerCase() !== jwt.sub) {
      // having a token on an address that doesnt match the wallet
      //  => clear it.
      setAuthToken(null);
      return notConnected();
    }

    // get the wallet client
    const walletClient = await connector?.getWalletClient();
    if (!connector || !walletClient) {
      return notConnected();
    }

    // wallet is connected and authed
    return {
      status: 'connected',
      isAuthed: true,
      address: makeChainAddress(targetChain, address),
      walletClient,
      logout: async () => {
        await disconnectAsync();
        setAuthToken(null);
      },
      login: startLogin,
      changeNetwork: async (toChain: Chain) => {
        // force the network
        setTargetNetwork(toChain);
        return toChain;
      },
      connectWalletToTargetNetwork: async () => {
        // get the latest up-to-date value
        const toSwitch = getRecoil(targetNetworkAtomDoNotUseUnlessSelectionScreen);
        if (!toSwitch) {
          throw new Error('No target network selected');
        }
        const toId = networksByChain[toSwitch].chainId;
        if (toId === walletChain?.id) {
          return;
        }
        const resultingChain = await connector!.switchChain!(toId);
        if (toId !== resultingChain.id) {
          throw new Error('Failed to switch to ' + toSwitch);
        }
      },
      // this is only used for display purposes
      walletChain: getNetworkFromChainId(walletChain?.id)?.chain,
    } satisfies ConnectedWallet;
  });
}

const makeNotConnected = (login: () => Promise<void>): WalletData => ({
  status: 'not connected',
  isAuthed: false,
  logout: () => {},
  login,
  walletClient: null,
});
