import cls from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { match } from 'ts-pattern';

import { PercentInput } from '@components/NumberInputs/PercentInput';
import { ApproximateIcon, SpotIcon } from '@ui-kit/Icons';
import { Skeleton } from '@ui-kit/atoms/Skeleton';
import { Chip } from '@ui-kit/molecules/Chip';
import { Entry } from '@ui-kit/organisms/Entry';
import { TokenLogo } from '@ui-kit/organisms/TokenLogo';
import { BudgetWithQuote, Cls, Float, ICoin, Loadable, Loader, OneOf, safeMult, toNumber } from '@utils';
import { formatNumber } from '@utils/numbers/NumberFormat';

import { Percent } from '../flows/multiSwap/multiBuy/multiBuy.utils';

export type PercentInputEntryProps = {
  coin: Loadable<ICoin>;
  percent: Percent;
  onChange: (percent: Percent) => void;
  onLock?: (locked: boolean) => void;
  maxBudget: Loadable<bigint>;
  hint?: Loadable<OneOf<[{ errorMessage: string }, { budget: BudgetWithQuote<ICoin> }]>>;
  disabled?: boolean;
  interactive?: boolean;
  locked?: boolean;
  onDelete?: (coin: ICoin) => void;
  skeletonClassName?: string;
  maxVal?: bigint;
  onSelectCoin?: VoidFunction;
} & Cls;

export default function PercentInputEntry({
  coin,
  percent,
  onChange,
  maxBudget,
  hint,
  interactive = true,
  locked = false,
  onDelete,
  className,
  skeletonClassName,
  onLock,
  maxVal,
  disabled = false,
  onSelectCoin,
}: PercentInputEntryProps) {
  const [isFocused, setIsFocused] = useState(false);
  const { t } = useTranslation();
  const coin$ = Loader.useWrap(coin);
  const percent$ = Loader.useWrap(percent);
  const maxBudget$ = Loader.useWrap(maxBudget);
  const hint$ = Loader.useWrap(hint);

  const hintRender = hint$
    .noFlickering()
    .match.loadingOrSkipped(() => <Skeleton className="w-[30%] h-4" />)
    .error(() => <Skeleton className="w-[30%] h-4" noAnimation />)
    .ok(_hint =>
      match(_hint)
        .with(undefined, () => <span />)
        .when(
          h => 'budget' in h,
          ({ budget }) => {
            if (!budget) return null;
            const { quote, amtBase, token } = budget;
            return (
              <span className="text-font-variant flex gap-1">
                {formatNumber(toNumber(amtBase, token.decimals), 'qty')}&nbsp;{token.symbol}
                <ApproximateIcon width={12} height={12} className="my-auto inline" />
                $&nbsp;{formatNumber(toNumber(safeMult(amtBase, quote), token.decimals), 'price')}
              </span>
            );
          },
        )
        .when(
          h => 'errorMessage' in h,
          ({ errorMessage }) => <span className="text-danger">{errorMessage}</span>,
        )
        .run(),
    );

  const errorClassName = hint$.match.notOk(() => '').ok(ht => (ht && ht.errorMessage ? 'border !border-danger' : ''));

  return Loader.array([coin$, percent$, maxBudget$, hint$] as const)
    .match.loadingOrSkipped(() => <Skeleton className={cls('w-full h-[82px]', skeletonClassName)} />)
    .error(() => null) // TODO: handle error @Winangel
    .ok(([_coin, _percent, _maxBudget, _hint]) => {
      return (
        <Entry
          isCard
          className={cls(className, errorClassName, '!bg-surface-muted !cursor-auto', {
            '!border-font-variant': isFocused && !errorClassName,
          })}
          content={{
            top: (
              <div className="flex justify-between">
                <PercentInput
                  percent={_percent}
                  maxVal={maxVal}
                  onChange={onChange}
                  disabled={!interactive || disabled}
                  withLock
                  locked={locked}
                  onFocus={() => setIsFocused(true)}
                  onBlur={() => setIsFocused(false)}
                  onLock={onLock}
                  hasError={isFocused && !!errorClassName}
                />
                <Chip
                  label={_coin.symbol}
                  leadingItem={{ Icon: <TokenLogo token={_coin} withBadge={false} /> }}
                  onClick={onSelectCoin}
                />
              </div>
            ),
            bottom: (
              <div className="flex justify-between mt-2 text-sm">
                {hintRender}
                <span className="flex gap-1 items-center">
                  <SpotIcon height={12} />
                  <Float.Custom value={toNumber(_maxBudget, _coin.decimals)} type="qty" />
                  <span className="select-none">|</span>
                  <span
                    className="text-danger select-none"
                    role="button"
                    tabIndex={0}
                    onClick={() => onDelete?.(_coin)}
                  >
                    {t('Transactions.BudgetInput.delete')}
                  </span>
                </span>
              </div>
            ),
          }}
        />
      );
    });
}
