import { gql } from '@apollo/client';

import { Chain, SearchTokens, SearchTokensVariables, SearchTokens_tokensAggregated } from '@gql';
import { Loader } from '@utils';

const SEARCH_TOKENS = gql`
  query SearchTokens($search: String, $limit: Int!, $chains: [Chain!]) {
    tokensAggregated(limit: $limit, search: $search, chains: $chains) {
      id
      logo
      symbol
      name
      chainIds
      price: quote
      oneDayVolume: volume(timeframe: p1d)
      oneDayPerf: curves(timeframe: p1d) {
        totalPerf
      }
      oneWeekPerf: curves(timeframe: p1w) {
        totalPerf
      }
    }
  }
`;

export const useSearchTokens = (
  params: {
    search: string | nil;
    chain: Chain | nil;
  },
  refetchWhenChanges?: any[],
): Loader<SearchTokens_tokensAggregated[] | nil> => {
  return Loader.query<SearchTokens, SearchTokensVariables>(SEARCH_TOKENS, {
    variables: {
      search: params.search,
      limit: 20,
      chains: params.chain ? [params.chain] : undefined,
    },
    refetchWhenChanges,
  }).map(({ tokensAggregated }) => tokensAggregated?.map(token => ({ ...token })));
};
