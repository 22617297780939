import cls from 'classnames';
import { HTMLAttributeAnchorTarget, ReactNode, useMemo } from 'react';
import { match } from 'ts-pattern';

import { SuccessIcon, InfoIcon, WarningIcon, ErrorIcon, CloseIcon, MassIcon } from '@ui-kit/Icons';
import { nullish } from '@utils';

import { IProgressBar, ProgressBar } from './ProgressBar';

export type AlertVariant = 'success' | 'info' | 'warning' | 'error';

type AlertLink = {
  url?: string;
  label: string;
  onClick?: VoidFunction;
  target?: HTMLAttributeAnchorTarget;
};

export interface IAlert {
  className?: string;
  variant?: AlertVariant;
  title?: string;
  content?: string;
  hideIcon?: boolean;
  link?: AlertLink;
  secondaryLink?: AlertLink;
  isFixed?: boolean;
  onClose?: VoidFunction;
  progressBarValuePercent?: IProgressBar['valuePercent'];
  theme?: 'default' | 'brand';
  bottom?: ReactNode;
}

export const Alert = ({
  className,
  variant = 'success',
  title,
  content,
  hideIcon,
  link,
  isFixed,
  onClose,
  progressBarValuePercent,
  secondaryLink,
  theme = 'default',
  bottom,
}: IAlert) => {
  const hasContent = !!content;
  const showIcon = !hideIcon && title;
  const closeButton = useMemo(
    () =>
      onClose && (
        <CloseIcon onClick={onClose} className="w-3.5 h-3.5 fill-current flex-shrink-0 text-font cursor-pointer" />
      ),
    [onClose],
  );

  const links = useMemo(
    () =>
      [link, secondaryLink]
        .filter(_link => !nullish(_link))
        .map((_link, index, { length }) => (
          <>
            {_link?.url ? (
              <a
                key={_link?.label}
                href={_link?.url}
                target={_link?.target}
                className="text-accent text-lg font-medium hover:text-accent-hover-font-on-accent"
                rel="noreferrer"
              >
                {_link?.label}
              </a>
            ) : (
              <span
                onClick={_link?.onClick}
                className="text-accent text-lg font-medium hover:text-accent-hover-font-on-accent cursor-pointer"
              >
                {_link?.label}
              </span>
            )}
            {length > 1 && index === 0 && <div className="bg-font-variant rounded-full h-1 w-1" />}
          </>
        )),
    [link, secondaryLink],
  );

  const variantClassName = match(variant)
    .with('success', () => 'text-success')
    .with('info', () => 'text-info')
    .with('warning', () => 'text-warning')
    .with('error', () => 'text-danger')
    .exhaustive();

  const Icon = match(variant)
    .with('success', () => SuccessIcon)
    .with('info', () => InfoIcon)
    .with('warning', () => WarningIcon)
    .with('error', () => ErrorIcon)
    .exhaustive();

  return (
    <div
      className={cls(
        'flex flex-col gap-3 rounded-xl p-3 relative font-sans whitespace-normal break-words',
        (typeof progressBarValuePercent === 'number' || theme === 'brand') && 'overflow-hidden',
        isFixed && 'backdrop-blur-2xl max-w-[320px]',
        {
          'bg-surface-elevated': isFixed && theme === 'default',
          'bg-surface-muted': theme === 'default',
          'bg-background': theme === 'brand',
        },
        theme === 'default' ? variantClassName : 'text-font',
        className,
      )}
    >
      {title && (
        <div className="flex justify-between items-center h-full">
          <div className="flex flex-row items-center gap-1">
            {showIcon && theme === 'default' && (
              <Icon className={cls('w-4 h-4 fill-current flex-shrink-0 my-auto', variantClassName)} />
            )}
            {title && (
              <span className={cls('font-medium', theme === 'default' ? 'text-lg' : 'text-xl leading-[26px]')}>
                {title}
              </span>
            )}
          </div>
          <div className="flex items-center gap-2">
            {!hasContent && links}
            {closeButton}
          </div>
        </div>
      )}
      {hasContent && <p className="text-font text-base">{content}</p>}
      {hasContent && !!links?.length && <div className="flex items-center gap-2">{hasContent && links}</div>}
      {bottom}
      {typeof progressBarValuePercent === 'number' && (
        <ProgressBar theme={variant} className="absolute bottom-0 inset-x-0" valuePercent={progressBarValuePercent} />
      )}
      {theme === 'brand' && (
        <MassIcon
          className="absolute blur-[6px] opacity-12 h-[442px]
        w-[792px] top-[-180px] right-[-350px] pointer-events-none"
        />
      )}
    </div>
  );
};
