import { useTranslation } from 'react-i18next';
import { match } from 'ts-pattern';

import { SideContentHeader } from '@components/layouts/side-content/SideContentHeader';
import { GetTransaction_transaction, TransactionType, Chain } from '@gql';
import {
  ArrowRightIcon,
  BridgeIcon,
  DepositIcon,
  ErrorIcon,
  LimitBuyIcon,
  LoadingIcon,
  NoRefCoinIcon,
  SuccessIcon,
  SwapCoinIcon,
  WithdrawIcon,
} from '@ui-kit/Icons';
import { Alert } from '@ui-kit/atoms/Alert';
import { Button } from '@ui-kit/atoms/Button';
import { Pill } from '@ui-kit/atoms/Pill';
import { TextLink } from '@ui-kit/atoms/TextLink';
import { LabelItemList } from '@ui-kit/organisms/LabelItemList';
import {
  Float,
  Loadable,
  Loader,
  formatDate,
  shortenAddress,
  toNumber,
  getTransactionDetailsUrl,
  chainOf,
} from '@utils';
import { networksByChain } from '@utils/networks';
import { TransactionState } from '@utils/uiKitUtils/statusTransactionUtils';

import { getNetworkIcon, getTransactionStatus } from './Transactions.utils';

type Transaction = GetTransaction_transaction & {
  // below are mock fields, remove when avaialable from api
  protocol?: {
    name: string;
    link: string;
  };
  account?: string;
  status?: TransactionState;
  transactionLink?: string;
};

export type TransactionDetailsUIProps = {
  transaction?: Loadable<Transaction | null>;
  onGoBack?: VoidFunction;
  onRetry?: VoidFunction;
};

export function TransactionDetailsUI({ transaction: _transaction, onGoBack, onRetry }: TransactionDetailsUIProps) {
  const { t } = useTranslation();

  const transaction$ = Loader.useWrap(_transaction);
  const transaction = transaction$.match.notOk(() => null).ok(data => data);

  // TODO(Hadrien) : Improve the way we display the transaction details : .match for each type/section
  // /!\ inputs et outputs sont relatifs au Vault, et pas à la transaction.
  // inputs = tokens arrivant dans le Vault
  // outputs = tokens sortant du Vault
  const inputs = transaction?.inputs;
  const outputs = transaction?.outputs;
  const fees: any = null; // transaction?.fees?.[0]; TODO fees
  const network = transaction ? networksByChain[chainOf(transaction?.id)] : undefined;
  const isPending =
    transaction?.status === TransactionState.Pending || transaction?.status === TransactionState.Processing;

  const statusPill = match(transaction && getTransactionStatus(transaction))
    .with(TransactionState.Processing, () => (
      <Pill
        theme={{ type: 'info', variant: 'soft' }}
        LeadingIcon={LoadingIcon}
        leadingIconClassName="animate-spin"
        label={t('Common.TransactionStatus.processing')}
      />
    ))
    .with(TransactionState.Success, TransactionState.Completed, () => (
      <Pill
        theme={{ type: 'success', variant: 'soft' }}
        LeadingIcon={SuccessIcon}
        label={t('Common.TransactionStatus.success')}
      />
    ))
    .with(TransactionState.Failed, () => (
      <Pill
        theme={{ type: 'error', variant: 'soft' }}
        LeadingIcon={ErrorIcon}
        label={t('Common.TransactionStatus.failed')}
      />
    ))
    .with(TransactionState.Pending, () => (
      <Pill theme={{ type: 'default', variant: 'soft' }} label={t('Common.TransactionStatus.pending')} />
    ))
    .otherwise(() => null);

  const type = match(transaction?.type)
    .with(TransactionType.deposit, () => ({
      Icon: DepositIcon,
      label: t('TransactionDetails.type.deposit'),
    }))
    .with(TransactionType.withdraw, () => ({
      Icon: WithdrawIcon,
      label: t('TransactionDetails.type.withdraw'),
    }))
    .with(TransactionType.swap, () => ({
      Icon: SwapCoinIcon,
      label: t('TransactionDetails.type.swap'),
    }))
    .with(TransactionType.limitOrder, () => ({
      Icon: LimitBuyIcon,
      label: t('TransactionDetails.type.limitOrder'),
    }))
    .with(TransactionType.bridge, () => ({
      Icon: BridgeIcon,
      label: t('TransactionDetails.type.bridge'),
    }))
    .with(TransactionType.vaultCreation, () => ({
      Icon: LimitBuyIcon,
      label: t('TransactionDetails.type.vaultCreation'),
    }))
    .with(TransactionType.unknown, () => ({
      Icon: NoRefCoinIcon,
      label: t('TransactionDetails.type.unknown'),
    }))
    .with(TransactionType.multibuy, () => ({
      Icon: SwapCoinIcon,
      label: t('TransactionDetails.type.multibuy'),
    }))
    .with(TransactionType.multisell, () => ({
      Icon: SwapCoinIcon,
      label: t('TransactionDetails.type.multisell'),
    }))
    .otherwise(() => null);

  const fields = [
    {
      label: t('TransactionDetails.fields.type'),
      item: match(transaction?.type)
        .with(null, () => 'unknown')
        .with(undefined, () => 'unknown')
        .otherwise(
          () =>
            type && (
              <div className="flex gap-1 items-center text-font font-medium">
                <type.Icon className="fill-current w-3.5 h-3.5" />
                <span>{type.label}</span>
              </div>
            ),
        ),
    },
    {
      label: t('TransactionDetails.fields.fromTo'),
      item: match(transaction?.type)
        .with(TransactionType.swap, TransactionType.multibuy, TransactionType.multisell, () =>
          inputs && outputs && inputs.length > 0 && outputs.length > 0 ? (
            <div className="flex gap-1 items-center">
              {outputs.map(({ token }) => token.symbol).join(', ')}
              <ArrowRightIcon className="w-3 h-3 text-font-variant" />
              {inputs.map(({ token }) => token.symbol).join(', ')}
            </div>
          ) : (
            <div className="flex gap-1 items-center">
              unknown
              <ArrowRightIcon className="w-3 h-3 text-font-variant" />
              unknown
            </div>
          ),
        )
        .with(TransactionType.withdraw, TransactionType.deposit, TransactionType.bridge, () => undefined)
        .otherwise(() =>
          inputs && outputs && inputs.length > 0 && outputs.length > 0 ? (
            <div className="flex gap-1 items-center">
              {inputs.map(({ token }) => token.symbol).join(', ')}
              <ArrowRightIcon className="w-3 h-3 text-font-variant" />
              {outputs.map(({ token }) => token.symbol).join(', ')}
            </div>
          ) : (
            <div className="flex gap-1 items-center">
              unknown
              <ArrowRightIcon className="w-3 h-3 text-font-variant" />
              unknown
            </div>
          ),
        ),
    },
    {
      label: t('TransactionDetails.fields.amountSent'),
      item: match(transaction?.type)
        .with(TransactionType.multibuy, TransactionType.multisell, TransactionType.swap, () =>
          outputs && outputs.length > 0
            ? outputs.map(input => (
              <>
                <Float.Custom
                  key={input.token.symbol}
                  value={toNumber(input.qty, input.token.decimals)}
                  type="qty"
                  unit={input.token.symbol}
                  takeAbsoluteValue
                />
                <br />
              </>
            ))
            : 'unknown',
        )
        .with(TransactionType.withdraw, TransactionType.deposit, TransactionType.bridge, () => undefined)
        .otherwise(() =>
          inputs && inputs.length > 0
            ? inputs.map(input => (
              <>
                <Float.Custom
                  key={input.token.symbol}
                  value={toNumber(input.qty, input.token.decimals)}
                  type="qty"
                  unit={input.token.symbol}
                  takeAbsoluteValue
                />
                <br />
              </>
            ))
            : 'unknown',
        ),
    },
    {
      label: t('TransactionDetails.fields.amountReceived'),
      item: match(transaction?.type)
        .with(TransactionType.multibuy, TransactionType.multisell, TransactionType.swap, () => (
          <div className="text-right">
            {inputs && !isPending && inputs.length > 0
              ? inputs.map(input => (
                <>
                  <Float.Custom
                    key={input.token.symbol}
                    value={toNumber(input.qty, input.token.decimals)}
                    type="qty"
                    unit={input.token.symbol}
                    takeAbsoluteValue
                  />
                  <br />
                </>
              ))
              : 'unknown'}
          </div>
        ))
        .with(TransactionType.withdraw, TransactionType.deposit, TransactionType.bridge, () => undefined)
        .otherwise(() => (
          <div className="text-right">
            {outputs && !isPending && outputs.length > 0
              ? outputs.map(output => (
                <>
                  <Float.Custom
                    key={output.token.symbol}
                    value={toNumber(output.qty, output.token.decimals)}
                    type="qty"
                    unit={output.token.symbol}
                    takeAbsoluteValue
                  />
                  <br />
                </>
              ))
              : 'unknown'}
          </div>
        )),
    },
    {
      label: t('TransactionDetails.fields.amount'),
      item: match(transaction?.type)
        .with(TransactionType.withdraw, TransactionType.bridge, () =>
          outputs && !isPending && outputs.length > 0
            ? outputs.map(output => (
              <>
                <Float.Custom
                  key={output.token.symbol}
                  value={toNumber(output.qty, output.token.decimals)}
                  type="qty"
                  unit={output.token.symbol}
                  takeAbsoluteValue
                />
                <br />
              </>
            ))
            : 'unknown',
        )
        .with(TransactionType.deposit, () =>
          inputs && !isPending && inputs.length > 0
            ? inputs.map(input => (
              <>
                <Float.Custom
                  key={input.token.symbol}
                  value={toNumber(input.qty, input.token.decimals)}
                  type="qty"
                  unit={input.token.symbol}
                  takeAbsoluteValue
                />
                <br />
              </>
            ))
            : 'unknown',
        )
        .otherwise(() => undefined),
    },
    {
      label: t('TransactionDetails.fields.protocolFees'),
      itemTooltipContent: t('TransactionDetails.fields.protocolFees'),
      item:
        fees && !isPending ? (
          <Float.Custom
            value={toNumber(fees.qty, fees.token.decimals)}
            type="qty"
            unit={fees.token.symbol}
            takeAbsoluteValue
          />
        ) : undefined,
    },
    {
      label: t('TransactionDetails.fields.protocolUsed'),
      item: transaction?.protocol ? (
        <TextLink to={transaction?.protocol.link} isExternal>
          {transaction?.protocol.name}
        </TextLink>
      ) : undefined,
    },
    {
      label: t('TransactionDetails.fields.massFees'),
      itemTooltipContent: t('TransactionDetails.fields.massFees'),
      item: <Pill label={t('TransactionDetails.free')} theme={{ type: 'accent', variant: 'heavy' }} />,
    },
    {
      label: t('TransactionDetails.fields.network'),
      item: match(transaction?.type)
        .with(TransactionType.bridge, () => {
          // TODO(Hadrien) : This is enforced TEMPORARY because
          //  we have only 2 networks and dont get the inputs tx from backend
          // https://linear.app/mass/issue/MASS-1288/transaction-history-bridge#comment-83173073
          const outputNetwork = transaction?.outputs?.[0]?.token.chain;
          const inputNetwork =
            transaction?.inputs?.[0]?.token.chain || (outputNetwork === Chain.arbi ? Chain.opti : Chain.arbi);
          return !transaction?.outputs?.[0]?.token.chain && !transaction?.inputs?.[0]?.token.chain ? null : (
            <span className="flex flex-row gap-1 items-center">
              {getNetworkIcon(outputNetwork)}
              <ArrowRightIcon className="w-3 h-3 text-font-variant" />
              {getNetworkIcon(inputNetwork)}
            </span>
          );
        })
        .otherwise(() => (network ? <Pill LeadingIcon={network.MonochromeIcon} label={network.name} /> : undefined)),
    },
    {
      label: t('TransactionDetails.fields.in'),
      item: transaction?.account,
    },
    { label: t('TransactionDetails.fields.transactionState'), item: statusPill },
    {
      label: t('TransactionDetails.fields.timestamp'),
      itemTooltipContent: t('TransactionDetails.fields.timestamp'),
      item: transaction?.processedAt ? formatDate(new Date(transaction.processedAt), 'long-date-time') : undefined,
    },
    {
      label: t('TransactionDetails.fields.networkTransactionLink'),
      itemTooltipContent: t('TransactionDetails.fields.networkTransactionLink'),
      item: transaction && (
        <TextLink to={getTransactionDetailsUrl(transaction.id, transaction.type)} isExternal>
          {shortenAddress(transaction.id)}
        </TextLink>
      ),
    },
  ].map(field => ({ ...field, className: 'py-3.5' }));

  return (
    <div className="h-full flex flex-col w-full gap-6 pb-8">
      <SideContentHeader title={t('TransactionDetails.title')} onGoBack={onGoBack} alignment="left" />
      <div className="overflow-y-auto hide-scrollbars flex flex-col gap-6 h-full">
        {Loader.useWrap(transaction$)
          .match.loadingOrSkipped(() => (
            <LabelItemList
              contentClassname="!gap-0"
              isLoading
              labelItems={fields.map(field => ({
                ...field,
                className: '!py-4',
              }))}
            />
          ))
          .error(() => (
            <Alert
              title={t('TransactionDetails.error.title')}
              content={t('TransactionDetails.error.description')}
              variant="error"
              bottom={
                <Button label={t('TransactionDetails.error.retry')} onClick={onRetry} size="s" variant="surface" />
              }
            />
          ))
          .ok(() => (
            <LabelItemList
              contentClassname="!gap-0"
              labelItems={fields.map(field => ({ ...field, className: '!py-4 text-base' }))}
            />
          ))}
      </div>
    </div>
  );
}
