import cls from 'classnames';
import { useEffect, useRef } from 'react';
import { match } from 'ts-pattern';

import { CheckIcon, DashIcon } from '@ui-kit/Icons';

export interface ICheckbox {
  className?: string;
  indeterminate?: boolean;
  checked?: boolean;
  disabled?: boolean;
  onCheck?: VoidFunction;
}

export const Checkbox = ({ className, checked = false, indeterminate = false, disabled, onCheck }: ICheckbox) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current?.indeterminate) {
      inputRef.current.indeterminate = indeterminate;
    }
  }, [inputRef, indeterminate]);

  const statusClassName = match({
    d: disabled,
    c: checked,
    i: indeterminate,
  })
    .when(
      ({ d }) => !!d,
      () => 'bg-surface-disabled text-font-disabled',
    )
    .when(
      ({ c, i }) => c || i,
      () => 'bg-accent hover:bg-accent-hover-font-on-accent text-font cursor-pointer',
    )
    .otherwise(() => 'bg-transparent hover:bg-hover-accent border border-font-variant cursor-pointer');

  return (
    <div
      className={cls('relative flex justify-center items-center w-6 h-6 rounded', statusClassName, className)}
      onClick={() => inputRef.current?.onclick}
    >
      <input
        ref={inputRef}
        checked={checked}
        readOnly={!onCheck}
        onClick={onCheck}
        type="checkbox"
        className={cls('w-full h-full opacity-0 absolute', { 'cursor-pointer': !disabled })}
      />
      {indeterminate ? <DashIcon className={cls('w-3 text-font-on-accent', { '!text-font-disabled': disabled })} /> :
        checked && <CheckIcon className={cls('w-3 text-font-on-accent', { '!text-font-disabled': disabled })} />}
    </div>
  );
};
