import cls from 'classnames';
import { createContext, useEffect, useState, Dispatch, SetStateAction } from 'react';
import { atom, atomFamily, useRecoilState } from 'recoil';
import { getRecoil, setRecoil } from 'recoil-nexus';

import { useTransactionFlow } from '@transactions/hooks/useTransactionFlow';
import { last, nullish, Cls, Elt } from '@utils';
import { theme } from 'theme';

import {
  SideContentBuilderOptionalProps,
  SideContentComp,
  SideContentOp,
  SideContentScreen,
  SideContentConfirmContent,
  SideContentOpener,
  SideContentBuilder,
  SideContentProps,
  UiConfig,
  SideContentPosition,
} from './SideContent.types';
import { SideContentConfirmBeforeClosing } from './SideContentConfirmBeforeClosing';

const ANIMATION_DURATION = 150; // see classnames in TransitionContainer
let idGen = 1;

export const currentOpenSideContentAtom = atom<string>({
  key: 'currentOpenSideContent',
  default: '',
});

const screensAtomFamily = atomFamily<readonly SideContentScreen[], string>({
  key: 'SideContentScreens',
  default: [],
});

const sideContentOpsAtomFamily = atomFamily<SideContentOp[], string>({
  key: 'SideContentOpsQueue',
  default: [],
});

export function makeNewContentUI(name: SideContentPosition) {
  class SideContentBuilderImpl<result, props> implements SideContentBuilderOptionalProps<props, result> {
    private op: Omit<SideContentOp, 'resolve' | 'reject'>;

    constructor(componentOrOp: SideContentComp<result> | SideContentOp) {
      this.op = typeof componentOrOp === 'function' ? { component: componentOrOp } : componentOrOp;
    }

    withProps(values: props): SideContentOpener<result> {
      this.op.props = values;
      return this;
    }

    forbidNoResult(): SideContentOpener<Exclude<result, nil>> {
      this.op.forbidNoResult = true;
      return this as any;
    }

    withConfig(config: UiConfig): SideContentOpener<result> {
      this.op.config = config;
      return this;
    }

    closeOthers(): SideContentOpener<result> {
      this.op.closeOthers = true;
      return this;
    }

    /** Prevents other sideContents to be opened on top of this one */
    preventOthers(): SideContentOpener<result> {
      this.op.preventOthers = true;
      return this;
    }

    open() {
      pushSideContentOp({
        ...this.op,
        $$typeof: Symbol('SideContentApi'),
      });
    }

    openWithResult() {
      // eslint-disable-next-line no-promise-executor-return
      return new Promise<any>((resolve, reject) =>
        pushSideContentOp({
          ...this.op,
          resolve,
          reject,
          $$typeof: Symbol('SideContentApi'),
        }),
      );
    }
  }

  const sideContentOpsAtom = sideContentOpsAtomFamily(name);

  function pushSideContentOp(op: SideContentOp) {
    setRecoil(sideContentOpsAtom, v => [...v, op]);
    setRecoil(currentOpenSideContentAtom, sideContentOpsAtom.key);
  }
  const screensAtom = screensAtomFamily(name);

  const SideContentPositionContext = createContext<'left' | 'center' | 'right'>('center');

  /**
   * Presents a confirm screen on top of the current sideContent screens stack
   * and **returns the bool result of the confirm screen** like so:
   * * `true`: the user confirmed they wanted to close the whole sideContent
   * * `false`: the user preferred to return to the previous screen
   */
  function getConfirmScreenResult(
    customContent?: SideContentConfirmContent,
    screen?: SideContentScreen | nil,
  ): Promise<boolean> {
    return (screen?.props.pushSubmenu ?? makeSideContent)(SideContentConfirmBeforeClosing)
      .withProps({ customContent })
      .forbidNoResult()
      .openWithResult();
  }

  /**
   * Open a sideContent component.
   *
   * Will return a fluent builder that has the following methods:
   *    .withProps() => specifies your component properties
   *    .forbidNoResult() => will force the sideContent to stay open until the component gives a non nil result
   *    .noCloseOnFocusLost() => will prevent the sideContent to be closed when losing focus
   *
   * Just await the .open() method when you have configured it to open the sideContent and get the result.
   *
   * Example:
   *
   *
      function MySideContentComponent(props: { myProp: number } & SideContentMenuProps<string>) {
        write your sideContent component here
      }

     const result = await openSideContent(MySideContentComponent)
                   .withProps({ myProp: 42 })
                   .noCloseOnFocusLost()
                   .open();
   */
  function makeSideContent<tcomp>(component: tcomp): SideContentBuilder<tcomp> {
    return new SideContentBuilderImpl(component as any) as any;
  }

  function SideContentUI({ NoScreenFallback, className }: { NoScreenFallback?: Elt; children?: Elt } & Cls) {
    const [opsQueue, setOpsQueue] = useRecoilState(sideContentOpsAtom);
    const [screenCount, setScreenCount] = useState(0);
    const [screens, setScreens] = useRecoilState(screensAtom);
    const { focusFlowTrigger } = useTransactionFlow();

    const currentOp = last(screens);
    const curIndex = screenCount - 1;

    const fullSize = currentOp?.op.config?.fullSize;

    // TODO REMOVE THIS AND FIND A WAY TO CLOSE ALL FULL SIZED SIDE CONTENT WHEN CLICK A FLOW BUTTON
    // (ie: Swap button on the overview)
    useEffect(() => {
      if (focusFlowTrigger) {
        for (const screen of screens) {
          if (screen.op.config?.fullSize) {
            screen.props.setResult(null, { displayConfirms: false });
          }
        }
      }
    }, [focusFlowTrigger]);

    // when a new sideContent is open, then push screen on top of current ones
    useEffect(() => {
      if (!opsQueue.length) {
        return;
      }
      if (currentOp?.op.preventOthers) {
        // ignore sideContent request if the current screen locks other sideContents
        for (const o of opsQueue) {
          o.reject?.('Cannot open another sideContent');
        }
        setOpsQueue([]);
        return;
      }

      const newScreens = opsQueue.map<SideContentScreen>(op =>
        buildRootSideContentScreen(op, setScreenCount, setScreens),
      );

      if (last(newScreens)?.op.closeOthers) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        closeAll(undefined, undefined, true);
      }
      setScreens(s => [...s, ...newScreens]);
      setScreenCount(c => c + newScreens.length);
      setOpsQueue([]); // empty queue
    }, [opsQueue]);

    const closeAll = async (fromScreen?: SideContentScreen, fromScreenResult?: any, displayConfirms?: boolean) => {
      // get last up-to-date value, since this "closeAll" function
      //  might have been embbeded in another function that will be called later
      //    ex: when passing "closeAll" to be used in a new sideContent screen
      const curScreens = getRecoil(screensAtom);
      const confirmations = curScreens.filter(s => !!s.op.confirmContent);

      // if any off the screens needs a confirmation, then ask for it
      if (displayConfirms === true && confirmations.length > 0) {
        const lastOp = last(confirmations)?.op;
        const lastScreen = last(curScreens);
        if (lastScreen?.op.component === SideContentConfirmBeforeClosing) {
          return false;
        }
        const customConfirmContent = lastOp?.confirmContent;
        // user confirmed they want to close all
        if (await getConfirmScreenResult(customConfirmContent, lastScreen)) {
          return doCloseAll();
        }
        // user cancelled closing
        return false;
      }

      return doCloseAll();

      async function doCloseAll() {
        // close all current sideContent operations, one by one (from newly opened to oldest),
        //  ... unless one is blocked by a no-null-result constraint
        for (const s of [...curScreens].reverse()) {
          // stop closing if the current screen says so and it is not the one that triggered the closeAll
          if (s.op.shouldStopCloseAll === true && s !== fromScreen) {
            return false;
          }
          if (fromScreen === s) {
            // eslint-disable-next-line no-await-in-loop
            if (!(await s.props.setResult(fromScreenResult, { displayConfirms: false }))) {
              return false;
            }
          } else {
            if (s.op.forbidNoResult) {
              // yup, this current stack of menus is blocked by a screen that does not allow to be closed
              // => stop here the close-all.
              return false;
            }
            // eslint-disable-next-line no-await-in-loop
            await s.props.setResult(null, { displayConfirms: false });
          }
        }
        return true;
      }
    };
    const spacing = theme.spacing;
    const fullHeight = fullSize ? `calc(100svh - ${spacing[6] * 2}px)` : '';

    return (
      <div
        id={sideContentOpsAtom.key}
        data-cy={sideContentOpsAtom.key}
        className={cls(
          // Stop adding overflow-hidden here, it breaks the other SideContentUis
          'relative',
          'bg-surface rounded-2xl p-4',
          className,
        )}
        onWheel={e => e.stopPropagation()}
      >
        <div className={cls('w-full', !screenCount ? '' : 'hidden')}>{NoScreenFallback}</div>
        <div>
          {screens?.map((s, i) => {
            const { op, id, props } = s;
            const Component = op.component;
            // eslint-disable-next-line no-nested-ternary
            const position = i === curIndex ? 'center' : i > curIndex ? 'right' : 'left';

            return (
              <SideContentPositionContext.Provider value={position} key={id}>
                <>
                  {/* // TODO(Hadrien) : Handle animations when stacking screens instead of display: none */}
                  <div
                    className="flex flex-col"
                    style={{ minHeight: fullHeight, display: position !== 'center' ? 'none' : undefined }}
                  >
                    <Component
                      {...props}
                      closeAll={(myResult, options) => closeAll(s, myResult, options?.displayConfirms === true)}
                    />
                  </div>
                </>
              </SideContentPositionContext.Provider>
            );
          })}
        </div>
      </div>
    );
  }

  /**
   * Builds a state that represents a sideContent screen
   *  ⚠️⚠️  All functions stored in properties are persisted => DO PASS CONTEXTUAL DATA in this function (only setters)
   */
  function buildRootSideContentScreen(
    op: SideContentOp,
    setScreenCount: Dispatch<SetStateAction<number>>,
    setScreens: Dispatch<SetStateAction<readonly SideContentScreen[]>>,
  ): SideContentScreen {
    const id = sideContentOpsAtom.key + ++idGen;
    let closed = false;

    const props: Omit<SideContentProps<any>, 'closeAll'> = {
      position: 'center',
      pushSubmenu: (comp: any) => {
        return new SideContentBuilderImpl({
          ...op,
          component: comp,
          // give some context to child about who pushed this screen
          ancestors: {
            root: op.ancestors?.root ?? id,
            parent: id,
          },
        }) as any;
      },

      confirmBeforeClosingWithoutResult(content) {
        // eslint-disable-next-line no-param-reassign
        op.confirmContent = content;
      },

      stopCloseAllHere() {
        // eslint-disable-next-line no-param-reassign
        op.shouldStopCloseAll = true;
      },

      setResult: async (result, { displayConfirms } = {}) => {
        if (closed) {
          return true;
        }
        if (nullish(result) && op.forbidNoResult) {
          return false;
        }
        if (displayConfirms && nullish(result) && op.confirmContent) {
          if (op.component === SideContentConfirmBeforeClosing) {
            return false;
          }
          const shouldClose = await getConfirmScreenResult(op.confirmContent);
          return shouldClose ? doClose() : false;
        }
        return doClose();

        function doClose() {
          closed = true;
          op.resolve?.(result);
          // make this screen disappear, but only after the out animation has finished
          setScreenCount(c => c - 1);
          setTimeout(() => setScreens(s => s.filter(x => x !== thisScreen)), ANIMATION_DURATION);
          return true;
        }
      },
    };
    const thisScreen: SideContentScreen = {
      id,
      op,
      props: {
        // aggregate sideContent props & props specified by .withProps() when opening the sideContent
        ...op.props,
        ...props,
      },
    };
    return thisScreen;
  }

  return {
    UI: SideContentUI,
    make: makeSideContent,
    // @deprecated This is only used is dev because Hot Reload injects SideContents multiple times
    clear: () => {
      setRecoil(screensAtom, []);
      setRecoil(sideContentOpsAtom, []);
    },
  };
}
