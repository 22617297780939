import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { match } from 'ts-pattern';

import { useMainVault, refreshMainVaultData } from '@hooks/useMainVault';
import { useNativeTokenInfo, useTokenInfo, useTokensInfo } from '@hooks/useTokensInfo';
import { AggregatorRequest } from '@tetris/dexes/dex-aggregator-types';
import { PreparedMultiSwap, useMultiSwap } from '@tetris/swaps';
import { useCreateOrCallVault } from '@tetris/tx';
import { SendTxButton } from '@transactions/components/SendTxButton';
import TransactionRecap, { MultipleTxData } from '@transactions/components/TransactionRecap';
import IncentiveMessage from '@transactions/components/ui/IncentiveMessage';
import { useChangeCoin } from '@transactions/hooks/useChangeCoin';
import { useTransactionFlow } from '@transactions/hooks/useTransactionFlow';
import { TransactionFlowType, UnfairLevel } from '@transactions/types';
import { getUnfairLevelForPriceDiff, computePriceDiff, getBudgetUSDvalue } from '@transactions/utils';
import { Button } from '@ui-kit/atoms/Button';
import { Divider } from '@ui-kit/atoms/Divider';
import { TxType, TxSpeed } from '@ui-kit/organisms/SingleTxReviewContent';
import {
  ICoin,
  Loader,
  NATIVE_TOKENS,
  chainOf,
  encodeAddress,
  getDefaultTokenForChain,
  parseNum,
  useTokenQuote,
  useTokensQuotes,
  withoutChain,
  wrapToken,
} from '@utils';
import { useWallet } from '@wallet';

import MultiSellUI from './MultiSellUI';
import { MultiSellState, defaultMultiSellState, MultiSellAction, multiSellReducer } from './multiSell.reducer';
import { getTargetBudgetEstimate } from './multiSell.utils';
import { getTransactionRecap } from '../../swap/swapFlow.utils';

export default function MultiSellFlow() {
  const { t } = useTranslation();
  const { currentChain, settings, setCurrentFlow, resetScroll } = useTransactionFlow();
  const [refresh, setRefresh] = useState(false);
  const nativeToken$ = useNativeTokenInfo(false).mapNotLoaded('skipped', () => ({
    id: getDefaultTokenForChain(currentChain),
  }));
  const wallet$ = useWallet(false);
  const login = wallet$.makeCallback(({ login: l }) => l());

  const [state$, dispatch] = nativeToken$
    .mapNotLoaded('skipped', () => ({
      id: getDefaultTokenForChain(currentChain),
    }))
    .map<MultiSellState>(({ id }) => ({
    ...defaultMultiSellState,
    targetTokenId: id,
  }))
    .noFlickering()
    .asReducer<MultiSellAction>(multiSellReducer);

  useEffect(() => {
    if (currentChain && NATIVE_TOKENS[currentChain]) {
      dispatch({
        type: 'SET_DEFAULT',
        payload: {
          targetTokenId: NATIVE_TOKENS[currentChain]!,
        },
      });
    }
    // the dispatch of asReducer changes... we should reimplement asReducer to have a stable dispatch function
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentChain, dispatch]);

  const holdings$ = useMainVault(false, false)
    .map(vault => {
      return vault?.spot || [];
    })
    .mapNotLoaded('skipped', () => []);

  const inputTokensArray$ = state$.map(s => Array.from(s.inputTokenIds));
  const inputTokensInfos$ = useTokensInfo(inputTokensArray$).noFlickering();
  const inpuTokensQuotes$ = useTokensQuotes(inputTokensArray$).noFlickering();
  const inputBudgets$ = Loader.array([
    inputTokensInfos$,
    inpuTokensQuotes$,
    state$.map(s => s.inputTokenAmounts),
  ] as const).map(([tokensInfo, quotes, amounts]) => {
    return tokensInfo.map(tokenInfo => ({
      token: tokenInfo,
      amtBase: amounts[tokenInfo.id]?.value || 0n,
      quote: quotes.get(tokenInfo.id as ChainAddress) || 0,
    }));
  });

  const selectedCoins = Loader.array([state$, inputTokensInfos$] as const)
    // issuewith Set() type, does not trigger update for .ok(), we need to use Array.from()
    .map<[ChainAddress, ICoin][]>(([s, _outputTokenInfo]) =>
    Array.from(s.inputTokenIds).map(id => {
      return [id, _outputTokenInfo.find(_t => _t.id === id) as ICoin];
    }),
  )
    .match.notOk(() => new Map<ChainAddress, ICoin>())
    .ok(v => new Map<ChainAddress, ICoin>(v));

  const selectMultiCoin = useChangeCoin(true, {
    currentSelectedCoins: selectedCoins,
    onlyOwned: true,
    disabledCoins: state$
      .map(s => s.targetTokenId)
      .match.notOk<{ [key: ChainAddress]: string }>(() => ({}))
      .ok<{ [key: ChainAddress]: string }>(v => (v ? { [v]: '' } : {})),
  });
  const selectCoin = useChangeCoin(false, {
    disabledCoins: inputTokensArray$
      .map(s => s.reduce((acc, key) => ({ ...acc, [key]: '' }), {} as { [key: ChainAddress]: string }))
      .unwrapOr<any>({}),
  });

  const inputTokensHoldings$ = holdings$
    .combine(inputTokensArray$, (holdings, tokenIds) => {
      return tokenIds.reduce((acc, tokenId) => {
        const holding = holdings.find(h => h.token.id === tokenId);
        if (holding) {
          acc[tokenId] = parseNum(holding.qty);
        }
        return acc;
      }, {} as Record<ChainAddress, bigint>);
    })
    .mapNotLoaded<Record<ChainAddress, bigint>>('skipped', () => ({}));

  const inputHints$ = Loader.array([inputBudgets$, inputTokensHoldings$] as const).map(
    ([inputBudgets, inputTokensHoldings]) => {
      return inputBudgets.reduce(
        (acc: Record<ChainAddress, { usdValue: number } | { errorMessage: string }>, _budget) => {
          const holding = inputTokensHoldings[_budget.token.id];
          const insufficientBalance = holding && holding < _budget.amtBase;
          if (insufficientBalance) {
            acc[_budget.token.id] = { errorMessage: t('Transactions.Recap.insufficientBalance') };
          } else {
            acc[_budget.token.id] = {
              usdValue: getBudgetUSDvalue(_budget),
            };
          }
          return acc;
        },
        {},
      );
    },
  );

  const targetTokenInfos$ = useTokenInfo(state$.map(s => s.targetTokenId)).noFlickering();
  const targetTokenQuote$ = useTokenQuote(state$.map(s => s.targetTokenId)).noFlickering();

  const targetHoldings$ = holdings$.combine(
    state$.map(s => s.targetTokenId),
    (holdings, targetTokenId) => {
      const holding = holdings.find(h => h.token.id === targetTokenId);
      return holding ? parseNum(holding.qty) : 0n;
    },
  );

  const isConfirmedSelection = state$
    .map(s => s.isSelectionConfirmed)
    .match.notOk(() => false)
    .ok(v => v);

  const vault$ = useMainVault(false, true).mapNotLoaded('skipped', () => null);
  const vaultAddress$ = Loader.array([vault$, targetTokenInfos$] as const).map(([vault, targetTokenInfo]) => {
    if (!vault || !targetTokenInfo) {
      return undefined;
    }
    return encodeAddress(chainOf(targetTokenInfo.id), vault.address);
  });
  const chain$ = targetTokenInfos$.map(x => chainOf(x.id));

  const swapRequests$: Loader<AggregatorRequest[]> = Loader.array([
    inputBudgets$,
    targetTokenInfos$,
    inputTokensHoldings$,
    state$.map(s => s.inputTokenAmounts),
    vaultAddress$,
  ] as const)
    .map(([inputBudgets, targetToken, holdings, amounts, _vaultAddress]) => {
      return inputBudgets.map(_sourceBudget => {
        return {
          spendToken: _sourceBudget.token,
          buyToken: targetToken,
          slippage: settings.slippage,
          spendQty: amounts[_sourceBudget.token.id]?.isMax ? holdings[_sourceBudget.token.id] : _sourceBudget.amtBase,
          // we need it to bypass chain token validation
          vaultAddress: _vaultAddress ?? `${chainOf(_sourceBudget.token.id)}:0x0`,
        };
      });
    })
    .combine(state$, (swapRequests, s) => {
      if (s.isSelectionConfirmed) return swapRequests;
      return Loader.skipped;
    });

  const preparedSwaps$ = useMultiSwap(swapRequests$, refresh);

  const targetBudget$ = Loader.array([
    targetTokenInfos$,
    targetTokenQuote$,
    inputBudgets$.mapNotLoaded('skipped', () => []),
    preparedSwaps$
      .mapNotLoaded<PreparedMultiSwap[]>('skipped', () => [])
      .mapNotLoaded<PreparedMultiSwap[]>('error', () => []),
  ] as const).map(([targetTokenInfo, targetTokenQuote, inputBudgets, _swaps]) => {
    const budget = getTargetBudgetEstimate(inputBudgets, { ...targetTokenInfo, quote: targetTokenQuote });
    if (_swaps && _swaps.length > 0) {
      return {
        ...budget,
        amtBase: _swaps.reduce((acc, s: PreparedMultiSwap) => {
          return acc + parseNum(s.rawResponse.buyAmount);
        }, 0n),
      };
    }
    return budget;
  });

  const targetHint$ = targetBudget$.map(_budget => ({
    usdValue: getBudgetUSDvalue(_budget),
  }));

  const { sendTx: send$, operation } = useCreateOrCallVault(chain$, preparedSwaps$, {
    failedToast: {
      content: t('Transactions.ToastStatus.tradeFailed', {
        inputToken: inputBudgets$.match.notOk(() => '').ok(budgets => budgets.map(b => b.token.symbol).join(', ')),
        outputToken: targetBudget$.match.notOk(() => '').ok(budget => budget.token.symbol),
      }),
    },
  });

  operation.onOk([dispatch], () => {
    dispatch({
      type: 'RESET_FLOW',
    });
    refreshMainVaultData();
  });

  const WarnigMessage = Loader.array([preparedSwaps$, send$] as const)
    .noFlickering()
    .match.loadingOrSkipped(() => null)
    .error(e => {
      if (e.message === 'INSUFFICIENT_ASSET_LIQUIDITY') {
        <IncentiveMessage
          type="error"
          title={t('Transactions.IncentiveMessage.insufficientLiquidityTitle')}
          description={t('Transactions.IncentiveMessage.insufficientLiquidityDescription')}
        />;
      }
      return null;
    })
    .ok(() => null);

  const sendTxButton = Loader.array([inputBudgets$, targetBudget$, preparedSwaps$, send$.map(s => s.sendNext)] as const)
    .match.loadingOrSkipped(() => null)
    .error(e => {
      if (e.message === 'INSUFFICIENT_ASSET_LIQUIDITY') {
        return <Button size="l" label={t('Transactions.Recap.insufficientLiquidity')} fullWidth disabled />;
      }
      return <Button size="l" label={t('Transactions.Recap.insufficientBalance')} fullWidth disabled />;
    })
    .ok(([inputBudgets, targetBudget, _swaps, _onConfirm]) => {
      if (!_swaps) return null;
      const unfairLevel = _swaps
        ? inputBudgets.reduce((acc, b) => {
          const swapResponse = _swaps.find(
            s => s.rawResponse.sellTokenAddress.toLowerCase() === withoutChain(wrapToken(b.token.id)).toLowerCase(),
          );
          if (!swapResponse) return acc;
          return Math.max(
            acc,
            getUnfairLevelForPriceDiff(
              computePriceDiff(Number(swapResponse.rawResponse.price), b.quote, targetBudget.quote),
            ),
          );
        }, 0)
        : 0;

      return (
        <SendTxButton
          sendTx={_onConfirm}
          isLoading={operation.isLoading}
          label={match(unfairLevel)
            .with(UnfairLevel.MEDIUM, () => t('Transactions.Swap.swapAnyway'))
            .otherwise(() => t('Transactions.MultiSwap.confirmMultiSwap'))}
          dataCy="AmountScreen_cta"
          hasMultipleTxs
          txDataList={inputBudgets.map(b => ({
            variant: TxType.swap,
            inputToken: b,
            outputToken: targetBudget,
            fees: { total: 0, paraswap: 0, nested: 0, network: 0 },
            txSpeed: TxSpeed.normal,
            onSelectTxSpeed: () => {},
            onOpenSlippageSettings: () => {},
            rate: 0.03,
            aggregator: _swaps.find(
              s => s.rawResponse.sellTokenAddress.toLowerCase() === withoutChain(wrapToken(b.token.id)).toLowerCase(),
            )!.rawResponse.aggregator,
          }))}
          txSpeed={TxSpeed.normal}
          onSelectTxSpeed={() => {}}
          unfairLevel={unfairLevel}
        />
      );
    });

  const recapData$ = Loader.array([inputBudgets$, targetBudget$, preparedSwaps$, nativeToken$] as const).map(
    ([_inputBudgets, _targetBudget, _swaps, _nativeToken]) => {
      return _swaps
        .map(_swap => {
          const inputBudget = _inputBudgets.find(
            budget => withoutChain(wrapToken(budget.token.id)) === _swap.rawResponse.sellTokenAddress.toLowerCase(),
          );
          if (!inputBudget) return null;
          return {
            sourceSymbol: inputBudget.token.symbol,
            targetSymbol: _targetBudget.token.symbol,
            data: getTransactionRecap(
              inputBudget,
              { ..._targetBudget, amtBase: parseNum(_swap.rawResponse.buyAmount) },
              inputBudget.quote,
              _targetBudget.quote,
              {
                price: Number(_swap.rawResponse.price),
                dex: _swap.rawResponse.aggregator,
              },
              settings.slippage,
            ),
          };
        })
        .filter(Boolean) as MultipleTxData;
    },
  );

  const isConfirmSelectionDisabled = Loader.array([inputBudgets$, inputTokensHoldings$] as const)
    .match.notOk(() => true)
    .ok(
      ([v, holdings]) =>
        v.length === 0 || v.some(b => b.amtBase === 0n) || v.some(b => b.amtBase > holdings[b.token.id]),
    );

  const inputBudgetsWithUnfair$ = Loader.array([
    inputBudgets$,
    targetBudget$.mapNotLoaded('loading', () => null).mapNotLoaded('skipped', () => null),
    preparedSwaps$.mapNotLoaded('loading', () => []).mapNotLoaded('skipped', () => []),
  ] as const).map(([_inputBudgets, _targetBudget, _swaps]) => {
    return _inputBudgets.map(budget => {
      const swapResponse = _swaps.find(
        s => s.rawResponse.sellTokenAddress.toLowerCase() === withoutChain(wrapToken(budget.token.id)).toLowerCase(),
      );
      if (!swapResponse || !_targetBudget) return budget;
      return {
        ...budget,
        isUnfair:
          getUnfairLevelForPriceDiff(
            computePriceDiff(Number(swapResponse.rawResponse.price), budget.quote, _targetBudget.quote),
          ) > UnfairLevel.LOW,
      };
    });
  });

  return (
    <section className="flex flex-col gap-6 max-h-full">
      <MultiSellUI
        inputBudgets={inputBudgetsWithUnfair$.noFlickering()}
        targetBudget={targetBudget$}
        onEditSelection={() =>
          selectMultiCoin(
            s => s && resetScroll() && dispatch({ type: 'SET_INPUT_TOKEN_IDS', payload: new Set(s.keys()) }),
          )
        }
        onBackToSelection={() => dispatch({ type: 'SET_IS_SELECTION_CONFIRMED', payload: false })}
        onSelectTarget={() =>
          selectCoin(
            s => s && resetScroll() && dispatch({ type: 'SET_TARGET_TOKEN_ID', payload: s.id as ChainAddress }),
          )
        }
        isConfirmed={isConfirmedSelection}
        onInputBudgetChange={(budget, isMax) => {
          dispatch({
            type: 'SET_INPUT_TOKEN_AMOUNT',
            payload: { tokenId: budget.token.id, value: budget.amtBase, isMax },
          });
        }}
        onBudgetRemove={b => dispatch({ type: 'REMOVE_TOKEN_ID', payload: b.token.id })}
        holdings={inputTokensHoldings$}
        inputHints={inputHints$}
        targetHint={targetHint$}
        targetHoldings={targetHoldings$}
        onSwitch={() => setCurrentFlow(TransactionFlowType.MULTIBUY)}
      />
      {WarnigMessage}
      {state$.match
        .notOk(() => null)
        .ok(_s =>
          _s.targetTokenId && isConfirmedSelection ? (
            <div className="h-fit">
              <TransactionRecap
                multipleTxs
                txData={recapData$}
                onCountdownComplete={() => setRefresh(r => !r)}
                resetDep={_s.targetTokenId}
              />
            </div>
          ) : null,
        )}
      {isConfirmedSelection
        ? sendTxButton
        : wallet$.match
          .notOk(() => (
            <>
              <Divider />
              <Button label={t('Common.connectWallet')} onClick={login} variant="surface-accent" size="l" fullWidth />
            </>
          ))
          .ok(v => {
            return v.isAuthed ? (
              <>
                <Divider />
                <Button
                  label={t('Transactions.MultiSwap.confirmSelection')}
                  onClick={() => dispatch({ type: 'SET_IS_SELECTION_CONFIRMED', payload: true })}
                  fullWidth
                  size="l"
                  disabled={isConfirmSelectionDisabled}
                />
              </>
            ) : (
              <>
                <Divider />
                <Button
                  label={t('Common.connectWallet')}
                  onClick={login}
                  variant="surface-accent"
                  size="l"
                  fullWidth
                />
              </>
            );
          })}
    </section>
  );
}
