import { useState } from 'react';

import { useNavigationButtons } from '@hooks/useNavigationButtons';
import { DotsIcon, SearchIcon } from '@ui-kit/Icons';
import { Button } from '@ui-kit/atoms/Button';

import { SearchTokensInput } from './search/SearchTokensInput';

type Props = { scrollable?: HTMLDivElement | null; isLoading?: boolean; height?: number };

export function NavigationButtons({ scrollable, isLoading, height }: Props) {
  const navigationButtons = useNavigationButtons(scrollable, isLoading, height);
  const buttonsWithoutMarket = navigationButtons.slice(0, navigationButtons.length - 1);
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  return isSearchOpen ? (
    <SearchTokensInput onCancel={() => setIsSearchOpen(false)} />
  ) : (
    <div className="flex relative gap-2 w-full z-10">
      {buttonsWithoutMarket.map((buttonProps, i) => (
        <Button key={buttonProps.label ?? i} {...buttonProps} />
      ))}
      <div className="group relative hidden max-2xl:flex">
        <Button
          Icon={DotsIcon}
          variant="surface"
          className={
            'group-hover:inline-block after:block ' +
            'after:absolute after:-inset-y-2 after:-inset-x-1 after:bg-transparent'
          }
        />
        <div
          className={
            'hidden group-hover:flex ' +
            'flex-col items-center gap-3 p-3 ' +
            'bg-surface-elevated absolute -right-10 top-12 z-1 rounded-xl'
          }
        >
          {buttonsWithoutMarket.map((buttonProps, i) => {
            const { className, ...rest } = buttonProps;
            return <Button key={buttonProps.label ?? i} {...rest} fullWidth className={rest.hiddenClassName} />;
          })}
        </div>
      </div>
      <Button {...navigationButtons[navigationButtons.length - 1]} />
      <Button Icon={SearchIcon} variant="surface" onClick={() => setIsSearchOpen(true)} />
    </div>
  );
}
