import cls from 'classnames';
import { cloneElement, ReactElement, useCallback } from 'react';
import { createPortal } from 'react-dom';
import { atom, useRecoilState, useSetRecoilState } from 'recoil';

import {
  IconButton,
  SideContentHeader,
  SideContentHeaderProps,
} from '@components/layouts/side-content/SideContentHeader';
import { CloseIcon } from '@ui-kit/Icons';
import { Button } from '@ui-kit/atoms/Button';

export type ModalContentType = {
  modalProps?: {
    closeModal?: VoidFunction;
    rightIcons: SideContentHeaderProps['rightIcons'];
  };
};

interface ModalProps {
  content: ReactElement<ModalContentType>;
  title?: ReactElement<SideContentHeaderProps> | string;
  preventBackdropClose?: boolean;
  containerClassName?: string;
}

const modalContentAtom = atom<ModalProps | null>({
  key: 'modal-content',
  default: null,
});

export function useModal(content: ModalProps) {
  const setModalContent = useSetRecoilState(modalContentAtom);

  return {
    open: () => setModalContent(content),
    close: () => setModalContent(null),
  };
}

export function Modal() {
  const [modalContent, setModalContent] = useRecoilState(modalContentAtom);
  const modalElement = document.getElementById('modal');

  const header: ReactElement<SideContentHeaderProps> | undefined =
    typeof modalContent?.title === 'string' ? <SideContentHeader title={modalContent.title} /> : modalContent?.title;

  const closeModal = useCallback(() => setModalContent(null), [setModalContent]);
  const rightIcons = [<Button key="close" Icon={CloseIcon} onClick={closeModal} />] as [IconButton];

  return (
    <>
      <div id="modal" className="z-20 absolute" />
      {modalContent && modalElement
        ? createPortal(
          <div
            onClick={() => !modalContent.preventBackdropClose && setModalContent(null)}
            className="fixed inset-0 flex items-center justify-center bg-overlay backdrop-blur-[6px]"
          >
            <div
              onClick={event => event.stopPropagation()}
              className={cls(
                'flex flex-col items-center gap-8 p-6 rounded-3xl bg-surface-elevated w-fit min-w-[416px]',
                modalContent.containerClassName,
              )}
            >
              {header && cloneElement(header, { rightIcons })}
              {cloneElement(modalContent.content, {
                modalProps: { closeModal, rightIcons },
              })}
            </div>
          </div>,
          modalElement,
        )
        : null}
    </>
  );
}
