import cls from 'classnames';

import { Badge, IBadge } from './Badge';
import { Skeleton } from './Skeleton';

export interface ITab {
  className?: string;
  tabIndex: number;
  tabId: string;
  label: string;
  isActive: boolean;
  onClick: (tabId: string) => void;
  badgeProps?: IBadge & { isLoading?: boolean };
}

export function Tab({ tabId, tabIndex, label, isActive, onClick, className, badgeProps }: ITab) {
  return (
    <div
      tabIndex={tabIndex}
      className={cls(
        'relative flex flex-col items-center w-min select-none',
        isActive ? 'text-font cursor-default' : 'text-font-variant cursor-pointer',
        className,
      )}
      onClick={() => onClick(tabId)}
    >
      <span className="text-lg font-medium whitespace-nowrap items-center flex gap-2">
        {label}
        {badgeProps &&
          (badgeProps.isLoading ? (
            <Skeleton isRound className="w-4 h-4" />
          ) : (
            <Badge {...badgeProps} className="my-auto" />
          ))}
      </span>
      {isActive && <div className="h-px w-full bg-font rounded-full absolute bottom-[-7px]" />}
    </div>
  );
}
