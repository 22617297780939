import cls from 'classnames';
import { ReactNode } from 'react';

import { Skeleton } from '@ui-kit/atoms/Skeleton';
import { Tooltip } from '@ui-kit/molecules/Tooltip';
import { Children, Cls } from '@utils';

export type ILabelItem = {
  label: string;
  labelClassName?: string;
  tooltipClassName?: string;
  item?: ReactNode;
  vertical?: boolean;
  itemTooltipContent?: string;
  itemClassName?: string;
  isLoading?: boolean;
} & Cls &
Children;

export const LabelItem = ({
  children,
  label,
  item,
  itemTooltipContent,
  vertical,
  className,
  labelClassName,
  tooltipClassName,
  itemClassName,
  isLoading,
}: ILabelItem) => (
  <div className={cls('flex', vertical ? 'flex-col gap-1' : 'flex-row justify-between w-full', className)}>
    {isLoading ? (
      <>
        <Skeleton className="h-4 w-16" />
        <Skeleton className="h-4 w-16" />
      </>
    ) : (
      <>
        <div className="flex flex-row items-center gap-1 text-font-variant font-medium text-base">
          <span className={cls('whitespace-nowrap select-none', labelClassName)}>{label}</span>
          {itemTooltipContent && (
            <Tooltip iconClassName={cls('!w-3.5 !h-3.5', tooltipClassName)} tooltipContent={itemTooltipContent} />
          )}
        </div>
        <span className={itemClassName}>
          {item}
          {children}
        </span>
      </>
    )}
  </div>
);
