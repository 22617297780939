import cls from 'classnames';

import { Cls, Float, Loadable, Loader, getVariationTrend } from '@utils';

import { Skeleton } from './Skeleton';

export type IVariationProps = {
  value: Loadable<VariationValue>;
  shouldDisplayUsd?: boolean;
} & Cls;

export interface VariationValue {
  variation: number;
  usd?: number;
}

export function Variation({ value, shouldDisplayUsd = true, className }: IVariationProps) {
  return Loader.wrap(value)
    .match.loadingOrSkipped(() => <Skeleton />)
    .error(() => <Skeleton noAnimation />)
    .ok(({ variation, usd }) => {
      const { textColor, Icon } = getVariationTrend(variation);
      return (
        <div className={cls('flex items-center font-medium gap-1 text-lg', textColor, className)}>
          <Icon className="w-4 h-4" />
          <Float.Percent value={variation} takeAbsoluteValue />
          {shouldDisplayUsd && typeof usd === 'number' && (
            <span>
              (<Float.UsdPrice value={usd} />)
            </span>
          )}
        </div>
      );
    });
}
