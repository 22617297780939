// ⚠️ NO IMPORTS HERE PLEASE ! (other than type imports)
// ⚠️ this file is reserved to pure-vanilla js utilities with no dependencies
// why no impots ? because this file is used in files like config.ts, and this could cause bad circular references

import type { FC, SVGProps } from 'react';
import { isValidElement } from 'react';
import { XOR } from 'ts-xor';

export type SVGIcon = FC<SVGProps<SVGSVGElement>>;

export interface Ctor<T> extends Function {
  new (...params: any[]): T;
  prototype: T;
}

export type ArgsOf<T> = T extends (...args: infer A) => any ? A : never;

export function parseNum(num: BigNumberish): bigint;
export function parseNum(num: BigNumberish | nil): bigint | nil;
export function parseNum(num: BigNumberish | nil): bigint | nil {
  if (nullish(num)) {
    return num;
  }
  if (typeof num === 'bigint') {
    return num;
  }
  // BigInt() can't parse negative hex numbers  (i.e. things like "-0x123")
  if (typeof num === 'string' && num[0] === '-') {
    return -BigInt(num.slice(1));
  }
  return BigInt(num);
}

export function toHex(num: bigint): HexNumber {
  if (num < 0) {
    return '-' + toHex(-num);
  }
  return '0x' + num.toString(16);
}

export function zero(val: BigNumberish): boolean {
  return !val || !parseNum(val);
}

export function unique<T extends string>(arr: T[]): T[] {
  return Array.from(new Set(arr));
}

export function uniqueBy<T>(arr: T[], predicate: (item: T) => string): T[] {
  const seen = new Set<string>();
  return arr.filter(item => {
    const key = predicate(item);
    if (seen.has(key)) {
      return false;
    }
    seen.add(key);
    return true;
  });
}

export function randomString(length = 5, chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789') {
  let result = '';
  for (let i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return result;
}

export type Lazy<T> = (() => Promise<T>) & { refresh: () => void; cachedValue: T | undefined };

export function lazy<T>(ctor: () => Promise<T>): Lazy<T> {
  let cached: Promise<T>;
  let retreived = false;
  const ret = async () => {
    if (retreived) {
      return cached;
    }
    cached = ctor();
    retreived = true;
    ret.cachedValue = await cached;
    return ret.cachedValue;
  };
  ret.cachedValue = undefined as T | undefined;
  ret.refresh = () => {
    retreived = false;
  };
  return ret;
}
lazy.resolve = <T>(val: T) => lazy(() => Promise.resolve(val));

export type LazySync<T> = () => T;
export function lazySync<T>(ctor: () => T): LazySync<T> {
  let cached: T;
  let retreived = false;
  return () => {
    if (retreived) {
      return cached;
    }
    cached = ctor();
    retreived = true;
    return cached;
  };
}
lazySync.resolve = <T>(val: T) => lazySync(() => val);

export function toMap<k, v>(arr: readonly v[], key: (item: v) => k): Map<k, v>;
export function toMap<i, k, v>(arr: readonly i[], key: (item: i) => k, val: (item: i) => v): Map<k, v>;
export function toMap<i, k, v>(arr: readonly i[], key: (item: i) => k, val?: (item: i) => v): Map<k, v> {
  return new Map(arr.map(item => [key(item), val ? val(item) : (item as any)]));
}

export function groupBy<k, v>(arr: readonly v[], key: (item: v) => k): Map<k, v[]>;
export function groupBy<i, k, v>(arr: readonly i[], key: (item: i) => k, val: (item: i) => v): Map<k, v[]>;
export function groupBy<i, k, v>(arr: readonly i[], key: (item: i) => k, val?: (item: i) => v): Map<k, v[]> {
  const ret = new Map<k, v[]>();
  for (const item of arr) {
    const _key = key(item);
    const _val = val ? val(item) : (item as any);
    let col = ret.get(_key);
    if (!col) {
      ret.set(_key, (col = []));
    }
    col.push(_val);
  }
  return ret;
}

export function sum(arr: number[]) {
  return arr.reduce((acc, cur) => acc + cur, 0);
}

export const toPercentage = (value: number, decimals?: number): number => {
  return decimals ? parseFloat((100 * value).toFixed(decimals)) : Math.round(value * 100);
};

// Take the X (14 by default) first chars of a string and add '...' at the end if necessary
export const truncateFor = (stringToTruncate: string, charNumberToDisplay?: number) =>
  stringToTruncate.length > 14 ? `${stringToTruncate.substring(0, charNumberToDisplay || 14)}...` : stringToTruncate;

// Computes deep equality
export function deepEqual<T>(obj1: T, obj2: T): boolean {
  if (obj1 === obj2) {
    return true;
  }

  if (isValidElement(obj1)) {
    if (isValidElement(obj2)) {
      return obj1.type === obj2.type && deepEqual(obj1.props, obj2.props);
    }
    return false;
  }
  if (isValidElement(obj2)) {
    return false;
  }

  if (isObject(obj1) && isObject(obj2)) {
    if (Object.keys(obj1).length !== Object.keys(obj2).length) {
      return false;
    }
    for (const prop in obj1) {
      if (!deepEqual(obj1[prop], obj2[prop])) {
        return false;
      }
    }
    return true;
  }
  // Private
  function isObject(obj: any): obj is object {
    if (typeof obj === 'object' && obj != null) {
      return true;
    }
    return false;
  }

  return false;
}

export function isValidEnum<T extends Object>(myEnum: T, value: any): value is T[keyof T] {
  return typeof value === 'string' && Object.values(myEnum).includes(value);
}

/** Some dumb function to force typing a raw value as a specific interface
 * ex:  `as<MyInterface>({ some raw def })`
 * has better typing cheching compared to `{ some raw def } as MyInterface`
 */
export function as<T>(value: T): T {
  return value;
}

export type PropsOf<T> = T extends React.ComponentType<infer P> ? P : never;

export function last<T>(array: readonly T[] | nil): T | nil {
  return array?.[array.length - 1];
}

export function notNil<T>(value: (T | nil)[]): Exclude<T, null>[] {
  return (value ?? []).filter(x => !nullish(x)) as any[];
}

export function nullish(value: any): value is nil {
  return value === null || value === undefined;
}

export function unreachable(value: never): void {
  // eslint-disable-next-line no-console
  console.error('Value was supposed to be unreachable', value);
}
export function unreachableError(value: never): Error {
  return new Error('Value was supposed to be unreachable' + JSON.stringify(value));
}

/**
 * Similar to Promise.all(), but limits parallelization to a certain numbe of parallel threads.
 */
export async function parallel<T>(
  concurrent: number,
  collection: Iterable<T>,
  processor: (item: T, i: number) => Promise<any>,
) {
  // queue up simultaneous calls
  const queue: any[] = [];
  const ret = [];
  let i = 0;
  for (const fn of collection) {
    // fire the async function, add its promise to the queue, and remove
    // it from queue when complete
    const p = processor(fn, i++).then(res => {
      queue.splice(queue.indexOf(p), 1);
      return res;
    });
    queue.push(p);
    ret.push(p);
    // if max concurrent, wait for one to finish
    if (queue.length >= concurrent) {
      // eslint-disable-next-line no-await-in-loop
      await Promise.race(queue);
    }
  }
  // wait for the rest of the calls to finish
  await Promise.all(queue);
}

export async function mapParallel<T, V>(
  maxParallels: number,
  array: T[],
  map: (x: T, i: number) => Promise<V>,
): Promise<V[]> {
  const ret = Array<V>(array.length);
  await parallel(maxParallels, array, async (x, i) => {
    ret[i] = await map(x, i);
  });
  return ret;
}

export function sumBn(arr: BigNumberish[]): bigint {
  return arr.reduce<bigint>((acc, cur) => acc + parseNum(cur), 0n);
}

export function capitalize(stringToCapitalize: string | nil) {
  if (!stringToCapitalize) return stringToCapitalize;
  return stringToCapitalize[0].toUpperCase() + stringToCapitalize.slice(1);
}

export function isPromiseLike(value: any): value is PromiseLike<any> {
  return value && typeof value.then === 'function';
}

/**
 * Takes an array of types and allow only a type within this array but not an superset of the types of the array
 * tldr: a XOR with more than two elements
 * @example:
 *  type FooBarBaz = OneOf<[{ foo: string}, {bar: string}, {baz: string}]>
 *  const a = { foo: 'foo' } //ok
 *  const b = { bar: 'bar' } //ok
 *  const c = { foo: 'foo', bar: 'bar' } // not ok
 */
export type OneOf<T extends any[]> = T extends [infer Head, ...infer Tail]
  ? Tail extends []
    ? Head
    : Head extends object
      ? XOR<Head, OneOf<Tail>>
      : Head
  : never;

export const debounce = (func: () => void, delay: number) => {
  let timeoutId: NodeJS.Timeout;
  return () => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(func, delay);
  };
};
