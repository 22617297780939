import cls from 'classnames';
import { useState } from 'react';
import { CircleFlag } from 'react-circle-flags';

import { ITokenAggregatedSummary } from '@gql';
import { Cls, IAsset, IFiat, Loadable, Loader, chainOf, isCoin } from '@utils';
import { networksByChain } from '@utils/networks';

import { BadgedItem, IBadgedItem } from './BadgedItem';
import { Skeleton } from '../atoms/Skeleton';
import { UnknownTokenLogo } from '../atoms/UnknownTokenLogo';

type IAssetLogoProps = {
  asset: Loadable<IAsset | nil>;
  shouldDisplayNetwork?: boolean;
  withBadge?: boolean | Partial<IBadgedItem>;
} & Cls;

type ITokenLogoProps = {
  token: Loadable<IAsset | ITokenAggregatedSummary | nil>;
  shouldDisplayNetwork?: boolean;
  withBadge?: boolean | Partial<IBadgedItem>;
  isDisabled?: boolean;
} & Cls;

export function TokenLogo({
  token,
  shouldDisplayNetwork = true,
  withBadge = true,
  isDisabled = false,
  className,
}: ITokenLogoProps) {
  const [imgIsOnError, setImgIsOnError] = useState(false);

  return Loader.wrap(token)
    .match.loadingOrSkipped(() => <Skeleton className={className} isRound />)
    .error(() => <UnknownTokenLogo className={className} />) // todo error
    .ok(tok => {
      const NetworkLogo = shouldDisplayNetwork ? isCoin(tok) && networksByChain[chainOf(tok.id)].Icon : undefined;
      const image = tok?.logo ? (
        <img
          src={tok?.logo}
          className={cls('w-10 h-10 flex-shrink-0 rounded-full select-none', className)}
          onError={() => setImgIsOnError(true)}
        />
      ) : (
        <UnknownTokenLogo className={className} />
      );

      const securisedImg = imgIsOnError ? <UnknownTokenLogo className={className} /> : image;

      if (!NetworkLogo) {
        return securisedImg;
      }

      const badge = typeof withBadge === 'boolean' ? {} : withBadge;
      return withBadge ? (
        <BadgedItem
          badge={{ content: <NetworkLogo />, style: 'outline' }}
          className={cls('flex-shrink-0', isDisabled && 'opacity-20')}
          {...badge}
        >
          {securisedImg}
        </BadgedItem>
      ) : (
        securisedImg
      );
    });
}

export function FiatLogo({ fiat, className }: { fiat: Loadable<IFiat> } & Cls) {
  return Loader.wrap(fiat)
    .match.loadingOrSkipped(() => <Skeleton className={className} isRound />)
    .error(() => <UnknownTokenLogo className={className} />)
    .ok(_fiat =>
      _fiat.logo ? (
        <CircleFlag
          countryCode={_fiat.logo.toLowerCase()}
          className={cls('w-10 h-10 flex-shrink-0 rounded-full', className)}
        />
      ) : (
        <UnknownTokenLogo className={className} />
      ),
    );
}

export function AssetLogo({ asset, className, ...rest }: IAssetLogoProps) {
  return Loader.wrap(asset)
    .match.loadingOrSkipped(() => <Skeleton className={className} isRound />)
    .error(() => <UnknownTokenLogo className={className} />)
    .ok(_asset => {
      if (!_asset) return <UnknownTokenLogo className={className} />;
      return isCoin(_asset) ? (
        <TokenLogo token={_asset} {...rest} className={className} />
      ) : (
        <FiatLogo fiat={_asset} className={className} />
      );
    });
}
