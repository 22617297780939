import { Chain } from '@gql';

export enum TransactionFlowType {
  SWAP = 'swap',
  DEPOSIT = 'deposit',
  WITHDRAW = 'withdraw',
  LIMIT = 'limit',
  BRIDGE = 'bridge',
  DCA = 'dca',
  CROSS_CHAIN_SWAP = 'crossChainSwap',
  MULTISELL = 'multisell',
  MULTIBUY = 'multibuy',
  STOP_LOSS = 'stopLoss',
}

export type CurrentTransactionFlowState = { showHeader: boolean; currentChain: Chain | nil } & {
  type: TransactionFlowType | nil;
  focusFlowTrigger: number;
  props: TransactionFlowProps[TransactionFlowType];
  settings: Settings;
  animationTrigger: number;
};

export type TransactionFlowProps = {
  [TransactionFlowType.SWAP]: SwapFlowProps;
  [TransactionFlowType.DEPOSIT]: DepositFlowProps;
  [TransactionFlowType.WITHDRAW]: WithdrawFlowProps;
  [TransactionFlowType.LIMIT]: LimitOrdersProps;
  [TransactionFlowType.MULTISELL]: {};
  [TransactionFlowType.MULTIBUY]: {};
  [TransactionFlowType.DCA]: DcaProps;
  [TransactionFlowType.BRIDGE]: BridgeFlowProps;
  [TransactionFlowType.CROSS_CHAIN_SWAP]: {};
  [TransactionFlowType.STOP_LOSS]: {};
};

export type SwapFlowProps = {
  prefillInputTokenId?: ChainAddress;
  prefillOutputTokenId?: ChainAddress;
  prefillInputAmount?: bigint;
};

export type DepositFlowProps = {
  prefillInputTokenId?: ChainAddress | ChainAddress[];
  prefillInputAmount?: bigint;
};

export type WithdrawFlowProps = {
  prefillInputTokenId?: ChainAddress;
  prefillInputAmount?: bigint;
};

export type LimitOrdersProps = {
  prefillInputTokenId?: ChainAddress;
  prefillOutputTokenId?: ChainAddress;
};

export type DcaProps = {
  prefillInputTokenId?: ChainAddress;
  prefillOutputTokenId?: ChainAddress;
};

export type BridgeFlowProps = {
  prefillInputTokenId?: ChainAddress;
  prefillOutputTokenId?: ChainAddress;
  prefillInputAmount?: bigint;
};

export enum UnfairLevel {
  LOW = 0,
  MEDIUM = 1,
  HIGH = 2,
}

export type Settings = {
  slippage: number;
};
