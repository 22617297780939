import { ReactNode, useMemo } from 'react';

import { Loadable, Loader } from '@utils';

/**
 * A little HOC to wrap a component accepting props into a component accepting Loadable props.
 *
 * @param Component
 * @returns a component accepting Loadable props
 */
export function wrapComponent<T, K extends keyof T>(Component: React.FC<T>, loadableProps: K[]) {
  const loadablePropsSet = new Set<K>(loadableProps as any);

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const LoadableComponent: React.FC<
  { [k in keyof T]: k extends K ? Loadable<T[k]> : T[k] } & {
    onLoad: () => ReactNode;
    onError: (e: Error) => ReactNode;
    onSkip?: () => ReactNode;
  }
  > = ({ onLoad, onError, onSkip, ...props }) => {
    const entries = loadableProps
      ? Object.entries(props).filter(([key]) => loadablePropsSet.has(key as any))
      : Object.entries(props);
    const loadersEntries = useMemo(() => entries.map(([k, v]) => [k, Loader.wrap(v)]), [entries]);
    return Loader.array(loadersEntries.map(([_, v]) => v))
      .match.skipped(() => onSkip?.() || onLoad())
      .loading(() => onLoad())
      .error(onError)
      .ok<any>(_values => {
      const _props = Object.fromEntries(_values.map((v, i) => [loadersEntries[i][0], v]));
      return <Component {...props} {...(_props as any)} />;
    });
  };

  return LoadableComponent;
}
