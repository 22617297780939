import cls from 'classnames';
import React, { ReactElement } from 'react';
import { match } from 'ts-pattern';

import { IEntryList } from '@components/entries/EntryList';
import { ButtonVariant, IButton } from '@ui-kit/atoms/Button';
import { ILabelItemList } from '@ui-kit/organisms/LabelItemList';
import { Children, Cls } from '@utils';

import { UnfairLevel } from '../../types';

export type TransactionRecapUIProps = {
  countdownDurationInMs?: number;
  unfairLevel: UnfairLevel;
  noCountdown?: boolean;
  button?: ReactElement<IButton>;
  content: ReactElement<ILabelItemList> | ReactElement<IEntryList<any>>;
  holdCountdown?: boolean;
  countDownClassName?: string;
} & Cls;

export function TransactionRecapWrapper({
  countdownDuration,
  countDownClassName,
  holdCountdown = false,
  unfairLevel = UnfairLevel.LOW,
  noCountdown = false,
  className,
  children,
}: TransactionRecapWrapperProps) {
  return (
    <div
      className={cls(
        'relative py-6 px-4 z-1 bg-surface-muted flex flex-col gap-6 rounded-2xl overflow-hidden',
        className,
      )}
    >
      <div
        className={cls(
          countDownClassName,
          'h-1 absolute inset-0',
          { 'animate-decrease-width': !holdCountdown && !noCountdown },
          { 'bg-surface-muted': holdCountdown },
          match(unfairLevel)
            .with(UnfairLevel.MEDIUM, () => 'bg-warning')
            .with(UnfairLevel.HIGH, () => 'bg-danger')
            .otherwise(() => 'bg-accent'),
        )}
        style={{ animationDuration: `${countdownDuration}ms` }}
      />
      {children}
    </div>
  );
}

export default function TransactionRecapUI({
  countdownDurationInMs = 5000,
  className,
  unfairLevel = UnfairLevel.LOW,
  noCountdown = false,
  button,
  content,
  holdCountdown = false,
  countDownClassName,
}: TransactionRecapUIProps) {
  return (
    <TransactionRecapWrapper
      countdownDuration={countdownDurationInMs}
      countDownClassName={countDownClassName}
      unfairLevel={unfairLevel}
      className={cls(className, 'w-full')}
      noCountdown={noCountdown}
      holdCountdown={holdCountdown}
    >
      {content}
      {button &&
        React.cloneElement(button, {
          variant: match(unfairLevel)
            .with(0, () => 'accent')
            .with(1, () => 'warning')
            .with(2, () => 'danger')
            .exhaustive() as ButtonVariant,
          fullWidth: true,
        })}
    </TransactionRecapWrapper>
  );
}

type TransactionRecapWrapperProps = {
  countdownDuration: number;
  countDownClassName?: string;
  unfairLevel?: UnfairLevel;
  noCountdown?: boolean;
  holdCountdown?: boolean;
} & Cls &
Children;
