import cls from 'classnames';
import { match } from 'ts-pattern';

import { ErrorIcon, InfoIcon, RingIcon, SuccessIcon, WarningIcon } from '@ui-kit/Icons';
import { Cls, SVGIcon } from '@utils';

export enum StatusDisplayTheme {
  INFO,
  SUCCESS,
  WARNING,
  ERROR,
  LOADING,
  ACCENT,
}

export type IStatusDisplay = {
  theme: StatusDisplayTheme;
  Icon?: SVGIcon;
} & Cls;

export function StatusDisplay({ theme, Icon: IconOverride, className }: IStatusDisplay) {
  const { themeCls, Icon: DefaultIcon } = match(theme)
    .with(StatusDisplayTheme.INFO, () => ({ themeCls: 'bg-surface-info text-info', Icon: InfoIcon }))
    .with(StatusDisplayTheme.ACCENT, () => ({ themeCls: 'bg-surface-accent text-accent', Icon: InfoIcon }))
    .with(StatusDisplayTheme.SUCCESS, () => ({
      themeCls: 'bg-surface-success text-success',
      Icon: SuccessIcon,
    }))
    .with(StatusDisplayTheme.WARNING, () => ({
      themeCls: 'bg-surface-warning text-warning',
      Icon: WarningIcon,
    }))
    .with(StatusDisplayTheme.ERROR, () => ({ themeCls: 'bg-surface-danger text-danger', Icon: ErrorIcon }))
    .with(StatusDisplayTheme.LOADING, () => ({ themeCls: 'bg-surface-info text-accent', Icon: RingIcon }))
    .run();
  const sizeCls = 'h-[156px] w-[156px]';

  const Icon = IconOverride || DefaultIcon;

  return (
    <div
      className={cls(
        'flex items-center justify-center rounded-full relative z-10 flex-shrink-0',
        themeCls,
        sizeCls,
        className,
      )}
    >
      <Icon className={theme === StatusDisplayTheme.LOADING ? 'animate-spin' : ''} width={60} height={60} />
      <div className={cls('absolute rounded-full blur-xl left-1.5 top-1.5 -z-10', themeCls, sizeCls)} />
    </div>
  );
}
