import { gql } from '@apollo/client';

export const GET_TRANSACTIONS = gql`
  query GetTransactions($filters: TxFilter, $pagination: PaginationArgs) {
    transactions(filters: $filters, pagination: $pagination) {
      results {
        id
        type
        inputs {
          token {
            id
            aggregatedId
            chain
            symbol
          }
          qty
          quote
          usdValue
        }
        outputs {
          token {
            id
            aggregatedId
            chain
            symbol
          }
          qty
          quote
          usdValue
        }
        txTimestamp
        processedAt
      }
      totalCount
    }
  }
`;

export const GET_TRANSACTION_DETAILS = gql`
  query GetTransaction($hash: TxId!) {
    transaction(hash: $hash) {
      id
      type
      inputs {
        token {
          id
          aggregatedId
          chain
          symbol
          decimals
        }
        qty
        quote
        usdValue
      }
      outputs {
        token {
          id
          aggregatedId
          chain
          symbol
          decimals
        }
        qty
        quote
        usdValue
      }
      txTimestamp
      processedAt
    }
  }
`;
