import { OAuthExtension } from '@magic-ext/oauth';
import { Magic } from 'magic-sdk';

export type MagicLinkLoginParams =
  | { type: 'email'; email: string }
  | { type: 'phone'; phoneNumber: string }
  | { type: 'twitter' }
  | { type: 'google' };

export const magicSdk = new Magic(import.meta.env.VITE_MAGIC_API_KEY || 'pk_live_844EF81BBFE99B0D', {
  extensions: [new OAuthExtension()],
});
