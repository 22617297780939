import resolveConfig from 'tailwindcss/resolveConfig';
import { ThemeConfig } from 'tailwindcss/types/config';

import * as tailwindConfig from 'tailwind.config';

const config = resolveConfig(tailwindConfig);
const tailwindTheme = config.theme as ThemeConfig;
const spacing = tailwindTheme.spacing as { [k: string]: number | string };

if (typeof spacing[1] === 'string') {
  // this is to prevent hot reload from reassigning spacing on each module reload
  Object.keys(spacing).forEach(s => {
    spacing[s] = 16 * parseFloat(`${spacing[s]}`); // 0.25rem * 16 = 4
  });
}

export type Theme = NestedReplace<ThemeConfig, ['spacing'], { [k: string]: number }>;
export const theme = config.theme as Theme;

export const useActiveBreakpoint = () => {
  const sorted = Object.entries(theme.screens);
  sorted.sort((x, y) => parseInt(y[1] as string, 10) - parseInt(x[1] as string, 10));

  const breakpoint = sorted.find(s => window.innerWidth >= parseInt(s[1] as string, 10));

  if (!breakpoint) return 'mb';
  return { breakpoint: breakpoint[0], width: parseInt(breakpoint[1] as string, 10) };
};
