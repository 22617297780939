import { personalSign, decrypt } from 'eth-sig-util';

import { Chain } from '@gql';
import { nullish } from '@utils';
import { withoutChain } from '@utils/utils';

import { fetchEphemeral } from '../apollo';

/**
 * To generate more:
 *
const ethWallet = require('ethereumjs-wallet');
for(let index=0; index < 3; index++) {
    let addressData = ethWallet.default.generate();
    console.log(`Private key = , ${addressData.getPrivateKeyString()}`);
    console.log(`Address = , ${addressData.getAddressString()}`);
}
 => don't forget to declare those users server-side
 */
export const KNOWN_ACCOUNTS = {
  defaultuser: {
    id: 'defaultuser000000000000000',
    address: '0xB98bD7C7f656290071E52D1aA617D9cB4467Fd6D',
    privateKey: 'de926db3012af759b4f24b5a51ef6afa397f04670f634aa4f48d4480417007f3',
  },
  influencer: {
    id: 'influencer0000000000000000',
    address: '0x1448d0a224a347744f0c4645b8409b7ad264520c',
    privateKey: 'fcc11e42d3afb25fe3c40e499ba2973ccd3bc264dc08b525f12fa4343e37883c',
  },
  virgin: {
    id: 'virgin00000000000000000000',
    address: '0x62a6b49cee4c7d03cb074db7943b7277fec81b7d',
    privateKey: '94a863532f057804eeee408475bfad0eee5bf49a7529c48145cc58e0fca611ed',
  },
} as const;

type ProviderSetup = {
  address: string;
  privateKey: string;
  chainId: number;
  debug?: boolean;
  manualConfirmEnable?: boolean;
};

interface Web3Events {
  connect: [];
  disconnect: [];
  accountsChanged: [accounts: string[]];
  chainChanged: [chainId: string];
  networkChanged: [networkId: string];
  close: [];
}

type Web3EventsStore = { [k in keyof Web3Events]: Set<(...args: Web3Events[k]) => void> };

let i = 0;

export interface InstantTxProcessingCfg {
  /** If true, then you'll have to manually trigger transaction processing */
  blockchain?: boolean;
  /** If true, then polling will not be instantly polled in backend on blockchain tx processing */
  polling?: boolean;
}

// eslint-disable-next-line import/prefer-default-export
export class MockProvider {
  private setup: ProviderSetup;

  public isMetaMask = true;

  public ready = true;

  private acceptEnable?: (value: unknown) => void;

  private rejectEnable?: (value: unknown) => void;

  private events: Web3EventsStore = {
    accountsChanged: new Set(),
    networkChanged: new Set(),
    chainChanged: new Set(),
    connect: new Set(),
    disconnect: new Set(),
    close: new Set(),
  };

  mode: InstantTxProcessingCfg = {};

  private _onTx: {
    handler?: ((id: ChainAddress) => void) | nil;
    last?: ChainAddress | nil;
  } = {};

  onTx(): Promise<ChainAddress> {
    return new Promise<ChainAddress>(handler => {
      if (this._onTx.last) {
        handler(this._onTx.last);
        this._onTx.last = null;
      } else {
        this._onTx.handler = handler;
      }
    });
  }

  constructor(setup: ProviderSetup) {
    this.setup = setup;
  }

  // eslint-disable-next-line no-console
  private log = (...args: (any | null)[]) => this.setup.debug && console.log('🦄', ...args);

  get selectedAddress(): string {
    return this.setup.address;
  }

  get networkVersion(): number {
    return this.setup.chainId;
  }

  public chain: Chain = Chain.eth;

  public isMock = true;

  get chainAddress(): ChainAddress {
    return `${this.chain}:${this.selectedAddress.toLowerCase() as HexString}`;
  }

  get chainId(): string {
    return `0x${this.setup.chainId.toString(16)}`;
  }

  blockNumber: HexString = '0xe63d8f';

  answerEnable(acceptance: boolean) {
    if (acceptance) this.acceptEnable!('Accepted');
    else this.rejectEnable!('User rejected');
  }

  async request({ method, params }: any): Promise<any> {
    let paramsDump;
    if (nullish(params)) {
      paramsDump = [];
    } else if (params[Symbol.iterator]) {
      paramsDump = params;
    } else {
      paramsDump = [params];
    }

    try {
      const result = await (() => {
        switch (method) {
          case 'eth_requestAccounts':
          case 'eth_accounts':
            if (this.setup.manualConfirmEnable) {
              return new Promise((resolve, reject) => {
                this.acceptEnable = resolve;
                this.rejectEnable = reject;
              }).then(() => [this.selectedAddress]);
            }
            return Promise.resolve([this.selectedAddress]);

          case 'net_version':
            return Promise.resolve(this.setup.chainId);

          case 'eth_chainId':
            return Promise.resolve(this.chainId);

          case 'personal_sign': {
            const privKey = Buffer.from(this.setup.privateKey, 'hex');

            const signed: string = personalSign(privKey, { data: params[0] });

            return Promise.resolve(signed);
          }
          case 'wallet_switchEthereumChain':
            if (params[0]?.chainId !== '0x1') {
              throw new Error('Mock only allows ETH for now');
            }
            // ignore
            this.emit('connect');
            this.emit('chainChanged', params[0]?.chainId);
            this.emit('networkChanged', params[0]?.chainId);
            this.emit('accountsChanged', [this.setup.address]);
            return Promise.resolve();

          case 'eth_decrypt': {
            const stripped = params[0].substring(2);
            const buff = Buffer.from(stripped, 'hex');
            const data = JSON.parse(buff.toString('utf8'));

            const decrypted: string = decrypt(data, this.setup.privateKey);

            return Promise.resolve(decrypted);
          }
          case 'eth_blockNumber':
            return Promise.resolve(this.blockNumber);
          case 'eth_gasPrice':
            return Promise.resolve(BigInt('50000000000').toString()); // 50 GWEI
          case 'eth_estimateGas':
            // fake gas estimation
            return Promise.resolve('0x432');
          case 'eth_sendTransaction': {
            return fetchEphemeral(
              `/tx?process=${this.mode.blockchain ? 'false' : 'true'}&poll=${
                this.mode.polling ? 'false' : 'true'
              }&chain=${this.chain}`,
              {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(params[0]),
              },
            )
              .then(r => r.json())
              .then(({ id }) => {
                if (this._onTx.handler) {
                  this._onTx.handler(id);
                  this._onTx.handler = null;
                } else {
                  this._onTx.last = id;
                }
                return withoutChain(id);
              });
          }
          case 'eth_getTransactionByHash':
            return fetchEphemeral(`/tx/${this.chain}:${params[0]}`)
              .then(r => r.json())
              .then(x => x.tx);
          case 'eth_getTransactionReceipt':
            return fetchEphemeral(`/tx/${this.chain}:${params[0]}/receipt`)
              .then(r => r.json())
              .then(x => x.receipt);
          default:
            // eslint-disable-next-line prefer-promise-reject-errors
            return Promise.reject(`The method ${method} is not implemented by the mock provider.`);
        }
      })();
      this.log(`${method}(`, ...paramsDump, ') => ', result);
      return result;
    } catch (e) {
      this.log(`${method}(`, ...paramsDump, ') => ', e);
      throw e;
    }
  }

  sendAsync(props: { method: string }, cb: any) {
    this.request(props).then(
      v => cb(null, jsonRpcResp(props, v)),
      e => cb(jsonRpcResp(props, null, e)),
    );
  }

  send(props: string | { method: string }, paramsOrCb: any) {
    const payload =
      typeof props === 'string'
        ? {
          id: '_r' + ++i,
          method: props,
          params: typeof paramsOrCb === 'function' ? undefined : paramsOrCb,
        }
        : props;
    const query = this.request(payload);
    if (typeof paramsOrCb === 'function') {
      query.then(
        v => paramsOrCb(jsonRpcResp(payload, v)),
        e => paramsOrCb(jsonRpcResp(payload, null, e)),
      );
    }
    return query.then(v => {
      return jsonRpcResp(payload, v);
    });
  }

  on(evt: keyof Web3Events, handler: (...args: Web3Events[typeof evt]) => void) {
    // this.log('registering web3 event:', evt);
    this.getEvtCol(evt).add(handler);
  }

  removeListener(evt: keyof Web3Events, handler: (...args: Web3Events[typeof evt]) => void) {
    // this.log('de-registering web3 event:', evt);
    if (!handler) {
      this.getEvtCol(evt).clear();
    } else {
      this.getEvtCol(evt).delete(handler);
    }
  }

  private emit<k extends keyof Web3Events>(evt: k, ...args: Web3Events[k]) {
    setTimeout(() => {
      const col = this.events[evt];
      this.log(`Broadcasting web3 event ${evt} to ${col.size} listeners`);
      for (const h of col) {
        (h as any)(...args);
      }
    }, 1);
  }

  private getEvtCol<k extends keyof Web3EventsStore>(evt: k): Web3EventsStore[k] {
    if (!this.events[evt]) {
      const msg = 'web3 event not supported by mock: ' + evt;
      console.error(msg);
      throw new Error(msg);
    }
    return this.events[evt];
  }

  // eslint-disable-next-line class-methods-use-this
  removeAllListeners() {}
}

// see https://docs.metamask.io/guide/ethereum-provider.html#legacy-methods
function jsonRpcResp(request: any, result: any, error?: any) {
  return {
    id: request.id,
    jsonrpc: '2.0',
    method: request.method,
    ...(!nullish(result) && { result }),
    ...(error && { error }),
  };
}
