import { gql } from '@apollo/client';

import { Chain, GetAggregatedHoldings, GetAggregatedHoldingsVariables, TokenSummaryWithQuote } from '@gql';
import { fetchApolloAsync } from '@utils/apollo-utils';

export const GET_USER_USD_BALANCES = gql`
  query GetUserUsdBalance {
    myUser {
      walletValue
    }
  }
`;

const MyUserBalances = gql`
  fragment IMyUserBalances on MassUser {
    walletBalances(tokens: $tokens, chain: $chain) {
      qty
      qtyNum
      token {
        ...ITokenSummaryWithQuote
        flags
        chain
      }
    }
  }
  ${TokenSummaryWithQuote}
`;

/** Get all your token balances for the provided Chain */
export const GET_USER_BALANCES = gql`
  query GetUserBalances($tokens: [ERC20!], $chain: Chain) {
    myUser {
      ...IMyUserBalances
    }
  }
  ${MyUserBalances}
`;
export const GET_USER_ACCOUNT_INFO = gql`
  query GetUserAccountInfo($tokens: [ERC20!], $chain: Chain) {
    myUser {
      ...IMyUserBalances
      walletValue(chain: $chain)
    }
    mainVault(chain: $chain) {
      curves(timeframe: p1d) {
        totalPerf
      }
    }
  }
  ${MyUserBalances}
`;

/** Get all your token aggregated holdings for the provided Chain */
export const GET_USER_AGGREGATED_HOLDINGS = gql`
  query GetAggregatedHoldings($chain: Chain) {
    mainVault(chain: $chain) {
      spot {
        qtyNum
        qty
        token {
          ...ITokenSummaryWithQuote
        }
      }
    }
  }
  ${TokenSummaryWithQuote}
`;

export const getUserAggregatedHoldings = (chain: Chain | nil) => {
  return fetchApolloAsync<GetAggregatedHoldings, GetAggregatedHoldingsVariables>(GET_USER_AGGREGATED_HOLDINGS, {
    chain,
  });
};
