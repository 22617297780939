import { useRef, KeyboardEvent } from 'react';
import { match } from 'ts-pattern';

export function useNumberInputHandler(textFormatter: (text: string) => string) {
  const inputRef = useRef<HTMLInputElement>(null);

  const handler = (cb: (value: string) => void) => (e: KeyboardEvent) => {
    if (e.metaKey || (e.ctrlKey && !['Backspace', 'Delete'].includes(e.key)) || e.altKey) {
      return;
    }
    if (!inputRef.current) {
      return;
    }

    const key = e.key === ',' ? '.' : e.key;

    // do not listen to changes
    if (key !== 'ArrowLeft' && key !== 'ArrowRight' && key !== 'ArrowUp' && key !== 'ArrowDown') {
      e.preventDefault();
    }

    // prevent non numeric inputs
    if ((key < '0' || key > '9') && key !== '.' && key !== 'Backspace' && key !== 'Delete') {
      return;
    }

    // only one decimal point admitted
    if (key === '.' && inputRef.current.value.includes('.')) {
      return;
    }

    // ======================================================================
    // ======================= HANDLE FORMATTING ===========================
    // ======================================================================

    (() => {
      // find where we were, and where we're going
      const oldText = inputRef.current.value;
      const startSel = inputRef.current.selectionStart ?? oldText.length;
      const endSel = inputRef.current.selectionEnd ?? oldText.length;
      let newText: string;
      if (startSel !== endSel || e.ctrlKey) {
        switch (key) {
          case 'Backspace':
            if (e.ctrlKey) {
              newText = oldText.slice(endSel);
            } else {
              newText = oldText.slice(0, startSel) + oldText.slice(endSel);
            }
            break;
          case 'Delete':
            if (e.ctrlKey) {
              newText = oldText.slice(0, startSel);
            } else {
              newText = oldText.slice(0, startSel) + oldText.slice(endSel);
            }
            break;
          default:
            newText = oldText.slice(0, startSel) + key + oldText.slice(endSel);
            break;
        }
      } else {
        switch (key) {
          case 'Backspace': {
            const diff = oldText[startSel - 1] === ',' ? 2 : 1; // handles thousand formats like 100,000.00
            newText = oldText.slice(0, Math.max(startSel - diff, 0)) + oldText.slice(Math.max(startSel, 0));
            break;
          }
          case 'Delete': {
            const diff = oldText[startSel] === ',' ? 2 : 1;
            newText = oldText.slice(0, startSel) + oldText.slice(startSel + diff);
            break;
          }
          case '.': {
            if (oldText === '') newText = '0.';
            else newText = oldText.slice(0, startSel) + key + oldText.slice(startSel);
            break;
          }
          default:
            if (!oldText.slice(0, startSel).includes('.') && parseFloat(oldText.slice(0, startSel)) === 0)
              newText = key + oldText.slice(startSel);
            else newText = oldText.slice(0, startSel) + key + oldText.slice(startSel);
            break;
        }
      }
      // when not inputing the decimal part (i.e. not finishing by sth like ".00000")
      if (newText && !/\.0*$/.test(newText)) {
        // if we just input a point, but we were left of a point, just override it
        if (key === '.' && oldText[startSel] === '.') {
          e.preventDefault();
          inputRef.current.selectionStart = startSel + 1;
          inputRef.current.selectionEnd = startSel + 1;
          return;
        }
        const slice = [...oldText].slice(0, startSel);
        const notNumbers = slice.filter(x => (x < '0' || x > '9') && x !== '.').length;
        const hadPassedDot = slice.includes('.');
        const oldNums = startSel - notNumbers;
        // format number
        const newStr = textFormatter(newText);
        inputRef.current.value = newStr;

        // compute how much chars the carret has moved
        const carretMove = match(key)
          .with('Backspace', () => -1)
          .with('Delete', () => 0)
          .otherwise(() => 1);

        // compute how many numbers we should now have left of carret
        const newNums = oldNums + carretMove;
        // find the new position at which to put the caret
        let passedNums = 0;
        let i = 0;
        for (i = 0; i < newStr.length; i++) {
          const c = newStr[i];
          if ((c >= '0' && c <= '9') || c === '.') {
            if (passedNums === newNums) {
              break;
            }
            passedNums++;
          }
          if (passedNums === newNums && c === '.' && !hadPassedDot) {
            // stay to the left of the dot
            break;
          }
        }
        inputRef.current.selectionStart = i;
        inputRef.current.selectionEnd = i;
      } else {
        const newStr = textFormatter(newText);
        inputRef.current.value = newStr;
      }
    })();

    cb(inputRef.current.value);
  };

  return [inputRef, handler] as const;
}
