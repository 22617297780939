import cls from 'classnames';

import { TooltipIcon } from '@ui-kit/Icons';
import { Cls, Loadable, Loader, SVGIcon } from '@utils';

import { Skeleton } from './Skeleton';

type ILogo = string | SVGIcon | nil;
export type LogoProps = { src?: Loadable<ILogo> } & Cls;

export const Logo = ({ src, className }: LogoProps) => {
  return Loader.wrap(src)
    .match //
    .loadingOrSkipped(() => <Skeleton className={cls('w-5 h-5', className)} isRound />)
    .error(() => null)
    .ok(loadedLogo => {
      if (!loadedLogo) {
        return (
          <div
            className={cls('w-5 h-5 relative flex justify-center items-center bg-font-variant rounded-full', className)}
          >
            <div className="absolute bg-white w-2/3 h-2/3 rounded-full z-0" />
            <TooltipIcon className={cls('relative w-5 h-5 rounded-full text-font-variant', className)} />
          </div>
        );
      }

      if (typeof loadedLogo === 'string') {
        return <img src={loadedLogo} className={cls('w-5 h-5 rounded-full', className)} />;
      }

      const Icon = loadedLogo;
      return <Icon className={cls('w-5 h-5 rounded-full', className)} />;
    });
};
