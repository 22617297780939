import { gql, ApolloClient } from '@apollo/client';

import { GetTxStatus } from '@gql';
import { delay } from '@utils';

export const TX_PROCESSING_BY_BACK_MINUTES_TIMEOUT_SECS = 3 * 60; // 3 minutes

export interface TxWaiter {
  /** Reminder of the chain address we're waiting for */
  txAddress: ChainAddress;
  /** Will be resolved when our backend has polled the transaction */
  completed: Promise<ChainAddress>;
}
const transactionWaiters = new Map<ChainAddress, TxWaiter>();

export function waitTransactionProcessing(apollo: ApolloClient<any>, txAddress: ChainAddress): TxWaiter {
  if (transactionWaiters.has(txAddress)) {
    return transactionWaiters.get(txAddress)!;
  }
  // TODO(Hadrien/GOlivier) : Handle tx replacement/speed up
  const completed = (async () => {
    let retry = 0;
    // === wait for transaction processing
    // eslint-disable-next-line no-constant-condition
    while (true) {
      if (++retry > TX_PROCESSING_BY_BACK_MINUTES_TIMEOUT_SECS) {
        throw new Error('Transaction processing by our server is taking too long');
      }

      // wait 1s
      // eslint-disable-next-line no-await-in-loop
      await delay(1000);

      // check if processed
      // eslint-disable-next-line no-await-in-loop
      const { data, error } = await apollo.query<GetTxStatus>({
        query: gql`
          query GetTxStatus($tx: TxId!) {
            transaction(hash: $tx) {
              id
            }
          }
        `,
        variables: {
          tx: txAddress,
        },
      });
      if (error) {
        throw new Error('Failed to fetch tx status: ' + error.message);
      }
      if (data?.transaction?.id) {
        return data.transaction.id;
      }
    }
  })();
  const waiter: TxWaiter = { completed, txAddress };
  transactionWaiters.set(txAddress, waiter);
  return waiter;
}
