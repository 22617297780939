import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { match } from 'ts-pattern';

import { BigInputDropdown } from '@components/BigInputDropdown';
import { ITxProcessor } from '@tetris/send';
import { ArrowsButton } from '@transactions/components/ui/ArrowsButton';
import { Button } from '@ui-kit/atoms/Button';
import { Divider } from '@ui-kit/atoms/Divider';
import { Skeleton } from '@ui-kit/atoms/Skeleton';
import { EntryType } from '@ui-kit/organisms/Entry';
import { BudgetWithQuote, ICoin, Loadable, Loader, TokenBudget, convertAmt, safeMult, toNumber } from '@utils';
import { formatTimestamp } from '@utils/dateUtils';

import { TriggerPriceInput } from './TriggerPriceInput';
import { Expiration } from './enum';
import TransactionRecap from '../../TransactionRecap';
import BudgetInputEmptyEntry from '../../ui/BudgetInputEmptyEntry';
import BudgetInputEntry from '../../ui/BudgetInputEntry';

export type limitOrderUIProps = {
  inputBudgetWithQuote: Loadable<BudgetWithQuote<ICoin> | null>;
  outputBudgetWithQuote: Loadable<BudgetWithQuote<ICoin> | null>;
  inputAvailable: Loadable<bigint>;
  expiration: Expiration;
  setInputBudget: Dispatch<SetStateAction<nil | TokenBudget>>;
  setOutputToken: Dispatch<SetStateAction<nil | ICoin>>;
  setTargetPrice: Dispatch<SetStateAction<number>>;
  setExpiration: Dispatch<SetStateAction<Expiration>>;
  onSourceCoinSelect: VoidFunction;
  onTargetCoinSelect: VoidFunction;
  sendTx?: Loader<ITxProcessor>;
  inputRef?: React.RefObject<HTMLInputElement>;
  isOperationLoading?: boolean;
};

const MINIMUM_USD_VALUE = 50;

export function LimitOrdersUI({
  inputBudgetWithQuote,
  outputBudgetWithQuote,
  inputAvailable,
  expiration,
  setExpiration,
  onTargetCoinSelect,
  onSourceCoinSelect,
  setInputBudget,
  setOutputToken,
  setTargetPrice,
  sendTx,
  inputRef,
  isOperationLoading,
}: limitOrderUIProps) {
  const { t } = useTranslation();
  const inputBudgetWithQuote$ = Loader.useWrap(inputBudgetWithQuote).noFlickering();
  const outputBudgetWithQuote$ = Loader.useWrap(outputBudgetWithQuote).noFlickering();
  const inputAvailable$ = Loader.useWrap(inputAvailable).noFlickering();

  const inputOutputWithQuote$ = Loader.array([inputBudgetWithQuote$, outputBudgetWithQuote$] as const);

  const [triggerPriceError, setTriggerPriceError] = useState<string>('');

  const ioQuote$ = inputOutputWithQuote$.map(([inp, out]) => (!inp?.quote || !out?.quote ? 0 : inp.quote / out.quote));

  const marketRate$ = inputOutputWithQuote$.map(([_inputBudget, _outputBudget]) => {
    if (!outputBudgetWithQuote) return { marketRate: [] };
    return {
      marketRate: {
        value: _outputBudget && _inputBudget && _inputBudget.quote / _outputBudget.quote,
        source: _inputBudget?.token,
        target: _outputBudget?.token,
      },
    };
  });
  const inputHint$ = Loader.array([inputBudgetWithQuote$, inputAvailable$] as const).map(
    ([_inputBudgetWithQuote, _inputAvailable]) => {
      const usdValue =
        toNumber(
          safeMult(_inputBudgetWithQuote?.amtBase, _inputBudgetWithQuote?.quote),
          _inputBudgetWithQuote?.token.decimals,
        ) || 0;

      const hasInsufficientUsdValue = usdValue ? usdValue < MINIMUM_USD_VALUE : false;
      const hasInsufficientBalance = _inputBudgetWithQuote?.amtBase
        ? _inputBudgetWithQuote.amtBase > _inputAvailable
        : false;

      return [
        {
          usdValue,
        },
        {
          errorMessage: match({
            _hasInsufficientUsdValue: hasInsufficientUsdValue,
            _hasInsufficientBalance: hasInsufficientBalance,
          })
            .when(
              ({ _hasInsufficientUsdValue }) => _hasInsufficientUsdValue,
              () => t('Common.Errors.valueShouldBeGreaterThan', { value: MINIMUM_USD_VALUE }),
            )
            .when(
              ({ _hasInsufficientBalance }) => _hasInsufficientBalance,
              () => t('Common.Errors.insufficientBalance'),
            )
            .otherwise(() => ''),
        },
      ];
    },
  );

  const inputErrorMessage = inputHint$
    .map(hint => hint[1].errorMessage)
    .match.notOk(() => '')
    .ok(x => x || '');

  const isButtonDisabled = inputOutputWithQuote$.match
    .notOk(() => true)
    .ok(([_inputBudget, _outputBudget]) => {
      if (!_inputBudget?.amtBase || !_outputBudget?.amtBase) return true;
      return (
        _inputBudget.amtBase === 0n || _outputBudget.amtBase === 0n || _inputBudget.token.id === _outputBudget.token.id
      );
    });

  const OutputBudgetEntry = Loader.array([inputBudgetWithQuote$, outputBudgetWithQuote$] as const)
    .match.notOk(status => (
      <Skeleton noAnimation={status === 'error'}>
        <BudgetInputEmptyEntry onSelect={onTargetCoinSelect} disabled label={t('Common.get')} />
      </Skeleton>
    ))
    .ok(([inputBudget, outputBudget]) =>
      outputBudget ? (
        <BudgetInputEntry
          budget={outputBudget}
          hint={marketRate$ as any}
          interactive={false}
          onSelectCoin={onTargetCoinSelect}
          label={t('Common.get')}
          disabled={isOperationLoading}
          disabledSelectToken={isOperationLoading}
          budgetInputClassName={
            !inputBudget?.amtBase || inputBudget.amtBase === 0n ? '!text-font-disabled' : '!text-font'
          }
        />
      ) : (
        <BudgetInputEmptyEntry onSelect={onTargetCoinSelect} label={t('Common.get')} />
      ),
    );

  const InputBudgetEntry = inputBudgetWithQuote$.match
    .notOk(status => (
      <Skeleton noAnimation={status === 'error'} className="!block">
        <BudgetInputEntry
          budget={{ amtBase: 0n, quote: 0, token: emptyCoin }}
          hint={Loader.loading}
          onChange={() => {}}
          onSelectCoin={onSourceCoinSelect}
          label={t('Common.sell')}
        />
      </Skeleton>
    ))
    .ok(inputBudget =>
      inputBudget ? (
        <BudgetInputEntry
          ref={inputRef}
          budget={inputBudget}
          onChange={setInputBudget}
          maxBudget={inputAvailable$}
          hint={inputHint$ as any}
          onSelectCoin={onSourceCoinSelect}
          label={t('Common.sell')}
          disabled={isOperationLoading}
          disabledSelectToken={isOperationLoading}
        />
      ) : (
        <BudgetInputEmptyEntry onSelect={onSourceCoinSelect} />
      ),
    );

  const switchInputAndOutput = inputOutputWithQuote$.makeCallback(([_input, _output]) => {
    setOutputToken(_input?.token);

    if (!_output) {
      setInputBudget(null);
    } else {
      setInputBudget({
        amtBase: convertAmt(
          _input?.amtBase || 0n,
          _input?.token?.decimals || _output.token.decimals,
          _output.token.decimals,
        ),
        token: _output.token,
      });
    }
  });

  const TxRecap = inputOutputWithQuote$.match
    .notOk(() => <Divider className="mt-6" />)
    .ok(([_inputBudget, _outputBudget]) => {
      if (
        !_inputBudget?.amtBase ||
        !_outputBudget?.amtBase ||
        _inputBudget.amtBase === 0n ||
        _outputBudget.amtBase === 0n
      )
        return <Divider className="mt-6" />;

      const timeStampExpiration = fromExpirationToTimestamp(expiration);
      const expirationDate = timeStampExpiration
        ? formatTimestamp(timeStampExpiration)
        : t('Transactions.TxReviewLimit.noExpirationDate');
      return (
        <div className="h-fit mt-6">
          <TransactionRecap
            className="h-fit overflow-hidden"
            noCountdown
            txData={{
              expirationDate,
              aggregator: '1inch',
              massFees: 0,
            }}
          />
        </div>
      );
    });

  return (
    <div className="flex flex-col gap-1 overflow-y-scroll scrollbar max-h-full">
      <div className="!h-24 w-full">{InputBudgetEntry}</div>
      <div className="flex gap-1 h-[74px]">
        <div className="w-[252px] h-full">
          <TriggerPriceInput
            input={inputBudgetWithQuote$}
            output={outputBudgetWithQuote$}
            setTargetPrice={setTargetPrice}
            ioPrice={ioQuote$}
            triggerPriceError={triggerPriceError}
            setTriggerPriceError={setTriggerPriceError}
            isLoading={isOperationLoading}
          />
        </div>
        <div className="w-[112px] h-full">
          <BigInputDropdown
            disabled={isOperationLoading}
            alignment="bottomLeft"
            onSelectEntries={(entries: EntryType[]) => {
              const selectedEntry = entries[0];
              setExpiration(selectedEntry.id as Expiration);
            }}
            defaultSelectedEntriesIds={[expiration]}
            entries={Object.values(Expiration).map(makeEntry)}
            indication={t('Screens.LimitBuySell.expires')}
            containerClassName="!w-full"
            chipItemsClassName="!w-full !justify-between"
          />
        </div>
      </div>
      <div className="flex gap-3 my-2">
        <Divider className="my-auto flex-1" />
        <div className="bg-surface-muted flex items-center justify-center rounded-full w-8 h-8">
          <ArrowsButton onClick={switchInputAndOutput} />
        </div>
        <Divider className="my-auto flex-1" />
      </div>
      <div className="!max-h-24">{OutputBudgetEntry}</div>
      {TxRecap}
      <Button
        label={triggerPriceError || inputErrorMessage || t('Screens.LimitBuySell.placeOrder')}
        disabled={!!triggerPriceError || !!inputErrorMessage || isButtonDisabled}
        fullWidth
        size="l"
        onClick={sendTx?.noFlickering().map(x => x.sendNext)}
        dataCy="place-order-button"
        className="mt-6"
        isLoading={isOperationLoading}
      />
    </div>
  );
}

const makeEntry = (value: Expiration): EntryType => {
  const label = value.charAt(0).toUpperCase() + value.slice(1);
  return {
    id: value,
    selectLabel: label,
    content: {
      top: label,
    },
  };
};

const emptyCoin: ICoin = {
  id: '' as ChainAddress,
  decimals: 0,
  logo: '',
  name: '',
  symbol: '',
};

const fromExpirationToTimestamp = (expiration: Expiration) => {
  const expirationInHour = match(expiration)
    .with(Expiration.Never, () => undefined)
    .with(Expiration.OneDay, () => 24)
    .with(Expiration.OneWeek, () => 7 * 24)
    .with(Expiration.OneMonth, () => 30 * 24)
    .with(Expiration.OneYear, () => 365 * 24)
    .exhaustive();

  return expirationInHour ? Date.now() + expirationInHour * 3600 * 1000 : null;
};
