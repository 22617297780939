import { gql } from '@apollo/client';

export const TokenSummary = gql`
  fragment ITokenSummary on TokenInfo {
    id
    aggregatedId
    decimals
    color
    logo
    status
    name
    symbol
    category
  }
`;

export const TokenSummaryWithQuote = gql`
  fragment ITokenSummaryWithQuote on TokenInfo {
    id
    quote
    ...ITokenSummary
  }
  ${TokenSummary}
`;

export const TokenWithHistory = gql`
  fragment ITokenWithHistory on TokenInfo {
    id
    curves(timeframe: $frame) {
      totalPerf
    }
  }
`;

export const HoldingWithQuote = gql`
  fragment IHoldingWithQuote on TokenQty {
    token {
      id
      chain
      ...ITokenSummaryWithQuote
    }
    qty
  }
  ${TokenSummaryWithQuote}
`;

export const HoldingWithQuoteAndHistory = gql`
  fragment IHoldingWithQuoteAndHistory on TokenQty {
    token {
      id
      chain
      ...ITokenSummaryWithQuote
      ...ITokenWithHistory
    }
    qty
  }
  ${TokenSummaryWithQuote}
  ${TokenWithHistory}
`;

export const GraphHistoryFrame = gql`
  fragment IGraphHistoryFrame on HistoryFrame {
    value
    totalPerf
    graph
    perfGraph
    pnl
  }
`;

export const ChainDataPortfolio = gql`
  fragment IChainDataPortfolio on Vault {
    curves(timeframe: $frame) {
      value
      ...IGraphHistoryFrame
    }
    spot {
      token {
        category
        chain
        flags
        aggregatedId
        ...ITokenSummaryWithQuote
        ...ITokenWithHistory
      }
      qty
    }
  }
  ${GraphHistoryFrame}
  ${TokenSummaryWithQuote}
  ${TokenWithHistory}
`;

export const TokenAggregatedSummary = gql`
  fragment ITokenAggregatedSummary on TokenAggregatedInfo {
    id
    symbol
    name
    logo
    chainIds
  }
`;
