import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';

import { mainRoutes } from '../routes/mainRoutes';
import { IButton } from '../ui-kit/atoms/Button';

export type NavigationButton = IButton & {
  hiddenClassName: string;
};

const overviewPath = mainRoutes.overview.path;
const scrollabledSections = ['section-spot', 'section-perps', 'section-credit', 'section-yield'];

export function useNavigationButtons(scrollable?: HTMLDivElement | null, isLoading?: boolean, height?: number) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname, hash } = useLocation();

  useEffect(() => {
    document.getElementById('section-' + hash.replace('#', ''))?.scrollIntoView();
  }, []);

  useEffect(() => {
    const options = {
      root: scrollable,
      // Intersection area is almost at the center of the page, but triggers directly when touched
      // This handles very small section like empty "Spot" or big ones like Credit
      rootMargin: '-40% 0px -50%',
      threshold: 0,
    };

    const handleIntersection: IntersectionObserverCallback = entries => {
      entries.find(entry => {
        if (!isLoading && entry.isIntersecting && scrollable?.scrollTop !== 0) {
          const sectionId = entry.target.getAttribute('id');
          if (!sectionId) {
            return;
          }

          if (sectionId === 'section-overview') {
            navigate(overviewPath, { replace: true });
            return true;
          }
          if (scrollabledSections.includes(sectionId)) {
            navigate(overviewPath + '#' + sectionId.replace('section-', ''), { replace: true });
            return true;
          }
        }
        return false;
      });
    };
    const observer = new IntersectionObserver(handleIntersection, options);
    const sections = document.querySelectorAll('[id^="section-"]');
    sections.forEach(section => {
      observer.observe(section);
    });
    return () => {
      observer.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, height, scrollable]);

  return [
    {
      id: 'overview',
      label: t('Navigation.overview'),
      path: overviewPath,
      className: 'hidden lg:inline-block',
      hiddenClassName: 'hidden',
    },
    {
      id: 'spot',
      label: t('Navigation.spot'),
      path: overviewPath + '#spot',
      activeVariant: 'lime',
      className: 'hidden lg:inline-block',
      hiddenClassName: 'hidden',
    },
    {
      id: 'perps',
      label: t('Navigation.perps'),
      path: overviewPath + '#perps',
      activeVariant: 'orange',
      className: 'hidden lg:inline-block',
      hiddenClassName: 'hidden',
    },
    {
      id: 'credit',
      label: t('Navigation.credit'),
      path: overviewPath + '#credit',
      activeVariant: 'pink',
      className: 'hidden xl:inline-block',
      hiddenClassName: 'hidden max-xl:!flex',
    },
    {
      id: 'yield',
      label: t('Navigation.yield'),
      path: overviewPath + '#yield',
      activeVariant: 'blue',
      className: 'hidden xl:inline-block',
      hiddenClassName: 'hidden max-xl:!flex',
    },
    {
      id: 'options',
      label: t('Navigation.options'),
      path: overviewPath + '#options',
      activeVariant: 'cyan',
      className: 'hidden 2xl:inline-block',
      hiddenClassName: '2xl:hidden lg:inline-block !flex',
    },
    {
      id: 'stocks',
      label: t('Navigation.stocks'),
      path: overviewPath + '#stocks',
      activeVariant: 'red',
      className: 'hidden 2xl:inline-block',
      hiddenClassName: '2xl:hidden lg:inline-block !flex',
    },
    {
      id: 'nfts',
      label: t('Navigation.nfts'),
      path: overviewPath + '#nfts',
      activeVariant: 'lilac',
      className: 'hidden 2xl:inline-block',
      hiddenClassName: '2xl:hidden lg:inline-block !flex',
    },
    {
      id: 'market',
      label: t('Navigation.market'),
      path: mainRoutes.market.path,
      className: 'flex-1',
      hiddenClassName: '2xl:hidden xl:hidden lg:hidden',
      fullWidth: true,
    },
  ].map(it => {
    const activeVariant = it.activeVariant || 'surface-accent';
    return {
      id: it.id,
      type: 'button',
      label: it.label,
      variant: it.path === pathname + hash ? activeVariant : 'surface',
      onClick: () => {
        navigate(it.path);
        if (it.id && it.id !== 'overview') {
          document.getElementById('section-' + it.id)?.scrollIntoView();
        } else {
          scrollable?.scrollTo(0, 0);
        }
      },
      className: it.className,
      hiddenClassName: it.hiddenClassName,
      fullWidth: it.fullWidth,
    } as NavigationButton;
  }) as NavigationButton[];
}
