import cls from 'classnames';
import { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { match, P } from 'ts-pattern';

// eslint-disable-next-line import/no-restricted-paths
import { DoubleArrowHorizontalIcon, ArrowRightIcon } from '@ui-kit/Icons';
import { Divider } from '@ui-kit/atoms/Divider';
import { Skeleton } from '@ui-kit/atoms/Skeleton';
import { LabelItemList } from '@ui-kit/organisms/LabelItemList';
import { Float, budgetNum, BudgetWithQuote, budgetNumValue, Loadable, Loader, chainOf, Cls } from '@utils';
import { networksByChain } from '@utils/networks';

import { ILabelItem } from './LabelItem';

export enum TxType {
  deposit = 'deposit',
  withdraw = 'withdraw',
  swap = 'swap',
  bridge = 'bridge',
}

export enum TxSpeed {
  slow = 'slow',
  normal = 'normal',
  fast = 'fast',
  instant = 'instant',
}

export type Fees = {
  total: number;
  paraswap?: number;
  nested?: number;
  network?: number;
  bridgeProtocol?: {
    fee: number;
    name: string;
  };
};

export type ISingleTxReviewContent = {
  inputToken: Loadable<BudgetWithQuote>;
  outputToken: Loadable<BudgetWithQuote>;
  fees: Loadable<Fees>;
  txSpeed: TxSpeed;
  onOpenSlippageSettings: VoidFunction;
  onSelectTxSpeed: VoidFunction;
} & (
  | {
    variant: TxType.deposit | TxType.withdraw;
    rate?: undefined;
    aggregator?: undefined;
  }
  | {
    variant?: TxType.swap;
    rate: number;
    aggregator?: Loadable<string>;
  }
  | {
    variant?: TxType.bridge;
    rate: number;
    aggregator?: undefined;
  }
);

export const SingleTxReviewContent = ({
  className,
  variant = TxType.swap,
  rate,
  fees,
  inputToken,
  outputToken,
  txSpeed,
  onOpenSlippageSettings,
  onSelectTxSpeed,
}: ISingleTxReviewContent & Cls) => {
  const { t } = useTranslation();
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [swapConversion, setSwapConversion] = useState<boolean>(false);

  const fees$ = Loader.useWrap(fees);
  const outputToken$ = Loader.useWrap(outputToken);
  const inputToken$ = Loader.useWrap(inputToken);

  const rateOrSummaryItem = match(variant)
    .with(P.union(TxType.deposit, TxType.withdraw), v => ({
      label: t('Transactions.TransactionReview.fromto'),
      itemTooltipContent: 'TODO',
      item: (
        <div className="flex flex-row gap-1 font-medium text-font">
          <Trans
            i18nKey={
              v === TxType.deposit
                ? 'Transactions.TransactionReview.deposit.fromto'
                : 'Transactions.TransactionReview.withdraw.fromto'
            }
            components={{
              wrapper: <span className="text-font-variant" />,
              icon: <ArrowRightIcon width={16} height={16} />,
            }}
          />
        </div>
      ),
    }))
    .with(TxType.bridge, () => ({
      label: t('Transactions.TransactionReview.fromto'),
      itemTooltipContent: 'TODO',
      item: (
        <div className="flex flex-row gap-1 font-medium text-font">
          {inputToken$
            .combine(outputToken$, (_inputToken, _outputToken) => ({ _inputToken, _outputToken }))
            .match.loadingOrSkipped(() => <Skeleton className="w-40 h-4" />)
            .error(() => null)
            .ok(({ _inputToken, _outputToken }) => (
              <>
                <span>{networksByChain[chainOf(_inputToken.token!.id as ChainAddress)].name}</span>
                <ArrowRightIcon width={16} height={16} />
                <span>{networksByChain[chainOf(_outputToken.token!.id as ChainAddress)].name}</span>
              </>
            ))}
        </div>
      ),
    }))
    .with(TxType.swap, () => ({
      label: t('Transactions.TransactionReview.rate'),
      itemTooltipContent: 'TODO',
      item: (
        <div className="flex flex-row items-center gap-1 font-medium text-font">
          {outputToken$.match
            .loadingOrSkipped(() => <Skeleton className="w-40 h-4" />)
            .error(() => <Skeleton className="w-40 h-4" />)
            .ok(_outputToken => (
              <>
                <span className="text-accent cursor-pointer" onClick={onOpenSlippageSettings}>
                  ±&nbsp;
                  <Float.Percent value={rate} />
                </span>
                <Divider direction="vertical" />
                <div
                  className="flex flex-row items-center gap-1 cursor-pointer"
                  onClick={() => setSwapConversion(prev => !prev)}
                >
                  {swapConversion ? (
                    <Float.UsdPrice value={1} />
                  ) : (
                    <Float.Custom value={1} unit={_outputToken.token?.symbol.toUpperCase()} type="amount" />
                  )}
                  <DoubleArrowHorizontalIcon className="w-3 h-3 fill-current text-accent" />
                  {swapConversion ? (
                    <Float.Custom
                      value={1 / _outputToken.quote}
                      unit={_outputToken.token?.symbol.toUpperCase()}
                      type="amount"
                    />
                  ) : (
                    <Float.UsdPrice value={_outputToken.quote} />
                  )}
                </div>
              </>
            ))}
        </div>
      ),
    }))
    .run();

  const paraswapFees = t('Transactions.TransactionReview.fees.paraswap');
  const nestedFees = t('Transactions.TransactionReview.fees.nested');
  const networkFees = t('Transactions.TransactionReview.fees.network');
  const bridgeFees = t('Transactions.TransactionReview.fees.bridge');

  return (
    <div className={cls('flex flex-col gap-10', className)}>
      <div className="flex flex-col justify-center items-center h-[148px]">
        <span className="font-medium text-font-variant text-base">
          {[TxType.deposit, TxType.withdraw].includes(variant)
            ? t('Transactions.TransactionReview.transfer')
            : t('Transactions.TransactionReview.minReceived')}
        </span>
        <div className="flex flex-row items-end gap-1.5" data-cy="SingleTxReview_outputToken">
          {outputToken$.match
            .loadingOrSkipped(() => <Skeleton className="my-9 w-32 h-4" />)
            .error(() => <Skeleton className="my-9 w-32 h-4" />)
            .ok(_outputToken => (
              <>
                <div className="text-6xl text-font font-semibold">
                  <Float.Custom value={budgetNum(_outputToken)} type="qty" />
                </div>
                <span className="font-medium text-font text-base pb-[9px]">{_outputToken.token?.symbol}</span>
              </>
            ))}
        </div>
      </div>
      <div
        className={cls(
          'flex flex-row items-center justify-center gap-1',
          'font-medium text-font-variant text-base text-center',
        )}
      >
        {t('Transactions.TransactionReview.use')}
        {inputToken$.match
          .loadingOrSkipped(() => <Skeleton className="w-20 h-4" />)
          .error(() => <Skeleton className="w-20 h-4" />)
          .ok(_inputToken => (
            <>
              <Float.UsdValue value={budgetNumValue(_inputToken)} />
              <span>
                (<Float.Custom value={budgetNum(_inputToken)} unit={_inputToken.token?.symbol} type="qty" />)
              </span>
            </>
          ))}
      </div>
      <Divider />
      <LabelItemList
        className="text-base"
        hideDividers
        labelItems={[
          rateOrSummaryItem,
          {
            label: t('Transactions.TransactionReview.fees.processing'),
            itemTooltipContent: 'TODO',
            item: (
              <div className="flex flex-row items-center gap-1 font-medium text-font-variant">
                {fees$.match
                  .loadingOrSkipped(() => <Skeleton className="w-40 h-4" />)
                  .error(() => <Skeleton className="w-40 h-4" />)
                  .ok(_fees =>
                    variant === TxType.deposit ? (
                      <span className="text-font">{t('Transactions.TransactionReview.fees.free')}</span>
                    ) : (
                      <>
                        <span onClick={() => setShowDetails(p => !p)} className="text-accent cursor-pointer">
                          {t(`Transactions.TransactionReview.${showDetails ? 'hideDetails' : 'showDetails'}`)}
                        </span>
                        <Divider direction="vertical" />
                        <span>
                          {_fees.total ? '~' : ''}
                          <Float.UsdPrice value={_fees.total} className="text-font" />
                        </span>
                      </>
                    ),
                  )}
              </div>
            ),
          },
          ...(showDetails
            ? [
              fees$.match
                .loadingOrSkipped(() => feesLoading(paraswapFees))
                .error(() => feesLoading(paraswapFees))
                .ok(_fees =>
                  !_fees.paraswap
                    ? { label: 'hide' }
                    : {
                      className: 'pl-6',
                      label: paraswapFees,
                      item: (
                        <div className="flex flex-row items-center text-font-variant font-medium">
                          <Float.Percent value={_fees.paraswap / _fees.total} />
                              &nbsp;~
                          <Float.UsdPrice value={_fees.paraswap} className="text-font" />
                        </div>
                      ),
                    },
                ),
              fees$.match
                .loadingOrSkipped(() => feesLoading(nestedFees))
                .error(() => feesLoading(nestedFees))
                .ok(_fees => ({
                  className: 'pl-6',
                  label: nestedFees,
                  item: (
                    <div className="flex flex-row items-center text-font-variant font-medium">
                      {!_fees.nested ? null : <Float.Percent value={_fees.nested / _fees.total} />}
                        &nbsp;{_fees.nested ? '~' : ''}
                      <Float.UsdPrice value={_fees.nested} className="text-font" />
                    </div>
                  ),
                })),
              fees$.match
                .loadingOrSkipped(() => feesLoading(bridgeFees))
                .error(() => feesLoading(bridgeFees))
                .ok(_fees =>
                  !_fees.bridgeProtocol
                    ? { label: 'hide' }
                    : {
                      className: 'pl-6',
                      label: _fees.bridgeProtocol.name,
                      item: (
                        <div className="flex flex-row items-center text-font-variant font-medium">
                          <Float.UsdPrice value={_fees.bridgeProtocol.fee} className="text-font" />
                        </div>
                      ),
                    },
                ),
            ]
            : []),
          fees$
            .combine(outputToken$, (_fees, _outputToken) => ({ _fees, _outputToken }))
            .match.loadingOrSkipped(() => feesLoading(networkFees))
            .error(() => feesLoading(networkFees))
            .ok(({ _fees, _outputToken }) => ({
              label: networkFees,
              itemTooltipContent: 'TODO',
              item: !_fees.network ? null : (
                <div className="flex flex-row items-center gap-1 font-medium text-font-variant">
                  <span className="text-accent cursor-pointer" onClick={onSelectTxSpeed}>
                    {txSpeed.charAt(0).toUpperCase() + txSpeed.slice(1)}
                  </span>
                  <Divider direction="vertical" />
                  <Float.Custom
                    value={_fees.network / _outputToken.quote}
                    unit={_outputToken.token?.symbol.toUpperCase()}
                    type="amount"
                  />
                  <Float.UsdPrice value={_fees.network} className="text-font" />
                </div>
              ),
            })),
        ]}
      />
    </div>
  );
};

const feesLoading = (label: string): ILabelItem => ({
  label,
  item: <Skeleton className="w-40 h-4" />,
});
