export const mainRoutes = {
  home: {
    path: '/',
    label: 'home',
  },
  market: {
    path: '/market/',
    label: 'Market',
  },
  overview: {
    path: '/overview/',
    label: '',
  },
  spot: {
    path: '/spot-account/',
    label: 'Spot account',
  },
  coinDetails: {
    path: '/coin-details/:tokenId',
    label: 'Coin details',
  },
  yieldAccount: {
    path: '/yield-account/',
    label: 'Yield account',
  },
  perpsAccount: {
    path: '/perps-account/',
    label: 'Perps account',
  },
  optionsAccount: {
    path: '/options-account/',
    label: 'Options account',
  },
  nftsAccount: {
    path: '/nfts-account/',
    label: 'Nfts account',
  },
  stocksAccount: {
    path: '/stocks-account/',
    label: 'Stocks account',
  },
  creditAccount: {
    path: '/credit-account/',
    label: 'Credit account',
  },
  community: {
    path: '/community/',
    label: 'Community',
  },
  rewards: {
    path: '/rewards/',
    label: 'Rewards',
  },
  notifications: {
    path: '/notifications/',
    label: 'Notifications',
  },
  transactionManager: {
    path: '/transaction-manager/',
    label: 'Transaction manager',
  },
  settings: {
    path: '/settings/',
    label: 'Settings',
  },
  profile: {
    path: '/my-account/',
    label: 'My account',
  },
  favorites: {
    path: '/watchlist/',
    label: 'Watchlist',
  },
  // TODO (Jerem): temporary, remove when layout is validated
  layout1: {
    path: '/new-layout-1/',
    label: 'Layout 1',
  },
} as const;
