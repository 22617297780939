import { TranslationKeys } from 'i18next';
import { FC, SVGProps } from 'react';

import { Chain, Timeframe } from '@gql';
import { TwitterIcon, YoutubeIcon, TelegramIcon } from '@ui-kit/Icons';

export enum Periods {
  ONE_SECOND = 1000,
  ONE_MINUTE = 60 * 1000,
  ONE_HOUR = 3600 * 1000,
  ONE_DAY = 24 * 3600 * 1000,
  ONE_WEEK = 7 * 24 * 3600 * 1000,
  ONE_YEAR = 24 * 3600 * 1000 * 365.25,
}

export const EXPLORERS_URLS: Record<Chain, string> = {
  [Chain.bsc]: 'https://bscscan.com',
  [Chain.eth]: 'https://etherscan.io',
  [Chain.avax]: 'https://cchain.explorer.avax.network',
  [Chain.poly]: 'https://polygonscan.com',
  [Chain.opti]: 'https://optimistic.etherscan.io',
  // [Chain.celo]: 'https://explorer.celo.org',
  // [Chain.ftm]: 'https://ftmscan.com',
  [Chain.arbi]: 'https://arbiscan.io',
  [Chain.base]: 'TODO',
};

export const DEFAULT_CHAIN = Chain.opti;

// tailwind.config.cjs theme.screens.sm
export const MOBILE_SCREEN_SIZE = 512;
// tailwind.config.cjs theme.screens.lg
export const TABLET_SCREEN_SIZE = 1024;

export const QUOTE_EXPIRATION = 10 * Periods.ONE_SECOND;

export const ADDRESS_NATIVE: HexString = '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee';
export const defaultTokens: { [key in keyof typeof Chain]: ChainAddress } = {
  [Chain.eth]: `eth:${ADDRESS_NATIVE}` satisfies ChainAddress, // ETH
  [Chain.bsc]: `bsc:${ADDRESS_NATIVE}` satisfies ChainAddress, // BNB
  [Chain.avax]: `avax:${ADDRESS_NATIVE}` satisfies ChainAddress, // AVAX
  [Chain.poly]: `poly:${ADDRESS_NATIVE}` satisfies ChainAddress, // MATIC
  [Chain.opti]: `opti:${ADDRESS_NATIVE}` satisfies ChainAddress, // ETH
  [Chain.arbi]: `arbi:${ADDRESS_NATIVE}` satisfies ChainAddress, // ETH
  [Chain.base]: `base:${ADDRESS_NATIVE}` satisfies ChainAddress, // BASE
};

export const wrappedCoins: Record<ChainAddress, ChainAddress> = {
  [defaultTokens.eth]: 'eth:0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2' satisfies ChainAddress, // ETH,
  [defaultTokens.bsc]: 'bsc:0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c' satisfies ChainAddress, // BNB,
  [defaultTokens.avax]: 'avax:0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7' satisfies ChainAddress, // AVAX,
  [defaultTokens.poly]: 'poly:0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270' satisfies ChainAddress, // MATIC,
  [defaultTokens.opti]: 'opti:0x4200000000000000000000000000000000000006' satisfies ChainAddress, // ETH,
  [defaultTokens.arbi]: 'arbi:0x82af49447d8a07e3bd95bd0d56f35241523fbab1' satisfies ChainAddress, // ETH,
};

const DOCS_ROOT = 'https://docs.mass.money';
export const DOCS = {
  TERMS_OF_SERVICE: `${DOCS_ROOT}/other/terms-of-services`,
  PRIVACY_POLICY: `${DOCS_ROOT}/other/privacy-policy`,
  COOKIE: `${DOCS_ROOT}/other/privacy-policy#third-party-cookies`,
};

export const SORTED_TIMEFRAMES: Timeframe[] = [
  Timeframe.p1d,
  Timeframe.p1w,
  Timeframe.p1m,
  Timeframe.p6m,
  Timeframe.p1y,
  Timeframe.p5y,
];

export const SOCKET_API_KEY = 'dbca11ef-28bc-4b9e-9d67-d1da2657b01d';

export enum Socials {
  twitter = 'twitter',
  youtube = 'youtube',
  telegram = 'telegram',
  facebook = 'facebook',
  reddit = 'reddit',
}
export const SOCIALS_ICONS: Partial<Record<Socials, FC<SVGProps<SVGSVGElement>>>> = {
  [Socials.twitter]: TwitterIcon,
  [Socials.youtube]: YoutubeIcon,
  [Socials.telegram]: TelegramIcon,
};

export enum COIN_TYPES {
  stablecoins = 'stablecoins',
  cryptocurrencies = 'cryptocurrencies',
}

export type CoinTypeType = {
  id: COIN_TYPES;
  label: TranslationKeys;
};

export const COIN_TYPES_LIST: CoinTypeType[] = [
  {
    id: COIN_TYPES.stablecoins,
    label: 'Common.coinTypes.stablecoins',
  },
  {
    id: COIN_TYPES.cryptocurrencies,
    label: 'Common.coinTypes.cryptocurrencies',
  },
];

export enum Protocol {
  Kwenta = 'Kwenta',
  Polynomial = 'Polynomial',
  GMX = 'GMX',
  Gains = 'Gains',
  Level = 'Level',
  Synthetix = 'Synthetix',
}

export enum PerpsPosition {
  Long = 'Long',
  Short = 'Short',
}

export enum Account {
  Spot = 'Spot',
  Perps = 'Perps',
  Yield = 'Yield',
  Credit = 'Credit',
  Options = 'Options',
  Stocks = 'Stocks',
  NFTs = 'NFTs',
}

export const UNFAIR_PRICE_LIMIT = 0.1;

export const URL = 'https://x.com/MassDotMoney';
export const DISCORD_URL = 'https://discord.gg/MassDotMoney';
export const WEBSITE_URL = 'https://mass.money/';
export const DOCS_URL = 'https://docs.mass.money/';
export const BLOG_URL = 'https://blog.mass.money';
