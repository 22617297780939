import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { match } from 'ts-pattern';

import { BigInputDropdown } from '@components/BigInputDropdown';
import { ITxProcessor } from '@tetris/send';
import { DcaTimeframes, DcaTimeframesToSeconds } from '@tetris/useDca';
import { ArrowsButton } from '@transactions/components/ui/ArrowsButton';
import { Button } from '@ui-kit/atoms/Button';
import { Divider } from '@ui-kit/atoms/Divider';
import { Skeleton } from '@ui-kit/atoms/Skeleton';
import { Chip } from '@ui-kit/molecules/Chip';
import { EntryType, Entry } from '@ui-kit/organisms/Entry';
import { AssetLogo } from '@ui-kit/organisms/TokenLogo';
import { BudgetWithQuote, ICoin, Loadable, Loader, TokenBudget, convertAmt, safeMult, toNumber } from '@utils';
import { formatTimestamp } from '@utils/dateUtils';

import { TimeframeInput } from './TimeframeInput';
import TransactionRecap from '../../TransactionRecap';
import BudgetInputEmptyEntry from '../../ui/BudgetInputEmptyEntry';
import BudgetInputEntry from '../../ui/BudgetInputEntry';

export type DcaUIProps = {
  inputBudgetWithQuote: Loadable<BudgetWithQuote<ICoin> | null>;
  outputToken: Loadable<ICoin>;
  inputAvailable: Loadable<bigint>;
  timeframe: DcaTimeframes;
  every: number;
  setEvery: Dispatch<SetStateAction<number>>;
  over: number;
  setOver: Dispatch<SetStateAction<number>>;
  setInputBudget: Dispatch<SetStateAction<nil | TokenBudget>>;
  setOutputToken: Dispatch<SetStateAction<ICoin>>;
  setTimeframe: Dispatch<SetStateAction<DcaTimeframes>>;
  onSourceCoinSelect: VoidFunction;
  onTargetCoinSelect: VoidFunction;
  sendTx?: Loader<ITxProcessor>;
  inputRef?: React.RefObject<HTMLInputElement>;
  isOperationLoading?: boolean;
};

const MINIMUM_USD_VALUE = 1;
const now = new Date().getTime();
export function DcaUI({
  inputBudgetWithQuote,
  outputToken,
  inputAvailable,
  timeframe,
  setTimeframe,
  every,
  setEvery,
  over,
  setOver,
  onTargetCoinSelect,
  onSourceCoinSelect,
  setInputBudget,
  setOutputToken,
  sendTx,
  inputRef,
  isOperationLoading,
}: DcaUIProps) {
  const { t } = useTranslation();
  const inputBudgetWithQuote$ = Loader.useWrap(inputBudgetWithQuote).noFlickering();
  const outputToken$ = Loader.useWrap(outputToken).noFlickering();
  const inputAvailable$ = Loader.useWrap(inputAvailable).noFlickering();
  const endDate$ = Loader.useWrap(now + every * DcaTimeframesToSeconds[timeframe] * over * 1000).noFlickering();

  const inputOutputWithQuote$ = Loader.array([inputBudgetWithQuote$, outputToken$, endDate$] as const);

  const inputHint$ = Loader.array([inputBudgetWithQuote$, inputAvailable$] as const).map(
    ([_inputBudgetWithQuote, _inputAvailable]) => {
      const usdValue =
        toNumber(
          safeMult(_inputBudgetWithQuote?.amtBase, _inputBudgetWithQuote?.quote),
          _inputBudgetWithQuote?.token.decimals,
        ) || 0;

      const hasInsufficientUsdValue = usdValue ? usdValue < MINIMUM_USD_VALUE : false;
      const hasInsufficientBalance = _inputBudgetWithQuote?.amtBase
        ? _inputBudgetWithQuote.amtBase > _inputAvailable
        : false;

      return [
        {
          usdValue,
        },
        {
          message: !usdValue && t('Screens.Dca.hintValueShouldBeGreaterThan', { value: MINIMUM_USD_VALUE }),
        },
        {
          errorMessage: match({
            _hasInsufficientUsdValue: hasInsufficientUsdValue,
            _hasInsufficientBalance: hasInsufficientBalance,
          })
            .when(
              ({ _hasInsufficientUsdValue }) => _hasInsufficientUsdValue,
              () => t('Common.Errors.valueShouldBeGreaterThan', { value: MINIMUM_USD_VALUE }),
            )
            .when(
              ({ _hasInsufficientBalance }) => _hasInsufficientBalance,
              () => t('Common.Errors.insufficientBalance'),
            )
            .otherwise(() => ''),
        },
      ];
    },
  );

  const inputErrorMessage = inputHint$
    .map(hint => hint[1].errorMessage)
    .match.notOk(() => '')
    .ok(x => x || '');

  const isButtonDisabled = inputOutputWithQuote$.match
    .notOk(() => true)
    .ok(
      ([_inputBudget, _outputToken]) =>
        !_inputBudget?.amtBase || _inputBudget.amtBase === 0n || _inputBudget?.token.id === _outputToken.id,
    );

  const InputBudgetEntry = inputBudgetWithQuote$.match
    .notOk(status => (
      <Skeleton noAnimation={status === 'error'} className="!block">
        <BudgetInputEntry
          budget={{ amtBase: 0n, quote: 0, token: emptyCoin }}
          hint={Loader.loading}
          onChange={() => {}}
          onSelectCoin={onSourceCoinSelect}
          label={t('Screens.Dca.amountPerCycle')}
        />
      </Skeleton>
    ))
    .ok(inputBudget =>
      inputBudget ? (
        <BudgetInputEntry
          ref={inputRef}
          budget={inputBudget}
          onChange={setInputBudget}
          maxBudget={inputAvailable$}
          hint={inputHint$ as any}
          onSelectCoin={onSourceCoinSelect}
          label={t('Screens.Dca.amountPerCycle')}
          disabled={isOperationLoading}
          disabledSelectToken={isOperationLoading}
        />
      ) : (
        <BudgetInputEmptyEntry onSelect={onSourceCoinSelect} />
      ),
    );

  const OutputBudgetEntry = Loader.array([inputBudgetWithQuote$, outputToken$] as const)
    .match.notOk(status => (
      <Skeleton noAnimation={status === 'error'}>
        <BudgetInputEmptyEntry onSelect={onTargetCoinSelect} disabled label={t('Screens.Dca.buy')} />
      </Skeleton>
    ))
    .ok(([_inputBudget, _outputToken]) => (
      <Entry
        key="dca-output"
        isCard
        noMinHeight
        disabled={isOperationLoading}
        content={{
          top: (
            <div className="flex justify-between select-none">
              <span className="text-base font-medium">{t('Screens.Dca.buy')}</span>
              <Chip
                label={_outputToken.symbol}
                leadingItem={
                  _outputToken.id
                    ? { Icon: <AssetLogo asset={_outputToken} withBadge={false} className="w-4 h-4" /> }
                    : undefined
                }
                onClick={onTargetCoinSelect}
                className="h-8 min-w-[56px]"
              />
            </div>
          ),
        }}
      />
    ));

  const switchInputAndOutput = inputOutputWithQuote$.makeCallback(([_input, _output]) => {
    if (_input?.token) setOutputToken(_input.token);

    if (!_output) {
      setInputBudget(null);
    } else {
      setInputBudget({
        amtBase: convertAmt(_input?.amtBase || 0n, _input?.token?.decimals || _output.decimals, _output.decimals),
        token: _output,
      });
    }
  });

  const TxRecap = inputOutputWithQuote$.match
    .notOk(() => <Divider className="mt-6" />)
    .ok(([_inputBudget, _outputToken, _endDate]) => {
      if (!_inputBudget?.amtBase || _inputBudget.amtBase === 0n) return <Divider className="mt-6" />;

      return (
        <div className="h-fit mt-6">
          <TransactionRecap
            className="h-fit overflow-hidden"
            noCountdown
            txData={{
              globalBudget: { value: safeMult(_inputBudget.amtBase, over / every), source: _inputBudget.token },
              endDate: formatTimestamp(_endDate),
              priceImpact: 0.04,
              aggregator: 'Brink',
              // TODO(Hadrien) : Should probably come from the API
              protocolFees: { value: 2.5 },
              massFees: 0,
            }}
          />
        </div>
      );
    });

  return (
    <div className="flex flex-col gap-1 overflow-y-scroll scrollbar max-h-full">
      <div className="!h-24 w-full">{InputBudgetEntry}</div>
      <div className="flex gap-3 my-2">
        <Divider className="my-auto flex-1" />
        <div className="bg-surface-muted flex items-center justify-center rounded-full w-8 h-8">
          <ArrowsButton onClick={switchInputAndOutput} />
        </div>
        <Divider className="my-auto flex-1" />
      </div>
      <div className="!max-h-24">{OutputBudgetEntry}</div>
      <div className="flex gap-1 h-[74px] w-full">
        <div className="w-[112px]">
          <BigInputDropdown
            disabled={isOperationLoading}
            alignment="bottomLeft"
            onSelectEntries={(entries: EntryType[]) => {
              const selectedEntry = entries[0];
              setTimeframe(selectedEntry.id as DcaTimeframes);
            }}
            defaultSelectedEntriesIds={[timeframe]}
            entries={Object.values(DcaTimeframes).map(makeEntry)}
            indication={t('Screens.Dca.timeframe')}
            containerClassName="!w-full"
            chipItemsClassName="!w-full !justify-between"
          />
        </div>
        <div className="flex-grow">
          <TimeframeInput
            count={every}
            timeframe={timeframe}
            defaultCount={1}
            setCount={setEvery}
            indication={t('Screens.Dca.every')}
            isLoading={isOperationLoading}
          />
        </div>
        <div className="flex-grow">
          <TimeframeInput
            count={over}
            timeframe={timeframe}
            defaultCount={1}
            setCount={setOver}
            indication={t('Screens.Dca.over')}
            isLoading={isOperationLoading}
          />
        </div>
      </div>
      {TxRecap}
      <Button
        label={inputErrorMessage || t('Screens.Dca.placeOrder')}
        disabled={!!inputErrorMessage || isButtonDisabled || sendTx?.isLoading || sendTx?.isError}
        fullWidth
        size="l"
        onClick={sendTx?.noFlickering().map(x => x.sendNext)}
        dataCy="place-order-button"
        className="mt-6"
        isLoading={isOperationLoading}
      />
    </div>
  );
}

const makeEntry = (value: DcaTimeframes): EntryType => {
  const label = value.charAt(0).toUpperCase() + value.slice(1);
  return {
    id: value,
    selectLabel: label,
    content: {
      top: label,
    },
  };
};

const emptyCoin: ICoin = {
  id: '' as ChainAddress,
  decimals: 0,
  logo: '',
  name: '',
  symbol: '',
};
