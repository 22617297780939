import { useBlockNumber } from 'wagmi';

import { Chain } from '@gql';
import { Budget, ICoin, Loadable, Loader, withoutChain, wrapToken } from '@utils';

type StopLimitPayload = {
  spendTokenBudget: Loadable<Budget<ICoin>>;
  buyTokenBudget: Loadable<Budget<ICoin>>;
  limitPrice: Loadable<number>;
  expiration: Loadable<EpochTimeStamp | nil>;
  chain: Loadable<Chain | nil>;
};

const blocktime: Record<Chain, number> = {
  // TODO Need to dynamically fetch blocktime
  [Chain.opti]: 2,
  [Chain.arbi]: 0.26,
  [Chain.eth]: 12,
  [Chain.bsc]: 3.01,
  [Chain.poly]: 2.15,
  [Chain.avax]: 2,
  [Chain.base]: 2,
};

export type StopLossOrder = {
  type: 'stopLoss';
  script: string;
};

export function useStopLoss(data: StopLimitPayload): Loader<StopLossOrder> {
  const spendTokenBudget$ = Loader.useWrap(data.spendTokenBudget);
  const buyTokenBudget$ = Loader.useWrap(data.buyTokenBudget);
  const limitPrice$ = Loader.useWrap(data.limitPrice);
  const chain$ = Loader.useWrap(data.chain);
  const expiration$ = Loader.useWrap(data.expiration);
  const blockNumberAsyncPattern = useBlockNumber({
    watch: true,
  });
  const blockNumber$ = Loader.fromReactAsyncPattern(blockNumberAsyncPattern);

  const expirationBlock$ = Loader.array([expiration$, blockNumber$, chain$.map(c => c || Loader.skipped)] as const).map(
    ([expiration, blockNumber, chain]) => {
      if (!expiration) return 0;
      if (!blockNumber) return Loader.loading;
      const blockCount = BigInt(Math.floor(expiration / blocktime[chain]));
      return blockNumber + blockCount;
    },
  );

  const op$ = Loader.array([
    spendTokenBudget$,
    buyTokenBudget$,
    limitPrice$,
    expirationBlock$,
  ] as const).map<StopLossOrder>(([spendTokenBudget, buyTokenBudget, limitPrice, expirationBlock]) => {
    if (!spendTokenBudget || !buyTokenBudget || !limitPrice) return Loader.skipped as any;
    return {
      type: 'stopLoss',
      script: `brink.stop.limitSell(${spendTokenBudget.amtBase}u ${withoutChain(
        wrapToken(spendTokenBudget.token.id),
      )}, ${buyTokenBudget.amtBase}u  ${withoutChain(
        wrapToken(buyTokenBudget.token.id),
      )}, ${limitPrice}, ${expirationBlock});`,
    };
  });

  return op$;
}
