import cls from 'classnames';

export interface IDivider {
  className?: string;
  direction?: 'horizontal' | 'vertical';
}

export function Divider({ direction = 'horizontal', className }: IDivider) {
  return (
    <div
      className={cls(
        'rounded-sm bg-outline flex-shrink-0',
        direction === 'horizontal' ? 'h-px w-full' : 'w-px h-full',
        className,
      )}
    />
  );
}
