import { useTranslation } from 'react-i18next';

import { EntryDropdown } from '@components/EntryDropdown';
import { Chain } from '@gql';
import BudgetInputEntry from '@transactions/components/ui/BudgetInputEntry';
import { VerticalProcessLayout } from '@transactions/components/ui/VerticalProcessLayout';
import EntryGroup from '@ui-kit/organisms/EntryGroup';
import { Budget, BudgetWithQuote, ICoin, Loadable, Loader, budgetNumValue } from '@utils';
import { allNetworks, networksByChain } from '@utils/networks';

export type BridgeUIProps = {
  fromChain: Loadable<Chain | nil>;
  setFromChain: (chain: Chain) => void;
  fromBudget: Loadable<BudgetWithQuote<ICoin>>;
  maxFromBudget: Loadable<bigint>;
  onFromBudgetChange: (budget: Budget<ICoin>, isMax?: boolean) => void;
  onCoinFromSelect: VoidFunction;
  setToChain: (chain: Chain) => void;
  toChain: Loadable<Chain>;
  toBudget: Loadable<BudgetWithQuote<ICoin>>;
  onCoinToSelect: VoidFunction;
  onSwitchTokens: VoidFunction;
  isAuthed: Loadable<boolean>;
  parentScrollableRef: React.MutableRefObject<HTMLDivElement | null>;
  isOperationLoading?: boolean;
  chainsWithVaults?: Chain[];
  openDeposit: VoidFunction;
};

export function BridgeUI({
  fromChain,
  setFromChain,
  fromBudget,
  maxFromBudget,
  onFromBudgetChange,
  onCoinFromSelect,
  setToChain,
  toChain,
  toBudget,
  onCoinToSelect,
  onSwitchTokens,
  isAuthed,
  parentScrollableRef,
  isOperationLoading,
  chainsWithVaults,
  openDeposit,
}: BridgeUIProps) {
  const { t } = useTranslation();

  const fromChain$ = Loader.useWrap(fromChain);
  const fromBudget$ = Loader.useWrap(fromBudget);
  const maxFromBudget$ = Loader.useWrap(maxFromBudget);
  const toChain$ = Loader.useWrap(toChain);
  const toBudget$ = Loader.useWrap(toBudget);
  const isAuthed$ = Loader.useWrap(isAuthed);
  const chainsWithVaults$ = Loader.useWrap(chainsWithVaults);

  const hint$ = Loader.array([fromBudget$, maxFromBudget$, isAuthed$] as const).map(
    ([_fromBudget, _maxFromBudget, _isAuthed]) => {
      if (_isAuthed && _fromBudget.amtBase > _maxFromBudget) {
        return { errorMessage: t('Common.Errors.insufficientBalance') };
      }
      return { usdValue: budgetNumValue(_fromBudget) };
    },
  );

  const targetHint$ = Loader.array([chainsWithVaults$, toChain$] as const).map(([_chainsWithVaults, _toChain]) => {
    if (_chainsWithVaults && !_chainsWithVaults.includes(_toChain)) {
      return { errorMessage: t('Common.Errors.notDestinationVault') };
    }
    return null;
  });
  const hasTargetError = targetHint$.match.notOk(() => false).ok(hint => Boolean(hint?.errorMessage));

  const marketRate$ = Loader.array([fromBudget$, toBudget$, targetHint$] as const).map(
    ([_fromBudget, _toBudget, _targetHint]) => {
      if (_targetHint?.errorMessage) return _targetHint;

      if (!_fromBudget) return Loader.skipped;
      if (!_toBudget) return Loader.loading;
      return {
        marketRate: {
          value: _fromBudget.quote / _toBudget.quote,
          source: _fromBudget.token,
          target: _toBudget.token,
        },
      };
    },
  );

  const chainList$ = Loader.array([fromChain$, toChain$] as const).map(([_fromChain, _toChain]) =>
    allNetworks.filter(n => !n.disabled).filter(({ chain }) => ![_fromChain, _toChain].includes(chain)),
  );

  return (
    <VerticalProcessLayout
      onArrowClick={onSwitchTokens}
      steps={[
        {
          key: 'in',
          item: (
            <EntryGroup
              isError={hint$.match.notOk(() => false).ok(({ errorMessage }) => Boolean(errorMessage))}
              entries={[
                <EntryDropdown
                  key="fromChain"
                  alignment="bottomRight"
                  isPortal
                  parentScrollableRef={parentScrollableRef}
                  isDisabled={chainList$.match.notOk(() => true).ok(_chainList => _chainList.length === 0)}
                  entries={chainList$.match
                    .notOk(() => [])
                    .ok(_chainList =>
                      _chainList.map(({ name: chainName, chain, Icon, MonochromeIcon }) => ({
                        leadingItem: (
                          <div className="w-6 h-6">
                            <Icon className="w-6 h-6 shrink-0" />
                          </div>
                        ),
                        leadingItemSelected: (
                          <div className="w-6 h-6">
                            <MonochromeIcon className="w-6 h-6 shrink-0" />
                          </div>
                        ),
                        content: {
                          top: <span className="text-base font-medium text-font whitespace-nowrap">{chainName}</span>,
                        },
                        id: `${chain}`,
                        selectLabel: chainName,
                      })),
                    )}
                  onSelectEntries={entry => setFromChain(entry[0].id as Chain)}
                  entryProps={{
                    content: {
                      top: (
                        <div className="w-full select-none">
                          {fromChain$.match
                            .notOk(() => null)
                            .ok(_fromChain => _fromChain && networksByChain[_fromChain]?.name)}
                        </div>
                      ),
                    },
                    trailingItem: undefined,
                    leadingItem: (
                      <>
                        {fromChain$.match
                          .notOk(() => null)
                          .ok(_fromChain => {
                            const Icon = _fromChain && networksByChain[_fromChain]?.Icon;
                            if (Icon) {
                              return <Icon className="w-4 h-4 shrink-0" />;
                            }
                          })}
                      </>
                    ),
                    className: 'rounded-b-none focus:bg-surface-muted !gap-2',
                  }}
                />,
                <BudgetInputEntry
                  key="budgetInput"
                  type="spot"
                  budget={fromBudget}
                  onChange={onFromBudgetChange}
                  maxBudget={maxFromBudget}
                  hint={hint$}
                  onSelectCoin={onCoinFromSelect}
                  disabled={isOperationLoading}
                />,
              ]}
            />
          ),
        },
        {
          key: 'out',
          item: (
            <EntryGroup
              isError={hasTargetError}
              entries={[
                <EntryDropdown
                  key="toChain"
                  alignment="bottomRight"
                  isPortal
                  parentScrollableRef={parentScrollableRef}
                  isDisabled={chainList$.match.notOk(() => true).ok(_chainList => _chainList.length === 0)}
                  entries={chainList$.match
                    .notOk(() => [])
                    .ok(_chainList =>
                      _chainList.map(({ name: chainName, chain, Icon, MonochromeIcon }) => ({
                        leadingItem: (
                          <div className="w-6 h-6">
                            <Icon className="w-6 h-6 shrink-0" />
                          </div>
                        ),
                        leadingItemSelected: (
                          <div className="w-6 h-6">
                            <MonochromeIcon className="w-6 h-6 shrink-0" />
                          </div>
                        ),
                        content: {
                          top: <span className="text-base font-medium text-font whitespace-nowrap">{chainName}</span>,
                        },
                        id: `${chain}`,
                        selectLabel: chainName,
                      })),
                    )}
                  onSelectEntries={entry => setToChain(entry[0].id as Chain)}
                  entryProps={{
                    content: {
                      top: (
                        <div className="w-full select-none">
                          {toChain$.match.notOk(() => null).ok(_toChain => networksByChain[_toChain]?.name)}
                        </div>
                      ),
                    },
                    trailingItem: undefined,
                    leadingItem: (
                      <>
                        {toChain$.match
                          .notOk(() => null)
                          .ok(_toChain => {
                            const Icon = networksByChain[_toChain]?.Icon;
                            if (Icon) {
                              return <Icon className="w-4 h-4 shrink-0" />;
                            }
                          })}
                      </>
                    ),
                    className: 'rounded-b-none focus:bg-surface-muted !gap-2',
                  }}
                />,
                <BudgetInputEntry
                  key="budgetOutput"
                  type="spot"
                  disabled
                  disabledSelectToken={hasTargetError}
                  interactive={false}
                  budget={toBudget$}
                  hint={marketRate$}
                  customAction={
                    hasTargetError ? (
                      <span className="text-accent" role="button" onClick={openDeposit} tabIndex={0}>
                        {t('Transactions.Bridge.depositFirst')}
                      </span>
                    ) : null
                  }
                  onSelectCoin={onCoinToSelect}
                />,
              ]}
            />
          ),
        },
      ]}
    />
  );
}
