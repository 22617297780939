import { useApolloClient } from '@apollo/client';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DEFAULT_CHAIN } from '@constants';
import { Chain, GetMainVaultAllChains_mainVault_spot } from '@gql';
import { useMainVault } from '@hooks/useMainVault';
import { getTokenInfo } from '@hooks/useTokensInfo';
import {
  BridgeOperation,
  SocketQuote,
  UseSocketRouteArgs,
  getRouteProtocolName,
  useBridgeOperation,
  useSocketRoutes,
} from '@tetris/socket-bridge';
import { useCreateOrCallVault } from '@tetris/tx';
import { SendTxButton } from '@transactions/components/SendTxButton';
import IncentiveMessage from '@transactions/components/ui/IncentiveMessage';
import { useChangeCoin } from '@transactions/hooks/useChangeCoin';
import { useHighestHolding } from '@transactions/hooks/useHighestHolding';
import { useTransactionFlow } from '@transactions/hooks/useTransactionFlow';
import { Button } from '@ui-kit/atoms/Button';
import { Divider } from '@ui-kit/atoms/Divider';
import { TxSpeed, TxType } from '@ui-kit/organisms/SingleTxReviewContent';
import {
  Budget,
  BudgetWithQuote,
  ICoin,
  Loader,
  NATIVE_TOKENS,
  chainOf,
  convertAmt,
  getTokenQuote,
  isTokenBudget,
  parseNum,
  safeMult,
} from '@utils';
import { useWallet } from '@wallet';

import { BridgeUI } from './BridgeUI';
import { WATCH_FUTURE_TRANSFERS } from './bridge.queries';
import { BridgeFlowProps, TransactionFlowType } from '../../../types';
import { BridgeRoutesSelection } from '../../ui/BridgeRoutesSelection';
import { BridgeTransactionRecap } from '../../ui/BridgeTransactionRecap';

export const BridgeFlow: React.FC<BridgeFlowProps> = ({
  prefillInputTokenId,
  prefillOutputTokenId,
  prefillInputAmount,
}) => {
  const { t } = useTranslation();
  const apollo = useApolloClient();
  const parentScrollableRef = useRef<HTMLDivElement | null>(null);
  const { currentChain, setCurrentChain, setCurrentFlow, props, settings, resetScroll } = useTransactionFlow();
  const isAuthed$ = useWallet(false).map(w => w.isAuthed);
  const wallet$ = useWallet();
  const [refresh, setRefresh] = useState(false);
  const [selectedRoute, setSelectedRoute] = useState<SocketQuote | null>(null);
  const [toChain, setToChain] = useState(!currentChain || currentChain === DEFAULT_CHAIN ? Chain.arbi : DEFAULT_CHAIN);

  useHighestHolding()
    .noFlickering()
    .onOk(_holding => {
      const initializeCurrentChain = async (hld: GetMainVaultAllChains_mainVault_spot | undefined) => {
        if (hld) {
          const chain = chainOf(hld.token.id);
          await setCurrentChain(chain);
          if (toChain === chain) {
            setToChain(chain === DEFAULT_CHAIN ? Chain.arbi : DEFAULT_CHAIN);
          }
        } else {
          await setCurrentChain(DEFAULT_CHAIN);
        }
      };

      if (!currentChain) initializeCurrentChain(_holding).catch(e => e);
    });

  const login = wallet$.makeCallback(({ login: l }) => l());

  useEffect(() => {
    setRefresh(r => !r);
  }, [settings.slippage]);

  const changeCurrentChain = async (chain: Chain) => {
    setCurrentFlow(TransactionFlowType.BRIDGE, {
      ...props,
      prefillInputTokenId: undefined,
    });
    await setCurrentChain(chain);
  };

  const [fromBudget$, setFromBudget$] = Loader.array([isAuthed$, currentChain] as const)
    .map(([_isAuthed]) => (_isAuthed ? null : Loader.skipped))
    .combine(useHighestHolding(currentChain), (_, _holding) => _holding)
    .mapNotLoaded('skipped', () => null)
    .map(async _holding => {
      const tokenId = prefillInputTokenId || _holding?.token.id || (currentChain && NATIVE_TOKENS[currentChain]);
      const token = await getTokenInfo(tokenId);
      if (!token) return Loader.error('No token found');

      const quote = await getTokenQuote(apollo, token.id);
      return { token, quote };
    })
    .map<BudgetWithQuote<ICoin>>(({ token, quote }) => ({ token, quote, amtBase: prefillInputAmount || 0n }))
    .asState<BudgetWithQuote<ICoin>>();

  const fromVault$ = useMainVault(true, currentChain || false);

  const fromHoldings$ = isAuthed$
    .map(isAuthed => (isAuthed ? null : Loader.skipped))
    .combine(fromVault$, (_, _fromVault) => _fromVault?.spot || [])
    .mapNotLoaded('skipped', () => [])
    .combine(fromBudget$, (_holdings, _fromBudget) => {
      const tokenHoldings = _holdings.find(holding => holding.token.id === _fromBudget.token?.id);

      return {
        amtBase: tokenHoldings ? parseNum(tokenHoldings.qty) : 0n,
        quote: tokenHoldings ? tokenHoldings.token.quote : 0,
        token: tokenHoldings ? tokenHoldings.token : _fromBudget.token,
      };
    });
  const chainsWithVaults = fromVault$.match.notOk(() => undefined).ok(vault => (vault?.existsOn || []) as Chain[]);

  const changeToChain = (chain: Chain) => {
    setCurrentFlow(TransactionFlowType.BRIDGE, { ...props, prefillOutputTokenId: undefined } as BridgeFlowProps);
    setToChain(chain);
  };

  const [toBudget$, setToBudget$] = Loader.useWrap(toChain)
    .map(_toChain => {
      const token = prefillOutputTokenId || NATIVE_TOKENS[_toChain];
      return getTokenInfo(token);
    })
    .map(x => x || Loader.error('No token found'))
    .map(async token => {
      const quote = await getTokenQuote(apollo, token.id);
      return { token, quote };
    })
    .combine(fromBudget$, (_tokenWithQuote, _fromBudget) => ({
      ..._tokenWithQuote,
      amtBase: safeMult(_fromBudget.amtBase, _fromBudget.quote / _tokenWithQuote.quote) || 0n,
    }))
    .map<BudgetWithQuote<ICoin>>(_budgetWithQuote => _budgetWithQuote)
    .asState<BudgetWithQuote<ICoin>>();

  const changeCoinTo = useChangeCoin(false, { defaultChain: toChain });

  const onCoinToSelect = Loader.array([fromBudget$, toBudget$] as const).makeCallback(
    async ([_fromBudget, _toBudget]) => {
      await changeCoinTo(async token => {
        if (token) {
          resetScroll();
          const quote = await getTokenQuote(apollo, token.id);
          setToBudget$({
            token: {
              ...token,
              decimals: _fromBudget.token.decimals,
            },
            quote,
            amtBase: safeMult(_fromBudget.amtBase, _fromBudget.quote / quote) || 0n,
          });
          setSelectedRoute(null);
        }
      });
    },
  );

  const onRouteSelected = (route: SocketQuote | null) => {
    setRefresh(r => !r);
    setSelectedRoute(route);

    if (route) {
      setToBudget$(({ token, quote }) => ({
        token: {
          ...token,
          decimals: route.userTxs[0]?.toAsset.decimals || token.decimals,
        },
        quote,
        amtBase: BigInt(route.toAmount),
      }));
    }
  };

  // ======= prepare socket routes
  const routeArgs: UseSocketRouteArgs = {
    fromBudget: Loader.array([fromBudget$, fromHoldings$, isAuthed$] as const).map(
      ([_fromBudget, _fromHoldings, _isAuthed]) =>
        _fromBudget.amtBase && (_fromBudget.amtBase <= _fromHoldings.amtBase || !_isAuthed)
          ? _fromBudget
          : Loader.skipped,
    ),
    sourceVaultAddress: isAuthed$
      .map(_isAuthed => (_isAuthed ? null : Loader.skipped))
      .combine(fromVault$, (_, _fromVault) =>
        _fromVault ? (`${currentChain}:${_fromVault.address}` as ChainAddress) : Loader.skipped,
      )
      .mapNotLoaded('skipped', () => `${currentChain}:0x0` as ChainAddress),
    toTokenAddress: toBudget$.map(_toBudget => _toBudget.token.id),
    refreshOnChange: refresh,
  };

  const routes$ = useSocketRoutes(routeArgs);

  routes$.onOk(routes => {
    if (routes.length === 1 && !selectedRoute) {
      onRouteSelected(routes[0]);
    }
  });

  const changeCoinFrom = useChangeCoin(false, { defaultChain: currentChain });

  const onCoinFromSelect = Loader.array([fromBudget$, toBudget$] as const).makeCallback(
    async ([_fromBudget, _toBudget]) => {
      await changeCoinFrom(async token => {
        if (token) {
          resetScroll();
          const quote = await getTokenQuote(apollo, token.id);
          setFromBudget$(({ amtBase }) => {
            const fromAmtBase = convertAmt(amtBase, _fromBudget.token.decimals, token.decimals);
            setToBudget$({
              token: {
                ..._toBudget.token,
                decimals: token.decimals,
              },
              quote: _toBudget.quote,
              amtBase: safeMult(fromAmtBase, quote / _toBudget.quote) || 0n,
            });

            return {
              token,
              amtBase: fromAmtBase,
              quote,
            };
          });
        }
      });
    },
  );

  const onBudgetFromChange = async (newBudget: Budget<ICoin>, isMax?: boolean) => {
    if (isTokenBudget(newBudget)) {
      setFromBudget$(_fromBudget => ({
        token: newBudget.token,
        quote: _fromBudget.quote,
        amtBase: isMax ? fromHoldings$.match.notOk(() => 0n).ok(h => h.amtBase) : newBudget.amtBase,
      }));

      const quote = await getTokenQuote(apollo, newBudget.token.id);
      setToBudget$(_toBudget => ({
        token: {
          ..._toBudget.token,
          decimals: newBudget.token.decimals,
        },
        quote: _toBudget.quote,
        amtBase: safeMult(newBudget.amtBase, quote / _toBudget.quote) || 0n,
      }));
      setSelectedRoute(null);
      setRefresh(r => !r);
    }
  };

  const onSwitchTokens = Loader.array([fromBudget$, toBudget$] as const).makeCallback(
    async ([_fromBudget, _toBudget]) => {
      setSelectedRoute(null);
      setCurrentFlow(TransactionFlowType.BRIDGE, {
        prefillInputTokenId: _toBudget.token.id,
        prefillOutputTokenId: _fromBudget.token.id,
      });
      await setCurrentChain(toChain);
      setFromBudget$({ ..._toBudget, amtBase: 0n });
      setToBudget$({ ..._fromBudget, amtBase: 0n });
      setToChain(currentChain as Chain);
    },
  );

  const openDeposit = async () => {
    setCurrentFlow(TransactionFlowType.DEPOSIT);
    await setCurrentChain(toChain);
  };

  const bridge$ = useBridgeOperation(selectedRoute, refresh);

  const toastConfig = Loader.array([fromBudget$, toBudget$] as const)
    .match.notOk(() => ({}))
    .ok(([_fromBudget, _toBudget]) => ({
      failedToast: {
        title: t('Transactions.ToastStatus.txFailed'),
        content: t('Transactions.ToastStatus.tradeFailed', {
          inputToken: _fromBudget.token.symbol,
          outputToken: _toBudget.token.symbol,
        }),
      },
    }));

  const { sendTx, operation } = useCreateOrCallVault(currentChain, [bridge$], toastConfig);

  operation.onOk(() => {
    resetBudgets();
  });

  const resetBudgets = Loader.array([fromBudget$, toBudget$] as const).makeCallback(([_input, _output]) => {
    if (_input) setFromBudget$({ ..._input, amtBase: 0n });
    if (_output) setToBudget$({ ..._output, amtBase: 0n });
  });

  const confirmTx$ = sendTx.map(s => () => {
    apollo.query({ query: WATCH_FUTURE_TRANSFERS, variables: { chain: toChain } });
    return s.sendNext();
  });

  const labelButton = Loader.array([fromBudget$, fromHoldings$, toBudget$, bridge$] as const)
    .match.loadingOrSkipped(() => t('Transactions.Bridge.buttonConfirm'))
    .error(e => {
      if (e.message === 'INSUFFICIENT_ASSET_LIQUIDITY') t('Transactions.Recap.insufficientLiquidity');
      return t('Transactions.Bridge.buttonConfirm');
    })
    .ok(([_fromBudget, _fromHoldings, _toBudget]) => {
      if (_fromBudget.amtBase === 0n) return t('Transactions.Bridge.buttonConfirm');
      if (_fromBudget.amtBase > _fromHoldings.amtBase) return t('Transactions.Bridge.buttonInsufficient');
      if ((routes$.isLoading && !selectedRoute) || (bridge$.isLoading && selectedRoute))
        return t('Transactions.Bridge.buttonSearching');
      if (routes$.match.notOk(() => false).ok(_routes => _routes.length === 0))
        return t('Transactions.Bridge.buttonNoRoute');
      if (routes$.isOk && !selectedRoute) return t('Transactions.Bridge.buttonRoute');
      return t('Transactions.Bridge.buttonConfirm');
    });

  const WarnigMessage = Loader.array([bridge$, sendTx] as const)
    .noFlickering()
    .match.loadingOrSkipped(() => null)
    .error(e => {
      if (e.message === 'INSUFFICIENT_ASSET_LIQUIDITY') {
        <IncentiveMessage
          type="error"
          title={t('Transactions.IncentiveMessage.insufficientLiquidityTitle')}
          description={t('Transactions.IncentiveMessage.insufficientLiquidityDescription')}
        />;
      }
      return null;
    })
    .ok(() => null);

  const sendTxButton = Loader.array([fromBudget$, fromHoldings$, toBudget$, confirmTx$, chainsWithVaults] as const)
    .match.notOk(() => <Button size="l" label={labelButton} fullWidth disabled />)
    .ok(([_fromBudget, _fromHoldings, _toBudget, _confirm, _chainsWithVaults]) => {
      const disabled =
        !_fromBudget.amtBase ||
        !_fromBudget.token ||
        _fromBudget.amtBase > _fromHoldings.amtBase ||
        !_chainsWithVaults?.includes(toChain);
      return (
        <SendTxButton
          sendTx={_confirm}
          isLoading={operation.isLoading}
          disabled={disabled}
          label={labelButton}
          dataCy="AmountScreen_cta"
          txData={{
            variant: TxType.bridge,
            rate: settings.slippage,
            inputToken: _fromBudget,
            outputToken: _toBudget,
            fees: {
              total: selectedRoute?.totalGasFeesInUsd || 0,
              network: 0,
              bridgeProtocol: {
                fee: selectedRoute?.totalGasFeesInUsd || 0,
                name: selectedRoute ? getRouteProtocolName(selectedRoute) : '',
              },
            },
            txSpeed: TxSpeed.normal,
            onSelectTxSpeed: () => {},
            onOpenSlippageSettings: () => {},
          }}
        />
      );
    });

  return (
    <div className="flex flex-col gap-4 overflow-y-auto hide-scrollbars h-full" ref={parentScrollableRef}>
      <BridgeUI
        fromChain={currentChain}
        setFromChain={changeCurrentChain}
        fromBudget={fromBudget$}
        maxFromBudget={fromHoldings$.map(b => b.amtBase || 0n)}
        onFromBudgetChange={onBudgetFromChange}
        onCoinFromSelect={onCoinFromSelect}
        toChain={toChain}
        setToChain={changeToChain}
        toBudget={toBudget$}
        onCoinToSelect={onCoinToSelect}
        onSwitchTokens={onSwitchTokens}
        isAuthed={isAuthed$}
        parentScrollableRef={parentScrollableRef}
        isOperationLoading={operation.isLoading}
        chainsWithVaults={chainsWithVaults}
        openDeposit={openDeposit}
      />
      <BridgeBottom
        fromBudget$={fromBudget$}
        toBudget$={toBudget$}
        routes$={routes$}
        selectedRoute={selectedRoute}
        toChain={toChain}
        onRouteSelected={onRouteSelected}
        setRefresh={setRefresh}
        bridge$={bridge$}
        chainsWithVaults={chainsWithVaults}
      />
      {WarnigMessage}
      {isAuthed$.match
        .notOk(() => <Button size="l" label={labelButton} fullWidth disabled />)
        .ok(isAuthed =>
          isAuthed ? (
            sendTxButton
          ) : (
            <Button label={t('Common.connect')} onClick={login} variant="accent" size="l" fullWidth />
          ),
        )}
    </div>
  );
};

type BridgeBottomProps = {
  fromBudget$: Loader<BudgetWithQuote<ICoin>>;
  toBudget$: Loader<BudgetWithQuote<ICoin>>;
  routes$: Loader<readonly SocketQuote[]>;
  selectedRoute: SocketQuote | null;
  toChain: Chain;
  onRouteSelected: (route: SocketQuote | null) => void;
  setRefresh: (r: any) => void;
  bridge$: Loader<BridgeOperation>;
  chainsWithVaults?: Chain[];
};

const BridgeBottom: React.FC<BridgeBottomProps> = ({
  fromBudget$,
  toBudget$,
  routes$,
  selectedRoute,
  toChain,
  onRouteSelected,
  setRefresh,
  bridge$,
  chainsWithVaults,
}) =>
  Loader.array([fromBudget$, toBudget$, chainsWithVaults] as const)
    .match.notOk(() => <Divider />)
    .ok(([_fromBudget, _toBudget, _chainsWithVaults]) => {
      if (!_fromBudget.amtBase || !_toBudget.amtBase || routes$.isSkipped || !_chainsWithVaults?.includes(toChain)) {
        return <Divider />;
      }
      if (!selectedRoute && toChain) {
        return (
          <div className="h-fit">
            <BridgeRoutesSelection
              routes={routes$}
              onSelectRoute={onRouteSelected}
              onCountdownComplete={() => setRefresh((r: boolean) => !r)}
            />
          </div>
        );
      }
      if (selectedRoute) {
        return (
          <div className="h-fit">
            <BridgeTransactionRecap
              bridge={bridge$}
              route={selectedRoute}
              moreRoutes={routes$.match.notOk(() => false).ok(routes => routes.length > 1)}
              onBack={() => onRouteSelected(null)}
              fromBudget={_fromBudget}
              toBudget={_toBudget}
              onCountdownComplete={() => setRefresh((r: boolean) => !r)}
            />
          </div>
        );
      }

      return <Divider />;
    });
