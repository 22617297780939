import cls from 'classnames';
import { ReactElement, ReactNode, cloneElement } from 'react';
import { match } from 'ts-pattern';
import { XOR } from 'ts-xor';

import { SegmentedChipsProps, SegmentedChips } from '@ui-kit/molecules/SegmentedChips';
import { ISliderProps, Slider } from '@ui-kit/molecules/Slider';
import { Tooltip } from '@ui-kit/molecules/Tooltip';
import { Elt, OneOf } from '@utils';

import { Dropdown, IDropdown } from './Dropdown';
import { ChipGroup, ItemChipType } from '../ui-kit/organisms/ChipGroup';
import { ITextInput, TextInput } from '../ui-kit/organisms/TextInput';

export type SupportingItem = XOR<{ text: ReactNode }, { chips: ItemChipType[] }>;
export type IInputFieldProps = {
  title?: Elt;
  tooltip?: string;
  rightItem?: Elt;
  hasError?: boolean;
  supportingItem?: SupportingItem;
  input?: OneOf<
  [
    { text: ITextInput },
    { dropdown: IDropdown },
    { doubleInput: { left: ITextInput; right: IDropdown } },
    { slider: ISliderProps },
    { sliderText: { slider: ISliderProps; text: ITextInput } },
    { segmentedChips: { chips: SegmentedChipsProps; text: ITextInput } },
  ]
  >;
  isContained?: boolean;
  backgroundLogo?: ReactElement;
};

export const InputField = ({
  title,
  rightItem,
  supportingItem,
  tooltip,
  input,
  hasError,
  isContained = false,
  backgroundLogo,
}: IInputFieldProps) => {
  return (
    <div
      className={cls('flex flex-col gap-3 relative w-full', {
        'bg-surface-muted rounded-xl p-4': isContained,
      })}
    >
      {isContained && backgroundLogo && (
        <div className="absolute inset-0 rounded-xl overflow-hidden -z-10">
          <div className="w-full h-full relative">
            <div className="absolute w-20 h-20 -left-6 -top-6 rounded-full opacity-12 blur-xxl pointer-events-none">
              <div className="flex-shrink-0">
                {cloneElement(backgroundLogo, {
                  ...backgroundLogo.props,
                  className: cls(backgroundLogo.props.className, '!w-20 !h-20'),
                })}
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="flex justify-between items-center">
        <div className="flex gap-2 items-center select-none">
          {title && <span className="font-medium text-base text-font">{title}</span>}
          {tooltip && <Tooltip className="text-font-variant" tooltipContent={tooltip} />}
        </div>
        <span className="flex flex-row justify-end gap-1 font-normal text-sm text-font-variant items-center">
          {rightItem}
        </span>
      </div>
      {input &&
        match(input)
          .when(
            _input => 'text' in _input,
            () => <TextInput {...input.text} hasError={hasError} />,
          )
          .when(
            _input => 'dropdown' in _input && input.dropdown,
            () => <Dropdown {...input.dropdown!} />,
          )
          .when(
            _input => 'doubleInput' in _input && input.doubleInput?.right,
            () => (
              <div className="flex gap-2">
                <TextInput {...input.doubleInput?.left} />
                <Dropdown {...input.doubleInput?.right!} />
              </div>
            ),
          )
          .when(
            _input => 'slider' in _input && input.slider,
            () => (
              <div className="px-3">
                <Slider {...input.slider!} />
              </div>
            ),
          )
          .when(
            _input => 'sliderText' in _input && input.sliderText,
            () => (
              <div className="flex flex-col gap-4">
                <TextInput {...input.sliderText!.text} type="number" />
                <Slider {...input.sliderText!.slider} />
              </div>
            ),
          )
          .when(
            _input => 'segmentedChips' in _input && input.segmentedChips,
            () => (
              <div className="flex gap-3">
                <SegmentedChips {...input.segmentedChips!.chips} />
                <TextInput {...input.segmentedChips!.text} />
              </div>
            ),
          )
          .run()}
      {supportingItem && 'text' in supportingItem && (
        <span className={cls('font-normal text-sm', hasError ? 'text-danger' : 'text-font-variant')}>
          {supportingItem.text}
        </span>
      )}
      {supportingItem && 'chips' in supportingItem && supportingItem.chips && (
        <ChipGroup items={supportingItem.chips} />
      )}
    </div>
  );
};
