import { useSetRecoilState } from 'recoil';

import { Chain, refreshLimitOrder, refreshLimitOrderVariables } from '@gql';
import { REFRESH_LIMIT_ORDERS } from '@queries/limit-orders.queries';
import { LimitOrderRefreshAtom } from '@recoil-atoms/limit-orders';
import { fetchApolloAsync } from '@utils/apollo-utils';
import { allNetworks } from '@utils/networks';

export const useLimitOrdersRefresh = () => {
  const setLimitOrderRefreshAtom = useSetRecoilState(LimitOrderRefreshAtom);

  const availableChaines = allNetworks.filter(n => !n.disabled).map(n => n.chain);

  async function refreshLimitOrdersForChain(chain: Chain, refreshAtom: boolean = true) {
    await fetchApolloAsync<refreshLimitOrder, refreshLimitOrderVariables>(REFRESH_LIMIT_ORDERS, {
      chain,
    });

    if (refreshAtom) setLimitOrderRefreshAtom(prev => prev + 1);
  }

  async function refreshLimitOrdersAllChains() {
    await Promise.all(availableChaines.map(chain => refreshLimitOrdersForChain(chain, false))).then(() => {
      setLimitOrderRefreshAtom(prev => prev + 1);
    });
  }

  return {
    refreshLimitOrdersForChain,
    refreshLimitOrdersAllChains,
  };
};
