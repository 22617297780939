import cls from 'classnames';
import { ImgHTMLAttributes, ReactElement, ReactNode, cloneElement } from 'react';
import { match } from 'ts-pattern';

import { Cls } from '@utils';
import { withLoading, WithLoadingInnerProps } from '@utils/uiKitUtils/withLoading';

export type IEntry = {
  id?: string;
  disabled?: boolean;
  isCard?: boolean;
  content?: {
    top: ReactNode;
    bottom?: ReactNode;
    bottomClassName?: string;
    className?: string;
  };
  leadingItem?: ReactElement<ImgHTMLAttributes<HTMLImageElement>>;
  trailingItem?: ReactElement<ImgHTMLAttributes<HTMLImageElement>>;
  leadingBackground?: boolean;
  trailingBackground?: boolean;
  noMinHeight?: boolean;
  wrapContent?: boolean;
  onClick?: VoidFunction;
  dataCy?: string;
  isActive?: boolean;
  isLoading?: boolean;
  children?: ReactNode;
} & Cls;

export type EntryType = Omit<IEntry, 'isCard' | 'noMinHeight'> & {
  selectLabel?: string;
  id: string;
  isFirstOfSection?: boolean; // if true, will display a top divider
};

export const SimpleEntry = ({
  id,
  className,
  isCard,
  disabled,
  content,
  leadingItem,
  trailingItem,
  leadingBackground = false,
  trailingBackground = false,
  noMinHeight,
  wrapContent,
  onClick,
  dataCy,
  isActive,
  isLoading,
  children,
}: IEntry) => {
  const bgColor = match([disabled, isCard, isActive, isLoading])
    .when(
      ([d]) => !!d,
      () => 'bg-surface-variant-muted',
    )
    .when(
      ([, , , l]) => !!l,
      () => 'bg-transparent',
    )
    .when(
      ([d, c, a]) => a && !d && c,
      () => 'bg-hover-accent',
    )
    .when(
      ([d, c]) => !d && c,
      () => 'bg-surface-muted hover:bg-surface-muted-hover-font',
    )
    .when(
      ([d, c]) => !d && !c,
      () => 'bg-none hover:bg-hover-invert',
    )
    .otherwise(() => '');

  return (
    <div
      id={id}
      tabIndex={onClick && typeof onClick === 'function' ? 0 : undefined}
      role={onClick && typeof onClick === 'function' ? 'button' : undefined}
      className={cls(
        'relative flex flex-row items-center gap-4 px-3 py-3 overflow-hidden',
        'transition-colors w-full',
        'border border-transparent',
        { 'focus:bg-hover-accent': !!onClick },
        { 'rounded-xl': isCard },
        { 'min-h-[72px]': !noMinHeight },
        disabled
          ? 'pointer-events-none cursor-default !bg-surface-disabled text-font-variant'
          : 'cursor-pointer text-font',
        isLoading && 'pointer-events-none cursor-default !text-font-disabled',
        bgColor,
        className,
      )}
      onClick={disabled || isLoading ? undefined : onClick}
      onKeyDown={e => {
        if (e.key === 'Enter' && onClick && !isLoading && !disabled) {
          onClick();
        }
      }}
      data-cy={dataCy}
    >
      {isCard && leadingBackground && leadingItem && (
        <div className="absolute -left-8 opacity-20 blur-md pointer-events-none scale-[3]">
          {cloneElement(leadingItem, {
            ...leadingItem.props,
          })}
        </div>
      )}
      {leadingItem &&
        cloneElement(leadingItem, {
          ...leadingItem.props,
          className: cls('w-12 h-12 flex items-center flex-shrink-0', leadingItem.props.className),
        })}

      <div className={cls('flex flex-col text-lg w-full', { truncate: !wrapContent }, content?.className)}>
        {children || (
          <>
            <span className={cls(!isLoading && 'text-font')}>{content?.top}</span>
            <span className={cls(!isLoading && 'text-font-variant')}>{content?.bottom}</span>
          </>
        )}
      </div>
      {trailingItem &&
        cloneElement(trailingItem, {
          ...trailingItem.props,
          className: cls('w-12 h-12 flex items-center flex-shrink-0', trailingItem.props.className),
        })}

      {isCard && trailingBackground && trailingItem && (
        <div className="absolute -right-8 opacity-20 blur-md pointer-events-none scale-[3]">
          {cloneElement(trailingItem, {
            ...trailingItem.props,
            className: cls(trailingItem.props.className, '!w-20 !h-20'),
          })}
        </div>
      )}
    </div>
  );
};

export function Entry(props: IEntry & WithLoadingInnerProps) {
  const entryProps = { invisibleChildren: false, ...props };
  return withLoading(SimpleEntry)(entryProps);
}

export function hasDisabledOrLoading(entry: any): entry is IEntry & { isLoading: boolean; disabled: boolean } {
  return 'isLoading' in entry || 'disabled' in entry;
}
