import { Reducer } from 'react';

import { formatNumber } from '@utils/numbers/NumberFormat';

import { Expiration } from '../limit-orders/enum';

export type StopLossConditionsState = {
  marketPrice: number;
  /** trigger price not dependant on the unit */
  triggerPrice: number;
  triggerPriceStr: string;
  /** limit price not dependant on the unit */
  limitPrice: number;
  limitPriceStr: string;
  expire: Expiration;
  unit: {
    in: string;
    out: string;
  };
  isInverted: boolean;
  bindValues: boolean;
  triggerVariation: any;
  limitVariation: any;
};

export type StopLossConditionsAction =
  | { type: 'SET_TRIGGER_PRICE'; payload: string }
  | { type: 'SET_LIMIT_PRICE'; payload: string }
  | { type: 'SET_EXPIRE'; payload: Expiration }
  | { type: 'SET_UNIT'; payload: string }
  | { type: 'SWAP_UNIT' }
  | { type: 'SET_TRIGGER_TO_MARKET' }
  | { type: 'TOGGLE_BIND_VALUES' };

function strToNum(str: string, inverted: boolean) {
  if (parseFloat(str.replace(',', '') || '0') === 0) return 0;
  return inverted ? 1 / parseFloat(str.replace(',', '')) : parseFloat(str.replace(',', ''));
}

function numToStr(num: number, inverted: boolean) {
  if (num === 0) return '0';
  return formatNumber(inverted ? 1 / num : num, 'qty');
}

export const StopLossConditionReducer: Reducer<StopLossConditionsState, StopLossConditionsAction> = (state, action) => {
  switch (action.type) {
    case 'SET_TRIGGER_PRICE': {
      const triggerPrice = strToNum(action.payload, state.isInverted);
      return {
        ...state,
        triggerPriceStr: action.payload,
        triggerPrice,
        limitPrice: state.bindValues ? triggerPrice * 0.97 : state.limitPrice,
        limitPriceStr: state.bindValues ? numToStr(triggerPrice * 0.97, state.isInverted) : state.limitPriceStr,
      };
    }
    case 'SET_LIMIT_PRICE':
      return {
        ...state,
        limitPriceStr: action.payload,
        limitPrice: strToNum(action.payload, state.isInverted),
        bindValues: false,
      };
    case 'SET_EXPIRE':
      return {
        ...state,
        expire: action.payload,
      };
    case 'SWAP_UNIT':
      return {
        ...state,
        triggerPriceStr: numToStr(state.triggerPrice, !state.isInverted),
        limitPriceStr: numToStr(state.limitPrice, !state.isInverted),
        unit: {
          in: state.unit.out,
          out: state.unit.in,
        },
        isInverted: !state.isInverted,
      };
    case 'SET_TRIGGER_TO_MARKET': {
      const newTriggerPrice = state.isInverted ? 1 / state.marketPrice : state.marketPrice;
      const newLimitPrice = state.isInverted ? 1 / (state.marketPrice * 0.97) : state.marketPrice * 0.97;
      return {
        ...state,
        triggerPriceStr: formatNumber(newTriggerPrice, 'qty'),
        triggerPrice: state.marketPrice,
        limitPriceStr: state.bindValues ? formatNumber(newLimitPrice, 'qty') : state.limitPriceStr,
        limitPrice: state.bindValues ? state.marketPrice * 0.97 : state.limitPrice,
      };
    }
    case 'TOGGLE_BIND_VALUES': {
      const newLimitPrice = state.triggerPrice * 0.97;
      return {
        ...state,
        limitPrice: newLimitPrice,
        limitPriceStr: numToStr(newLimitPrice, state.isInverted),
        bindValues: true,
      };
    }
    default:
      return state;
  }
};
