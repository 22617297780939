import cls from 'classnames';
import { XOR } from 'ts-essentials';
import { match } from 'ts-pattern';

import { SVGIcon, Cls, RequireAtLeastOne } from '@utils';

type CommonType = 'default' | 'info' | 'success' | 'error' | 'accent' | 'warning';

interface IPillCommonBaseProps {
  label?: string;
  LeadingIcon?: SVGIcon;
  leadingIconClassName?: string;
  TrailingIcon?: SVGIcon;
  theme?: XOR<{ type: 'default' | CommonType; variant: 'soft' }, { type: CommonType; variant: 'heavy' }>;
}

export type IPillProps = RequireAtLeastOne<IPillCommonBaseProps, 'label' | 'LeadingIcon' | 'TrailingIcon'>;

export function Pill({ label, LeadingIcon, TrailingIcon, theme, className, leadingIconClassName }: IPillProps & Cls) {
  const iconClassName = cls(label ? 'w-3.5 h-3.5' : 'w-4 h-4', 'fill-current flex-shrink-0');
  return (
    <div
      className={cls(
        'flex items-center w-fit h-fit justify-center px-1 gap-1 bg-surface-muted rounded-sm',
        'font-medium text-base text-font-variant cursor-default select-none',
        !label && '!w-5 h-5',
        theme &&
          match(theme?.variant)
            .with('soft', () => ({
              '!bg-surface-info !text-info': theme?.type === 'info',
              '!bg-surface-success !text-success': theme?.type === 'success',
              '!bg-surface-danger !text-danger': theme?.type === 'error',
              '!bg-surface-warning !text-warning': theme?.type === 'warning',
              '!bg-surface-accent !text-accent': theme?.type === 'accent',
            }))
            .with('heavy', () => ({
              '!bg-info-muted !text-font': theme?.type === 'info',
              '!bg-success-muted !text-font': theme?.type === 'success',
              '!bg-danger-muted !text-font': theme?.type === 'error',
              '!bg-warning-muted !text-font': theme?.type === 'warning',
              '!bg-accent !text-font-on-accent': theme?.type === 'accent',
            }))
            .run(),
        className,
      )}
    >
      {LeadingIcon && <LeadingIcon className={cls(iconClassName, leadingIconClassName)} />}
      {label}
      {TrailingIcon && <TrailingIcon className={iconClassName} />}
    </div>
  );
}
