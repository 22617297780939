import { Chain, Connector } from '@wagmi/core';
import { useLocation } from 'react-router';
import { createWalletClient, custom, getAddress, UserRejectedRequestError } from 'viem';

import { allNetworks } from '@utils/networks';

import { MagicLinkLoginParams, magicSdk } from '.';

export function useMagicAuth() {
  const location = useLocation();

  async function magicAuth(options: MagicLinkLoginParams) {
    switch (options.type) {
      case 'email':
        await magicSdk.auth.loginWithEmailOTP({ email: options.email, showUI: true });
        break;
      case 'phone':
        await magicSdk.auth.loginWithSMS({ phoneNumber: options.phoneNumber });
        break;
      case 'google':
        await magicSdk.oauth.loginWithRedirect({
          provider: 'google',
          scope: ['openid', 'https://www.googleapis.com/auth/userinfo.email'],
          redirectURI: `${window.location.origin}${location.pathname}`,
        });
        break;
      case 'twitter':
        await magicSdk.oauth.loginWithRedirect({
          provider: 'twitter',
          redirectURI: `${window.location.origin}${location.pathname}`,
        });
        break;
      default:
        throw new Error('Invalid login type');
    }
    return magicConnector.connect();
  }

  return { magicAuth };
}

// Heavily inspired from @EveripediaNetwork/wagmi-magic-connector
export class MagicConnector extends Connector {
  options = {};

  ready = true;

  readonly id = 'magic';

  readonly name = 'Magic';

  private magic = magicSdk;

  constructor() {
    super({ chains: allNetworks.map(n => n.viem), options: {} });
  }

  async connect() {
    const provider = await this.getProvider();

    if (provider?.on) {
      provider.on('accountsChanged', this.onAccountsChanged);
      provider.on('chainChanged', this.onChainChanged);
      provider.on('disconnect', this.onDisconnect);
    }

    // Check if we have a chainId, in case of error just assign 0 for legacy
    let chainId: number;
    try {
      chainId = await this.getChainId();
    } catch {
      chainId = 0;
    }

    // if there is a user logged in, return the user
    if (await this.isAuthorized()) {
      return {
        provider,
        chain: {
          id: chainId,
          unsupported: false,
        },
        account: await this.getAccount(),
      };
    }

    if (await this.magic.user.isLoggedIn())
      return {
        account: await this.getAccount(),
        chain: {
          id: chainId,
          unsupported: false,
        },
        provider,
      };

    throw new UserRejectedRequestError(Error('User Rejected Request'));
  }

  async isAuthorized() {
    return this.magic.user.isLoggedIn();
  }

  async getAccount() {
    const provider = await this.getProvider();
    const accounts = await provider?.request({
      method: 'eth_accounts',
    });
    return getAddress(accounts[0] as string);
  }

  async getWalletClient({ chainId }: { chainId?: number } = {}): Promise<any> {
    const provider = await this.getProvider();
    const account = await this.getAccount();
    const chain = this.chains.find(x => x.id === chainId) || this.chains[0];
    if (!provider) throw new Error('provider is required.');
    return createWalletClient({
      account,
      chain,
      transport: custom(provider),
    });
  }

  async switchChain(chainId: number): Promise<Chain> {
    this.onChainChanged(chainId);
    return this.chains.find(x => x.id === chainId) || this.chains[0];
  }

  async getProvider() {
    return this.magic?.rpcProvider;
  }

  protected onAccountsChanged(accounts: string[]): void {
    if (accounts.length === 0 || !accounts[0]) this.emit('disconnect');
    else this.emit('change', { account: getAddress(accounts[0]) });
  }

  protected onChainChanged(chainId: string | number): void {
    const id = normalizeChainId(chainId);
    const unsupported = this.isChainUnsupported(id);
    this.emit('change', { chain: { id, unsupported } });
  }

  async getChainId(): Promise<number> {
    const provider = await this.getProvider();
    if (provider) {
      const chainId = await provider.request({
        method: 'eth_chainId',
        params: [],
      });
      return normalizeChainId(chainId);
    }
    throw new Error('Chain ID is not defined');
  }

  protected onDisconnect(): void {
    this.emit('disconnect');
  }

  async disconnect(): Promise<void> {
    try {
      await this.magic?.wallet.disconnect();
      this.emit('disconnect');
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error disconnecting from Magic SDK:', error);
    }
  }
}

function normalizeChainId(chainId: string | number | bigint) {
  if (typeof chainId === 'string') return Number.parseInt(chainId, chainId.trim().substring(0, 2) === '0x' ? 16 : 10);
  if (typeof chainId === 'bigint') return Number(chainId);
  return chainId;
}

export const magicConnector = new MagicConnector();
