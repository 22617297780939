import { createPublicClient, getContract, http } from 'viem';
import { PublicClient } from 'wagmi';

import { Chain } from '@gql';

import { erc20abi } from './erc20';
import { networksByChain } from './networks';
import { chainOf, withoutChain } from './utils';

const chains = new Map<string, PublicClient>();

export function getPublicClient(chain: Chain): PublicClient {
  if (!chains.has(chain)) {
    chains.set(
      chain,
      createPublicClient({
        chain: networksByChain[chain].viem,
        transport: http(),
      }),
    );
  }
  return chains.get(chain)!;
}

const erc20clients = new Map<string, any>();
export function readERC20(token: ChainAddress) {
  const make = () =>
    getContract({
      abi: erc20abi,
      address: withoutChain(token),
      publicClient: getPublicClient(chainOf(token)),
    });
  if (!erc20clients.has(token)) {
    erc20clients.set(token, make());
  }
  return erc20clients.get(token) as ReturnType<typeof make>;
}
