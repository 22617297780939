import cls from 'classnames';
import { useTranslation } from 'react-i18next';
import { match } from 'ts-pattern';

import { Periods } from '@constants';
import { TransactionType, GetTransactions_transactions_results as Transaction, Chain } from '@gql';
import {
  ArrowRightIcon,
  WithdrawIcon,
  SwapCoinIcon,
  DepositIcon,
  BridgeIcon,
  CreateVaultIcon,
  LimitSellIcon,
} from '@ui-kit/Icons';
import { Badge } from '@ui-kit/atoms/Badge';
import { Pill } from '@ui-kit/atoms/Pill';
import { Entry } from '@ui-kit/organisms/Entry';
import { Cls, Float, chainOf, formatDate } from '@utils';
import { isInPeriod } from '@utils/dateUtils';
import { getTimeAgoTranslation } from '@utils/i18n-utils';
import { allNetworks } from '@utils/networks';
import { getTransactionIcon, TransactionState } from '@utils/uiKitUtils/statusTransactionUtils';

export type IEntryHistory = {
  tx: Transaction;
  source?: string;
  destination?: string;
  status?: TransactionState;
  subTransactionsCount?: number;
  isActive?: boolean;
} & Cls;

const networkIcon = (network?: Chain | null) => {
  const found = allNetworks.find(({ chain }) => chain === network);

  return found ? <Pill key={found.chain} TrailingIcon={found.MonochromeIcon} className="my-auto" /> : null;
};

export const EntryHistory = ({
  tx,
  className,
  source,
  destination,
  subTransactionsCount,
  status,
  isActive,
}: IEntryHistory) => {
  const { t } = useTranslation();

  const statusOrDate = match(status)
    .with(TransactionState.Failed, () => {
      const { Icon } = getTransactionIcon(TransactionState.Failed);
      return (
        <Pill
          theme={{ type: 'error', variant: 'soft' }}
          LeadingIcon={Icon}
          label={t('Common.TransactionStatus.failed')}
        />
      );
    })
    .with(TransactionState.Pending, () => {
      return <Pill theme={{ type: 'default', variant: 'soft' }} label={t('Common.TransactionStatus.pending')} />;
    })
    .with(TransactionState.Processing, () => {
      const { Icon, iconClassName } = getTransactionIcon(TransactionState.Processing);
      return (
        <Pill
          theme={{ type: 'info', variant: 'soft' }}
          LeadingIcon={Icon}
          leadingIconClassName={iconClassName}
          label={t('Common.TransactionStatus.processing')}
        />
      );
    })
    .otherwise(() =>
      isInPeriod(tx.processedAt, Periods.ONE_DAY)
        ? getTimeAgoTranslation(t, tx.processedAt!)
        : formatDate(new Date(tx.processedAt!), 'time'),
    );

  const HistoryIcon = match(tx.type)
    .with(TransactionType.deposit, () => DepositIcon)
    .with(TransactionType.withdraw, () => WithdrawIcon)
    .with(TransactionType.limitOrder, () => LimitSellIcon)
    .with(TransactionType.limitOrderCancel, () => LimitSellIcon)
    .with(TransactionType.bridge, () => BridgeIcon)
    .with(TransactionType.swap, () => SwapCoinIcon)
    .with(TransactionType.vaultCreation, () => CreateVaultIcon)
    .with(TransactionType.multibuy, () => SwapCoinIcon)
    .with(TransactionType.multisell, () => SwapCoinIcon)
    .otherwise(() => null);

  const usdValue = match(tx.type)
    .with(TransactionType.deposit, () => tx.inputs)
    .with(TransactionType.withdraw, () => tx.outputs)
    .with(TransactionType.limitOrder, () => tx.inputs)
    .with(TransactionType.bridge, () => tx.outputs)
    .with(TransactionType.swap, () => tx.inputs)
    .with(TransactionType.vaultCreation, () => tx.inputs)
    .with(TransactionType.multibuy, () => tx.inputs)
    .with(TransactionType.multisell, () => tx.outputs)
    .otherwise(() => [])
    ?.reduce((a, b) => a + (b?.usdValue || 0), 0);

  const inputNetwork = networkIcon(tx.inputs?.[0]?.token.chain);
  const outputNetwork = networkIcon(tx.outputs?.[0]?.token.chain);

  const arrowIcon = <ArrowRightIcon className="w-4 h-4 my-auto" />;
  const subtitle = match(tx.type)
    .with(TransactionType.deposit, () => (
      <>
        {source}
        {arrowIcon}
        {destination}
        {inputNetwork}
      </>
    ))
    .with(TransactionType.withdraw, () => (
      <>
        {source}
        {arrowIcon}
        {destination}
        {outputNetwork}
      </>
    ))
    .with(TransactionType.limitOrder, () => (
      <>
        {source}
        {inputNetwork}
      </>
    ))
    .with(TransactionType.bridge, () => {
      // TODO(Hadrien) : This is enforced TEMPORARY because
      // we have only 2 networks and dont get the inputs tx from backend
      // https://linear.app/mass/issue/MASS-1288/transaction-history-bridge#comment-83173073
      const bridgeOutputNetwork = tx?.outputs?.[0]?.token.chain;
      const bridgeInputNetwork =
        tx?.inputs?.[0]?.token.chain || (bridgeOutputNetwork === Chain.arbi ? Chain.opti : Chain.arbi);
      return (
        <>
          {source}
          {!tx?.outputs?.[0]?.token.chain && !tx?.inputs?.[0]?.token.chain ? null : (
            <div className="flex items-center gap-2">
              <span>{networkIcon(bridgeOutputNetwork) || '?'}</span>
              {arrowIcon}
              <span>{networkIcon(bridgeInputNetwork) || '?'}</span>
            </div>
          )}
        </>
      );
    })
    .with(TransactionType.swap, () => (
      <>
        {source}
        {inputNetwork}
      </>
    ))
    .with(TransactionType.multibuy, () => (
      <>
        {source}
        {inputNetwork}
      </>
    ))
    .with(TransactionType.multisell, () => (
      <>
        {source}
        {inputNetwork}
      </>
    ))
    .with(TransactionType.vaultCreation, () => (
      <>
        {allNetworks.find(({ chain }) => chain === chainOf(tx.id))?.name} {inputNetwork}
      </>
    ))
    .otherwise(() => (
      <>
        {source}
        {inputNetwork}
      </>
    ));

  return (
    <Entry
      key={tx.id}
      className={className}
      isCard
      isActive={isActive}
      content={{
        top: (
          <div className="flex flex-row justify-between items-center w-full text-font font-medium text-lg gap-1">
            <div className="flex gap-2 overflow-hidden select-none">
              {HistoryIcon && <HistoryIcon className="w-4 h-4 my-auto" />}
              <span className="truncate">
                <TransactionTitle tx={tx} status={status} />
              </span>
            </div>
            <Float.UsdPrice value={usdValue} className="text-font shrink-0" />
          </div>
        ),
        bottom: (
          <div
            className={cls(
              'flex flex-row justify-between items-center w-full text-lg text-font-variant select-none',
              className,
            )}
          >
            <div className="flex gap-2">{status === TransactionState.Failed ? '' : subtitle}</div>
            <div className="flex gap-4 items-center">
              {typeof subTransactionsCount === 'number' ? (
                // eslint-disable-next-line react/style-prop-object
                <Badge content={subTransactionsCount.toString()} style="default" variant="heavy" />
              ) : null}
              {statusOrDate}
            </div>
          </div>
        ),
      }}
    />
  );
};

export const TransactionTitle = ({ tx, status }: { tx: Partial<Transaction>; status?: TransactionState }) => {
  const { t } = useTranslation();

  if (status && status === TransactionState.Failed) {
    return t('UIKit.EntryHistory.failed', {
      txType: tx.type ? tx.type.charAt(0).toUpperCase() + tx.type.slice(1) : 'Unknown',
    });
  }

  // /!\ inputs et outputs sont relatifs au Vault, et pas à la transaction.
  // inputs = tokens arrivant dans le Vault
  // outputs = tokens sortant du Vault
  const inputToken = tx.inputs?.[0]?.token.symbol;
  const outputToken = tx.outputs?.[0]?.token.symbol;

  const inputTokens = tx.inputs?.map(({ token }) => token.symbol).join(', ');
  const outputTokens = tx.outputs?.map(({ token }) => token.symbol).join(', ');

  // TODO(Hadrien) : Cela ne gère pas les multi swaps
  return match(tx.type)
    .with(TransactionType.deposit, () => t('UIKit.EntryHistory.deposit', { token: inputToken }))
    .with(TransactionType.withdraw, () => t('UIKit.EntryHistory.withdraw', { token: outputToken }))
    .with(TransactionType.bridge, () => t('UIKit.EntryHistory.bridge', { outputToken }))
    .with(TransactionType.limitOrder, () => t('UIKit.EntryHistory.limitOrder', { inputToken, outputToken }))
    .with(TransactionType.limitOrderCancel, () => t('UIKit.EntryHistory.cancelLimitOrder'))
    .with(TransactionType.swap, () => t('UIKit.EntryHistory.swap', { inputToken, outputToken }))
    .with(TransactionType.vaultCreation, () => t('UIKit.EntryHistory.vaultCreation'))
    .with(TransactionType.multibuy, () => t('UIKit.EntryHistory.multiBuy', { inputTokens, outputToken }))
    .with(TransactionType.multisell, () => t('UIKit.EntryHistory.multiSell', { inputToken, outputTokens }))
    .otherwise(() => t('UIKit.EntryHistory.unknown', { type: tx.type }));
};
