import BudgetInputEntry, { BudgetInputEntryProps } from '@transactions/components/ui/BudgetInputEntry';
import MultiBudgetConfirm from '@transactions/components/ui/MultiBudgetConfirm';
import MultiBudgetInputEntry, { MultiBudgetInputEntryProps } from '@transactions/components/ui/MultiBudgetInputEntry';
import { VerticalProcessLayout } from '@transactions/components/ui/VerticalProcessLayout';
import { BudgetWithQuote, ICoin, Budget, Loader, Loadable, Cls } from '@utils';

export type MultiSellUIProps = {
  inputBudgets: Loadable<(BudgetWithQuote<ICoin> & { isUnfair?: boolean })[]>;
  inputHints?: MultiBudgetInputEntryProps['hints'];
  targetBudget: Loadable<BudgetWithQuote<ICoin>>;
  targetHint?: BudgetInputEntryProps['hint'];
  onEditSelection?: VoidFunction;
  onBackToSelection?: VoidFunction;
  isConfirmed?: boolean;
  onBudgetRemove?: (budget: Budget<ICoin>) => void;
  onSelectTarget?: VoidFunction;
  holdings?: Loadable<Record<ChainAddress, bigint>>;
  // unFairPrices?: Record<ChainAddress, boolean>; TODO handle unfair prices
  onInputBudgetChange?: (budget: Budget<ICoin>, isMax?: boolean) => void;
  targetHoldings?: Loadable<bigint>;
  onSwitch?: VoidFunction;
} & Cls;

export default function MultiSellUI({
  inputBudgets,
  targetBudget,
  inputHints,
  targetHint,
  onEditSelection,
  onBackToSelection,
  onBudgetRemove,
  onSelectTarget,
  isConfirmed = false,
  holdings = {},
  onInputBudgetChange,
  targetHoldings,
  className,
  onSwitch,
}: MultiSellUIProps) {
  const inputBudgets$ = Loader.useWrap(inputBudgets);
  const targetBudget$ = Loader.useWrap(targetBudget);
  const holdings$ = Loader.useWrap(holdings);
  const hints$ = Loader.useWrap(inputHints || {});
  return (
    <VerticalProcessLayout
      className={className}
      onArrowClick={onSwitch}
      steps={[
        {
          key: 'in',
          item: isConfirmed ? (
            <MultiBudgetConfirm budgetsWithUnfair={inputBudgets$} onEditSelection={onBackToSelection} />
          ) : (
            <MultiBudgetInputEntry
              budgets={inputBudgets$}
              hints={hints$}
              onEdit={onEditSelection}
              onRemove={onBudgetRemove}
              holdings={holdings$}
              onChange={onInputBudgetChange}
            />
          ),
        },
        {
          key: 'out',
          item: (
            <BudgetInputEntry
              budget={targetBudget$}
              onSelectCoin={onSelectTarget}
              hint={targetHint}
              interactive={false}
              maxBudget={targetHoldings}
              type="spot"
            />
          ),
        },
      ]}
    />
  );
}
