import { ApolloClient, gql, useApolloClient } from '@apollo/client';

import { GetMultiTokensQuotes, GetTokenQuote, GetTokenQuoteVariables, GetMultiTokensQuotesVariables } from '@gql';

import { nullish, toMap } from './basic-utils';
import { Loadable, Loader } from './loader';
import { wrapToken } from './utils';
import { QUOTE_EXPIRATION } from '../constants';

interface CacheValue {
  readonly quote: Promise<number>;
  readonly at: number;
}
const quoteCache = new Map<ChainAddress, CacheValue>();
function getCachedQuote(token: ChainAddress): null | Promise<number> {
  const now = new Date().getTime();
  const wrapped = wrapToken(token);
  const cached = wrapped && quoteCache.get(wrapped);
  return cached && cached.at > now - QUOTE_EXPIRATION ? cached.quote : null;
}

export async function getTokenQuote(apollo: ApolloClient<any>, token: ChainAddress | nil): Promise<number> {
  if (!token) {
    return 0;
  }
  const cached = getCachedQuote(token);
  if (!nullish(cached)) {
    return cached;
  }

  const fetcher = (async () => {
    const { error, data } = await apollo.query<GetTokenQuote, GetTokenQuoteVariables>({
      query: gql`
        query GetTokenQuote($id: ERC20!) {
          token(id: $id) {
            quote
          }
        }
      `,
      variables: { id: token },
    });
    if (error) {
      quoteCache.delete(token);
      throw new Error('Error fetching token quote: ' + (error?.message ?? 'unknown error'));
    }
    return data.token?.quote ?? 0;
  })();

  quoteCache.set(token, {
    quote: fetcher,
    at: new Date().getTime(),
  });

  return fetcher;
}

interface CacheHit {
  readonly id: ChainAddress;
  readonly quote: number;
}
export class TokensQuotes {
  private readonly byId: Map<ChainAddress, number>;

  constructor(readonly list: readonly CacheHit[]) {
    this.byId = toMap(
      list,
      t => wrapToken(t.id),
      t => t.quote,
    );
  }

  get(_id: ChainAddress | nil): number {
    if (!_id) {
      return 0;
    }
    const id = wrapToken(_id);
    if (!id || !this.byId.has(id)) {
      // eslint-disable-next-line no-console
      console.warn(`No quote found for token ${id}.`);
      return 0;
    }
    return this.byId.get(id) ?? 0;
  }
}

// TODO debug this: some times there are duplicates in the
// list and only one of the duplicates has a quote. The other is
// undefined leading tu undefined quotes in the map byId
export function useTokensQuotes(_tokens: ChainAddress[] | Loader<ChainAddress[]>): Loader<TokensQuotes> {
  const apollo = useApolloClient();

  function fetch(tokens: ChainAddress[] | nil) {
    if (!tokens?.length) {
      return Promise.resolve(new TokensQuotes([]));
    }

    // fetch cached quotes
    const cached: Promise<CacheHit>[] = [];
    const nonCached: ChainAddress[] = [];
    for (const id of new Set(tokens.map(x => wrapToken(x)))) {
      const hit = getCachedQuote(id);
      if (hit) {
        cached.push(hit.then<CacheHit>(quote => ({ id, quote })));
      } else {
        nonCached.push(id);
      }
    }

    // if all quotes are cached, return them without query the server
    if (!nonCached.length) {
      return Promise.all(cached).then(list => new TokensQuotes(list));
    }

    // query the server for missing quotes
    // DO NOT AWAIT HERE !  (see below)
    const quotesFetcher = (async () => {
      const { data, error } = await apollo.query<GetMultiTokensQuotes, GetMultiTokensQuotesVariables>({
        query: gql`
          query GetMultiTokensQuotes($ids: [ChainAddress!]!) {
            tokens(ids: $ids) {
              id
              quote
            }
          }
        `,
        variables: { ids: nonCached },
      });

      if (error) {
        for (const token of nonCached) {
          quoteCache.delete(token);
        }
        throw new Error('Error fetching tokens quotes: ' + (error?.message ?? 'unknown error'));
      }
      return toMap(
        data.tokens,
        t => wrapToken(t.id),
        t => t.quote,
      );
    })();

    // put the fetched quotes in the cache
    for (const _id of nonCached) {
      const id = wrapToken(_id);
      const fetcher = quotesFetcher.then(quotes => {
        const quote = quotes.get(id);
        if (typeof quote !== 'number') {
          // should not happen (if it happens, then our back is badly broken)
          throw new Error('Error fetching token quote: ' + id);
        }
        return quote;
      });
      const hit: CacheValue = {
        quote: fetcher,
        at: new Date().getTime(),
      };
      quoteCache.set(id, hit);
      cached.push(fetcher.then<CacheHit>(quote => ({ id: wrapToken(id), quote })));
    }

    // wait for the quotes to be fetched
    return Promise.all(cached).then(list => new TokensQuotes(list));
  }

  return Loader.useWrap(_tokens).map(fetch);
}

export function useTokenQuote(token: Loadable<ChainAddress | nil>): Loader<number> {
  const apollo = useApolloClient();
  return Loader.useWrap(token).map(t => getTokenQuote(apollo, t));
}
