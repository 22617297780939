import { useTranslation } from 'react-i18next';

import { MainContent } from '@components/layouts/main-content/MainContent';
import { mainRoutes } from '@routes/mainRoutes';
import { Button } from '@ui-kit/atoms/Button';
import NotFoundVideo from '@videos/NotFound.mp4';

export function NotFound() {
  const { t } = useTranslation();

  return (
    <MainContent
      header={<span className="text-3xl font-youth-black font-black">{t('Components.NotFound.title')}</span>}
      sections={[
        {
          id: 'not-found',
          className: '!bg-transparent h-full pt-14 flex bg-danger items-center',
          render: (
            <div className="flex flex-col gap-8 w-fit items-center">
              <video
                className="pointer-events-none h-[300px] aspect-square"
                src={NotFoundVideo}
                autoPlay
                loop
                muted
                playsInline
              />
              <span className="text-lg font-normal">{t('Components.NotFound.subtitle')}</span>
              <div className="flex gap-2">
                <Button
                  variant="accent"
                  label={t('Components.NotFound.reload')}
                  onClick={() => window.location.reload()}
                />
                <a href={mainRoutes.overview.path}>
                  <Button variant="surface-accent" label={t('Components.NotFound.goHome')} />
                </a>
              </div>
            </div>
          ),
        },
      ]}
    />
  );
}
