import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { isDev } from '../env';
import { useRefresh } from '../hooks/useRefresh';
import { RefreshIcon } from '../ui-kit/Icons';

declare const __COMMIT_HASH__: string;

function RefreshAppToastContent() {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-2 p-4">
      <div className="flex gap-2 font-youth-medium text-font">
        <span>🚀</span>
        {t('Components.RefreshOnUpdate.updateAvailable')}
      </div>
      <div className="font-youth-medium text-font text-sm">
        {t('Components.RefreshOnUpdate.newVersionJustReleased')}
      </div>
      <div>
        <span
          onClick={() => clearCacheAndReload()}
          className="font-youth-medium text-font underline text-sm cursor-pointer"
        >
          <RefreshIcon className="fill-current w-3 inline mr-1" />
          {t('Components.RefreshOnUpdate.refreshPage')}
        </span>
      </div>
    </div>
  );
}

export function RefreshOnUpdate() {
  const refresher = useRefresh(30000, 'refreshOnUpdate');

  const fetchVersion = async () => {
    if (isDev) {
      return;
    }
    try {
      const res = await fetch(`${window.location.origin}/version.txt`);
      const version = await res.text();
      if (version && version !== __COMMIT_HASH__) {
        toast(<RefreshAppToastContent />, {
          toastId: 'refreshOnUpdate',
          autoClose: false,
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    } catch (e) {
      console.warn('Could not fetch current app version.', e);
    }
  };

  useEffect(() => {
    fetchVersion();
  }, [refresher]);

  return null;
}

function clearCacheAndReload() {
  if ('caches' in window) {
    caches.keys().then(async names => {
      await Promise.all(
        names.map(async name => {
          caches.delete(name);
        }),
      );
    });

    window.location.reload();
  }
}
