import { GetMainVaultAllChains_mainVault } from '@gql';
import { useTokenInfo } from '@hooks/useTokensInfo';
import { useTokenQuote, parseNum, Loadable, Loader } from '@utils';

export function useBudgetInputInfo(
  tokenId: Loadable<ChainAddress>,
  vault: Loadable<GetMainVaultAllChains_mainVault | null>,
) {
  const tokenId$ = Loader.useWrap(tokenId);
  const vault$ = Loader.useWrap(vault);

  const tokenInfos$ = useTokenInfo(tokenId$, true);
  const tokenQuote$ = useTokenQuote(tokenId$).noFlickering();

  const tokenHoldings$ = vault$.combine(tokenId$, (_vault, _inputTokenId) => {
    return parseNum(_vault?.spot.find(holding => holding.token.id === _inputTokenId)?.qty || 0n);
  });

  return [tokenInfos$, tokenQuote$, tokenHoldings$] as const;
}
