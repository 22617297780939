import { match } from 'ts-pattern';

import { ErrorIcon, LoadingIcon, SuccessIcon, TransactionHistoryIcon } from '@ui-kit/Icons';
import { ButtonVariant } from '@ui-kit/atoms/Button';

export enum TransactionState {
  Pending = 'pending',
  Processing = 'processing',
  Success = 'success',
  Completed = 'completed',
  Failed = 'failed',
}

type TransactionIconUIProps = {
  Icon: React.FC;
  iconClassName: string;
  variant: ButtonVariant;
};

export function getTransactionIcon(state?: TransactionState): TransactionIconUIProps {
  const { Icon, iconClassName, variant } = match(state)
    .with(TransactionState.Processing, () => ({
      Icon: LoadingIcon,
      iconClassName: 'animate-spin',
      variant: 'info' as const,
    }))
    .with(TransactionState.Success, () => ({
      Icon: SuccessIcon,
      iconClassName: '',
      variant: 'success' as const,
    }))
    .with(TransactionState.Completed, () => ({
      Icon: SuccessIcon,
      iconClassName: '',
      variant: 'success' as const,
    }))
    .with(TransactionState.Failed, () => ({
      Icon: ErrorIcon,
      iconClassName: '',
      variant: 'danger' as const,
    }))
    .otherwise(() => ({
      Icon: TransactionHistoryIcon,
      iconClassName: '',
      variant: 'surface' as const,
    }));

  return {
    Icon,
    iconClassName,
    variant,
  };
}
