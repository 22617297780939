import { BudgetWithQuote, ICoin, ICoinWithQuote, toBigNumber, toNumber } from '@utils';

export const getTargetBudgetEstimate = (
  inputBudgets: BudgetWithQuote<ICoin>[],
  target: ICoinWithQuote,
): BudgetWithQuote<ICoin> => {
  const targetBudget = {
    token: target,
    quote: target.quote,
    amtBase: '0',
  };
  const inputBudgetsSum = inputBudgets.reduce((acc, inputBudget) => {
    return acc + toNumber(inputBudget.amtBase, inputBudget.token.decimals) * inputBudget.quote;
  }, 0);
  const targetBudgetEstimate = toBigNumber(inputBudgetsSum / targetBudget.quote, target.decimals);
  return {
    ...targetBudget,
    amtBase: targetBudgetEstimate,
  };
};
