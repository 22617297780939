import { useTranslation } from 'react-i18next';

import { IEntryHistory, EntryHistory } from '@components/entries/EntryHistory';
import { EntryList } from '@components/entries/EntryList';
import { Periods } from '@constants';
import { GetTransactions_transactions_results as Transaction } from '@gql';
import { Alert } from '@ui-kit/atoms/Alert';
import { Button } from '@ui-kit/atoms/Button';
import { Skeleton } from '@ui-kit/atoms/Skeleton';
import { Entry } from '@ui-kit/organisms/Entry';
import { chainOf, formatDate } from '@utils';
import { isInPeriod } from '@utils/dateUtils';

import { getTxSource, getTxDestination, getTransactionStatus } from './Transactions.utils';

const LOADER_ENTRIES_COUNT = 5;

export const ListErrorEntry = ({ onListRetry }: { onListRetry: VoidFunction }) => {
  const { t } = useTranslation();

  return (
    <Alert
      title={t('Transactions.TransactionManager.error.list.title')}
      content={t('Transactions.TransactionManager.error.list.description')}
      variant="error"
      bottom={
        <Button
          label={t('Transactions.TransactionManager.error.retry')}
          onClick={onListRetry}
          size="s"
          variant="surface"
        />
      }
    />
  );
};

export const EmptyList = ({ message }: { message: string }) => (
  <div key="content" className="flex justify-center items-center text-base text-font h-[72px]">
    {message}
  </div>
);

export const LoadingList = ({ count = LOADER_ENTRIES_COUNT }) => (
  <div className="flex flex-col gap-4">
    <Skeleton className="h-4 w-16" />
    {[...Array(count).keys()].map(key => (
      <Skeleton key={key} className="h-[72px]" asOverlay>
        <Entry
          disabled
          content={{
            top: (
              <div className="flex justify-between">
                <Skeleton className="h-4 w-20" />
                <Skeleton className="h-4 w-10" />
              </div>
            ),
            bottom: (
              <div className="flex justify-between">
                <Skeleton className="h-4 w-10" />
                <Skeleton className="h-4 w-20" />
              </div>
            ),
            className: 'h-full flex gap-2',
          }}
        />
      </Skeleton>
    ))}
  </div>
);

const ListHeader = ({ date }: { date: string }) => {
  const { t } = useTranslation();

  return (
    <span className="font-medium text-lg text-font-variant select-none">
      {isInPeriod(date, Periods.ONE_DAY) ? t('Transactions.TransactionManager.today') : formatDate(date, 'long-date')}
    </span>
  );
};

export const TransactionManagerList = ({
  transactions,
  onSelect,
}: {
  transactions: Map<string, Transaction[]>;
  onSelect: (selected: IEntryHistory) => void;
}) => {
  const { t } = useTranslation();

  return (
    <>
      {[...transactions.entries()].map(([group, list]) => (
        <EntryList<IEntryHistory>
          key={group}
          entries={list.map(_tx => ({
            tx: _tx,
            source: getTxSource(_tx, t),
            destination: getTxDestination(_tx, t),
            network: chainOf(_tx.id),
            status: getTransactionStatus(_tx),
          }))}
          header={<ListHeader date={group} />}
          render={EntryHistory}
          onClickEntry={entry => onSelect(entry)}
        />
      ))}
    </>
  );
};
