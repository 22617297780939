import { gql } from '@apollo/client';

import { HoldingWithQuote } from '@gql';

export const GET_USER_MAIN_VAULT = gql`
  query GetMainVault($chain: Chain!) {
    mainVault(chain: $chain) {
      existsOn
      address
      address
      spot {
        ...IHoldingWithQuote
      }
    }
  }
  ${HoldingWithQuote}
`;

export const GET_USER_MAIN_VAULT_ALL_CHAINS = gql`
  query GetMainVaultAllChains {
    mainVault {
      existsOn
      address
      spot {
        ...IHoldingWithQuote
      }
    }
  }
  ${HoldingWithQuote}
`;

export const GET_USER_MAIN_VAULT_SUMMARY = gql`
  query GetMainVaultSummary {
    mainVault {
      creationDate
      existsOn
      address
    }
  }
`;
