import cls from 'classnames';
import { ElementType } from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';
import { LinkProps } from 'react-router-dom';
import 'react-popper-tooltip/dist/styles.css';

import { Cls, Elts } from './utils';

interface ElementProps extends OptionalProp<LinkProps, 'to'>, Cls {
  as?: ElementType;
  children: Elts;
  tooltipContent: Elts | nil;
  tooltipClassName?: string;
  onClick?: (e?: React.MouseEvent<any, any>) => void;
  placement?: 'top' | 'bottom' | 'right' | 'left';
  removeDefaultClasses?: boolean;
  delayHide?: number;
  delayShow?: number;
  hideTooltip?: boolean;
  withArrow?: boolean;
}
/** When using as prop for a navigation, use as={Link} * */
export function DivWithTooltip({
  children,
  tooltipContent,
  className,
  onClick,
  placement = 'top',
  tooltipClassName,
  removeDefaultClasses,
  delayHide = 0,
  delayShow = 0,
  hideTooltip,
  as: Tag = 'div',
  withArrow = false,
  ...rest
}: ElementProps) {
  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip({
    placement,
    delayHide,
    delayShow,
  });

  return (
    <Tag ref={setTriggerRef} className={className} onClick={onClick} {...rest}>
      {children}
      {visible && !hideTooltip && tooltipContent && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({
            className: !removeDefaultClasses
              ? cls(
                'tooltip-container',
                '!p-0 !bg-surface-elevated !rounded-lg shadow-sm !border-none overflow-hidden',
                tooltipClassName,
              )
              : cls('fixed z-50', tooltipClassName),
          })}
        >
          <span className="p-1.5 text-sm font-light text-white"> {tooltipContent}</span>
          {!withArrow && <div {...getArrowProps({ className: cls('tooltip-arrow') })} />}
        </div>
      )}
    </Tag>
  );
}
