import { IEntry } from '@ui-kit/organisms/Entry';

import { Dropdown, IDropdown } from './Dropdown';

export type EntryDropdownProps = {
  entryProps: Omit<IEntry, 'isCard' | 'noMinHeight'>;
} & Omit<IDropdown, 'inputType' | 'entryProps'>;

export function EntryDropdown({ entryProps, ...props }: EntryDropdownProps) {
  return <Dropdown inputType="entry" entryProps={{ ...entryProps, isCard: true, noMinHeight: true }} {...props} />;
}
