import cls from 'classnames';
import { ReactNode, ReactElement, useEffect } from 'react';

import { ContentHeaderProps } from '@components/layouts/main-content/ContentHeader';
import { useSearchState } from '@components/search/useSearchState';
import { useElementSize } from '@hooks/useElementSize';
import { useMainContentScroll } from '@hooks/useMainContentScroll';

import { NavigationButtons } from '../../NavigationButtons';
import { Section } from '../Section';

export enum BackgroundColor {
  Pink = 'pink',
  Green = 'green',
  Blue = 'blue',
}

export type SectionType = { id: string; render: ReactNode; className?: string; hasSubs?: boolean };
export type SubsectionType = { id: string; subsections: SectionType[]; className?: string; hasSubs?: boolean };

export interface IMainContentProps {
  header?: ReactElement<ContentHeaderProps>;
  headerClassName?: string;
  withNavigationButtons?: boolean;
  sections: (SectionType | SubsectionType)[];
  sectionsLoading?: boolean;
  innerContainerClassName?: string;
}

export function MainContent({
  header,
  withNavigationButtons,
  sections,
  sectionsLoading,
  innerContainerClassName,
  headerClassName,
}: IMainContentProps) {
  const [scrollableRef, setScrollableRef, { height }] = useElementSize<HTMLDivElement>();
  const { isSearchOpen } = useSearchState();

  const { setMainContentRef } = useMainContentScroll();
  useEffect(() => {
    if (scrollableRef) {
      setMainContentRef(scrollableRef);
    }
  }, [scrollableRef, setMainContentRef]);

  return (
    // This div cannot be relative or the button of the navbar will unfold under it
    <div className="overflow-hidden rounded-t-2xl h-full w-full bg-background-variant px-4 pt-3">
      <div className={cls('relative h-full', innerContainerClassName)}>
        <div className="relative flex flex-col rounded-2xl w-full h-full gap-4">
          {(header || withNavigationButtons) && (
            <div className={cls('flex items-center justify-center w-full rounded-2xl pb-4', headerClassName)}>
              {withNavigationButtons && (
                <NavigationButtons scrollable={scrollableRef} height={height} isLoading={sectionsLoading} />
              )}
              {header}
            </div>
          )}
          <div
            className={cls(
              'flex flex-col gap-4 overflow-auto hide-scrollbars w-full h-full rounded-t-2xl pb-6',
              isSearchOpen && 'px-4',
            )}
            ref={setScrollableRef}
            onWheel={e => {
              e.stopPropagation();
            }}
          >
            {sections.map(section => {
              if (Array.isArray((section as SubsectionType).subsections)) {
                const { id, subsections = [], className } = section as SubsectionType;
                return (
                  <div key={id} className={cls(className, 'flex flex-col lg:flex-row gap-4 w-full')}>
                    {subsections.map(({ id: subId, render, className: subClassName }) => (
                      <Section key={subId} className={cls(subClassName, 'bg-surface rounded-2xl p-4 overflow-auto')}>
                        {render}
                      </Section>
                    ))}
                  </div>
                );
              }

              const { id, render, className } = section as SectionType;
              return (
                <Section key={id} className={cls(className, 'bg-surface rounded-2xl p-4')}>
                  {render}
                </Section>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
