import cls from 'classnames';
import React, { useState } from 'react';

import { ArrowUpThinIcon, ArrowDownThinIcon } from '@ui-kit/Icons';

export const ArrowsButton: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = ({ className, ...props }) => {
  const [animation, setAnimation] = useState(true);

  return (
    <button
      type="button"
      className={cls('bg-surface-elevated rounded-full p-4 relative overflow-hidden border-0', className)}
      onMouseEnter={() => {
        setAnimation(false);
        setTimeout(() => setAnimation(true), 100);
      }}
      {...props}
    >
      <ArrowUpThinIcon className={cls('h-4 w-4 absolute left-1 top-2', { 'animate-pulse-up': animation })} />
      <ArrowDownThinIcon className={cls('h-4 w-4 absolute right-1 top-2', { 'animate-pulse-down': animation })} />
    </button>
  );
};
