import cls from 'classnames';
import { ReactNode } from 'react';

import { Divider } from '@ui-kit/atoms/Divider';
import { ITab, Tab } from '@ui-kit/atoms/Tab';

export interface ITabs {
  tabs: { id: string; label: string; content?: ReactNode; badge?: ITab['badgeProps'] }[];
  onChange: (tabId: string) => void;
  currentTab: string;
  hasDivider?: boolean;
  className?: string;
  tabsClassName?: string;
  tabClassName?: string;
}

export function Tabs({ tabs, onChange, currentTab, hasDivider = true, className, tabsClassName, tabClassName }: ITabs) {
  return (
    <div className={cls('flex flex-col gap-8', className)}>
      <div className="flex flex-col gap-1.5">
        <div className={cls('flex justify-evenly gap-10 w-min items-center', tabsClassName)}>
          {tabs.map((tab, index) => (
            <Tab
              key={tab.id}
              tabId={tab.id}
              tabIndex={index + 1}
              label={tab.label}
              onClick={() => onChange(tab.id)}
              isActive={currentTab === tab.id}
              className={tabClassName}
              badgeProps={tab.badge}
            />
          ))}
        </div>
        {hasDivider && <Divider />}
      </div>
      {tabs.find(({ id }) => id === currentTab)?.content}
    </div>
  );
}
