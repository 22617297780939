import { match } from 'ts-pattern';

import { UNFAIR_PRICE_LIMIT } from '@constants';
import { BudgetWithQuote, ICoin, safeMult, toNumber } from '@utils';

import { Expiration } from './components/ExpireSelect';
import { UnfairLevel } from './types';

export function computePriceDiff(fetchedPrice: number, sourceQuote: number, targetQuote: number) {
  const fairPrice = sourceQuote / targetQuote;
  return Math.abs((fetchedPrice - fairPrice) / fairPrice);
}

export function getUnfairLevelForPriceDiff(priceDiff: number): UnfairLevel {
  if (priceDiff > UNFAIR_PRICE_LIMIT) {
    return UnfairLevel.MEDIUM;
  }
  return UnfairLevel.LOW;
}

export function getBudgetUSDvalue(budget: BudgetWithQuote<ICoin>) {
  return toNumber(safeMult(budget.amtBase, budget.quote), budget.token.decimals);
}

export function getExpirationInSeconds(expiration: Expiration) {
  return match(expiration)
    .with(Expiration.Never, () => 0)
    .with(Expiration.OneDay, () => 24 * 3600)
    .with(Expiration.OneWeek, () => 7 * 24 * 3600)
    .with(Expiration.OneMonth, () => 30 * 24 * 3600)
    .with(Expiration.OneYear, () => 365 * 24 * 3600)
    .exhaustive();
}
