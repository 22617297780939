import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CopyIcon, CopySuccessIcon } from '@ui-kit/Icons';
import { Chip, IChipProps } from '@ui-kit/molecules/Chip';

export type CopyChipProps = {
  label: string;
  value?: string | nil;
  leadingItem?: IChipProps['leadingItem'];
};

export function CopyChip({ value, label, leadingItem }: CopyChipProps) {
  const props = useCopyChip(value, label, leadingItem);
  return <Chip {...props} />;
}

export function useCopyChip(
  value?: CopyChipProps['value'],
  label?: CopyChipProps['label'],
  leadingItem?: CopyChipProps['leadingItem'],
) {
  const { t } = useTranslation();
  const [copied, setCopied] = useState(false);
  const onCopyClick = () => {
    navigator.clipboard.writeText(value ?? '');
    setCopied(true);
    setTimeout(() => setCopied(false), 1000);
  };

  return {
    label: copied ? t('Common.copied') : label,
    trailingItem: { Icon: copied ? <CopySuccessIcon /> : <CopyIcon /> },
    leadingItem,
    onClick: onCopyClick,
    state: value ? undefined : 'disabled',
  } as IChipProps;
}
