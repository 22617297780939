import cls from 'classnames';
import { useState, useEffect, forwardRef, useImperativeHandle, InputHTMLAttributes } from 'react';
import { deepEqual } from 'wagmi';

import { formatInput } from '@components/bigInput/bigInputUtils';
import { PERCENT_PRECISION, Percent } from '@transactions/components/flows/multiSwap/multiBuy/multiBuy.utils';
import { LockIcon } from '@ui-kit/Icons';
import { boundedValue, toBigNumber, toNumber } from '@utils';

import { useNumberInputHandler } from './useNumberInputHandler';

export type IPercentInput = {
  percent: Percent;
  onChange: (arg: Percent) => void;
  onLock?: (locked: boolean) => void;
  disabled?: boolean;
  locked?: boolean;
  withLock?: boolean;
  maxVal?: bigint;
  hasError?: boolean;
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'value' | 'onChange'>;

const LOCK_ICON_WIDTH = 24;
const PERCENT_SYMBOL_LEFT_MARGIN = 3;

export const PercentInput = forwardRef(
  (
    {
      percent = { value: 0n, precision: PERCENT_PRECISION },
      onChange,
      disabled = false,
      locked = false,
      withLock = false,
      onLock,
      maxVal,
      hasError,
      ...rest
    }: IPercentInput,
    ref,
  ) => {
    const [inputRef, handler] = useNumberInputHandler(v => {
      let result =
        '' +
        boundedValue(
          +parseFloat(v || '0').toFixed(percent.precision),
          0,
          maxVal ? toNumber(maxVal, percent.precision) : 100,
        );
      if (v[v.length - 1] === '.') result += '.';
      return result;
    });

    useImperativeHandle(ref, () => inputRef.current);

    const [symbolPosition, setSymbolPosition] = useState(0);

    // set text from current value when it changes from the outside
    useEffect(() => {
      if (inputRef.current && parseFloat(inputRef.current.value) !== toNumber(percent.value, percent.precision)) {
        inputRef.current.value = '' + toNumber(percent.value, percent.precision);
      }

      setSymbolPosition(measureText('' + toNumber(percent.value, percent.precision)));

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [percent]); // do NOT add 'valueSetHere' as dependency

    const handleInputChange = handler(curVal => {
      // position the '%' symbol correctly
      const val = curVal;
      if (curVal === '') {
        setSymbolPosition(measureText('0'));
      }
      setSymbolPosition(measureText(curVal ?? '' + (percent?.value ?? 0)));

      inputRef.current!.value = val;
      // ======================================================================
      // ========================== PROPAGATE CHANGE ==========================
      // ======================================================================
      if (onChange) {
        const formattedText = formatInput(val);
        const formattedStringNumber = formattedText.replace(/,/g, '');
        const newValue = parseFloat(formattedStringNumber);
        if (!deepEqual(newValue, percent?.value)) {
          onChange({ value: toBigNumber(newValue, percent.precision), precision: percent.precision });
        }
      }
    });

    // ======================================================================
    // =============================== RENDER ===============================
    // ======================================================================

    return (
      <div className="relative w-fit font-youth-medium text-white flex items-center" data-cy="PercentInput">
        {withLock && (
          <LockIcon
            onClick={() => onLock?.(!locked)}
            className={cls('text-font-variant-disabled h-4 inline cursor-pointer', { '!text-white': locked })}
          />
        )}

        <div
          className=" text-3xl flex gap-2 items-center"
          style={{
            position: 'absolute',
            left: `${symbolPosition + PERCENT_SYMBOL_LEFT_MARGIN + LOCK_ICON_WIDTH}px`,
            top: '-1px',
          }}
        >
          <span className="leading-[33px]">%</span>
        </div>
        <input
          disabled={disabled}
          ref={inputRef}
          placeholder="0"
          className={cls(
            'text-3xl cursor-caret',
            hasError ? 'caret-danger' : 'caret-accent',
            'placeholder:text-font-variant',
            'outline-none focus:outline-none bg-transparent',
            'max-w-[10rem]',
          )}
          onKeyDown={handleInputChange}
          {...rest}
        />
      </div>
    );
  },
);
PercentInput.displayName = 'PercentInput';

export default PercentInput;

function measureText(inputText: string) {
  const font = '24px Youth-Regular';
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d')!;
  context.font = font;
  const width = context.measureText(inputText).width;
  return Math.ceil(width); // in 'px'
}
