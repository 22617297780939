import { useTranslation } from 'react-i18next';

import { GetUserBalances_myUser_walletBalances_token } from '@gql';
import { Button } from '@ui-kit/atoms/Button';
import { Entry } from '@ui-kit/organisms/Entry';
import { AssetLogo } from '@ui-kit/organisms/TokenLogo';
import { Float } from '@utils';

export type EntryCoinProps = {
  coin: Pick<GetUserBalances_myUser_walletBalances_token, 'id' | 'logo' | 'symbol' | 'chain' | 'name'>;
  quantity: number;
  usdValue: number;
  onDepositClick: (id: GetUserBalances_myUser_walletBalances_token['id']) => void;
};

export function EntryCoin({ coin, quantity, usdValue, onDepositClick }: EntryCoinProps) {
  const { t } = useTranslation();

  return (
    <Entry
      isCard
      leadingItem={<AssetLogo asset={coin} className="w-10 h-10" />}
      trailingItem={
        <Button
          variant="surface-accent"
          label={t('MyAccount.wallet.actions.deposit')}
          onClick={() => onDepositClick(coin.id)}
        />
      }
      content={{
        top: <Float.Custom type="qty" value={quantity} unit={coin.symbol} />,
        bottom: <Float.UsdValue value={usdValue} />,
      }}
    />
  );
}
