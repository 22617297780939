import { makeNewContentUI } from './SideContentUI';

/**
 * Open a sidecontent component.
 *
 * Will return a fluent builder that has the following methods:
 *    .withProps() => specifies your component properties
 *    .forbidNoResult() => will force the sidecontent to stay open until the component gives a non nil result
 *    .noCloseOnFocusLost() => will prevent the sidecontent to be closed when losing focus
 *
 * Just await the .open() method when you have configured it to open the sidecontent and get the result.
 *
 * Example:
 *
 *
    function MySidecontentComponent(props: { myProp: number } & SidecontentMenuProps<string>) {
      write your sidecontent component here
    }

   const result = await openSidecontent(MySidecontentComponent)
                 .withProps({ myProp: 42 })
                 .noCloseOnFocusLost()
                 .open();
 */

const topSideContent = makeNewContentUI('top');
const centerSideContent = makeNewContentUI('center');
const bottomSideContent = makeNewContentUI('bottom');

export const makeTopSideContent = topSideContent.make;
export const makeCenterSideContent = centerSideContent.make;
export const makeBottomSideContent = bottomSideContent.make;

export const TopSideContentUI = topSideContent.UI;
export const CenterSideContentUI = centerSideContent.UI;
export const BottomSideContentUI = bottomSideContent.UI;

// @deprecated This is only used is dev because Hot Reload injects SideContents multiple times
export const clearTopSideContent = topSideContent.clear;
// @deprecated This is only used is dev because Hot Reload injects SideContents multiple times
export const clearCenterSideContent = centerSideContent.clear;
// @deprecated This is only used is dev because Hot Reload injects SideContents multiple times
export const clearBottomSideContent = bottomSideContent.clear;
