import { Reducer } from 'react';

import { BudgetWithQuote, ICoin } from '@utils';

export type SwapFlowState = {
  inputTokenId: ChainAddress | undefined;
  outputTokenId: ChainAddress | undefined;
  inputAmount: bigint;
  isMaxBudget: boolean | undefined;
  isAuthed: boolean;
  lastBudget: BudgetWithQuote<ICoin> | null;
};

export type SwapFlowAction =
  | { type: 'SET_INPUT_TOKEN_ID'; payload: ChainAddress | undefined }
  | { type: 'SET_OUTPUT_TOKEN_ID'; payload: ChainAddress | undefined }
  | { type: 'SET_IS_AUTHED'; payload: boolean }
  | { type: 'SET_INPUT_AMOUNT'; payload: { value: bigint; isMaxBudget?: boolean } }
  | { type: 'SWITCH_TOKENS'; payload: { inputAmount: bigint } }
  | { type: 'SET_LAST_BUDGET'; payload: BudgetWithQuote<ICoin> | null }
  | {
    type: 'SET_DEFAULT';
    payload: { inputTokenId: ChainAddress | undefined; outputTokenId?: ChainAddress; inputAmount: bigint };
  }
  | { type: 'RESET_FLOW' };

export const SwapFlowReducer: Reducer<SwapFlowState, SwapFlowAction> = (state, action) => {
  switch (action.type) {
    case 'SET_INPUT_TOKEN_ID':
      return { ...state, inputTokenId: action.payload };
    case 'SET_OUTPUT_TOKEN_ID':
      return { ...state, outputTokenId: action.payload };
    case 'SET_IS_AUTHED':
      return { ...state, isAuthed: action.payload };
    case 'SET_INPUT_AMOUNT':
      return { ...state, inputAmount: action.payload.value, isMaxBudget: action.payload.isMaxBudget };
    case 'SWITCH_TOKENS':
      return {
        ...state,
        inputTokenId: state.outputTokenId,
        outputTokenId: state.inputTokenId,
        inputAmount: action.payload.inputAmount,
      };
    case 'SET_LAST_BUDGET':
      return { ...state, lastBudget: action.payload };
    case 'SET_DEFAULT':
      return {
        ...state,
        ...action.payload,
      };
    case 'RESET_FLOW':
      return {
        ...state,
        inputAmount: 0n,
        outputTokenId: undefined,
      };
    default:
      return state;
  }
};
