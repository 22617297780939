import { connect, Connector } from '@wagmi/core';
import { CoinbaseWalletConnector } from '@wagmi/core/connectors/coinbaseWallet';
import { WalletConnectConnector } from '@wagmi/core/connectors/walletConnect';
import { publicProvider } from '@wagmi/core/providers/public';
import { walletConnectProvider } from '@web3modal/wagmi';
import { createWeb3Modal, EIP6963Connector } from '@web3modal/wagmi/react';
import { ReactNode, useMemo } from 'react';
import { useSetRecoilState } from 'recoil';
import { configureChains, WagmiConfig, useAccount, createConfig } from 'wagmi';
import { arbitrum, avalanche, bsc, mainnet, optimism, polygon } from 'wagmi/chains';
import { InjectedConnector } from 'wagmi/connectors/injected';

import { isEphemeralEnv, isEphemeralEnvConnected, walletPreLoadLogic, isDev } from '@env';
import { SignMessageContainer } from '@login/flows/login-flow/SignMessage.container';
import { magicConnector } from '@login/flows/magic/MagicConnector';
// eslint-disable-next-line import/no-restricted-paths

import { alphaEligibleAtom } from './alphaEligible';
import { useWallet } from './wallet-hooks';
import { useModal } from '../components/modal/Modal';
import { DOCS } from '../constants';
import { Loader } from '../utils';

const chains = [arbitrum, avalanche, bsc, mainnet, optimism, polygon];
const projectId = isDev ? '5ad1a196b5dfad9e75e9cbf545b78963' : '9a44473b8abf213f76bd7bdc1634cb30';

const { publicClient } = configureChains(chains, [walletConnectProvider({ projectId }), publicProvider()]);
// TODO(Hadrien) : Fill properly metadata. test with Coinbase wallet
const metadata = {
  name: 'Connect to Mass',
  description: 'Connect your wallet to Mass',
  url: 'https://mass.money',
  icons: [],
};

let connectors: Connector[] = [
  new WalletConnectConnector({ chains, options: { projectId, showQrModal: false, metadata } }),
  new InjectedConnector({ chains }),
  new EIP6963Connector({ chains }),
  new CoinbaseWalletConnector({ chains, options: { appName: metadata.name } }),
  magicConnector,
];

if (isEphemeralEnv) {
  walletPreLoadLogic();
  connectors = [
    new InjectedConnector({
      chains: [mainnet],
      options: {
        name: 'Ephemeral injected wallet',
        getProvider: () => (typeof window !== 'undefined' ? (window as any).ephemeralInjectedWallet : undefined),
      },
    }),
    new WalletConnectConnector({ chains, options: { projectId, showQrModal: false, metadata } }),
  ];
}

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
});

createWeb3Modal({
  wagmiConfig,
  projectId,
  chains,
  themeMode: 'dark',
  themeVariables: {
    '--w3m-accent': '#ebff42',
    '--w3m-font-family': 'Outfit, sans-serif',
  },
  termsConditionsUrl: DOCS.TERMS_OF_SERVICE,
  privacyPolicyUrl: DOCS.PRIVACY_POLICY,
});

// wagmi's autoConnect does not work for injected connectors right now
// => we need to manually connect
// @source https://github.com/wagmi-dev/wagmi/issues/2511
if (isEphemeralEnv && isEphemeralEnvConnected) {
  connect({ connector: connectors[0] });
}

export function WalletProvider({ children }: { children: ReactNode }) {
  return <WagmiConfig config={wagmiConfig}>{children}</WagmiConfig>;
}

export function useSignWeb3Modal() {
  const wallet$ = useWallet();
  const setAlphaEligible = useSetRecoilState(alphaEligibleAtom);

  // wagmi usage is OK here coz we're in the login flow => DONT USE ELSEWHERE
  const { address, status: wagmiStatus } = useAccount();
  Loader.useWrap(address).onOk(() => {
    setAlphaEligible(null);
  });

  const content = useMemo(
    () => ({
      content: <SignMessageContainer address={address} />,
      containerClassName: 'max-w-[360px]', // Styles from WalletConnect Modal to look the same
      preventBackdropClose: true,
    }),
    [address],
  );
  const { open: openSigning, close } = useModal(content);

  Loader.array([wallet$, wagmiStatus] as const).onOk([address], ([x, _wagmiStatus]) => {
    // TODO(Hadrien) : re-add/re-test after alpha filter is removed ?
    // const walletNotAuth = x.status !== 'connected' ||
    // (_wagmiStatus !== 'connected' && _wagmiStatus !== 'reconnecting');
    if (_wagmiStatus === 'connected' && x.status === 'not connected' && !isEphemeralEnv) {
      openSigning();
    } else if (x.status === 'error') {
      close();
    }
  });
}
