import { format } from 'date-fns';
import { ReactNode } from 'react';
import limit from 'simple-rate-limiter';
import { match } from 'ts-pattern';
import { callbackify, promisify } from 'util';
import { formatUnits } from 'viem';

import { isEphemeralEnv } from '@env';
import { Chain, ITokenSummary, Timeframe, TransactionType } from '@gql';
import { TrendFlatIcon, TrendDownIcon, TrendUpIcon } from '@ui-kit/Icons';

import type { WithWarn } from './Num';
import { as, isValidEnum, nullish, parseNum, toMap } from './basic-utils';
import { Periods, wrappedCoins, EXPLORERS_URLS, DEFAULT_CHAIN } from '../constants';

export type Point = [t: number, y: number];

// same as in chartUtils.tsx but duplicated here to avoid dependency cycle
enum ChartColors {
  RED = 'var(--danger)',
  GREEN = 'var(--success)',
  GREY = 'var(--font-variant)',
  DISABLED = 'var(--font-disabled)',
}

export interface Cls {
  className?: string;
}

export type Elt = ReactNode;
export type Elts = Elt | Elt[];
export interface Children {
  children?: Elts;
}

export type RequireAtLeastOne<T, Keys extends keyof T = keyof T> = Pick<T, Exclude<keyof T, Keys>> &
{
  [K in Keys]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<Keys, K>>>;
}[Keys];

export const isTestEnv = import.meta.env.VITE_TEST_ENV === 'true';

export function qtyNum(val: { qty: HexNumber; token: { decimals: number } }): number {
  return toNumber(val?.qty, val?.token?.decimals);
}

export function toNumber(num: bigint | HexNumber, decimals: number): number;
export function toNumber(num: bigint | HexNumber | nil, decimals: number | nil): number | nil;
export function toNumber(num: bigint | HexNumber | nil, decimals: number | nil): number | nil {
  if (nullish(num) || nullish(decimals)) {
    return null;
  }
  const str = formatUnits(parseNum(num), decimals);
  return parseFloat(str);
}

export interface Quotable {
  qty: HexNumber;
  token: { decimals: number; quote: number };
}
export function holdingQuote(h: Quotable): number;
export function holdingQuote(h: Quotable | nil): number | nil;
export function holdingQuote(h: Quotable | nil): number | nil {
  return h ? qtyNum(h) * h.token.quote : null;
}

/** Gets the 0x part of a chain address */
export function hex(address: ChainAddress): HexString {
  return parseAddress(address).address;
}

export type ParsedAddress = {
  chain: Chain;
  address: HexString;
};

/** Parse a chain address */
export function parseAddress(chainAddress: ChainAddress | VaultId): ParsedAddress {
  if (typeof chainAddress !== 'string') {
    throw new Error('Invalid chain address');
  }
  const [, chain, address] = /^(\w+):(\w+)$/.exec(chainAddress) ?? [];
  if (!chainAddress || !chain) {
    throw new Error(`Invalid chain address '${chainAddress}'`);
  }
  if (!isValidEnum(Chain, chain)) {
    throw new Error(`Invalid chain '${chain}' in token id ${chainAddress}`);
  }
  return {
    chain: chain as Chain,
    address: address as HexString,
  };
}

export function makeChainAddress(chain: Chain, address: HexString): ChainAddress {
  return `${chain}:${address.toLowerCase()}` as ChainAddress;
}

export function makeTxId(chain: Chain, txHash: HexString): TxId {
  return makeChainAddress(chain, txHash) as TxId;
}

export function makeVaultId(chain: Chain, vault: HexString): VaultId {
  return makeChainAddress(chain, vault) as VaultId;
}

export function makeUserId(userId: HexString): UserId {
  return userId.toLowerCase() as UserId;
}

export function makeErc20(chain: Chain, address: HexString): ERC20 {
  return makeChainAddress(chain, address) as ERC20;
}

export function isChainAddress(value: any): value is ChainAddress {
  if (typeof value !== 'string') {
    return false;
  }
  const [, chain] = /^(\w+):0x[a-fA-F\d]{40}$/.exec(value) ?? [];
  return !!chain && isValidEnum(Chain, chain);
}

export function chainOf(address: ChainAddress | VaultId): Chain;
export function chainOf(address: ChainAddress | VaultId | nil): Chain | nil;
export function chainOf(address: ChainAddress | VaultId | nil): Chain | nil {
  if (!address) {
    return null;
  }
  return parseAddress(address).chain;
}

export function isHexString(str: string): str is HexString {
  return /^0x[0-9a-fA-F]+$/.test(str);
}

export function withoutChain(address: ChainAddress): HexString;
export function withoutChain(address: ChainAddress | nil): HexString | nil;
export function withoutChain(address: ChainAddress | nil): HexString | nil {
  return address && parseAddress(address).address;
}

export function toVaultId(chain: Chain, vaultAddress: ChainAddress): VaultId {
  return `${chain}:v:${withoutChain(vaultAddress)}`;
}

export function getTransactionDetailsUrl(chainAddress: ChainAddress, type: TransactionType | null): string {
  const { chain, address } = parseAddress(chainAddress);
  const url = type === TransactionType.bridge ? 'https://socketscan.io' : EXPLORERS_URLS[chain];
  if (!url) {
    throw new Error('Unkown chain ' + chain);
  }
  return `${url}/tx/${address}`;
}

export function encodeAddress(chain: Chain, address: HexString): ChainAddress {
  return `${chain}:${address.toLowerCase() as HexString}`;
}

export type DateFormat = 'date' | 'time' | 'time-12-hour' | 'long-date' | 'long-date-time' | 'date-and-time';

const DATE_FORMATS: Record<DateFormat, string> = {
  date: 'd. LLL',
  'long-date': 'LLL d, yyyy',
  time: 'HH:mm',
  'time-12-hour': 'h:mm a',
  'long-date-time': 'LLL. d, yyy - h:mm a',
  'date-and-time': 'LLL. d, yyyy - h:mm a',
} as const;

export function formatDate(value: string | Date, dateFormat: DateFormat): string {
  if (!value) {
    return '';
  }
  const newValue = new Date(value);
  return format(newValue, DATE_FORMATS[dateFormat]);
}

export function isValidEmail(email: string): boolean {
  // RFC2822 email regex
  /* eslint-disable max-len */
  const pattern =
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  /* eslint-enable max-len */

  return pattern.test(email.toLowerCase());
}

export const TimeframesWithLabels = [
  { text: 'ALL', value: Timeframe.all },
  { text: '1Y', value: Timeframe.p1y },
  { text: '6M', value: Timeframe.p6m },
  { text: '1M', value: Timeframe.p1m },
  { text: '1W', value: Timeframe.p1w },
  { text: '24H', value: Timeframe.p1d },
];

export const isValidAddress = (address: string | undefined): address is HexString =>
  !!address?.match(/^0x[a-fA-F0-9]{40}$/);

export function enumValues<T extends {}>(myEnum: T): T[keyof T][] {
  return Object.values(myEnum);
}

export function timeframeToMs(tf: Timeframe): number | null {
  return match(tf)
    .with(Timeframe.all, () => null)
    .with(Timeframe.p1d, () => Periods.ONE_DAY)
    .with(Timeframe.p1w, () => 7 * Periods.ONE_DAY)
    .with(Timeframe.p1m, () => 30 * Periods.ONE_DAY)
    .with(Timeframe.p6m, () => 6 * 30 * Periods.ONE_DAY)
    .with(Timeframe.p1y, () => 365 * Periods.ONE_DAY)
    .with(Timeframe.p5y, () => 365 * 5 * Periods.ONE_DAY)
    .exhaustive();
}

export const dateToTimeframe = (date?: DateTime | null): Timeframe => {
  if (!date) {
    return Timeframe.all;
  }

  const delta = new Date().getTime() - new Date(date!).getTime();
  const timeFrame = enumValues(Timeframe)
    .map(p => ({ p, t: timeframeToMs(p) }))
    .sort((a, b) => a.t! - b.t!)
    .reduce(
      (m, x) =>
        m.when(
          time => time < x.t! + 30 * Periods.ONE_MINUTE,
          () => x.p,
        ),
      match<number, Timeframe>(delta),
    )
    .otherwise(() => Timeframe.all);
  return timeFrame;
};

export const formatPercentage = (value: number): string => {
  const positiveValue = Math.max(0, value);
  if (positiveValue === 0) {
    return '0%';
  }
  if (positiveValue < 0.005) {
    return '< 0.5%';
  }
  return `${Math.round(value * 100)}%`;
};

export function isValidNumber(num: any): num is number {
  return typeof num === 'number' && Number.isFinite(num);
}
/*
 * Returns the minimum existence period of time in milliseconds
 * that an asset or group of assets should have for its performance to be relevant for a given timeframe.
 *
 * If it hasn't been existing for long enough, we might not want to display the related perfomance in the app.
 * */
export function minValidPeriodPerTimeframe(timeframe: Timeframe): number {
  return match(timeframe)
    .with(Timeframe.p1d, () => Periods.ONE_DAY)
    .with(Timeframe.p1w, () => Periods.ONE_WEEK)
    .with(Timeframe.p1m, () => 30 * Periods.ONE_DAY)
    .with(Timeframe.p6m, () => Periods.ONE_YEAR / 2)
    .with(Timeframe.p1y, () => Periods.ONE_YEAR)
    .with(Timeframe.p5y, () => Periods.ONE_YEAR * 5)
    .with(Timeframe.all, () => 0)
    .exhaustive();
}

export function isValidPeriodForTimeframe(timeframe: Timeframe, refDate: Date): boolean {
  return new Date().getTime() - refDate.getTime() > minValidPeriodPerTimeframe(timeframe);
}

// lock the app on provided chains (used by LedgerLive)
const [, lockOnChains] = /\bchains=(\w+(:?,\w+)*)\b/.exec(window.location.hash ?? '') ?? [];
if (lockOnChains) {
  localStorage.setItem('lock-on-chains', lockOnChains);
}

const chains = localStorage.getItem('lock-on-chains')?.split(',') as Chain[];
/** Will tell which ledger-live chains are supported. Null if not on ledger live. */
const ledgerLiveChains: ReadonlySet<Chain> | undefined = chains && new Set(chains);

export function wrapToken(token: ChainAddress): ChainAddress;
export function wrapToken(token: ChainAddress | nil): ChainAddress | nil;
export function wrapToken<T extends Pick<ITokenSummary, 'id'>>(token: T): T;
export function wrapToken(
  token: ChainAddress | HexString | Pick<ITokenSummary, 'id'> | nil,
): ChainAddress | HexString | nil | Pick<ITokenSummary, 'id'> {
  if (!token) {
    return token;
  }
  if (typeof token !== 'string') {
    return {
      ...token,
      id: wrapToken(token.id),
    };
  }
  const wrapped = wrappedCoins[token as ChainAddress];
  if (!wrapped) {
    return token?.toLowerCase() as ChainAddress | undefined;
  }
  return wrapped.toLowerCase() as ChainAddress;
}

export function unwrapToken(token: ChainAddress): ChainAddress;
export function unwrapToken(token: ChainAddress | nil): ChainAddress | nil;
export function unwrapToken(token: ChainAddress | nil): ChainAddress | nil {
  const unwrappedCoins = toMap(
    Object.entries(wrappedCoins),
    ([, wrapped]) => wrapped.toLowerCase(),
    ([unwrapped]) => unwrapped,
  );
  const unwrapped = token && unwrappedCoins.get(token);
  if (!unwrapped) {
    return token?.toLowerCase() as ChainAddress | undefined;
  }
  return unwrapped.toLowerCase() as ChainAddress;
}

export function isNetworkSupported(chain: Chain) {
  return !ledgerLiveChains || ledgerLiveChains.has(chain);
}

/** Returns a promise that will resolve after a given period */
export function delay(ms: number): Promise<void> {
  // eslint-disable-next-line no-promise-executor-return
  const ret = new Promise<void>(resolve => setTimeout(resolve, ms));
  if (!isEphemeralEnv) {
    return ret;
  }

  // when in an controlled test suit environement,
  // then waits must be controllable
  // (we dont want our tests to wait all this precious time)
  //  👉 Call "cy.resolveDelays()" to resolve those promises.
  const control = new Promise<void>(_resolve => {
    (window as any).EPHEMERAL_DELAYS ??= new Set();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const delays = (window as any).EPHEMERAL_DELAYS;
    const resolve = () => {
      delays.delete(resolve);
      _resolve();
    };
    delays.add(resolve);
  });
  return Promise.race([ret, control]);
}

export function holdingQuoteSum(holdings: Quotable[]): WithWarn {
  return holdings.reduce(({ hasUnknownQuote, value }, h) => {
    const q = holdingQuote(h);
    if (!q) {
      return { hasUnknownQuote: true, value };
    }
    return { hasUnknownQuote, value: value + q };
  }, as<WithWarn>({ value: 0 }));
}

export function rateLimit<T extends (...args: any[]) => Promise<any>>(
  fn: T,
  limits: { interval: number; limit: number }[],
): T {
  let callback = callbackify(fn);
  for (const l of limits) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    callback = limit(callback).to(l.limit).per(l.interval);
  }
  return promisify(callback) as any;
}

export function shortenAddress(address?: ChainAddress | HexString | nil, veryShort?: boolean) {
  if (!address) {
    return '-';
  }
  const zx = isHexString(address) ? address : withoutChain(address);

  return `${zx.slice(0, veryShort ? 3 : 5)}…${zx.slice(-(veryShort ? 3 : 4))}`;
}

export function getOwners(address?: ChainAddress | nil, chain?: Chain | null): ChainAddress[] {
  if (!address) {
    return [];
  }

  if (chain) {
    return [`${chain}:${hex(address)}` as ChainAddress];
  }

  return enumValues(Chain).map<ChainAddress>(c => `${c}:${hex(address)}`);
}

export type ICoin = Pick<ITokenSummary, 'id' | 'symbol' | 'name' | 'logo' | 'decimals'>;

export interface IFiat {
  id: string;
  symbol: string;
  name: string | nil;
  logo?: string | nil;
  inputSymbol?: string;
}

/** Number of digits for fiat amounts */
export const FIAT_DIGITS = 18;

export type IAsset = ICoin | IFiat;

export type ICoinWithQuote = ICoin & { quote: number };

export function isCoin(coin: IAsset | nil): coin is ICoin {
  return isChainAddress(coin?.id);
}

export function assetDecimals(asset: IAsset | nil): number {
  return isCoin(asset) ? asset.decimals : FIAT_DIGITS;
}

export const Fiat = Object.freeze({
  USD: {
    symbol: 'USD',
    logo: 'US',
    name: 'US Dollar',
    inputSymbol: '$',
    id: 'USD',
  },
  EUR: {
    symbol: 'EUR',
    logo: 'EU',
    name: 'Euro',
    inputSymbol: '€',
    id: 'EUR',
  },
  CHF: {
    symbol: 'CHF',
    logo: 'CH',
    name: 'Swiss franc',
    inputSymbol: 'CHF',
    id: 'CHF',
  },
  GBP: {
    symbol: 'GBP',
    logo: 'GB',
    name: 'British pound',
    inputSymbol: '£',
    id: 'GBP',
  },
  SGD: {
    symbol: 'SGD',
    logo: 'SG',
    name: 'Singapore Dollar',
    inputSymbol: '$',
    id: 'SGD',
  },
  BND: {
    symbol: 'BND',
    logo: 'BN',
    name: 'Brunei Dollar',
    inputSymbol: '$',
    id: 'BND',
  },
  AUD: {
    symbol: 'AUD',
    logo: 'AU',
    name: 'Australian dollar',
    inputSymbol: '$',
    id: 'AUD',
  },
  CAD: {
    symbol: 'CAD',
    logo: 'CA',
    name: 'Canadian dollar',
    inputSymbol: '$',
    id: 'CAD',
  },
  MXN: {
    symbol: 'MXN',
    logo: 'MX',
    name: 'Mexican peso',
    inputSymbol: '$',
    id: 'MXN',
  },
  ARS: {
    symbol: 'ARS',
    logo: 'AR',
    name: 'Argentine Peso',
    inputSymbol: '$',
    id: 'ARS',
  },
  AOA: {
    symbol: 'AOA',
    logo: 'AO',
    name: 'Kwanza',
    inputSymbol: 'Kz',
    id: 'AOA',
  },

  BBD: {
    symbol: 'BBD',
    logo: 'BB',
    name: 'Barbados Dollar',
    inputSymbol: '$',
    id: 'BBD',
  },
  BGN: {
    symbol: 'BGN',
    logo: 'BG',
    name: 'Bulgarian Lev',
    inputSymbol: 'лв',
    id: 'BGN',
  },
  BMD: {
    symbol: 'BMD',
    logo: 'BM',
    name: 'Bermudian Dollar',
    inputSymbol: '$',
    id: 'BMD',
  },

  BRL: {
    symbol: 'BRL',
    logo: 'BR',
    name: 'Brazilian Real',
    inputSymbol: 'R$',
    id: 'BRL',
  },
  BZD: {
    symbol: 'BZD',
    logo: 'BZ',
    name: 'Belize Dollar',
    inputSymbol: 'BZ$',
    id: 'BZD',
  },

  CLP: {
    symbol: 'CLP',
    logo: 'CL',
    name: 'Chilean Peso',
    inputSymbol: '$',
    id: 'CLP',
  },
  COP: {
    symbol: 'COP',
    logo: 'CO',
    name: 'Colombian peso',
    inputSymbol: '$',
    id: 'COP',
  },
  CRC: {
    symbol: 'CRC',
    logo: 'CR',
    name: 'Costa Rican Colon',
    inputSymbol: '₡',
    id: 'CRC',
  },
  CZK: {
    symbol: 'CZK',
    logo: 'CZ',
    name: 'Czech Koruna',
    inputSymbol: 'Kč',
    id: 'CZK',
  },
  DJF: {
    symbol: 'DJF',
    logo: 'DJ',
    name: 'Djibouti Franc',
    inputSymbol: 'Fdj',
    id: 'DJF',
  },
  DKK: {
    symbol: 'DKK',
    logo: 'DK',
    name: 'Danish Krone',
    inputSymbol: 'kr.',
    id: 'DKK',
  },
  DOP: {
    symbol: 'DOP',
    logo: 'DO',
    name: 'Dominican Peso',
    inputSymbol: 'RD$',
    id: 'DOP',
  },

  FJD: {
    symbol: 'FJD',
    logo: 'FJ',
    name: 'Fiji Dollar',
    inputSymbol: '$',
    id: 'FJD',
  },
  FKP: {
    symbol: 'FKP',
    logo: 'FK',
    name: 'Falkland Islands Pound',
    inputSymbol: '£',
    id: 'FKP',
  },

  GEL: {
    symbol: 'GEL',
    logo: 'GE',
    name: 'Lari',
    inputSymbol: '₾',
    id: 'GEL',
  },
  GIP: {
    symbol: 'GIP',
    logo: 'GI',
    name: 'Gibraltar Pound',
    inputSymbol: '£',
    id: 'GIP',
  },
  GTQ: {
    symbol: 'GTQ',
    logo: 'GT',
    name: 'Quetzal',
    inputSymbol: 'Q',
    id: 'GTQ',
  },
  HKD: {
    symbol: 'HKD',
    logo: 'HK',
    name: 'Hong Kong Dollar',
    inputSymbol: '$',
    id: 'HKD',
  },
  HNL: {
    symbol: 'HNL',
    logo: 'HN',
    name: 'Lempira',
    inputSymbol: 'L',
    id: 'HNL',
  },
  HRK: {
    symbol: 'HRK',
    logo: 'HR',
    name: 'Kuna',
    inputSymbol: 'kn',
    id: 'HRK',
  },
  HUF: {
    symbol: 'HUF',
    logo: 'HU',
    name: 'Forint',
    inputSymbol: 'Ft',
    id: 'HUF',
  },
  ILS: {
    symbol: 'ILS',
    logo: 'IL',
    name: 'Israeli Shekel',
    inputSymbol: '₪',
    id: 'ILS',
  },
  INR: {
    symbol: 'INR',
    logo: 'IN',
    name: 'Indian rupee',
    inputSymbol: '₹',
    id: 'INR',
  },
  ISK: {
    symbol: 'ISK',
    logo: 'IS',
    name: 'Iceland Krona',
    inputSymbol: 'kr',
    id: 'ISK',
  },
  JMD: {
    symbol: 'JMD',
    logo: 'JM',
    name: 'Jamaican Dollar',
    inputSymbol: 'J$',
    id: 'JMD',
  },
  JPY: {
    symbol: 'JPY',
    logo: 'JP',
    name: 'Japanese Yen',
    inputSymbol: '¥',
    id: 'JPY',
  },
  KES: {
    symbol: 'KES',
    logo: 'KE',
    name: 'Kenyan Shilling',
    inputSymbol: 'KSh',
    id: 'KES',
  },
  KGS: {
    symbol: 'KGS',
    logo: 'KG',
    name: 'Som',
    inputSymbol: 'с',
    id: 'KGS',
  },
  KMF: {
    symbol: 'KMF',
    logo: 'KM',
    name: 'Comoro Franc',
    inputSymbol: 'CF',
    id: 'KMF',
  },
  KRW: {
    symbol: 'KRW',
    logo: 'KR',
    name: 'South Korean Won',
    inputSymbol: '₩',
    id: 'KRW',
  },
  KZT: {
    symbol: 'KZT',
    logo: 'KZ',
    name: 'Tenge',
    inputSymbol: '₸',
    id: 'KZT',
  },
  MDL: {
    symbol: 'MDL',
    logo: 'MD',
    name: 'Moldovan Leu',
    inputSymbol: 'L',
    id: 'MDL',
  },
  MGA: {
    symbol: 'MGA',
    logo: 'MG',
    name: 'Malagasy Ariary',
    inputSymbol: 'Ar',
    id: 'MGA',
  },
  MRU: {
    symbol: 'MRU',
    logo: 'MR',
    name: 'Ouguiya',
    inputSymbol: 'UM',
    id: 'MRU',
  },
  MWK: {
    symbol: 'MWK',
    logo: 'MW',
    name: 'Kwacha',
    inputSymbol: 'MK',
    id: 'MWK',
  },

  MYR: {
    symbol: 'MYR',
    logo: 'MY',
    name: 'Malaysian Ringgit',
    inputSymbol: 'RM',
    id: 'MYR',
  },
  MZN: {
    symbol: 'MZN',
    logo: 'MZ',
    name: 'Mozambique Metical',
    inputSymbol: 'MT',
    id: 'MZN',
  },
  NOK: {
    symbol: 'NOK',
    logo: 'NO',
    name: 'Norwegian Krone',
    inputSymbol: 'kr',
    id: 'NOK',
  },
  NZD: {
    symbol: 'NZD',
    logo: 'NZ',
    name: 'New Zealand dollar',
    inputSymbol: '$',
    id: 'NZD',
  },
  OMR: {
    symbol: 'OMR',
    logo: 'OM',
    name: 'Rial Omani',
    inputSymbol: 'ر.ع.',
    id: 'OMR',
  },
  PEN: {
    symbol: 'PEN',
    logo: 'PE',
    name: 'Peruvian Sol',
    inputSymbol: 'S/',
    id: 'PEN',
  },
  PGK: {
    symbol: 'PGK',
    logo: 'PG',
    name: 'Kina',
    inputSymbol: 'K',
    id: 'PGK',
  },
  PHP: {
    symbol: 'PHP',
    logo: 'PH',
    name: 'Philippine Peso',
    inputSymbol: '₱',
    id: 'PHP',
  },
  PLN: {
    symbol: 'PLN',
    logo: 'PL',
    name: 'Polish Zloty',
    inputSymbol: 'zł',
    id: 'PLN',
  },
  PYG: {
    symbol: 'PYG',
    logo: 'PY',
    name: 'Paraguayan Guarani',
    inputSymbol: '₲',
    id: 'PYG',
  },
  RON: {
    symbol: 'RON',
    logo: 'RO',
    name: 'Romanian Leu',
    inputSymbol: 'lei',
    id: 'RON',
  },
  RWF: {
    symbol: 'RWF',
    logo: 'RW',
    name: 'Rwanda Franc',
    inputSymbol: 'FRw',
    id: 'RWF',
  },
  SBD: {
    symbol: 'SBD',
    logo: 'SB',
    name: 'Solomon Islands Dollar',
    inputSymbol: '$',
    id: 'SBD',
  },
  SCR: {
    symbol: 'SCR',
    logo: 'SC',
    name: 'Seychelles Rupee',
    inputSymbol: '₨',
    id: 'SCR',
  },
  SEK: {
    symbol: 'SEK',
    logo: 'SE',
    name: 'Swedish krona',
    inputSymbol: 'kr',
    id: 'SEK',
  },

  SRD: {
    symbol: 'SRD',
    logo: 'SR',
    name: 'Surinam Dollar',
    inputSymbol: '$',
    id: 'SRD',
  },
  STN: {
    symbol: 'STN',
    logo: 'ST',
    name: 'Dobra',
    inputSymbol: 'Db',
    id: 'STN',
  },
  SZL: {
    symbol: 'SZL',
    logo: 'SZ',
    name: 'Lilangeni',
    inputSymbol: 'L',
    id: 'SZL',
  },
  THB: {
    symbol: 'THB',
    logo: 'TH',
    name: 'Thai Baht',
    inputSymbol: '฿',
    id: 'THB',
  },
  TJS: {
    symbol: 'TJS',
    logo: 'TJ',
    name: 'Somoni',
    inputSymbol: 'ЅМ',
    id: 'TJS',
  },
  TMT: {
    symbol: 'TMT',
    logo: 'TM',
    name: 'Turkmenistan New Manat',
    inputSymbol: 'T',
    id: 'TMT',
  },
  TOP: {
    symbol: 'TOP',
    logo: 'TO',
    name: 'Pa’anga',
    inputSymbol: 'T$',
    id: 'TOP',
  },
  TRY: {
    symbol: 'TRY',
    logo: 'TR',
    name: 'Turkish Lira',
    inputSymbol: '₺',
    id: 'TRY',
  },
  TZS: {
    symbol: 'TZS',
    logo: 'TZ',
    name: 'Tanzanian Shilling',
    inputSymbol: 'TSh',
    id: 'TZS',
  },

  UYU: {
    symbol: 'UYU',
    logo: 'UY',
    name: 'Peso Uruguayo',
    inputSymbol: '$U',
    id: 'UYU',
  },
  VND: {
    symbol: 'VND',
    logo: 'VN',
    name: 'Dong',
    inputSymbol: '₫',
    id: 'VND',
  },
  XAF: {
    symbol: 'XAF',
    logo: 'CM',
    name: 'CFA Franc BEAC',
    inputSymbol: 'FCFA',
    id: 'XAF',
  },
  XCD: {
    symbol: 'XCD',
    logo: 'DM',
    name: 'East Caribbean Dollar',
    inputSymbol: '$',
    id: 'XCD',
  },
  ZAR: {
    symbol: 'ZAR',
    logo: 'ZA',
    name: 'South African Rand',
    inputSymbol: 'R',
    id: 'ZAR',
  },
} as const satisfies Record<string, IFiat>);

/**
 * @deprecated ONLY FOR CONVENIENCE, a list of tokens indexed by known symbols on each chain.
 * This should only be used to get the token's address in order to fetch its info from the API.
 * As much as possible The token's info should be fetched from the API by using the id, not from this list.
 * Remember that symbols are not unique.
 */
export const WELL_KNOWN_TOKENS_IDS: Record<Chain, Record<string, ChainAddress>> = Object.freeze({
  avax: {
    AVAX: 'avax:0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
    FRAX: 'avax:0xd24c2ad096400b6fbcd2ad8b24e7acbc21a1da64',
  },
  bsc: {
    BNB: 'bsc:0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
    BUSD: 'bsc:0xe9e7cea3dedca5984780bafc599bd69add087d56',
    BTCB: 'bsc:0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
    jCHF: 'bsc:0x7c869b5a294b1314e985283d01c702b62224a05f',
    jEUR: 'bsc:0x23b8683ff98f9e4781552dfe6f12aa32814924e8',
    jGBP: 'bsc:0x048e9b1ddf9ebbb224812372280e94ccac443f9e',
    USDC: 'bsc:0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
    USDT: 'bsc:0x55d398326f99059ff775485246999027b3197955',
    WETH: 'bsc:0x2170ed0880ac9a755fd29b2688956bd959f933f8',
  },
  eth: {
    ETH: 'eth:0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
    EUROe: 'eth:0x820802fa8a99901f52e39acd21177b0be6ee2974',
    EURS: 'eth:0xdb25f211ab05b1c97d595516f45794528a807ad8',
    EURT: 'eth:0xc581b735a1688071a1746c968e0798d642ede491',
    FRAX: 'eth:0x853d955acef822db058eb8505911ed77f175b99e',
    jCHF: 'eth:0x53dfea0a8cc2a2a2e425e1c174bc162999723ea0',
    jEUR: 'eth:0x0f17bc9a994b87b5225cfb6a2cd4d667adb4f20b',
    jGBP: 'eth:0x7409856cae628f5d578b285b45669b36e7005283',
    LUSD: 'eth:0x5f98805a4e8be255a32880fdec7f6728c6568ba0',
    USDC: 'eth:0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    USDT: 'eth:0xdac17f958d2ee523a2206206994597c13d831ec7',
    WBTC: 'eth:0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
    XCHF: 'eth:0xb4272071ecadd69d933adcd19ca99fe80664fc08',
  },
  poly: {
    agEUR: 'poly:0xe0b52e49357fd4daf2c15e02058dce6bc0057db4',
    EUROe: 'poly:0x820802fa8a99901f52e39acd21177b0be6ee2974',
    EURS: 'poly:0xe111178a87a3bff0c8d18decba5798827539ae99',
    FRAX: 'poly:0x45c32fa6df82ead1e2ef74d17b76547eddfaff89',
    USDC: 'poly:0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
    USDT: 'poly:0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
    WETH: 'poly:0x7ceb23fd6bc0add59e62ac25578270cff1b9f619',
    MATIC: 'poly:0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
  },
  opti: {
    ETH: 'opti:0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
    LUSD: 'opti:0xc40f949f8a4e094d1b49a23ea9241d289b7b2819',
    USDC: 'opti:0x7f5c764cbc14f9669b88837ca1490cca17c31607',
  },
  arbi: {
    ETH: 'arbi:0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
    EUROE: 'arbi:0xcf985aba4647a432e60efceeb8054bbd64244305',
    USDC: 'arbi:0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
  },
  celo: {},
  ftm: {},
  base: {},
});

export const NATIVE_TOKENS: Partial<Record<Chain, ChainAddress>> = Object.freeze({
  avax: WELL_KNOWN_TOKENS_IDS.avax.AVAX,
  bsc: WELL_KNOWN_TOKENS_IDS.bsc.BNB,
  eth: WELL_KNOWN_TOKENS_IDS.eth.ETH,
  poly: WELL_KNOWN_TOKENS_IDS.poly.MATIC,
  opti: WELL_KNOWN_TOKENS_IDS.opti.ETH,
  arbi: WELL_KNOWN_TOKENS_IDS.arbi.ETH,
});

export const DEFAULT_TOKEN_ID = NATIVE_TOKENS[DEFAULT_CHAIN];

export function getDefaultTokenForChain(currentChain: Chain | nil): ChainAddress {
  if (currentChain) {
    return NATIVE_TOKENS[currentChain] as ChainAddress;
  }
  return DEFAULT_TOKEN_ID as ChainAddress;
}

export const getVariationTrend = (variation: number) => {
  if (variation === 0) {
    return { textColor: 'text-font-disabled', Icon: TrendFlatIcon, chartColor: ChartColors.GREY };
  }
  if (variation < 0) {
    return { textColor: 'text-danger', Icon: TrendDownIcon, chartColor: ChartColors.RED };
  }
  return { textColor: 'text-success', Icon: TrendUpIcon, chartColor: ChartColors.GREEN };
};

export const simplifyWebsiteUrl = (url: string) => {
  if (!url) return '';

  const urlObj = new URL(url);
  const hostnameParts = urlObj.hostname.split('.');
  return hostnameParts.slice(-2).join('.');
};
