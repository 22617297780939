import cls from 'classnames';
import { ReactElement, createElement, isValidElement } from 'react';

import { ArrowLeftIcon } from '@ui-kit/Icons';
import { Button, IButton } from '@ui-kit/atoms/Button';

export type IconButton = ReactElement<Pick<IButton, 'Icon' | 'onClick'>>;

export type SideContentHeaderProps = {
  onGoBack?: VoidFunction;
  title: string;
  subtitle?: string;
  rightIcons?: [IconButton] | [IconButton, IconButton];
  alignment?: 'center' | 'left';
};

export function SideContentHeader({
  onGoBack,
  title,
  subtitle,
  rightIcons,
  alignment = 'center',
}: SideContentHeaderProps) {
  return (
    <div className="flex items-center relative gap-4 w-full">
      {onGoBack ? (
        <Button Icon={ArrowLeftIcon} variant="surface" onClick={onGoBack} size="s" />
      ) : (
        <div className="w-10" />
      )}
      <div className="flex flex-1" />
      <div
        className={cls('flex flex-col absolute inset-x-14 flex-1 select-none', {
          'items-center': alignment === 'center',
          'items-start': alignment === 'left',
        })}
      >
        <span className="text-2xl leading-7 font-medium text-white font-youth-medium">{title}</span>
        <span className="text-base font-normal text-font-variant">{subtitle}</span>
      </div>
      <div className="flex gap-3 z-20">
        {rightIcons?.map(iconButton =>
          iconButton.type === Button && isValidElement(iconButton)
            ? createElement(Button, {
              Icon: iconButton.props.Icon,
              onClick: iconButton.props.onClick,
              variant: 'surface',
              size: 'm',
              key: iconButton.key,
            })
            : null,
        )}
      </div>
    </div>
  );
}
