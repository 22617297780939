import { gql } from '@apollo/client';
import { type CompiledVaultOperation } from '@nested-finance/sdk/web'; // ⚡ MUST REMAIN "export type"
import dedent from 'dedent-js';
import indent from 'indent-string';

import { Chain, FetchMainVaultAddress } from '@gql';
import {
  chainOf,
  groupBy,
  Loadable,
  Loader,
  makeVaultId,
  notNil,
  parseNum,
  sumBn,
  unreachable,
  withoutChain,
} from '@utils';
import { networksByChain } from '@utils/networks';
import { useWallet } from '@wallet-hooks';

import type { RawDeposit } from './deposit';
// eslint-disable-next-line import/no-cycle
import { OtherConfig, ToastConfig, useSendVaultTransaction, UseSendVaultTx } from './send'; // this is NOT a cycle
import { BridgeOperation } from './socket-bridge';
import type { PreparedMultiSwap, PreparedSwap } from './swaps';
import { RawDca } from './useDca';
import { RawCancelLimitOrder, RawLimitOrder } from './useLimitOrder';
import { StopLossOrder } from './useStopLoss';
import { RawWithdraw } from './withdraw';
import { ADDRESS_NATIVE } from '../constants';

// ⚡ MUST REMAIN "import type"
export type { CompiledVaultOperation } from '@nested-finance/sdk/web';

export interface RawOperation {
  type: 'raw';
  script: string;
}

export type VaultOperation =
  | PreparedSwap
  | PreparedMultiSwap
  | BridgeOperation
  | RawOperation
  | RawDeposit
  | RawWithdraw
  | RawLimitOrder
  | RawCancelLimitOrder
  | RawDca
  | StopLossOrder;

type Ops = VaultOperation | readonly VaultOperation[];

export interface OperationOptions {
  /**
   * If false, then all swaps budget will be taken from the vault's balance.
   *
   * If true, then all swaps budget will be taken from:
   * - Wallet balance, if operation is compiled against a main vault
   * - Mainvault balance, if operation is compiled against a subvault
   *
   * (nb: This will just emit additional deposit statements)
   *
   * ⚠ WARNING: When true, all swaps must have an input budget. Not a fixed sell amount.
   *   (coz' we need to know exactly how much to deposit)
   */
  depositSwapBudgets?: boolean;
}

interface Script {
  // operations to execute
  readonly script: readonly string[];
  // required budgets
  readonly budgets: readonly [HexString, bigint][];
}

/** Just a helper that aggretages operations in a single script */
function useScript(operations: readonly Loader<Ops>[] | Loader<readonly Ops[]>): Loader<Script> {
  let _operations: Loader<readonly Ops[]>;
  if (Array.isArray(operations)) {
    _operations = Loader.array(operations);
  } else {
    _operations = operations as Loader<readonly Ops[]>;
  }
  return _operations.map(async _ops => {
    const ops = _ops.flatMap(x => x);
    return {
      // compute the actual operations
      script: ops.map(x => x.script),
      // compute deposits required for swaps
      budgets: collectBudgets(ops),
    } satisfies Script;
  });
}

function collectBudgets(ops: readonly VaultOperation[]): [HexString, bigint][] {
  const byToken = groupBy(
    notNil(ops.map(x => collectBudget(x))),
    x => x[0],
    x => x[1],
  );
  return [...byToken.entries()] //
    .map(([token, amounts]) => [token, sumBn(amounts)]);
}

function collectBudget(op: VaultOperation): [HexString, bigint] | nil {
  switch (op.type) {
    case 'swap':
      return [op.rawResponse.sellTokenAddress, parseNum(op.rawResponse.sellAmount)];
    case 'deposit':
    case 'withdraw':
    case 'raw':
    case 'socketBridge':
    case 'limitorder':
    case 'cancelOrderHash':
    case 'dca':
    case 'stopLoss':
      return null;
    default:
      unreachable(op);
      return null;
  }
}

function erc20Swapdeposits(budgets: readonly [HexString, bigint][], toSubvault?: HexString): string[] {
  return budgets //
    .filter(([token]) => token !== ADDRESS_NATIVE)
    .map(([token, amount]) => {
      if (toSubvault) {
        if (token === ADDRESS_NATIVE) {
          throw new Error('Cannot deposit native token to subvault');
        }
        return `vault.depositToChild(${toSubvault},${amount}u ${token});`;
      }
      if (token !== ADDRESS_NATIVE) {
        return `vault.deposit(${amount}u ${token});`;
      }
      return `vault.depositNative(${amount}u ${token});`;
    });
}

type CreateOrCallVaultResult = UseSendVaultTx & {
  /**
   * Compilation result... when this loader is ready, that means that you're ready to send the tx !
   *  (.sendTransaction() wont do anything until this loader is OK)
   */
  compilation: Loader<CompiledVaultOperation>;
};

export function useCreateOrCallVault(
  chain: Loadable<Chain | nil>,
  operations: Loader<readonly Ops[]>,
  config?: ToastConfig & OtherConfig,
  refresh?: any,
): CreateOrCallVaultResult;
export function useCreateOrCallVault(
  chain: Loadable<Chain | nil>,
  operations: Loader<Ops>[],
  config?: ToastConfig & OtherConfig,
  refresh?: any,
): CreateOrCallVaultResult;
export function useCreateOrCallVault(
  chain: Loadable<Chain | nil>,
  operations: readonly Loader<Ops>[] | Loader<readonly Ops[]>,
  config?: ToastConfig & OtherConfig,
  refresh?: any,
): CreateOrCallVaultResult {
  const userAddress = useWallet(true).map(x => x.address);
  const chainLoaded = Loader.useWrap(chain).map(x => x || Loader.skipped);

  const mainvaultAddrLoader = userAddress.query<FetchMainVaultAddress>(
    gql`
      query FetchMainVaultAddress {
        mainVault {
          address
          salt
        }
      }
    `,
    () => ({}),
  );

  const vaultAddressLoader = Loader.array([chainLoaded, mainvaultAddrLoader, userAddress] as const) //
    .map(
      async ([
        c,
        {
          mainVault: { address, salt },
        },
        usr,
      ]) => {
        // fetch the vault owner directly from RPC (server might not know it exists if it missed a tx)
        const { fetchVaultOwner, computeExpectedVaultAddress } = await import('@nested-finance/sdk/web');
        const rpc = networksByChain[c].rpcUrls[0];

        // ------- Just for safety, check that the address the server compted and the one we're gonna get are the same
        const expected = await computeExpectedVaultAddress({
          creatorAddress: withoutChain(usr),
          rpcUrl: rpc,
          salt,
        });
        if (expected.toLowerCase() !== address) {
          // should not happen
          return Loader.error(`Vault address mismatch (expected ${expected}, got ${address})`);
        }

        // ------- Now check if the vault exists directly on chain
        let owner: HexString;
        try {
          owner = await fetchVaultOwner(rpc, address);
        } catch (e) {
          console.info(`Vault ${address} does not seem to exist... creating it`);
          return null;
        }
        if (!owner) {
          return null;
        }

        // ------- Check that the vault owner is the same as the user
        const usrw = withoutChain(usr);
        if (owner.toLowerCase() !== usrw) {
          // should not happen
          return Loader.error('Vault owner mismatch');
        }
        return makeVaultId(c, address);
      },
    );
  const scriptLoader = useScript(operations);

  const compilation = Loader.array([scriptLoader, userAddress, vaultAddressLoader, mainvaultAddrLoader] as const).map(
    [refresh],
    ([
      script,
      user,
      vault,
      {
        mainVault: { salt },
      },
    ]) => {
      if (vault) {
        return processCall(vault)(script);
      }
      if (!salt) {
        // should not happen
        throw new Error('Missing salt');
      }
      return processCreate(script, user, salt);
    },
  );

  const sv = useSendVaultTransaction(compilation, config);

  return {
    compilation,
    ...sv,
  };
}

async function processCreate({ script, budgets }: Script, user: ChainAddress | nil, salt: HexString) {
  if (!user) {
    return Loader.skipped;
  }
  // load lib
  const { createVault } = await import('@nested-finance/sdk/web');

  // add deposit statements
  const source = [...erc20Swapdeposits(budgets), ...script].join('\n');

  // compile tx
  const created = await createVault({
    creatorAddress: withoutChain(user),
    rpcUrl: networksByChain[chainOf(user)].rpcUrls[0],
    code: source,
    salt,
  });
  return created;
}

/**
 * Compute a transaction to be sent to call a vault,
 * which will perform a given set of operations.
 *
 * It will also return which contract should be approved, for how much,
 * and will give you read-to-send tx to do so (and another to revert those approvals)
 */
export function useCallVault(
  vault: ChainAddress,
  operations: readonly Loader<Ops>[],
  options?: OperationOptions,
): Loader<CompiledVaultOperation> {
  return useScript(operations) //
    .map([vault], processCall(vault, options));
}

function processCall(vault: ChainAddress, options?: OperationOptions) {
  return async ({ script, budgets }: Script) => {
    // load lib
    const { callVault } = await import('@nested-finance/sdk/web');

    // add deposit statements if needed
    const source = [...(!options?.depositSwapBudgets ? [] : erc20Swapdeposits(budgets)), ...script].join('\n');

    // compile tx
    const created = await callVault(
      {
        vaultAddress: withoutChain(vault),
        rpcUrl: networksByChain[chainOf(vault)].rpcUrls[0],
        excludedDexes: ['Portals'], // TODO: remove this once portals is fixed
      },
      source,
    );
    return created;
  };
}

/** Create a vault operation that will create a subvault, and execute the given operations in it  */
export function useCreateSubvaultOperation(subvaultOperations: readonly Loader<Ops>[]): Loader<VaultOperation> {
  return useScript(subvaultOperations) //
    .map(async ({ script, budgets }) => {
      // compute all deposits to the subvault that are required to run this op
      const transfers = budgets //
        .map(([token, budget]) => {
          return `nested.depositToVault(newVault, ${budget}u ${token});`;
        });

      // build a script that will:
      // 1) create a subvault
      // 2) deposit the required tokens to it
      // 3) call the subvault with the given script
      return {
        type: 'raw',
        script: dedent`
            newVault = nested.createVault();
            ${transfers.join('\n')}
            call newVault {
            ${indent(script.join('\n'), 4)}
            }
        `,
      };
    });
}

/** Create a vault operation that will call a subvault, and execute the given operations in it  */
export function useSubvaultOperation(
  subvaultLoader: Loader<ChainAddress>,
  operations: readonly Loader<Ops>[],
  options?: OperationOptions,
): Loader<VaultOperation> {
  return useScript(operations) //
    .combine(subvaultLoader, async ({ script, budgets }, subvault) => {
      // compute all deposits to the subvault that are required to run this op
      const transfers = !options?.depositSwapBudgets ? [] : erc20Swapdeposits(budgets, withoutChain(subvault));
      return {
        type: 'raw',
        script: dedent`
            ${transfers.join('\n')}
            call ${withoutChain(subvault)} {
            ${indent(script.join('\n'), 4)}
            }
        `,
      };
    });
}
