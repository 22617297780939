import { gql } from '@apollo/client';

import { TokenSummaryWithQuote } from '@gql';

export const FIND_TOKENS = gql`
  query FindTokens($chain: Chain, $preferChain: Chain, $search: String, $group: TokenGroup) {
    tokens(chain: $chain, search: $search, group: $group, preferChain: $preferChain) {
      ...ITokenSummaryWithQuote
      curves(timeframe: p1d) {
        totalPerf
      }
    }
  }
  ${TokenSummaryWithQuote}
`;

export const FIND_TRENDING_TOKENS = gql`
  query FindTrendingTokens($chain: Chain, $preferChain: Chain, $group: TokenGroup, $ids: [ChainAddress!], $limit: Int) {
    tokens(chain: $chain, group: $group, preferChain: $preferChain, limit: $limit, ids: $ids) {
      ...ITokenSummaryWithQuote
      curves(timeframe: p1d) {
        totalPerf
      }
    }
  }
  ${TokenSummaryWithQuote}
`;

// Although this is not a find per say, it is used for search results.
// So it is renamed to not be mistaken with the GetFavoriteTokens query
export const FIND_FAVORITES_TOKENS = gql`
  query FindFavoritesTokens($chains: [Chain!]) {
    tokensAggregated(chains: $chains, isFavorite: true) {
      color
      logo
      name
      symbol
      chainIds
      curves(timeframe: p1d) {
        totalPerf
      }
      aggregatedTokens {
        id
        decimals
      }
    }
  }
`;

export const SET_FAVORITE_MUTATION = gql`
  mutation SetFavorite($id: String!, $isFavorite: Boolean!) {
    setFavorite(id: $id, isFavorite: $isFavorite)
  }
`;

export const GET_TRENDING_TOKENS = gql`
  query GetTrendingTokens($timeframe: Timeframe!, $limit: Int!) {
    tokens(limit: $limit) {
      id
      aggregatedId
      symbol
      name
      logo
      quote
      decimals
      cgRanking
      curves(timeframe: $timeframe) {
        totalPerf
        graph
      }
    }
  }
`;

export const GET_TOKENS_COUNT = gql`
  query GetTokensCount {
    tokensAggregatedCount
    favoritesCount: tokensAggregatedCount(isFavorite: true)
  }
`;

export const GET_SEARCH_TOKENS = gql`
  query GetSearchTokens($search: String, $limit: Int!) {
    tokensAggregated(limit: $limit, search: $search) {
      id
      symbol
      name
      logo
      chainIds
    }
    tokensAggregatedCount(search: $search)
  }
`;

export const SEARCH_TOKEN_QUERY = gql`
  query SearchCoin($search: String, $limit: Int!, $chain: Chain, $ids: [ChainAddress!]) {
    tokens(limit: $limit, search: $search, chain: $chain, ids: $ids) {
      id
      symbol
      name
      logo
      quote
      chain
      decimals
    }
  }
`;
