import { gql } from '@apollo/client';

import { GetTokensInfo, TokenSummary, GetTokensInfoVariables, ITokenSummary } from '@gql';
import { Loadable, Loader, NATIVE_TOKENS, chainOf } from '@utils';
import { fetchApolloAsync } from '@utils/apollo-utils';

import { useWallet } from '../wallet/wallet-hooks';

const tokensInfoCache = new Map<ChainAddress, ITokenSummary>();

const TOKENS_INFO = gql`
  query GetTokensInfo($ids: [ChainAddress!]) {
    tokens(ids: $ids) {
      ...ITokenSummary
    }
  }
  ${TokenSummary}
`;

export const useTokensInfo = (tokens: Loadable<ChainAddress[]>): Loader<readonly ITokenSummary[]> => {
  const results = Loader.wrap(tokens)
    .map(_tokens => (_tokens.length === 0 ? Loader.skipped : _tokens))
    .query<GetTokensInfo, GetTokensInfoVariables>(TOKENS_INFO, _tokens => ({ ids: _tokens }))
    .map(x => x.tokens);

  results.onOk(_tokens => {
    if (_tokens) {
      _tokens.forEach(t => {
        tokensInfoCache.set(t.id, t);
      });
    }
  });

  return results;
};

export const getTokenInfo = async (token: ChainAddress | nil): Promise<ITokenSummary | nil> => {
  if (!token) {
    return null;
  }
  const { tokens } = await fetchApolloAsync<GetTokensInfo, GetTokensInfoVariables>(TOKENS_INFO, { ids: [token] });

  return tokens[0];
};

const useTokenInfoFromCache = (token: Loadable<ChainAddress | nil>): Loader<ITokenSummary | nil> => {
  return Loader.useWrap(token).map(_token => (_token ? tokensInfoCache.get(_token) || null : null));
};

export const useTokenInfo = (token: Loadable<ChainAddress | nil>, useCache = false): Loader<ITokenSummary> => {
  const token$ = Loader.useWrap(token);
  const tokenFromCache$ = useTokenInfoFromCache(token);
  const tokens$ = Loader.array([tokenFromCache$, token$] as const).map(([_tokenFromCache, _token]) => {
    if (_tokenFromCache && useCache) {
      return Loader.skipped;
    }
    return _token ? [_token] : [];
  });
  const tokenInfos$ = useTokensInfo(tokens$).map(x => x[0] || Loader.ok(null));

  return Loader.array([tokenFromCache$, tokenInfos$.mapNotLoaded('skipped', () => null)] as const).map(
    ([_tokenFromCache, _tokenInfos]) => {
      if (_tokenFromCache && useCache) {
        return _tokenFromCache;
      }
      return tokenInfos$;
    },
  );
};

/**
 * Get info of the native token of the current chain
 * @returns Loader of the native token info of the current chain
 */
export const useNativeTokenInfo = (shouldConnect = true) => {
  return useWallet(shouldConnect)
    .map(({ isAuthed, address }) => {
      if (!isAuthed) return Loader.skipped;
      return address && NATIVE_TOKENS[chainOf(address)];
    })
    .query<GetTokensInfo, GetTokensInfoVariables>(TOKENS_INFO, tokenId => ({ ids: [tokenId!] }))
    .map(x => x.tokens[0]);
};
