/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-param-reassign */
import { useApolloClient, ApolloClient } from '@apollo/client';
import { waitForTransaction, SendTransactionResult } from '@wagmi/core';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSendTransaction, useSignTypedData } from 'wagmi';

import { Periods } from '@constants';
import { Chain } from '@gql';
import { ToastDetails, useTxManagerAlerts } from '@hooks/useTxManagerAlerts';
import { Loader, chainOf, isApproved, last, mapParallel, notNil, parseNum, delay, encodeAddress } from '@utils';
import { networksByChain } from '@utils/networks';
import { TransactionState } from '@utils/uiKitUtils/statusTransactionUtils';
import { useWallet } from '@wallet-hooks';

import type { CompiledVaultOperation } from './tx';
import { ConnectedWallet } from '../wallet/_interfaces';
import { waitTransactionProcessing } from '../wallet/wait-tx';

/**
 *
 * ================================================================================================
 * ================================================================================================
 * ================================================================================================
 * ============================                                      ==============================
 * ============================                                      ==============================
 * ============================         please think twice           ==============================
 * ============================         before fuck around           ==============================
 * ============================             with this file           ==============================
 * ============================                     - Gandhi         ==============================
 * ============================                                      ==============================
 * ============================                                      ==============================
 * ============================              (thaaaaanks 😘)         ==============================
 * ============================                                      ==============================
 * ================================================================================================
 * ================================================================================================
 * ================================================================================================
 * ================================================================================================
 */

export interface SendVaultTransactionResult {
  /**
   * The number of approvals left to send.
   * Beware: This is 0 until `.compilation` loader is ready.
   */
  approvesLeft: number;

  /**
   * The global state of the operation. Will be loading when the operation starts, and ok when it's done.
   */
  operation: Loader<any>;
}

export interface ToastConfig {
  pendingToast?: ToastDetails;
  failedToast?: ToastDetails;
}

export interface OtherConfig {
  forcedChain?: Chain;
}

export interface ITxProcessor {
  useState(): SendVaultTransactionResult;
  /**
   * A function that sends one or multiple transaction (approves + operation).
   *
   * It will:
   *  - return `true` if the transaction was sent successfully
   *  - throw an error if a transaction failed
   *  - return `false | nil` if the tx was not ready to be sent (see .compilation loader)
   */
  sendNext: () => void | Promise<boolean | nil | void>;
}

export interface UseSendVaultTx {
  sendTx: Loader<ITxProcessor>;
  operation: Loader<any>;
}

export function useSendVaultTransaction(
  action: Loader<CompiledVaultOperation>,
  config?: ToastConfig & OtherConfig,
): UseSendVaultTx {
  const wallet = useWallet(true);
  const txMan = useTxManagerAlerts();

  const t = useT();
  const sendTransactionAsync = useST();
  const signedTypedDataAsync = useSign();
  const [operation, setOperation] = useState(Loader.skipped);
  const apollo = useApolloClient();

  const sendTx = Loader.array([action, wallet] as const).map(async ([x, wal]) => {
    const ret = new TxProcessor(setOperation, apollo);
    await ret.initialize(wal, x, txMan, sendTransactionAsync, signedTypedDataAsync, config, t);
    return ret;
  });

  return {
    sendTx,
    operation,
  };
}

// =======================================================================================================
// -----=============================== IMPLEMENTATION  ==================================================
// =======================================================================================================

type Trans = ReturnType<typeof useT>;
function useT() {
  const { t } = useTranslation();
  return t;
}
type DoSend = ReturnType<typeof useST>;
type DoSign = ReturnType<typeof useSign>;
function useST() {
  const { sendTransactionAsync } = useSendTransaction();
  return sendTransactionAsync;
}
function useSign() {
  const { signTypedDataAsync } = useSignTypedData();
  return signTypedDataAsync;
}

type SendTransaction = () => void | Promise<boolean | nil | void>;

let globalTxCounter = 0;

/** This should stay as a Class, to allow passing an instance of txProcessor around in props,
 *  and being able to have a sendNext that changes over time */
class TxProcessor {
  private state: SendVaultTransactionResult = {
    approvesLeft: 0,
    operation: Loader.skipped,
  };

  private ops: SendTransaction[] = [];

  private idCount = 0;

  // eslint-disable-next-line no-spaced-func, func-call-spacing
  private setStates = new Map<number, (v: SendVaultTransactionResult) => void>();

  constructor(private _setOperation: (v: Loader<any>) => void, private apollo: ApolloClient<any>) {}

  useState() {
    const ref = useMemo(() => this.idCount++, []);
    const [state, setState] = useState(this.state);

    // subscribe, then unsubscribe to notifications when component unmounts
    useEffect(() => {
      this.setStates.set(ref, setState);
      return () => {
        this.setStates.delete(ref);
      };
    }, [ref]);

    return state;
  }

  private stateChange(fn: (v: SendVaultTransactionResult) => void) {
    fn(this.state);
    this.setStates.forEach(s => s({ ...this.state }));
  }

  sendNext = async () => {
    this.setOperation(Loader.loading);
    await last(this.ops)?.();
  };

  private setOperation(v: Loader<boolean>) {
    this.stateChange(x => {
      x.operation = v;
    });
    this._setOperation(v);
  }

  async initialize(
    { address, changeNetwork, connectWalletToTargetNetwork }: ConnectedWallet,
    op: CompiledVaultOperation,
    txMan: ReturnType<typeof useTxManagerAlerts>,
    sendTransactionAsync: DoSend,
    signedTypedDataAsync: DoSign,
    config: (ToastConfig & OtherConfig) | nil,
    t: Trans,
  ) {
    if (!address || !changeNetwork || !sendTransactionAsync) {
      return;
    }
    const txId = `tx-${globalTxCounter++}`;
    // determine vault address (can be a creation or a call)
    const chain = chainOf(address);
    const vault: ChainAddress = `${chain}:${op.vaultAddress}`;
    this.state.approvesLeft = op.requiredApproves.length;

    const wrapOp =
      (fn: (waitTx: (tx: SendTransactionResult) => Promise<ChainAddress>) => Promise<void>) => async () => {
        this.setOperation(Loader.loading);
        let sentTx: HexString | undefined;
        try {
          // change network
          await changeNetwork(config?.forcedChain || chain);

          // force metamask (& friends) to connect to the right network
          await connectWalletToTargetNetwork();

          // create/update tx
          txMan.createOrUpdateTransaction({
            id: txId,
            state: TransactionState.Pending,
            // todo default title ?
          });

          // execute
          await fn(async tx => {
            sentTx = tx.hash.toLowerCase() as HexString;
            const final = await waitForTransaction({
              chainId: networksByChain[chain].chainId,
              hash: tx.hash,
              timeout: Periods.ONE_MINUTE,
            });
            txMan.createOrUpdateTransaction({
              id: txId,
              ...tx,
              hash: final.transactionHash,
              state: TransactionState.Completed,
            });
            return encodeAddress(chain, final.transactionHash);
          });
          this.ops.pop();

          // signal that the whole operation succeeded !
          if (!this.ops.length) {
            // only add these alerts for the final tx ? or should this move in pushTx ?
            txMan.postAlert({
              id: txId,
              state: TransactionState.Success,
              hash: sentTx,
            });
            // signal that the whole operation succeeded !
            this.setOperation(Loader.ok(true));
          } else {
            // send next tx if any.
            // Set it optionnal if a flow does not need a one click transaction
            await this.sendNext();
          }
        } catch (e) {
          // TODO(GOlivier/Clement) : notify Sentry ?
          txMan.postAlert({
            title: t('Transactions.ToastStatus.txFailed'),
            ...config?.failedToast,
            id: txId,
            state: TransactionState.Failed,
            hash: sentTx,
          });
          this.setOperation(Loader.error(e as Error));
          throw e;
        }
      };

    const pushOp = (fn: () => Promise<void>) => {
      this.ops.splice(0, 0, fn);
    };

    // ------------- push approvals -----------------
    const toApprove = notNil(
      await mapParallel(10, op.requiredApproves, async a => {
        // check allowance
        const amt = a.knownAmount && parseNum(a.knownAmount?.amount);
        // eslint-disable-next-line no-await-in-loop
        const isOk = await isApproved(`${chain}:${a.token}`, address, vault, amt);
        return isOk ? null : a;
      }),
    );
    for (const a of toApprove) {
      //  => must approve !

      // utility that manually waits until allowance is OK
      let done = false;
      const waitUntilApproved = async () => {
        let it = 0;
        while (!done && ++it < 50) {
          // eslint-disable-next-line no-await-in-loop
          await delay(500);
          try {
            const amt = a.knownAmount && parseNum(a.knownAmount?.amount);
            // eslint-disable-next-line no-await-in-loop
            const isOk = await isApproved(`${chain}:${a.token}`, address, vault, amt);
            if (isOk) {
              break;
            }
          } catch (e) {
            console.warn('Failed to check allowance', e);
          }
        }
      };

      // perform op
      pushOp(
        wrapOp(async waitTx => {
          const tx = await sendTransactionAsync(a.knownAmount?.performApprove ?? a.performApproveMax);
          try {
            // concurrently wait for transaction processing and for the tx to pass
            // this is because we had problems with the tx not being detected as processed sometimes
            //  => polling bypasses it.
            const approval = waitUntilApproved();
            await Promise.race([waitTx(tx), approval]);
            // be sure that the approval is OK
            await approval;
            // TODO(GOlivier) : https://linear.app/nested-finance/issue/V2N-617
            await delay(2000);
          } finally {
            // stop polling if tx failed
            done = true;
          }
          this.stateChange(x => {
            x.approvesLeft--;
          });
        }),
      );
    }
    // ------------- push operation -----------------
    if (op.tx) {
      pushOp(
        wrapOp(async waitTx => {
          if (!op.tx) {
            // eslint-disable-next-line no-debugger
            debugger;
            throw new Error('todo: Handle gasless txs');
          }

          const sentTx = await sendTransactionAsync(op.tx);
          const finalHash = await waitTx(sentTx);
          const { completed } = waitTransactionProcessing(this.apollo, finalHash);
          await completed;
        }),
      );
    }

    // ------------- push signatures -----------------
    for (const s of op.requiredSignatures) {
      pushOp(
        wrapOp(async () => {
          const signed = await signedTypedDataAsync(s.dataToSign);
          await s.execute(signed);
        }),
      );
    }
  }
}
