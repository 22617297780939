import { Reducer } from 'react';

export type MultiSellState = {
  inputTokenIds: Set<ChainAddress>;
  targetTokenId: ChainAddress | nil;
  inputTokenAmounts: Record<ChainAddress, { value: bigint; isMax?: boolean }>;
  isSelectionConfirmed: boolean;
};

export type MultiSellAction =
  | { type: 'SET_INPUT_TOKEN_IDS'; payload: Set<ChainAddress> }
  | { type: 'REMOVE_TOKEN_ID'; payload: ChainAddress }
  | { type: 'SET_TARGET_TOKEN_ID'; payload: ChainAddress }
  | { type: 'SET_INPUT_TOKEN_AMOUNT'; payload: { tokenId: ChainAddress; value: bigint; isMax?: boolean } }
  | { type: 'SET_IS_SELECTION_CONFIRMED'; payload: boolean }
  | { type: 'SET_DEFAULT'; payload: { targetTokenId: ChainAddress } }
  | { type: 'RESET_FLOW' };

export const defaultMultiSellState: MultiSellState = {
  inputTokenIds: new Set(),
  targetTokenId: null,
  inputTokenAmounts: {},
  isSelectionConfirmed: false,
};

export const multiSellReducer: Reducer<MultiSellState, MultiSellAction> = (state, action) => {
  switch (action.type) {
    case 'SET_INPUT_TOKEN_IDS': {
      const inputTokenAmounts = { ...state.inputTokenAmounts };
      Object.keys(inputTokenAmounts).forEach(tokenId => {
        if (!action.payload.has(tokenId as ChainAddress)) {
          delete inputTokenAmounts[tokenId as ChainAddress];
        }
      });
      action.payload.forEach(tokenId => {
        if (!inputTokenAmounts[tokenId]) {
          inputTokenAmounts[tokenId] = { value: 0n };
        }
      });
      return {
        ...state,
        inputTokenIds: action.payload,
        inputTokenAmounts,
      };
    }
    case 'REMOVE_TOKEN_ID': {
      const inputTokenAmounts = { ...state.inputTokenAmounts };
      delete inputTokenAmounts[action.payload];
      const inputTokenIds = new Set(state.inputTokenIds);
      inputTokenIds.delete(action.payload);
      return {
        ...state,
        inputTokenIds,
        inputTokenAmounts,
      };
    }
    case 'SET_TARGET_TOKEN_ID':
      return {
        ...state,
        targetTokenId: action.payload,
      };
    case 'SET_INPUT_TOKEN_AMOUNT':
      return {
        ...state,
        inputTokenAmounts: {
          ...state.inputTokenAmounts,
          [action.payload.tokenId]: {
            value: action.payload.value,
            isMax: action.payload.isMax,
          },
        },
      };
    case 'SET_IS_SELECTION_CONFIRMED':
      return {
        ...state,
        isSelectionConfirmed: action.payload,
      };
    case 'SET_DEFAULT':
      return {
        ...defaultMultiSellState,
        targetTokenId: action.payload.targetTokenId,
        isSelectionConfirmed: false,
      };
    case 'RESET_FLOW':
      return {
        ...defaultMultiSellState,
        targetTokenId: state.targetTokenId,
      };
    default:
      return state;
  }
};
