import { SideContentProps } from '@components/SideContent/SideContent.types';
import {
  GetTransaction,
  GetTransactionVariables,
  GetTransaction_transaction as Transaction,
  getAllLimitOrders_limitOrders_results as LimitOrder,
} from '@gql';
import { Loader } from '@utils';

import { LimitOrderDetailsUI } from './LimitOrderDetailsUI';
import { TransactionDetailsUI } from './TransactionDetailsUI';
import { GET_TRANSACTION_DETAILS } from './TransactionManager.queries';
import { useIncrement } from '../../hooks/useIncrement';

export type TransactionDetailsProps = SideContentProps & { id: Transaction['id'] };

export function TransactionDetailsContainer({ setResult, id }: TransactionDetailsProps) {
  const [retry, triggerRetry] = useIncrement();

  const transaction$ = Loader.query<GetTransaction, GetTransactionVariables>(GET_TRANSACTION_DETAILS, {
    variables: { hash: id },
    refetchWhenChanges: [retry],
  }).map(result => result.transaction);

  return (
    <TransactionDetailsUI
      transaction={transaction$}
      onGoBack={() => setResult(undefined)}
      onRetry={() => triggerRetry()}
    />
  );
}

export function LimitOrderDetailsContainer({ setResult, limitOrder }: SideContentProps & { limitOrder: LimitOrder }) {
  return <LimitOrderDetailsUI limitOrder={limitOrder} onGoBack={() => setResult(undefined)} />;
}
