import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { makeCenterSideContent } from '@components/SideContent';
import { SideContentProps } from '@components/SideContent/SideContent.types';
import { SideContentHeader } from '@components/layouts/side-content/SideContentHeader';
import { Button } from '@ui-kit/atoms/Button';
import { Divider } from '@ui-kit/atoms/Divider';
import { Toggle } from '@ui-kit/atoms/Toggle';
import { LabelItemList } from '@ui-kit/organisms/LabelItemList';
import { isValidEnum, notNil, enumValues } from '@utils';

export enum Features {
  // Define the features flags here
  none = 'none',
  stoploss = 'stoploss',
  dca = 'dca',
}

const FEATURES_KEY = 'ff';
/**
 *   Use this method to check if a ff is activated
 */
export function isFeatureActive(feature: Features) {
  const storedFeatures = parseFeatures(localStorage.getItem(FEATURES_KEY));
  return storedFeatures.includes(feature);
}

/**
 *   To activate a feature flag:
 *   => Set the 'ff' query param in URL with the feature flags keys to activate (separated with comas)
 *
 *   eg: https://rekt.nested.fi/portfolios?ff=feature_flag_1,feature_flag_2
 */
export function useFeatureFlags(): void {
  const [urlParams] = useSearchParams();
  const newValue = urlParams.get(FEATURES_KEY);
  useEffect(() => {
    if (urlParams.has(FEATURES_KEY)) {
      setFeatureFlags(parseFeatures(newValue));
    }
  }, [newValue, urlParams]);
}

function setFeatureFlags(flags: Features[]) {
  if (!flags.length) {
    localStorage.removeItem(FEATURES_KEY);
  } else {
    localStorage.setItem(FEATURES_KEY, flags.join(','));
  }
}

function parseFeatures(features: string | null): Features[] {
  const ret = features
    ?.split(',')
    .map(i => i.trim())
    .map(f => (isValidEnum(Features, f) ? f : null));
  return notNil(ret ?? []);
}

const featureNames: Record<Features, string> = {
  [Features.none]: null!,
  [Features.stoploss]: 'Stoploss',
  [Features.dca]: 'DCA',
};
export function openFeatureFlagsSidebar() {
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  makeCenterSideContent(DevRibbonSidebar).withConfig({ fullSize: true }).openWithResult();
}

function DevRibbonSidebar({ setResult }: SideContentProps) {
  const [activeFeatures, setActiveFeatures] = useState(() => enumValues(Features).filter(f => isFeatureActive(f)));
  const [loading, setLoading] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const toggleFeature = (f: Features) => {
    if (activeFeatures.includes(f)) {
      setActiveFeatures(activeFeatures.filter(x => x !== f));
    } else {
      setActiveFeatures([...activeFeatures, f]);
    }
    setIsDirty(true);
  };

  const saveFeatures = () => {
    if (!isDirty) {
      setResult();
      return;
    }
    setFeatureFlags(activeFeatures);
    setLoading(true);
    window.location.reload();
  };

  return (
    <div>
      <SideContentHeader title="Feature Flags" onGoBack={saveFeatures} alignment="left" />
      <div className="flex flex-col gap-6 p-2 py-4">
        <LabelItemList
          labelItems={enumValues(Features)
            .filter(f => f !== Features.none)
            .map(f => ({
              key: f,
              label: featureNames[f],
              item: <Toggle checked={activeFeatures.includes(f)} onToggle={() => toggleFeature(f)} />,
              className: '!items-center',
            }))}
          hideDividers
        />
        <Divider />
        <Button isLoading={loading} onClick={saveFeatures} variant="accent" label="Save" fullWidth />
      </div>
    </div>
  );
}
