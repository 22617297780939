import React from 'react';

import { Skeleton } from '@ui-kit/atoms/Skeleton';
import EntryGroup from '@ui-kit/organisms/EntryGroup';
import { BudgetWithQuote, ICoin, Loadable, Loader } from '@utils';
import { wrapComponent } from '@utils/uiKitUtils/wrapComponent';

import { EditSelectionEntry, EmptySelectionEntry } from './MultiBudgetInputEntry';
import PercentInputEntry, { PercentInputEntryProps } from './PercentInputEntry';
import { Percent } from '../flows/multiSwap/multiBuy/multiBuy.utils';

export type BudgetAllocation = { budget: BudgetWithQuote<ICoin>; percent: Percent; locked?: boolean };

export type MultiPercentInputEntryProps = {
  distribution: Loadable<BudgetAllocation[]>;
  onChange?: (allocation: { coin: ICoin; percent: Percent; isLocked: boolean }) => void;
  onRemove?: (token: ICoin) => void;
  holdings?: Loadable<Record<ChainAddress, bigint>>;
  hints?: Record<ChainAddress, PercentInputEntryProps['hint']>;
  onEdit?: () => void;
};

const renderPercentEntry = (
  { budget, percent, locked }: BudgetAllocation,
  onChange: MultiPercentInputEntryProps['onChange'],
  onRemove: MultiPercentInputEntryProps['onRemove'],
  hint: PercentInputEntryProps['hint'],
  availableValue = 0n,
  allLocked = false,
  holdings: bigint = 0n,
  onSelectCoin?: VoidFunction,
) => {
  return (
    <PercentInputEntry
      key={budget.token.id}
      locked={locked}
      interactive={!locked}
      coin={budget.token}
      percent={percent}
      onChange={(p: Percent) => onChange && onChange({ coin: budget.token, percent: p, isLocked: !!locked })}
      onLock={(l: boolean) => onChange && onChange({ coin: budget.token, percent, isLocked: l })}
      onDelete={() => onRemove && onRemove(budget.token)}
      maxBudget={holdings}
      hint={hint}
      maxVal={availableValue}
      disabled={allLocked}
      onSelectCoin={onSelectCoin}
    />
  );
};

const MultiPercentEntryGroup = wrapComponent(EntryGroup, ['entries']);

export default function MultiPercentInputEntry({
  distribution,
  onChange,
  onRemove,
  holdings = {},
  hints = {},
  onEdit,
}: MultiPercentInputEntryProps) {
  const holdings$ = Loader.useWrap(holdings);
  const unlockedValue$ = Loader.useWrap(distribution)
    .map(d => d.filter(b => !b.locked))
    .map(d => d.reduce((acc, b) => acc + b.percent.value, 0n));
  const unlockedTokenCount$ = Loader.useWrap(distribution).map(d => d.filter(b => !b.locked).length);
  const entries$ = Loader.array([Loader.useWrap(distribution), holdings$, unlockedValue$, unlockedTokenCount$] as const)
    .noFlickering()
    .map(([_distribution, _holdings, _unlockedValue, _unlockedTokenCount]) => {
      return _distribution.length > 0
        ? [
          <EditSelectionEntry key="header" onEdit={onEdit} tokenCount={_distribution.length} maxTokenCount={5} />,
          ..._distribution.map(budget => {
            return renderPercentEntry(
              budget,
              onChange,
              onRemove,
              hints[budget.budget.token.id],
              _unlockedValue,
              _unlockedTokenCount <= 1,
              _holdings[budget.budget.token.id],
              onEdit,
            );
          }),
        ]
        : [<EmptySelectionEntry key="empty_selection" onEdit={onEdit} />];
    });

  const noFlickeringEntries = entries$
    .noFlickering()
    .match.notOk(() => [<EmptySelectionEntry key="header" onEdit={onEdit} />])
    .ok(v => v);

  return (
    <MultiPercentEntryGroup
      entries={entries$}
      onLoad={() => (
        <Skeleton asOverlay>
          <EntryGroup entries={noFlickeringEntries} className="w-full" />
        </Skeleton>
      )}
      onError={() => null}
      onSkip={() => <EmptySelectionEntry onEdit={onEdit} />}
    />
  );
}
