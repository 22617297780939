import cls from 'classnames';
import React, { FC, ReactNode, RefObject, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { match } from 'ts-pattern';

import { ChevronUpIcon } from '@ui-kit/Icons';
import { Divider } from '@ui-kit/atoms/Divider';
import { Skeleton } from '@ui-kit/atoms/Skeleton';
import { Entry, EntryType, hasDisabledOrLoading } from '@ui-kit/organisms/Entry';
import { Cls, Elt, Loadable, Loader } from '@utils';

const NUMBER_OF_LOADING_ENTRIES = 3;

export type EntryVariant = 'spaced' | 'small-spaced' | 'grouped' | 'menu';
export type IEntryList<item> = {
  header?: ReactNode;
  footer?: ReactNode;
  contentClassName?: string;
  contentScrollable?: boolean;
  variant?: EntryVariant;
  /** List items */
  entries: Loadable<readonly (item & { subEntryList?: IEntryList<item>; isOpen?: boolean })[]>;
  /** Clicked on an entry */
  onClickEntry?: (entry: item) => void;
  /** Provide an additional entry, which will be displayed with a separator */
  additionalEntry?: item;
  /** Clicked on additional entry */
  onClickAdditionalEntry?: (entry: item) => void;
  noMaxWidth?: boolean;
  /** To display when list is empty */
  emptyElement?: Elt;
  /** To display when loader is on Error */
  errorElement?: Elt;
  /** How to compute a unique key for this item ? */
  keyProperty?: (keyof item & string) | ((item: item, i: number) => string | number);
  /** Maximum height of the list, if given the list will be scrollable */
  maxHeightScroll?: string;
} & DefaultRender<item> &
Cls;

type DefaultRender<item> = item extends EntryType ? Partial<Render<item>> : Render<item>;

type Render<item> = {
  /** Which component to use to render this item ? */
  render: FC<EntryProps<item>>;
  renderLoading?: () => JSX.Element;
};

type EntryProps<item> = item & {
  listVariant?: EntryVariant;
};

function EntryItemDefault({ listVariant, ...props }: EntryProps<EntryType>) {
  return (
    <Entry
      {...props}
      isCard={listVariant === 'spaced' || listVariant === 'small-spaced'}
      noMinHeight={listVariant === 'menu'}
    />
  );
}

function LoadingEntries() {
  return (
    <>
      {Array.from({ length: NUMBER_OF_LOADING_ENTRIES }).map((_, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <Skeleton key={i + 'loading_entry'} className="w-full h-20" />
      ))}
    </>
  );
}

/**
 * Renders a list of custom item, which will be rendered using the given function.
 *
 * Example usages:
 *
 * ```typescript
 *    <EntryList<EntryType> render={EntryItemDefault} entries={[]} />
 *    <EntryList<EntryProductType> render={EntryProduct} entries={[]} />
 *    <EntryList<EntryAccountType> keyProperty={(_, i) => i} render={EntryAccount} entries={[]} />
 *    <EntryList<IEntryOption> keyProperty={(_, i) => i} render={EntryOption} entries={[]} />
 * ```
 */
export function EntryList<item>({
  variant = 'spaced',
  header,
  footer,
  keyProperty,
  entries,
  additionalEntry,
  contentClassName,
  contentScrollable,
  className,
  render: RenderEntry = EntryItemDefault,
  renderLoading = LoadingEntries,
  onClickEntry,
  onClickAdditionalEntry,
  noMaxWidth = false,
  emptyElement,
  errorElement,
  maxHeightScroll,
}: IEntryList<item>) {
  const valueLoader = Loader.useWrap(entries);
  const { t } = useTranslation();
  const [currentRef, setCurrentRef] = useState<HTMLDivElement | null>(null);
  const [isSubEntryOpen, setIsSubEntryOpen] = useState<boolean[]>(
    valueLoader.match
      .notOk(() => [])
      .ok(items =>
        Array(items.length)
          .fill(true)
          .map((_, index) => items[index].isOpen ?? true),
      ),
  );
  const scrollCaintainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollCaintainerRef.current) setCurrentRef(scrollCaintainerRef.current);
  }, [scrollCaintainerRef]);

  const variantClasses = match(variant)
    .with('grouped', () => 'bg-surface-muted rounded-xl')
    .with('spaced', () => 'gap-4')
    .with('small-spaced', () => 'gap-2')
    .with('menu', () => cls('bg-surface-elevated rounded-xl backdrop-blur-[32px]', { 'max-w-[280px]': !noMaxWidth }))
    .exhaustive();

  const isScrollbarVisible = (r: RefObject<HTMLDivElement>['current']) => {
    if (!r || !maxHeightScroll) return false;
    return r.scrollHeight > r.clientHeight;
  };

  return (
    <div
      className={cls('flex flex-col', className, variantClasses, {
        'rounded-xl overflow-hidden': ['grouped', 'menu'].includes(variant),
      })}
    >
      {header}
      {valueLoader.match
        .loadingOrSkipped(renderLoading)
        .error(() => errorElement)
        .ok(items => {
          if (!items.length && !additionalEntry) {
            return emptyElement;
          }
          return (
            <div
              ref={scrollCaintainerRef}
              style={{ maxHeight: maxHeightScroll ? maxHeightScroll + 'px' : 'none' }}
              className={cls(
                'flex flex-col',
                contentClassName,
                variantClasses,
                (variant === 'menu' || contentScrollable) && isScrollbarVisible(currentRef)
                  ? ' overflow-y-scroll scrollbar-entries scrollbar pr-1 mr-1'
                  : '',
              )}
            >
              {items.map((entry, i) => {
                // determine a key for this entry
                let key: string | number;
                if (!keyProperty || typeof keyProperty === 'string') {
                  key = (entry as Record<string, any>)[keyProperty ?? 'id']?.toString() ?? i;
                } else {
                  key = keyProperty(entry, i) ?? i;
                }
                // render entry
                const { isFirstOfSection, subEntryList } = entry as Record<string, any>;
                const cantClick = (hasDisabledOrLoading(entry) && (entry.isLoading || entry.disabled)) || false;

                const onClick = cantClick ? undefined : onClickEntry;
                return (
                  // TODO SYLVAIN DELETE WHEN THE DOUBLE ETH ENTRY IS FIXED
                  // eslint-disable-next-line react/no-array-index-key
                  <React.Fragment key={`${key}/index:${i}`}>
                    {isFirstOfSection && <Divider className="my-2" />}
                    <div
                      onClick={() =>
                        subEntryList
                          ? setIsSubEntryOpen(prev => {
                            const list = [...prev];
                            list[i] = !list[i];
                            return list;
                          })
                          : onClick?.(entry)
                      }
                      data-cy={`Entry_${key}`}
                    >
                      <RenderEntry {...entry} listVariant={variant} />
                    </div>
                    {subEntryList && isSubEntryOpen[i] && (
                      <EntryList<item>
                        {...subEntryList}
                        footer={
                          <div
                            onClick={() =>
                              setIsSubEntryOpen(prev => {
                                const list = [...prev];
                                list[i] = false;
                                return list;
                              })
                            }
                            className="flex gap-1 text-accent items-center cursor-pointer"
                          >
                            <span className="text-sm font-normal">{t('UIKit.EntryList.collapse')}</span>
                            <ChevronUpIcon className="fill-current w-3 h-3" />
                          </div>
                        }
                        className="pl-4 border-l border-outline"
                      />
                    )}
                  </React.Fragment>
                );
              })}
              {additionalEntry && variant === 'menu' && (
                <>
                  <Divider className="my-2" />
                  <div onClick={() => onClickAdditionalEntry?.(additionalEntry)} data-cy="AdditionalEntry">
                    <RenderEntry {...additionalEntry} listVariant={variant} />
                  </div>
                </>
              )}
            </div>
          );
        })}
      {footer}
    </div>
  );
}
