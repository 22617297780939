import { gql } from '@apollo/client';

import { useWallet } from '@wallet-hooks';

import { AccountSummary } from './AccountSummary';
import { makeTopSideContent } from '../../components/SideContent';
import { GetAccSummary } from '../../gql';
import { Loader } from '../../utils';
import MyAccount from '../my-account/MyAccountContainer';
import { TransactionManagerContainer } from '../transaction-manager/TransactionManagerContainer';

export function AccountSummaryContainer() {
  const wallet$ = useWallet();
  const isAuthed$ = wallet$.map(({ isAuthed }) => isAuthed);
  const walletAddress$ = wallet$.map(x => ({ address: x.address, chain: x.walletChain }));
  const login = wallet$.makeCallback(({ login: l }) => l());

  const isAuthed = wallet$
    .noFlickering()
    .match.notOk(() => false)
    .ok(x => x.isAuthed);
  const balanceUsd$ = Loader.query<GetAccSummary>(
    gql`
      query GetAccSummary {
        myUser {
          walletValue
        }
      }
    `,
    {
      skip: !isAuthed,
      refetchWhenChanges: [isAuthed],
    },
  ).map(({ myUser }) => myUser?.walletValue ?? 0);

  const onHistoryClick = () => {
    makeTopSideContent(TransactionManagerContainer).withConfig({ fullSize: true }).open();
  };
  const onWalletClick = () => {
    makeTopSideContent(MyAccount).withConfig({ fullSize: true }).open();
  };

  return (
    <AccountSummary
      isAuth={isAuthed$}
      walletAddress={walletAddress$}
      balance={balanceUsd$}
      login={login}
      onWalletClick={onWalletClick}
      onHistoryClick={onHistoryClick}
    />
  );
}
