import cls from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { makeCenterSideContent } from '@components/SideContent';
import { useTransactionFlow } from '@transactions/hooks/useTransactionFlow';
import { ArrowRightIcon, DoubleArrowHorizontalIcon, WarningIcon } from '@ui-kit/Icons';
import { Divider } from '@ui-kit/atoms/Divider';
import { Pill } from '@ui-kit/atoms/Pill';
import { Skeleton } from '@ui-kit/atoms/Skeleton';
import { ILabelItem } from '@ui-kit/organisms/LabelItem';
import { LabelItemList } from '@ui-kit/organisms/LabelItemList';
import { Cls, ICoin, Loadable, Loader, toNumber } from '@utils';
import { formatNumber } from '@utils/numbers/NumberFormat';
import { wrapComponent } from '@utils/uiKitUtils/wrapComponent';

import { SettingsContainer } from './SettingsContainer';
import TransactionRecapUI from './ui/TransactionRecapUI';
import { TransactionFlowType, UnfairLevel } from '../types';
import { getUnfairLevelForPriceDiff } from '../utils';

export type TransactionDetailsEntries = {
  estimatedReceived: { value: bigint; target: ICoin };
  aggregator: string;
  rate: { value: number; slippage: number; source: ICoin; target: ICoin };
  unfairPrice: number;
  protocolFees: { value: number; unit?: ICoin | undefined };
  massFees: number;
  expirationDate: number | string | null;
  globalBudget?: { value: bigint; source: ICoin };
  endDate?: number | string | null;
  priceImpact?: number;
};

export type SingleTxData = Partial<TransactionDetailsEntries>;

export type MultipleTxData = { data: SingleTxData; sourceSymbol: string; targetSymbol: string }[];

export type TransactionRecapProps = {
  countdownDurationInMs?: number;
  countDownClassName?: string;
  onCountdownComplete?: VoidFunction;
  /** @deprecated for stories only */
  waitForFirstDisplay?: boolean;
  alwayDisplayCountDown?: boolean;
  resetDep?: any;
  operation?: Loadable<any>;
  noCountdown?: boolean;
} & (
  | {
    txData: Loadable<SingleTxData>;
    multipleTxs?: false;
  }
  | {
    txData: Loadable<MultipleTxData>;
    multipleTxs: true;
  }
) &
Cls;

const renderTransactionDetails: {
  [key in keyof TransactionDetailsEntries]: (value: NonNullable<TransactionDetailsEntries[key]>) => ILabelItem | null;
} = {
  estimatedReceived: ({ value, target }) => ({
    label: 'Transactions.Recap.estimatedReceived',
    item: `${formatNumber(toNumber(value, target.decimals), 'qty')} ${target.symbol}`,
  }),
  aggregator: value => ({
    label: 'Transactions.Recap.protocol',
    item: <span className="select-none">{value}</span>,
  }),
  rate: ({ value, slippage, source, target }) => ({
    label: 'Transactions.Recap.rate',
    item: (
      <div className="flex">
        <span
          className="text-accent cursor-pointer"
          onClick={async () => makeCenterSideContent(SettingsContainer).open()}
        >
          {`±${formatNumber(slippage * 100, 'percentage')}`}
        </span>
        <span className="text-font-variant">{' |'}</span>
        <span>{` 1 ${source.symbol} `}</span>
        <DoubleArrowHorizontalIcon className="inline h-4 text-accent my-auto" />
        <span>{` ${formatNumber(value, 'qty')} ${target.symbol}`}</span>
      </div>
    ),
  }),
  protocolFees: ({ value, unit }) => ({
    label: 'Transactions.Recap.protocolFees',
    item: unit ? `${formatNumber(value, 'qty')} ${unit.symbol}` : `${formatNumber(value, 'qty')}%`,
  }),
  massFees: value => ({
    label: 'Transactions.Recap.massFees',
    item:
      value > 0 ? value : <Pill className="select-none" label="Free" theme={{ variant: 'heavy', type: 'accent' }} />,
    itemTooltipContent: 'Transactions.Recap.massFeesTooltip',
  }),
  expirationDate: value => ({
    label: 'Transactions.Recap.expirationDate',
    item: value,
    itemTooltipContent: 'This date is approximate and can vary slightly.',
  }),
  unfairPrice: value =>
    getUnfairLevelForPriceDiff(value) !== UnfairLevel.LOW
      ? {
        label: 'Transactions.Recap.unfairPrice',
        item: (
          <span className="text-warning">
            <WarningIcon className="inline h-4" />
            {formatNumber(value * 100, 'percentage')}
          </span>
        ),
        itemClassName: 'text-warning',
      }
      : null,
  globalBudget: ({ value, source }) => ({
    label: 'Transactions.Recap.globalBudget',
    item: `${formatNumber(toNumber(value, source.decimals), 'qty')} ${source.symbol}`,
  }),
  endDate: date => ({
    label: 'Transactions.Recap.endDate',
    item: date,
    itemTooltipContent: 'Transactions.Recap.endDateTooltip',
  }),
  priceImpact: value => ({
    label: 'Transactions.Recap.priceImpact',
    item: value ? `${formatNumber(value, 'percentage')}` : '0%',
  }),
};

const transactionFlowTooltipName: Record<TransactionFlowType, string> = {
  [TransactionFlowType.SWAP]: 'swap',
  [TransactionFlowType.DEPOSIT]: 'deposit',
  [TransactionFlowType.WITHDRAW]: 'withdraw',
  [TransactionFlowType.LIMIT]: 'limit',
  [TransactionFlowType.BRIDGE]: 'bridge',
  [TransactionFlowType.DCA]: 'dca',
  [TransactionFlowType.CROSS_CHAIN_SWAP]: 'cross chain swap',
  [TransactionFlowType.MULTIBUY]: 'multi buy',
  [TransactionFlowType.MULTISELL]: 'multi sell',
  [TransactionFlowType.STOP_LOSS]: 'stop loss',
};

const LoadableItemList = wrapComponent(LabelItemList, ['labelItems']);

const SingleTxContent = ({ txData }: { txData: Loadable<SingleTxData> }) => {
  const { t } = useTranslation();
  const { currentFlow } = useTransactionFlow();
  const content$ = Loader.useWrap(txData).map(_txData =>
    Object.entries(_txData)
      .map(([key, value]) => {
        if (value === undefined) return null;
        const render = renderTransactionDetails[key as keyof TransactionDetailsEntries];
        return render && (render as (v: typeof value) => ILabelItem)(value);
      })
      .filter(Boolean)
      .map(item => ({
        ...item,
        label: t(item!.label as any),
        itemTooltipContent: t(item!.itemTooltipContent as any, {
          type: transactionFlowTooltipName[currentFlow || TransactionFlowType.SWAP],
        }),
      })),
  );

  const NoFlickeringContent = content$
    .noFlickering()
    .match.notOk(() => [])
    .ok(c => c);

  return (
    <LoadableItemList
      hideDividers
      labelItems={content$}
      contentClassname="!gap-6"
      onLoad={() => {
        return (
          <LabelItemList
            labelItems={NoFlickeringContent.map(e => ({
              ...e,
              itemClassName: 'filter grayscale contrast-[10%] text-font-disabled',
              labelClassName: 'filter grayscale',
            }))}
            contentClassname="!gap-6"
            hideDividers
          />
        );
      }}
      onError={() => null /* TODO: @Winangel what do we do here? */}
    />
  );
};

const MultipleTxRow = ({
  sourceSymbol,
  targetSymbol,
  unfair,
  items,
  onToggleDetails,
  showDetails,
  showDivider = true,
  className,
}: {
  sourceSymbol: string;
  targetSymbol: string;
  unfair?: ILabelItem | nil;
  items: ILabelItem[];
  onToggleDetails?: VoidFunction;
  showDetails: boolean;
  showDivider?: boolean;
} & Cls) => {
  const { t } = useTranslation();
  return (
    <div className={cls('flex flex-col gap-3', className)}>
      <div className=" px-4 flex flex-col gap-3">
        <div className="flex flex-row justify-between gap-1">
          <div className="flex flow-row align-middle gap-1">
            {sourceSymbol}
            <ArrowRightIcon className="inline text-font-disabled w-4" />
            {`${targetSymbol}`}
          </div>
          <div className="flex flex-row gap-2">
            {unfair?.item}
            <span role="button" tabIndex={0} className="text-accent cursor-pointer" onClick={onToggleDetails}>
              {!showDetails ? t('Transactions.Recap.showDetails') : t('Transactions.Recap.hideDetails')}
            </span>
          </div>
        </div>
        {showDetails ? (
          <LabelItemList labelItems={items} hideDividers contentClassname="!gap-6" />
        ) : (
          <LabelItemList labelItems={[items[0]]} hideDividers contentClassname="!gap-6" />
        )}
      </div>
      {showDivider && <Divider />}
    </div>
  );
};

const MultipleTxsContent = ({ multiTxData }: { multiTxData: Loadable<MultipleTxData> }) => {
  const { t } = useTranslation();
  const { currentFlow } = useTransactionFlow();
  const [showDetails, setShowDetails] = useState<Set<string>>(new Set());
  const content$ = Loader.useWrap(multiTxData).map(_m =>
    _m.map(({ sourceSymbol, targetSymbol, data }) => ({
      sourceSymbol,
      targetSymbol,
      unfair: data.unfairPrice ? renderTransactionDetails.unfairPrice(data.unfairPrice) : undefined,
      items: Object.entries(data)
        .map(([key, value]) => {
          if (value === undefined) return null;
          const render = renderTransactionDetails[key as keyof TransactionDetailsEntries];
          return render && (render as (v: typeof value) => ILabelItem)(value);
        })
        .filter(Boolean)
        .map(item => ({
          ...item,
          label: t(item!.label as any),
          itemTooltipContent: t(item!.itemTooltipContent as any, {
            type: transactionFlowTooltipName[currentFlow || TransactionFlowType.MULTIBUY],
          }),
        })),
    })),
  );

  const noFlickeringContent = content$
    .noFlickering()
    .match.notOk(() => [])
    .ok(c => c);

  return (
    <div className="flex flex-col gap-3">
      {content$.match
        .loadingOrSkipped(() =>
          noFlickeringContent.map((content, i) => {
            const key = `${content.sourceSymbol}_${content.targetSymbol}`;
            return (
              <MultipleTxRow
                {...content}
                showDetails={showDetails.has(key)}
                key={key}
                className="filter grayscale"
                showDivider={i < noFlickeringContent.length - 1}
              />
            );
          }),
        )
        .error(() => null)
        .ok(_content =>
          _content.map((content, i) => {
            const key = `${content.sourceSymbol}_${content.targetSymbol}`;
            return (
              <MultipleTxRow
                {...content}
                onToggleDetails={() => {
                  if (showDetails.has(key)) {
                    showDetails.delete(key);
                    setShowDetails(new Set(showDetails));
                  } else {
                    showDetails.add(key);
                    setShowDetails(new Set(showDetails));
                  }
                }}
                showDetails={showDetails.has(key)}
                showDivider={i < _content.length - 1}
                key={key}
              />
            );
          }),
        )}
    </div>
  );
};

export default function TransactionRecap({
  txData,
  countdownDurationInMs = 10000,
  onCountdownComplete,
  alwayDisplayCountDown = false,
  waitForFirstDisplay = true,
  className,
  resetDep,
  countDownClassName,
  multipleTxs = false,
  operation,
  noCountdown,
}: TransactionRecapProps) {
  const [firstDisplay, setFirstDisplay] = useState(!waitForFirstDisplay);
  const [holdCountdown, setHoldCountdown] = useState(true);
  const txData$ = Loader.useWrap(txData);
  const operation$ = Loader.useWrap(operation || Loader.skipped);

  useEffect(() => {
    setFirstDisplay(!waitForFirstDisplay);
  }, [resetDep, waitForFirstDisplay]);

  useEffect(() => {
    if (!firstDisplay) return;
    if (holdCountdown) return;
    const refresh = setInterval(() => {
      onCountdownComplete?.();
    }, countdownDurationInMs);
    return () => clearInterval(refresh);
  }, [holdCountdown, firstDisplay, countdownDurationInMs, onCountdownComplete]);

  txData$.onOk(() => {
    setFirstDisplay(true);
    setHoldCountdown(alwayDisplayCountDown);
  });

  txData$.onLoading([resetDep], () => {
    setHoldCountdown(true);
  });

  txData$.onError(() => {
    setFirstDisplay(false);
  });

  txData$.onSkipped(() => {
    setFirstDisplay(false);
  });

  operation$.onLoading(() => {
    setHoldCountdown(true);
  });

  operation$.onOk(() => {
    setHoldCountdown(false);
  });

  operation$.onSkipped(() => {
    setHoldCountdown(false);
  });

  operation$.onError(() => {
    setHoldCountdown(false);
  });

  const unfairLevel = txData$.match
    .notOk(() => 0 as const)
    .ok(_txData =>
      multipleTxs
        ? Math.max(...(_txData as MultipleTxData).map(({ data }) => getUnfairLevelForPriceDiff(data.unfairPrice || 0)))
        : getUnfairLevelForPriceDiff((_txData as SingleTxData).unfairPrice || 0),
    );

  return (
    firstDisplay && (
      <Skeleton asOverlay={txData$.isLoading} className="w-full">
        <TransactionRecapUI
          noCountdown={noCountdown}
          className={cls(className, { '!px-0': multipleTxs })}
          countdownDurationInMs={countdownDurationInMs}
          countDownClassName={countDownClassName}
          holdCountdown={holdCountdown}
          unfairLevel={unfairLevel}
          content={
            multipleTxs ? (
              <MultipleTxsContent multiTxData={txData$ as Loadable<MultipleTxData>} />
            ) : (
              <SingleTxContent txData={txData$ as Loadable<SingleTxData>} />
            )
          }
        />
      </Skeleton>
    )
  );
}
