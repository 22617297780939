/**
 * This file is used to define the environment specific values.
 * It must not import anything from the rest of the app (except "import types")
 */

export const env = ((): 'production' | 'dev' | 'staging' => {
  if (import.meta.env.VITE_ENVIRONEMENT) {
    return import.meta.env.VITE_ENVIRONEMENT as 'production' | 'staging' | 'dev';
  }
  return 'dev';
})();

export const isProd = env === 'production';
export const isStaging = env === 'staging';
export const isDev = env === 'dev';

export const ephemeralApiUrl = import.meta.env.VITE_EPHEMERAL_SERVER || 'https://api.ephemeral.nested.finance';

export const isEphemeralEnv = !!localStorage.getItem('EPHEMERAL_API');
export const isEphemeralEnvConnected = !!localStorage.getItem('ephemeralAccountName');
