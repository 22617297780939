import '@i18n/i18n';

import cls from 'classnames';
import { ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router';
import { Outlet } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import {
  BottomSideContentUI,
  CenterSideContentUI,
  TopSideContentUI,
  clearCenterSideContent,
} from '@components/SideContent';
import { Modal } from '@components/modal/Modal';
import { useMainContentScroll } from '@hooks/useMainContentScroll';
import { RefreshOnUpdate } from '@utils/RefreshOnUpdate';

import { resolveAuthStatus } from './apollo';
import { AccountSummaryContainer as AccountSummary } from './categories/account-summary/AccountSummaryContainer';
import { AlphaEligibility } from './categories/miscellaneous/AlphaEligibility';
import { NotFound } from './categories/miscellaneous/NotFound';
import { Rewards } from './categories/rewards/Rewards';
import { Transactions } from './categories/transactions/components/Transactions';
import { isDev } from './env';
import { Cls, entryPathAtom } from './utils';
import { useWallet } from './wallet';
import { useSignWeb3Modal } from './wallet/WalletProvider';

// TODO remove when wallet auth is implemented
setTimeout(() => resolveAuthStatus(), 2000);

if (import.meta.hot) {
  import.meta.hot.accept();
  import.meta.hot.dispose(() => {
    clearCenterSideContent();
  });
}

function RootLayout({ children, className }: { children: ReactNode } & Cls) {
  const { scrollMainContentProps } = useMainContentScroll();

  return (
    <div
      className={cls(
        'flex min-h-screen whitespace-pre-line justify-between w-full shrink-0 pt-4 px-4',
        'sm:gap-2 sm:flex-row sm:justify-center bg-background max-w-[1536px] h-[calc(100svh-48px)]',
        className,
      )}
      {...scrollMainContentProps}
    >
      {children}
    </div>
  );
}

export function RouteNotFound() {
  const wallet$ = useWallet();
  const isAuthed = wallet$
    .noFlickering()
    .match.notOk(() => false)
    .ok(x => x.isAuthed || false);

  return (
    <RootLayout className={cls(!isAuthed && 'p-6')}>
      {isAuthed ? <EligibleRoot isRouteNotFound /> : <NotFound />}
    </RootLayout>
  );
}

export function Root() {
  const location = useLocation();
  const wallet$ = useWallet();
  const isAuthed = wallet$
    .noFlickering()
    .match.notOk(() => false)
    .ok(x => x.isAuthed || false);
  const isAlphaEligible = isDev || isAuthed;

  useSignWeb3Modal();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  // on entry, then after a while (once all redirections have settled)
  // then store what what the entry point of our journey on nested.
  const [entrypath, setEntryPath] = useRecoilState(entryPathAtom);
  useEffect(() => {
    const now = Date.now();
    const time = entrypath.time || now;
    if (!entrypath.time || entrypath.time > now + 3000) {
      // init phase
      setEntryPath({
        time,
        path: location.pathname,
      });
    } else if (entrypath.path === location.pathname) {
      // if we go back to entry point long after init phase, forget
      // entrypoint so `useBackOrFallback` does NOT use fallback any more
      setEntryPath({
        time,
        path: null,
      });
    }
  }, [location.pathname]);

  const { scrollMainContentProps } = useMainContentScroll();
  return (
    <>
      <div className="shrink w-full" {...scrollMainContentProps} />
      <RootLayout>
        {isAlphaEligible ? <EligibleRoot /> : <AlphaEligibility />}
        <Modal />
      </RootLayout>
      <div className="shrink w-full" {...scrollMainContentProps} />
    </>
  );
}

function EligibleRoot({ isRouteNotFound }: { isRouteNotFound?: boolean }) {
  const { scrollMainContentProps } = useMainContentScroll();

  return (
    <>
      {/* // TODO(Hadrien) : Remove fully navbar file */}
      <div id="content" className="flex-auto w-full mb-[104px] lg:mb-0 h-full" {...scrollMainContentProps}>
        {isRouteNotFound ? <NotFound /> : <Outlet />}
      </div>
      <div
        className="min-w-[432px] max-w-[432px]
       flex flex-col gap-4 flex-shrink-0 relative
       bg-background-variant p-4 pt-3 rounded-t-2xl
       overflow-auto hide-scrollbars
       "
      >
        <TopSideContentUI NoScreenFallback={<AccountSummary />} className="bg-transparent !p-0" />
        <div
          id="top-bottom-side-contents"
          className="h-full w-full overflow-auto hide-scrollbars
           flex flex-col gap-4"
          {...scrollMainContentProps}
        >
          <CenterSideContentUI NoScreenFallback={<Transactions />} />
          <BottomSideContentUI className="!p-0" NoScreenFallback={<Rewards />} />
        </div>
      </div>
      <RefreshOnUpdate />
    </>
  );
}
