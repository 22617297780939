import type { ITokenSummary } from '@gql';

export enum QuoteErrorReasons {
  INSUFFICIENT_ASSET_LIQUIDITY = 'INSUFFICIENT_ASSET_LIQUIDITY',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
}

export class QuoteFailedError extends Error {
  reason: QuoteErrorReasons;

  constructor(message: QuoteErrorReasons) {
    super(message);
    this.reason = message ?? QuoteErrorReasons.UNKNOWN_ERROR;
  }
}

export enum DexAggregator {
  ZeroEx = 'ZeroEx',
  Paraswap = 'Paraswap',
  KyberSwap = 'KyberSwap',
}

export type AggregatorRequest = {
  readonly vaultAddress: ChainAddress;
  /** Token you'd like to spend */
  readonly spendToken: Pick<ITokenSummary, 'id' | 'decimals'>;
  /** Token you'd like to receive */
  readonly buyToken: Pick<ITokenSummary, 'id' | 'decimals'>;
  /**
   * Accepted slippage (ex: '0.03' means 3% slippage accepted).
   * Applicable if this order is a swap (if spent & buy token are different) - ignored otherwise.
   */
  readonly slippage: number;
} & (
  | {
    /** Spent quantity */
    readonly spendQty: bigint;
  }
  | {
    /** Bought quantity */
    readonly boughtQty: bigint;
  }
);

export interface AggregatorQuoteResponse {
  spendToken?: any;
  aggregator: DexAggregator;
  chainId: number;
  price: string;
  guaranteedPrice: string;
  to: HexString;
  data: HexString;
  value: string;
  gas?: string;
  estimatedGas?: string;
  gasPrice?: string;
  protocolFee?: string;
  minimumProtocolFee?: string;
  buyTokenAddress: HexString;
  sellTokenAddress: HexString;
  buyAmount: string;
  sellAmount: string;
  allowanceTarget: HexString;
  estimatedPriceImpact: string;
}
