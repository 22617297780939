import { Chain } from '@gql';
import { Budget, ICoin, Loadable, Loader, unreachableError, withoutChain, wrapToken } from '@utils';

import { fetchLowestQuote } from './dexes/dex-aggregator';
import { AggregatorQuoteResponse, DexAggregator, AggregatorRequest } from './dexes/dex-aggregator-types';
import { ToastConfig } from './send';
import { useCreateOrCallVault } from './tx';

export interface PreparedSwap {
  readonly type: 'swap';
  readonly rawResponse: { sellTokenAddress: AggregatorQuoteResponse['sellTokenAddress']; sellAmount: bigint };
  readonly script: string;
}

export interface PreparedMultiSwap {
  readonly type: 'swap';
  readonly rawResponse: AggregatorQuoteResponse;
  readonly script: string;
}

export type SingleSwapPayload = {
  spendTokenBudget: Budget<ICoin>;
  buyTokenAddress: ChainAddress;
  slippage: number;
};

export function useSwap(
  chain: Loadable<Chain | nil>,
  data: Loadable<SingleSwapPayload>,
  config?: ToastConfig,
  refresh?: any,
) {
  const op$ = Loader.useWrap(data).map<PreparedSwap>(d => {
    return {
      type: 'swap',
      rawResponse: {
        sellTokenAddress: withoutChain(wrapToken(d.spendTokenBudget.token.id)),
        sellAmount: d.spendTokenBudget.amtBase,
      },
      script: `dex.all(${d.spendTokenBudget.amtBase}u ${withoutChain(
        wrapToken(d.spendTokenBudget.token.id),
      )}, ${withoutChain(wrapToken(d.buyTokenAddress))}, ${d.slippage});`,
    };
  });
  return useCreateOrCallVault(chain, [op$], config, refresh);
}

export function useMultiSwap(
  requests: Loadable<readonly AggregatorRequest[]>,
  refresh?: any,
): Loader<PreparedMultiSwap[]> {
  return Loader.useWrap(requests)
    .debounce(100, true) // debounce request to prevent spamming
    .map(x => x || Loader.skipped)
    .map([refresh], _requests => Promise.all(_requests.map(computeSwap)));
}

export async function computeSwap(request: AggregatorRequest): Promise<PreparedMultiSwap> {
  const rawResponse = await fetchLowestQuote(request);

  return {
    type: 'swap',
    rawResponse,
    // generates a line like:  dex.zerox(0x1234568, 0x1234567);
    script:
      sdkFunctionName(rawResponse.aggregator) + // fn name
      `(${withoutChain(wrapToken(request.spendToken.id))}, ${rawResponse.data});`, // args
  };
}

function sdkFunctionName(aggregator: DexAggregator): string {
  switch (aggregator) {
    case DexAggregator.ZeroEx:
      return 'dex.zerox';
    case DexAggregator.Paraswap:
      return 'dex.paraswap';
    case DexAggregator.KyberSwap:
      return 'dex.kyberswap';
    default:
      throw unreachableError(aggregator);
  }
}
