import { atom, useRecoilState } from 'recoil';

const SearchStateAtom = atom<boolean>({
  key: 'SearchStateAtom',
  default: false,
});

export function useSearchState() {
  const [isSearchOpen, setIsSearchOpen] = useRecoilState(SearchStateAtom);

  return { isSearchOpen, setIsSearchOpen };
}
