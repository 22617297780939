import { GetBlockReturnType } from 'viem';

import { Loadable, Loader, TokenBudget, withoutChain, wrapToken, getPublicClient, ICoin, toNumber } from '@utils';

import { Chain } from '../gql';

const SOLVER_FEE = 0.025;

export interface RawDca {
  type: 'dca';
  script: string;
}

const oneHourInSeconds = 60 * 60;
const oneDayInSeconds = 24 * oneHourInSeconds;
const oneWeekInSeconds = 7 * oneDayInSeconds;
const oneMonthInSeconds = 30 * oneDayInSeconds;

export enum DcaTimeframes {
  Hours = 'hours',
  Days = 'days',
  Weeks = 'weeks',
  Months = 'months',
}
export const DcaTimeframesToSeconds = {
  [DcaTimeframes.Hours]: oneHourInSeconds,
  [DcaTimeframes.Days]: oneDayInSeconds,
  [DcaTimeframes.Weeks]: oneWeekInSeconds,
  [DcaTimeframes.Months]: oneMonthInSeconds,
};
export type DcaConfig = {
  input: Loadable<nil | TokenBudget>;
  output: Loadable<ICoin>;
  chain: Loader<Chain>;
  timeframe: DcaTimeframes; // unit of the frequency
  interval?: number; // number of cycle of this timeframe
  maxIntervals?: number; // number of cycle of this interval*timeframe
  refresh?: number;
};

const useBlock = (chain: Loader<Chain>, refreshBlock: number) => {
  return Loader.useWrap(chain).map([refreshBlock], _chain => getPublicClient(_chain).getBlock());
};
const usePreviousBlock = (chain: Loader<Chain>, refreshBlock: number, block: Loader<GetBlockReturnType>) => {
  return Loader.array([block, chain] as const).map([refreshBlock], ([_block, _chain]) =>
    getPublicClient(_chain).getBlock({ blockNumber: BigInt(_block?.number) - 1n }),
  );
};
const useBlockDuration = (chain: Loader<Chain>, refreshBlock: number): Loader<number> => {
  const block = useBlock(chain, refreshBlock);
  const previousBlock = usePreviousBlock(chain, refreshBlock, block);

  return Loader.array([block, previousBlock] as const).map(([b, pb]) => {
    return toNumber(b.timestamp - pb.timestamp, 0); // unix timestamp in seconds
  });
};
export function useDca({
  input,
  output,
  chain,
  timeframe,
  interval,
  maxIntervals,
  refresh = 1,
}: DcaConfig): Loader<RawDca> {
  const blockDuration = useBlockDuration(chain, refresh);
  const block = useBlock(chain, refresh);

  return Loader.array([input, output, timeframe, interval, maxIntervals, blockDuration, block] as const).map(
    ([inp, out, _timeframe, _interval, _maxIntervals, _blockDuration, _block]) => {
      if (!_interval) return Loader.error('You must select a frequency');
      if (!_maxIntervals) return Loader.error('You must select a max number of cycles');
      if (!_blockDuration) return Loader.error('Cannot estimate block duration');

      const greaterThanPrice = '0.0'; // TODO(Hadrien) : Min price not implemented yet
      const lowerThanPrice = '0.0'; // TODO(Hadrien) : Max price not implemented yet

      let blockInterval: number;
      const intervalInSeconds = DcaTimeframesToSeconds[_timeframe] * _interval;
      if (interval) {
        blockInterval = Math.floor(intervalInSeconds / _blockDuration);
      } else {
        throw new Error('You must select a frequency');
      }
      // TODO(Hadrien) : implement no expiry = 0
      const expiryBlock = _block.number + BigInt(Math.ceil((intervalInSeconds * _maxIntervals) / _blockDuration));

      if (!inp || !out) {
        return {
          type: 'dca',
          script: '',
        } satisfies RawDca;
      }

      const preparedScript = `brink.dca(${inp.amtBase}u ${withoutChain(wrapToken(inp.token.id))}, ${withoutChain(
        wrapToken(out.id),
      )}, ${greaterThanPrice}, ${lowerThanPrice}, ${SOLVER_FEE}, ${blockInterval}, ${_maxIntervals}, ${expiryBlock});`;
      console.log(preparedScript);
      return { type: 'dca', script: preparedScript } satisfies RawDca;
    },
  );
}

// TODO(Hadrien) : How to cancel a DCA ?
