import { useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import { SideContentProps } from '@components/SideContent/SideContent.types';
import {
  GetTransactions_transactions_results as Transaction,
  GetTransactionsVariables,
  GetTransactions,
  getAllLimitOrders,
  getAllLimitOrdersVariables,
} from '@gql';
import { useLimitOrdersRefresh } from '@hooks/useLimitOrdersRefresh';
import { GET_ALL_LIMIT_ORDERS } from '@queries/limit-orders.queries';
import { LimitOrderRefreshAtom } from '@recoil-atoms/limit-orders';
import { Loader, useInfiniteScrollEffect, withoutChain } from '@utils';
import { useWallet } from '@wallet-hooks';

import { LimitOrderDetailsContainer, TransactionDetailsContainer } from './TransactionDetailsContainer';
import { GET_TRANSACTIONS } from './TransactionManager.queries';
import { TransactionManagerFilters, TransactionManagerFiltersProps } from './TransactionManagerFilters';
import { TransactionManagerUI, SCROLL_ID, TransactionManagerTab } from './TransactionManagerUI';

const limit = 10;

type TransactionManager = {
  defaultFilters?: GetTransactionsVariables['filters'];
  defaultTab?: TransactionManagerTab;
};

export function TransactionManagerContainer({
  pushSubmenu,
  setResult,
  defaultFilters,
  defaultTab,
}: TransactionManager & SideContentProps) {
  const isAuthed$ = useWallet().map(w => w.isAuthed);
  const isConnected = isAuthed$.match.notOk(() => false).ok(authed => authed);
  const limitOrderRefresh = useRecoilValue(LimitOrderRefreshAtom);

  const [filters, setFilters] = useState<GetTransactionsVariables['filters']>(defaultFilters);
  const [offset, setOffset] = useState(0);
  const nextPage = () => setOffset(offset + limit);
  const resetPage = () => {
    const scrollable = document.getElementById(SCROLL_ID);
    if (scrollable) {
      scrollable.scrollTop = 0;
    }
    setOffset(0);
  };
  useEffect(() => resetPage(), [filters]);

  const { refreshLimitOrdersAllChains } = useLimitOrdersRefresh();

  useEffect(() => {
    // TODO: remove this when we have an updated limit_orders table whit filled limit orders
    if (isConnected) refreshLimitOrdersAllChains();
  }, [isConnected]);

  const transactionsQuery$ = Loader.query<GetTransactions, GetTransactionsVariables>(GET_TRANSACTIONS, {
    variables: { filters, pagination: { limit, offset } },
    skip: !isConnected,
    refetchWhenChanges: [filters, isConnected],
  }).map(it => it.transactions);

  const totalCount = transactionsQuery$
    .map(it => it.totalCount)
    .match.notOk(() => 0)
    .ok(x => x);

  const allTransactions$ = transactionsQuery$
    .noFlickering(filters)
    .debounce(100)
    .reduce<Transaction[]>([], (prev, next) => (!prev ? [...next.results] : [...prev, ...next.results]), filters);

  const txsCount = allTransactions$
    .map(it => it.length)
    .match.notOk(() => 0)
    .ok(x => x);

  const limitOrders$ = Loader.query<getAllLimitOrders, getAllLimitOrdersVariables>(GET_ALL_LIMIT_ORDERS, {
    variables: { filters: { makerAsset: filters?.inputToken } },
    skip: !isConnected,
    refetchWhenChanges: [isConnected, limitOrderRefresh],
  });

  useInfiniteScrollEffect(
    async () => {
      if (transactionsQuery$.status === 'ok' && txsCount < totalCount) {
        nextPage();
      }
    },
    [transactionsQuery$],
    '#' + SCROLL_ID,
  );

  const onFilterChange: TransactionManagerFiltersProps['onFilterChange'] = (filter, value) => {
    setFilters(fltrs => ({ ...fltrs, [filter]: value }));
  };
  const onListRetry = () => {
    setFilters(fltrs => ({ ...fltrs }));
  };

  const onSelectTransaction = Loader.array([allTransactions$, limitOrders$] as const).makeCallback(
    [],
    ([_txs, _limitOrders], txId: TxId) => {
      const foundedTX = _txs.find(it => it.id === txId);

      if (foundedTX) {
        pushSubmenu(TransactionDetailsContainer).withProps({ id: foundedTX.id }).withConfig({ fullSize: true }).open();
        return;
      }

      const foundedLimitOrder = _limitOrders?.limitOrders?.results?.find(
        it => it.id === withoutChain(txId as ChainAddress),
      );

      if (foundedLimitOrder) {
        pushSubmenu(LimitOrderDetailsContainer)
          .withProps({ limitOrder: foundedLimitOrder })
          .withConfig({ fullSize: true })
          .open();
      }
    },
  );

  return (
    <TransactionManagerUI
      isAuthed={isAuthed$}
      transactions={allTransactions$}
      onSelectTransaction={onSelectTransaction}
      onListRetry={onListRetry}
      Filters={<TransactionManagerFilters filters={filters} onFilterChange={onFilterChange} />}
      onGoBack={() => setResult(undefined)}
      moreLoading={transactionsQuery$.isLoading && !allTransactions$.isLoading}
      limitOrders={limitOrders$}
      defaultTab={defaultTab}
    />
  );
}
