import cls from 'classnames';
import { Children, ReactNode } from 'react';

import { Badge, IBadge } from '@ui-kit/atoms/Badge';

export interface IBadgedItem {
  className?: string;
  badge: IBadge | nil;
  // you can use '-bottom-[6px] -right-[6px]' for smaller components
  customPosition?: `-bottom-${number | `[${number}px]`} -${'left' | 'right'}-${number | `[${number}px]`}`;
  alignToLeft?: boolean;
  children: ReactNode;
}

/* This component misplaces the badge if put on a component which is inside a CSS grid.
In that case, put "justify-items-start items-start" on the grid. Cf stories */
export function BadgedItem({ className, badge, customPosition, alignToLeft, children }: IBadgedItem) {
  if (!badge?.content) return Children.only(children) as JSX.Element;

  const content = Number(badge.content) > 99 ? '99' : badge.content;
  return (
    <div className={cls('relative inline-flex min-h-0 justify-center items-center', className)}>
      {children}
      <Badge
        className={cls('absolute', customPosition || `-bottom-0.5 ${alignToLeft ? '-left-0.5' : '-right-0.5'}`)}
        {...badge}
        content={content}
      />
    </div>
  );
}
