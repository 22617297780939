import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { BigInputDropdown } from '@components/BigInputDropdown';
import { EntryType } from '@ui-kit/organisms/Entry';
import { Cls } from '@utils';

export type ExpireSelectProps = {
  onExpireChange: (expiration: Expiration) => void;
  expiration: Expiration;
} & Cls;

export enum Expiration {
  Never = 'Never',
  OneDay = '1 day',
  OneWeek = '1 week',
  OneMonth = '1 month',
  OneYear = '1 year',
}

const makeEntry = (value: Expiration): EntryType => {
  const label = value.charAt(0).toUpperCase() + value.slice(1);
  return {
    id: value,
    selectLabel: label,
    content: {
      top: label,
    },
  };
};

export function ExpireSelect({ className, onExpireChange, expiration }: ExpireSelectProps) {
  const { t } = useTranslation();
  useEffect(() => {
    onExpireChange(Expiration.Never);
  }, []);
  return (
    <BigInputDropdown
      alignment="bottomLeft"
      onSelectEntries={(entries: EntryType[]) => {
        const selectedEntry = entries[0];
        onExpireChange(selectedEntry.id as Expiration);
      }}
      defaultSelectedEntriesIds={[expiration]}
      entries={Object.values(Expiration).map(makeEntry)}
      indication={t('Screens.LimitBuySell.expires')}
      containerClassName={className}
      chipItemsClassName="!w-full !justify-between"
    />
  );
}
