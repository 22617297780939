import { Chain } from '@gql';
// TODO(Hadrien) : Get from backend or fix with proper contracts for all
export const supportedPairs = {
  [Chain.arbi as Chain]: [
    ['0xaf88d065e77c8cC2239327C5EDb3A432268e5831'], // USDC
    [
      '0xfc5a1a6eb076a2c7ad06ed22c90d7e710e35ad0a', // GMX
      '0x912CE59144191C1204E64559FE8253a0e49E6548', // ARB
      '0x82af49447d8a07e3bd95bd0d56f35241523fbab1', // WETH
      '0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f', // WBTC
      '0xf97f4df75117a78c1A5a0DBb814Af92458539FB4', // LINK
    ],
  ],
  [Chain.opti as Chain]: [
    ['0x7f5c764cbc14f9669b88837ca1490cca17c31607'], // USDC
    [
      '0x8700daec35af8ff88c16bdf0418774cb3d7599b4', // SNX
      '0x68f180fcce6836688e9084f035309e29bf0a2095', // WBTC
      '0x4200000000000000000000000000000000000006', // WETH
      '0x4200000000000000000000000000000000000042', // OP
      '0x350a791bfc2c21f9ed5d10980dad2e2638ffa7f6', // LINK
    ],
  ],
};
