import { useTranslation } from 'react-i18next';

import { WarningIcon } from '@ui-kit/Icons';
import { ChipGroup } from '@ui-kit/organisms/ChipGroup';
import { Entry } from '@ui-kit/organisms/Entry';
import EntryGroup from '@ui-kit/organisms/EntryGroup';
import { TokenLogo } from '@ui-kit/organisms/TokenLogo';
import { BudgetWithQuote, ICoin, Loadable, Loader, toNumber } from '@utils';
import { formatNumber } from '@utils/numbers/NumberFormat';

import { BudgetAllocation } from './MultiPercentInputEntry';

export default function MultiBudgetConfirm({
  budgetsWithUnfair,
  onEditSelection,
}: {
  budgetsWithUnfair: Loadable<(BudgetWithQuote<ICoin> & { isUnfair?: boolean })[]>;
  onEditSelection?: VoidFunction;
}) {
  const { t } = useTranslation();
  return Loader.useWrap(budgetsWithUnfair)
    .noFlickering()
    .match.notOk(() => null)
    .ok(_budgets => (
      <EntryGroup
        entries={[
          <Entry key="header" className="!h-fit !min-h-0">
            <div className="flex justify-between text-sm">
              <span className="text-font-variant">{`${_budgets.length}/5 tokens`}</span>
              <span role="button" tabIndex={0} className="text-accent" onClick={onEditSelection}>
                {t('Transactions.MultiSwap.backToSelection')}
              </span>
            </div>
          </Entry>,
          <Entry key="summary" isCard disabled>
            <ChipGroup
              items={_budgets.map(b => ({
                id: b.token.id as string,
                itemProps: {
                  leadingItem: { Icon: <TokenLogo token={b.token} withBadge={false} /> },
                  label: `${formatNumber(toNumber(b.amtBase, b.token.decimals), 'price')} ${b.token.symbol}`,
                  trailingItem: b.isUnfair ? { Icon: <WarningIcon className="text-warning" /> } : undefined,
                },
                type: 'chip',
                key: b.token.id as string,
              }))}
            />
          </Entry>,
        ]}
      />
    ));
}

export function MultiPercentConfirm({
  allocationsWithUnfair,
  onEditSelection,
}: {
  allocationsWithUnfair: Loadable<(BudgetAllocation & { isUnfair?: boolean })[]>;
  onEditSelection?: VoidFunction;
}) {
  const { t } = useTranslation();
  return Loader.useWrap(allocationsWithUnfair)
    .noFlickering()
    .match.notOk(() => null)
    .ok(_allocations => (
      <EntryGroup
        entries={[
          <Entry key="header" className="!h-fit !min-h-0">
            <div className="flex justify-between text-sm">
              <span className="text-font-disabled">{`${_allocations.length}/5 tokens`}</span>
              <span role="button" tabIndex={0} className="text-accent" onClick={onEditSelection}>
                {t('Transactions.MultiSwap.backToSelection')}
              </span>
            </div>
          </Entry>,
          <Entry key="summary" isCard disabled>
            <ChipGroup
              items={_allocations.map(a => ({
                id: a.budget.token.id as string,
                itemProps: {
                  leadingItem: { Icon: <TokenLogo token={a.budget.token} withBadge={false} /> },
                  label: `${formatNumber(toNumber(a.percent.value, a.percent.precision), 'percentage')} ${
                    a.budget.token.symbol
                  }`,
                  trailingItem: a.isUnfair ? { Icon: <WarningIcon className="text-warning" /> } : undefined,
                },
                type: 'chip',
                key: a.budget.token.id as string,
              }))}
            />
          </Entry>,
        ]}
      />
    ));
}
