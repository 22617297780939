import cls from 'classnames';
import { cloneElement } from 'react';
import { match, P } from 'ts-pattern';

import { Cls } from '@utils';

export type BadgeContent = string | JSX.Element;

export type BadgeStyle = 'outline' | 'info' | 'success' | 'warning' | 'error' | 'default' | 'accent';

export type BadgeVariant = 'soft' | 'heavy';

export type IBadge = {
  content: BadgeContent;
  style?: BadgeStyle;
  variant?: BadgeVariant;
};

export function Badge({ content, style = 'info', variant = 'heavy', className }: IBadge & Cls) {
  const isString = typeof content === 'string';
  return (
    <span
      className={cls(
        'flex items-center justify-center text-sm min-h-[1rem] min-w-[1rem] py-0.5 w-fit rounded-full overflow-hidden',
        isString ? 'px-1 !min-w-[16px] !max-h-[16px]' : 'px-0 w-4 h-4',
        match([style, variant])
          .with(
            ['outline', P.any],
            () => 'text-font outline outline-1 outline-surface outline-offset-[-1px] bg-transparent',
          )
          .with(['info', 'soft'], () => 'text-info bg-surface-info')
          .with(['info', 'heavy'], () => 'text-font bg-info')
          .with(['success', 'soft'], () => 'text-success bg-surface-success')
          .with(['success', 'heavy'], () => 'text-font bg-success')
          .with(['warning', 'soft'], () => 'text-warning bg-surface-warning')
          .with(['warning', 'heavy'], () => 'text-font bg-warning')
          .with(['error', 'soft'], () => 'text-danger bg-surface-danger')
          .with(['error', 'heavy'], () => 'text-font bg-danger')
          .with(['default', 'soft'], () => 'text-font-variant bg-surface-muted')
          .with(['default', 'heavy'], () => 'text-font-muted bg-surface-invert')
          .with(['accent', 'soft'], () => 'text-accent bg-surface-accent')
          .with(['accent', 'heavy'], () => 'text-font-on-accent bg-accent')
          .exhaustive(),
        className,
      )}
    >
      {isString ? content : cloneElement(content, { className: 'w-4 h-4' })}
    </span>
  );
}
