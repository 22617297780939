export function getSupportingLabels(max: number, count: number, minValue: 0 | 1) {
  const interval = (max - minValue) / (count - 1);
  const result = [];

  for (let i = 1; i < count; i++) {
    result.push(minValue + interval * i);
  }

  return [1, ...result];
}
