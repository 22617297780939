import { Budget, ICoin, Loader, isCoin, isNativeToken, withoutChain, wrapToken, Loadable } from '@utils';

export interface RawWithdraw {
  type: 'withdraw';
  script: string;
  token: ICoin;
  isNative: boolean;
  amount: bigint;
}

export function useWithdraw(budget: Loadable<Budget>): Loader<RawWithdraw> {
  return Loader.useWrap(budget).map(x => {
    if (!x.token) {
      return Loader.error('No token selected');
    }
    if (!isCoin(x.token)) {
      return Loader.error('Cannot withdraw FIAT');
    }
    const isNative = isNativeToken(x.token);
    const qty = x.amtBase.toString(10);
    const tokenAddress = withoutChain(wrapToken(x.token.id));

    return {
      type: 'withdraw',
      // readeable version:
      //     withdraw(5 usdc);
      // actually generates things like:
      //     withdraw(3989348934893u 0x2791bca1f2de4661ed88a30c99a7a9449aa84174);
      script: `vault.withdraw(${qty}u ${tokenAddress});`,
      isNative,
      amount: x.amtBase,
      token: x.token,
    } satisfies RawWithdraw;
  });
}
