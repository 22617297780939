import { usePopperTooltip } from 'react-popper-tooltip';

import { Children } from './utils';

export function SmallError({ children }: Children) {
  const {
    getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible,
  } = usePopperTooltip();

  return (
    <span className="cursor-pointer" ref={setTriggerRef}>
      💥
      {visible && (
        <div ref={setTooltipRef} {...getTooltipProps({ className: 'tooltip-container' })}>
          {children}
          <div {...getArrowProps({ className: 'tooltip-arrow' })} />
        </div>
      )}
    </span>
  );
}
